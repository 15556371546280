
(function () {
    'use strict';

    var Cache = function () {
        var items = {};

        this.get = function (key) {
            return items[key] || null;
        };
        this.set = function (key, value) {
            items[key] = value;
        };
        this.getAllKeys = function () {
            return Object.keys(items);
        };
        this.setCache = function (data) {
            items = data;
        };

        this.clear = function () {
            items = {};
        };
        this.getCache = function () {
            return items;
        };
    };

    var publicAPI = {
        create: function () {
            return new Cache();
        }
    };

    window.cylindo.addModule('cylindo.core.cache', publicAPI);
}).call(this);