(function () {
    'use strict';
    var ViewAbstract = cylindo.getModule('cylindo.classes.abstract.view');
    var CarouselViewer = cylindo.getModule('carouselViewer').getConstructor();
    var StackedViewer = function (options) {
        options.preventRender = true;
        CarouselViewer.call(this, options);
        var self = this;
        var util = cylindo.getModule('cylindo.core.util');
        var dom = options.dom;
        var scrollHelper = cylindo.getModule('cylindo.helpers.scroll');
        var easingHelper = cylindo.getModule('cylindo.helpers.easing');
        var tOut = null;
        var tOutValue = null;
        var isScrolling = false;
        var isTouchEvent = false;
        var hideZoomBtn = options.hideZoomBtn;
        var hideFullScreenBtn = options.hideFullScreenBtn;


        this.viewerPresentation = 'stacked';
        this.dom = dom;
        this.enable = enable.bind(this);
        this.disable = disable.bind(this);
        this.addMouseCallbacks = addMouseCallbacks;
        this.removeMouseCallbacks = removeMouseCallbacks;
        this.defaultTooltip = 'tooltipStackedText';
        this.elementsGroup = [];
        this.hideZoomBtn = hideZoomBtn;
        this.hideFullScreenBtn = hideFullScreenBtn;
        this.isCustomViewer = false;
        this.fullScreenEnabled = false;

        this.render();

        function addMouseCallbacks() {
            self.wrapper.addEventListener('touchstart', touchStart, false);
            self.wrapper.addEventListener('mousedown', mouseDown, false);
        }
        function removeMouseCallbacks() {
            self.wrapper.removeEventListener('touchstart', touchStart, false);
            self.wrapper.removeEventListener('mousedown', mouseDown, false);
            removeDocumentEvents();
        }
        function addDocumentEvents() {
            document.addEventListener('touchend', touchEnd, false);
            document.addEventListener('touchmove', touchMove, false);
            document.addEventListener('mouseup', mouseUp, false);
            document.addEventListener('mousemove', mouseMove, false);
        }
        function removeDocumentEvents() {
            document.removeEventListener('mouseup', mouseUp, false);
            document.removeEventListener('mousemove', mouseMove, false);
            document.removeEventListener('touchend', touchEnd, false);
            document.removeEventListener('touchmove', touchMove, false);
        }

        function enable() {
            if (self.isFullScreen()) {
                self.carouselViewer.enable.call(self);
            }
            else {
                self.addMouseCallbacks.call(self);
                ViewAbstract.prototype.enable.call(self, true);
            }
        }
        function disable() {
            if (self.isFullScreen()) {
                self.carouselViewer.disable.call(self);
            }
            else {
                self.enabled = false;
            }
        }
        function touchStart(evt) {
            if (isEventOnVideo(evt)) {
                return;
            }
            isTouchEvent = true;
            scrollHelper.preventScrollX(self.wrapper.parentNode);
            self.setMobileCoords(evt);
            mouseDown(evt);
        }
        function touchEnd(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            if (tOut) {
                window.clearTimeout(tOut);
                tOut = null;
            }
            self.setMobileCoords(evt);
            mouseUp(evt);
            isScrolling = false;
            isTouchEvent = false;
        }
        function touchMove(evt) {
            var yDiff, xDiff;

            self.setMobileCoords(evt);

            yDiff = Math.abs(evt.clientY - self.mouseClickPos.startY);
            xDiff = Math.abs(evt.clientX - self.mouseClickPos.startX);

            if (!self.isSignificantMove(evt)) {
                return false;
            }

            window.clearTimeout(tOut);
            tOut = null;

            if ((isScrolling || !self.wasDragging) && yDiff > xDiff) {
                isScrolling = true;
                scrollHelper.scroll(evt);
                return false;
            }
            if (!isScrolling) {
                mouseMove(evt);
            }
        }
        function mouseDown(evt) {
            if (isEventOnVideo(evt)) {
                return;
            }
            if (evt) {
                if (evt.target && evt.target.classList &&
                    (evt.target.classList.contains('fullscreen-button') ||
                        evt.target.classList.contains('threesixty-button'))) {
                    return;
                }
                if (!self.isRightClick(evt)) {
                    addDocumentEvents();
                    self.mouseState = 1;
                    self.mouseClickPos.startX = self.mouseClickPos.currentX = evt.clientX;
                    self.mouseClickPos.startY = self.mouseClickPos.currentY = evt.clientY;
                    self.mouseClickPos.startTime = evt.timeStamp;
                    self.currentEventTime = evt.timeStamp;
                }
                if (!evt.defaultPrevented && !isTouchEvent) {
                    evt.preventDefault();
                }
            }
        }
        function mouseMove(evt) {
            return false;
        }
        function mouseUp(evt) {
            var frame;
            var btnZoom, btnZoom2;
            var isFullScreenBtn = false;
            var target = evt && evt.target ? evt.target : null;
            self.isDragging = false;
            if (self.mouseState == 1 && evt && !self.isRightClick(evt)) {
                self.mouseState = 0;
                self.deltaX = 0;

                setTimeout(function () {
                    removeDocumentEvents();
                }, 0);
                if (!self.wasDragging &&
                    !isScrolling &&
                    evt.type === 'mouseup' &&
                    !isTouchEvent) {
                    if (isEventOnVideo(evt)) {
                        return;
                    }
                    frame = target.tagName === 'IMG' ?
                        target.parentElement :
                        target.tagName === 'LI' ?
                            target : null;
                    btnZoom = frame ?
                        frame.querySelector('.cylindo-action-button-group .threesixty-button') :
                        null;
                    btnZoom2 = frame ?
                        frame.querySelector('.cylindo-action-button-group .threesixty-button-secondary') :
                        null;
                    self.mouseClickPos.startX = self.mouseClickPos.currentX = evt.clientX;
                    self.mouseClickPos.startY = self.mouseClickPos.currentY = evt.clientY;
                    self.handleBtnClasses(isFullScreenBtn);
                    self.trigger(self.events.CLICKED, self.util.object.extend({
                        frame: frame || null,
                        btnZoom: btnZoom || null,
                        btnZoom2: btnZoom2 || null,
                    }, self.mouseClickPos));
                    return;
                }
                if (self.wasDragging) {
                    moveCarousel();
                }
                self.wasDragging = false;
                self.ul360.classList.remove('cylindo-dragging');
            }
        }
        function isEventOnVideo(evt) {
            var target = evt && evt.target ? evt.target : null;
            if ((target && target.tagName === 'VIDEO') ||
                (target &&
                    target.tagName === 'LI' &&
                    target.classList &&
                    target.classList.contains('cylindo-video'))) {
                return true;
            }
            return false;
        }
    };
    StackedViewer.prototype = Object.create(CarouselViewer.prototype);
    StackedViewer.prototype.constructor = StackedViewer;
    StackedViewer.prototype.events = {
        CLICKED: 'stacked:clicked',
        READY: 'stacked:ready',
        DOWNLOAD_COMPLETE: 'stacked:download:complete',
        CURRENT: 'stacked:current',
        IMG_LOADED: 'stacked:img:loaded',
        FEATURES_CHANGED: 'stacked:features:changed',
        FIRST_FRAME_COMPLETE: 'stacked:firstframe:complete',
        FRAME_ACTIVATED: 'stacked:frame:activated',
        ALT_CONTENT_REMOVED: 'stacked:alt:content:removed',
        ALT_CONTENT_LOADED: 'stacked:alt:content:loaded',
        CONTENT_TYPE_COMPLETE: 'stacked:content:type:complete',
        TOGGLE_ZOOM: 'stacked:toggle:zoom',
        FEATURES_CANCELED: 'stacked:features:canceled',
        FULLSCREEN_BTN_ADDED: 'stacked:fullscreen:btn:added',
        FULLSCREEN_BTN_REMOVED: 'stacked:fullscreen:btn:removed',
        FEATURES_ERROR: 'stacked:features:error'
    };

    StackedViewer.prototype.render = function () {
        ViewAbstract.prototype.render.call(this);
    };
    StackedViewer.prototype.goToIndex = function (index) {
        if (this.framesHandler.is360Frame(this.currentIndex)) {
            this.prevIndex = this.currentIndex;
        }
        this.tryToRemoveCustomImage();
        ViewAbstract.prototype.goToIndex.call(this, index);
        this.trigger(this.events.CURRENT, { current: index });
    };
    StackedViewer.prototype.refresh = function (evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var allListElements;
        var currentElement = this.ul360 ? this.ul360.querySelector('.active') : null;
        var currentActiveIndex;
        var refRequestID;
        var penImagesLen = this.pendingImages.length;
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;

        this.viewerUpdatedStartTime = Date.now();
        refRequestID = data && data.refRequestID ?
            data.refRequestID : this.model.getRequestID();
        this.requestID = refRequestID;
        for (var i = 0; i < penImagesLen; i++) {
            if (refRequestID !== this.pendingImages[i].requestID &&
                this.currentIndex !== this.pendingImages[i].index) {
                this.pendingImages[i].prepareDefer.resolve();
                this.pendingImages[i].img.onload = null;
                this.pendingImages[i].img.onerror = null;
                this.pendingImages[i].img.src = '';
                this.pendingImages.splice(i, 1);
                i--;
                penImagesLen--;
            }
        }
        if (this.framesHandler.isAltId(this.currentIndex)) {
            this.goToIndex(this.prevIndex);
        }
        if (this.isComplete === false) {
            this.trigger(this.events.FEATURES_CANCELED, {
                features: this.model.getCanceledFeatures()
            });
        }
        this.firstFrameIsReady = false;
        this.isReady = false;
        this.isComplete = false;
        this.promises = [];
        this.frameCount = frameCount;
        this.currentFeatures = this.model.get('currentFeatures');
        this.url = this.currentFeatures.paths;
        allListElements = this.dom.querySelectorAll(this.ul360, 'li:not([class*=alt]):not(.active-sibling)');
        allListElements.forEach(function (element) {
            element.classList.add('is-rendering');
        });
        if (currentElement) {
            currentActiveIndex = this.framesHandler.isAltId(this.currentIndex) ?
                this.prevIndex : this.currentIndex;
            this.prepare(currentActiveIndex, refRequestID);
            this.promises = this.framesHandler.is360Frame(currentActiveIndex) ? this.promises : [];
        }

        Promise.all(this.promises).then(function () {
            this.refreshRest(refRequestID);
        }.bind(this));
    };
    StackedViewer.prototype.refreshRest = function (requestID) {
        this.promises = [];
        this.downloadRest(requestID);
        Promise.all(this.promises).then(this.onFeaturesUpdated.bind(this));
    };
    StackedViewer.prototype.updateView = function (evt, data) {
    };
    StackedViewer.prototype.onFeaturesUpdated = function () {
        if (!this.model.get('hasUnsupportedFeatures')) {
            if (this.noFeatureTooltip) {
                this.noFeatureTooltip.hide();
            }
        }
        this.trigger(this.events.FEATURES_CHANGED);
    };
    StackedViewer.prototype.downloadRest = function (requestID) {
        var self = this;
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var oPreviousDeferred = null;
        var list = allFrames.VIEWER_ORDER.LIST;
        var shouldDownloadAll360Frames = this.framesHandler.shouldDownloadAll360Frames();
        var listLen = shouldDownloadAll360Frames ?
            allFrames.THREESIXTY.TO_BE_LOADED :
            allFrames.VIEWER_ORDER.LEN;
        var index;
        var i = 0;
        while (i < listLen && this.model.getRequestID() === requestID) {
            index = shouldDownloadAll360Frames ? i + 1 : list[i];
            if (index !== parseInt(this.currentIndex, 10) &&
                this.framesHandler.is360Frame(index)) {
                tryToPrepare(index, requestID);
            }
            i++;
        }
        function tryToPrepare(index, requestID) {
            if (oPreviousDeferred === null) {
                oPreviousDeferred = self.prepare(index, requestID);
            }
            else {
                oPreviousDeferred = oPreviousDeferred.then(function () {
                    return self.prepare(index, requestID);
                });
            }
        }
    };
    StackedViewer.prototype.prepareLi = function (index) {
        var baseClassName = 'cylindo-viewer-frame-';
        var className = baseClassName + index;
        var li = document.createElement('li');
        var liSelection = this.dom.querySelectorAll(this.ul360, '.' + className);
        li.setAttribute('aria-hidden', false);
        if (liSelection && liSelection.length === 0) {
            li.classList.add(className);
            li.classList.add('hidden');
            li.classList.add('is-rendering');
            try {
                li.dataset.index = index;
            }
            catch (ex) {
                li.setAttribute('data-index', index);
            }
            this.ul360.appendChild(li);
        }
        else {
            liSelection = liSelection[liSelection.length - 1];
            if (liSelection && !liSelection.classList.contains('is-rendering')) {
                liSelection.classList.add('is-rendering');
            }
        }
    };
    StackedViewer.prototype.prepare = function (index, requestID) {
        var self = this;
        var frameDeferred = this.util.promise.create();
        var deferred = this.util.promise.create();
        var baseClassName, className, li, img, totalFrames, imgTimeout, liSelection, integerIndex;
        if (this.framesHandler.is360Frame(index)) {
            baseClassName = 'cylindo-viewer-frame-';
            className = baseClassName + index;
            li = document.createElement('li');
            li.setAttribute('aria-hidden', false);
            img = new Image();
            totalFrames = this.frameCount;
            imgTimeout = this.model.get('imageTimeout');
            this.prepareLi(index);

            liSelection = this.dom.querySelectorAll(this.ul360, '.' + className);
            if (liSelection && liSelection.length >= 1) {
                li = liSelection[liSelection.length - 1];
            }
            if (this.elementsGroup.length > 0) {
                this.tryToRemoveElements.call(this, index);
            }

            if (this.url) {
                var rtvOptions = {
                    requestID: requestID,
                    viewer: this,
                    urls: this.url,
                    baseClassName: baseClassName,
                    index: index,
                    li: li,
                    totalFrames: totalFrames,
                    imgTimeout: imgTimeout,
                    onImageLoad: this.onImageLoad,
                    onError: this.onError,
                    prepareDefer: deferred
                };

                frameDeferred = this.renderer.useStrategy(this.renderer.strategies.RTV_SINGLE_MERGED, rtvOptions);
            }
            else {
                img.src = '';
            }

            this.promises.push(deferred);

            if (index === this.currentIndex && !li.classList.contains('active')) {
                this.handleActiveFrame(li, true);
            }
        }
        else if (this.framesHandler.isAltId(index)) {
            integerIndex = parseInt(index.replace(this.framesHandler.prefixes.ALT, ''), 10);
            frameDeferred = this.prepareAltContent(integerIndex);
        }
        frameDeferred.then(function () {
            self.firstFrameEvt(index);
            deferred.resolve();
        });

        return deferred;
    };

    StackedViewer.prototype.reloadAltContent = function (evt, data) {
        var self = this;
        var altContent = this.dom.querySelectorAll(this.ul360, '[class*="-alt-"]');
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var altContentLen = allFrames.ALTERNATE.LEN;
        var index, i;
        if (altContent && altContent.length > 0) {
            altContent.forEach(function (element) {
                index = element.dataset ? element.dataset.index : element.getAttribute('data-index');
                self.tryToRemoveElements(index);
            });
        }
        ViewAbstract.prototype.reloadAltContent.call(this, evt, data);
        for (i = 0; i < altContentLen; i++) {
            this.prepareAltContent(i, evt, data);
        }
    };
    StackedViewer.prototype.onReady = function () {
        ViewAbstract.prototype.onReady.call(this);
        ViewAbstract.prototype.sendFirstFrameCompleteEvt.call(this);
    };
    StackedViewer.prototype.onDownloadComplete = function () {
        ViewAbstract.prototype.onDownloadComplete.call(this);
    };
    StackedViewer.prototype.createViewerList = function () {
        ViewAbstract.prototype.createViewerList.call(this);
        this.wrapper.parentNode.classList.add('is-stacked');
        if (this.util.browser.isIE()) {
            this.wrapper.parentNode.classList.add('is-ie');
        }
        this.appendCarouselBtns();
    };
    StackedViewer.prototype.onImageLoad = function () {
        var li = this.li;
        var clonedLi = li.cloneNode();
        var img = this.img;
        var layers = this.layers;
        var that = this.self;
        var deferred = this.deferred;
        var imagesArrLen = img.length;
        var altText = 'Frame ' + this.index + ' of "__productName__" ';
        var pendingImageIndex = -1;
        var i;

        for (i = 0; i < that.pendingImages.length; i++) {
            if (that.pendingImages[i].index === this.index) {
                pendingImageIndex = i;
            }
        }
        if (pendingImageIndex !== -1) {
            that.pendingImages.splice(pendingImageIndex, 1);
        }
        if (this.requestID !== that.model.getRequestID()) {
            return;
        }

        if (this.index === that.currentIndex ||
            (!that.framesHandler.is360Frame(this.currentIndex) && this.index === that.startFrame)) {
            that.firstImageLoadedTime = Date.now();
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        altText = altText.replace('__productName__', that.model.get('fileName'));

        if (img.length > 1) {
            for (i = 0; i < imagesArrLen; i++) {
                img[i].alt = altText + '(' + layers[i].type + ' layer - ' + layers[i].code + ')';
                clonedLi.appendChild(img[i]);
            }
        }
        else {
            if (img instanceof Image) {
                img.alt = altText;
                clonedLi.appendChild(img);
            } else {
                img[0].alt = altText;
                clonedLi.appendChild(img[0]);
            }
        }
        clonedLi.style.position = 'absolute';
        clonedLi.style.top = that.dom.offset(li).top + 'px';
        try {
            li.insertAdjacentElement('afterend', clonedLi);
        }
        catch (ex) {
            that.dom.insertAfter(clonedLi, li);
        }
        li.style.display = 'none';
        clonedLi.style.position = '';
        clonedLi.style.top = '';
        that.dom.remove(li);
        clonedLi.classList.remove('is-rendering');
        that.tryToCreateElements.call(that, this.index, clonedLi);
        that.tryToEnableImageGroup();
        that.trigger(that.events.IMG_LOADED, { sequence: this.index, img: img });
        deferred.resolve();
    };
    StackedViewer.prototype.onAltContentLoad = function () {
        if (this.viewer.isCustomViewer) {
            this.viewer.tryToEnableImageGroup();
            this.li.classList.remove('is-rendering');
            this.viewer.trigger(this.viewer.events.ALT_CONTENT_LOADED, { sequence: this.viewer.framesHandler.prefixes.ALT + this.index, viewerId: this.viewer.id });
        }
        else {
            ViewAbstract.prototype.onAltContentLoad.call(this);
        }
        this.viewer.tryToCreateElements.call(this.viewer, this.viewer.framesHandler.prefixes.ALT + this.index, this.li);
    };

    StackedViewer.prototype.tryToEnableImageGroup = function () {
        var self = this;
        var allFrames = self.framesHandler.getAllFramesToBeLoaded();
        var totalFrames = allFrames.THREESIXTY.TO_BE_LOADED;
        var addedFrames;
        var active;
        if (this.isCustomViewer) {
            totalFrames = allFrames.ALTERNATE.LEN;
            addedFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-alt"]');
            active = this.ul360.querySelector('.active');
            if (!active) {
                this.firstImageLoadedTime = Date.now();
                this.handleActiveFrame(addedFrames[0], true);
            }
        }
        else {
            addedFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-"]:not([class*="-alt-"])');
            addedFrames = addedFrames.filter(function (element) {
                var className = element.className.match(/cylindo-viewer-frame-(\d+)/ig)[0];
                var occurrences = self.dom.querySelectorAll(self.ul360, '.' + className);
                return element.childNodes.length !== 0 &&
                    addedFrames.indexOf(element) === addedFrames.indexOf(occurrences[occurrences.length - 1]);
            });
        }
        if ((!this.isVisible && addedFrames.length > 0)) {
            this.displayViewer();
        }
        if (!this.isReady && addedFrames.length > 0) {
            this.onReady();
        }
        if (!this.isComplete && addedFrames.length >= totalFrames) {
            this.onDownloadComplete();
            if (this.isCustomStacked) {
                this.trigger(this.events.CONTENT_TYPE_COMPLETE, {
                    contentType: this.contentTypes.ALTERNATE
                });
            }
            addedFrames.forEach(function (el) {
                el.classList.remove('hidden');
            });
        }
    };
    StackedViewer.prototype.getThumbsDetails = function () {
        var thumbsDetails = {
            'ts': 0,
            'ac': 0
        };
        return thumbsDetails;
    };
    StackedViewer.prototype.tryToRemoveCustomImage = function () {
        var className = 'cylindo-custom-image';
        var customElement = this.ul360.querySelector('.' + className);
        try {
            this.dom.remove(customElement);
            this.customImageOn = false;
            this.activateCurrentIndex();
        }
        catch (ex) {
            this.logger.error('Cannot remove custome image', ex);
        }
        this.showCarouselBtns();
    };
    StackedViewer.prototype.showImage = function (url, large) {
        var self = this;
        var config = cylindo.getModule('cylindo.core.config');
        var className = 'cylindo-custom-image';
        var li = document.createElement('li');
        var img = new Image();
        var bindOpts = {
            ul360: this.ul360,
            img: img,
            li: li,
            instance: this
        };
        var src;
        li.setAttribute('aria-hidden', false);
        li.classList.add(className);
        li.appendChild(img);
        try {
            li.dataset.zoomImage = large || url;
            li.dataset.url = url;
        }
        catch (ex) {
            li.setAttribute('data-zoom-image', (large || url));
            li.setAttribute('data-url', url);
        }
        this.tryToRemoveCustomImage();
        img.onload = onCustomImageLoaded.bind(bindOpts);
        img.onerror = onError.bind(bindOpts);
        src = url;
        img.src = src;
        this.customImageOn = true;
        function onCustomImageLoaded() {
            var active;
            var isCustomImage = true;
            this.img.onload = null;
            this.ul360.appendChild(this.li);
            active = this.ul360.querySelector('.active');
            if (active) {
                this.instance.handleActiveFrame(active, false);
            }
            this.instance.handleActiveFrame(this.li, true);
            this.instance.tryToCreateElements('cylindo-custom-image', this.li);
            this.instance.handleActionBtns(this.li, '', isCustomImage);
            this.instance.handleZoomButton(this.li, '', '');

        }
        function onError() {
            var fallbackImg = this.instance.model.get('fallbackImage');
            this.img.onerror = onFallbackImageFail.bind(this);
            this.img.src = fallbackImg;
        }
        function onFallbackImageFail() {
            var fallbackImg = this.instance.model.get('defaultFallbackImage');
            this.img.onerror = null;
            this.img.src = fallbackImg;
        }

        return li;
    };
    StackedViewer.prototype.hideImage = function () {
        if (this.customImageOn) {
            var childrenArrLen = this.ul360.childNodes.length;
            var i, li, url;
            var className = 'cylindo-custom-image';
            var elementToRemove;
            for (i = 0; i < childrenArrLen; i++) {
                li = this.ul360.childNodes[i];
                if (li.classList.contains('cylindo-custom-image')) {
                    url = li.dataset ? li.dataset.url : li.getAttribute('data-url');
                    this.tryToRemoveElements('cylindo-custom-image', this.li);
                    try {
                        elementToRemove = this.ul360.querySelector('.' + className);
                        this.dom.remove(elementToRemove);
                    }
                    catch (ex) { }
                }
            }
            this.customImageOn = false;
            this.activateCurrentIndex();
        }
    };
    StackedViewer.prototype.activateCurrentIndex = function () {
        var currentActive = this.ul360.querySelector('li.active');
        var baseClassName = 'cylindo-viewer-frame-';
        var newActive = this.ul360.querySelector('.' + baseClassName + this.currentIndex);
        if (currentActive) {
            this.handleActiveFrame(currentActive, false);
        }
        if (newActive) {
            this.handleActiveFrame(newActive, true);
        }
    };
    StackedViewer.prototype.isRightClick = function (evt) {
        return ViewAbstract.prototype.isRightClick.call(this, evt);
    };
    StackedViewer.prototype.tryToCreateElements = function (index, li) {
        var tooltip = null;
        var tooltipText;
        var createButtons = false;
        var btnResult = {};
        var element = {};
        if (index) {
            if (this.framesHandler.is360Frame(index)) {
                if (li.classList && !li.classList.contains('cylindo-img-not-found')) {
                    createButtons = true;
                }
                tooltipText = this.shouldCreateTooltip(index) ?
                    this.getTooltipText() : '';
            }
            else if (this.framesHandler.isAltId(index) &&
                li.classList && !li.classList.contains('cylindo-video')) {
                if (!li.classList.contains('cylindo-img-not-found')) {
                    createButtons = true;
                }
                tooltipText = this.shouldCreateTooltip(index) ?
                    this.model.get('tooltipAltImgText') : '';
            }
            else if (li.classList &&
                li.classList.contains('cylindo-custom-image')) {
                if (li.classList && !li.classList.contains('cylindo-img-not-found')) {
                    createButtons = true;
                }
                tooltipText = this.model.get('tooltipAltImgText') ?
                    this.model.get('tooltipAltImgText') : '';
            }
            if (typeof tooltipText === 'string' && tooltipText.length > 0) {
                tooltip = cylindo.getModule('tooltip.viewer').create({
                    parent: li,
                    text: tooltipText,
                    dom: this.dom
                });
                tooltip.render();
                tooltip.el.classList.add('cylindo-dismiss-on-zoom');
            }
            if (createButtons) {
                btnResult = this.createBtnsGroup(index, li);
            }
            this.util.object.extend(element, { index: index.toString(), tooltip: tooltip }, btnResult);
            this.elementsGroup.push(element);
        }
    };
    StackedViewer.prototype.shouldCreateTooltip = function (index) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var position = this.model.get('tooltipStackedPosition');
        var altContent, altContentLen, viewerOrder, viewerOrderLen, i;
        var first, last;
        var baseIndex;

        if (position === 1) {
            return true;
        }
        else {
            if (this.framesHandler.is360Frame(index)) {
                first = allFrames.THREESIXTY.FIRST;
                last = allFrames.THREESIXTY.LAST;
                baseIndex = parseInt(index, 10);
            }
            else if (this.framesHandler.isAltId(index)) {
                altContent = allFrames.ALTERNATE.LIST;
                altContentLen = allFrames.ALTERNATE.LEN;
                for (i = 0; i < altContentLen; i++) {
                    if (typeof altContent[i].provider === 'undefined') {
                        first = i;
                        break;
                    }
                }
                for (i = altContentLen - 1; i >= 0; i--) {
                    if (typeof altContent[i].provider === 'undefined') {
                        last = i;
                        break;
                    }
                }
                baseIndex = parseInt(index.replace(this.framesHandler.prefixes.ALT, ''), 10);
            }
            if ((position === 2 && baseIndex === first) ||
                (position === 3 && baseIndex === last)) {
                return true;
            }
            else {
                return false;
            }
        }
    };
    StackedViewer.prototype.createBtnsGroup = function (index, li) {
        var self = this;
        var btnFullScreen = document.createElement('a');
        var btnZoom = document.createElement('a');
        var btnZoom2 = document.createElement('a');
        var btnGroup = document.createElement('div');
        var activeImg = li.childNodes[0];
        var imgURL = activeImg.src;
        var zoomURL = li.dataset ? li.dataset.zoomImage : li.getAttribute('data-zoom-image');
        var liRect = li ? li.getBoundingClientRect() : null;
        var alternateContentZoom = self.model.get('alternateContentZoom');
        var zoom = self.model.get('zoom');
        if (zoomURL && zoomURL.indexOf('data:image') === -1) {
            zoomURL = '//' + (zoomURL.split('//')[1]);
        }
        if (zoomURL && imgURL && zoomURL === imgURL.replace(/^http(s)*:/i, '')) {
            if (activeImg &&
                (activeImg.naturalHeight <= liRect.height || activeImg.naturalWidth <= liRect.width)) {
                btnZoom.classList.add('temporarily-hidden');
            }
        }
        if (self.framesHandler.isAltId(index) || index === 'cylindo-custom-image') {
            if (!alternateContentZoom) {
                btnZoom.classList.add('temporarily-hidden');
            }
        }
        else if (!zoom && this.framesHandler.is360Frame(index)) {
            btnZoom.classList.add('temporarily-hidden');
        }
        btnGroup.classList.add('cylindo-action-button-group', 'right', 'cylindo-dismiss-on-zoom');
        btnFullScreen.classList.add('fullscreen-button', 'cylindo-action-button-icon', 'cylindo-icon-fullscreen-off');
        btnZoom.classList.add('threesixty-button', 'cylindo-action-button-icon', 'cylindo-icon-zoom-off');
        btnZoom2.classList.add('threesixty-button-secondary', 'cylindo-action-button-icon', 'cylindo-icon-zoom-off');
        btnGroup.setAttribute('role', 'group');
        btnGroup.setAttribute('aria-label', this.ariaLabel.texts.ACTION_BUTTONS_GROUP);
        btnFullScreen.setAttribute('role', 'link');
        btnFullScreen.setAttribute('aria-label', this.ariaLabel.texts.ENTER_FULLSCREEN);
        btnZoom.setAttribute('role', 'link');
        btnZoom.setAttribute('aria-label', this.ariaLabel.texts.ENTER_ZOOM);
        btnZoom2.setAttribute('role', 'link');
        btnZoom2.setAttribute('aria-label', this.ariaLabel.texts.EXIT_ZOOM);
        li.appendChild(btnGroup);
        btnGroup.appendChild(btnFullScreen);
        btnGroup.appendChild(btnZoom);
        btnZoom2.style.display = 'none';
        if (!self.framesHandler.is360Frame(index)) {
            btnZoom2.classList.add('permanently-hidden');
        }
        btnGroup.appendChild(btnZoom2);
        this.trigger(this.events.FULLSCREEN_BTN_ADDED, {
            btnFullScreen: btnFullScreen
        });
        btnZoom.addEventListener('mousedown', stopPropagation, true);
        btnZoom.addEventListener('mouseup', toggleZoom, true);
        btnZoom.addEventListener('touchstart', stopPropagation, true);
        btnZoom.addEventListener('touchend', toggleZoom, true);
        try {
            btnZoom.dataset.index = index;
            btnFullScreen.dataset.index = index;
        }
        catch (ex) {
            btnZoom.setAttribute('data-index', index);
            btnFullScreen.setAttribute('data-index', index);
        }
        if (this.hideFullScreenBtn) {
            btnFullScreen.classList.add('permanently-hidden');
        }
        if (this.hideZoomBtn) {
            btnZoom.classList.add('permanently-hidden');
        }
        if (this.hideZoomBtn && this.hideFullScreenBtn) {
            btnGroup.classList.add('permanently-hidden');
        }
        function toggleZoom(evt) {
            var isFullScreenBtn = false;
            stopPropagation();
            self.handleBtnClasses(isFullScreenBtn);
            self.trigger(self.events.TOGGLE_ZOOM, {
                frame: li,
                btnZoom: btnZoom,
                btnZoom2: btnZoom2,
                withoutCoords: true,
            });
        }
        function stopPropagation(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();
            }
        }
        return {
            btnGroup: btnGroup,
            btnFullScreen: btnFullScreen,
            btnZoom: btnZoom
        };
    };
    StackedViewer.prototype.handleBtnClasses = function (isFullScreenBtn) {
        var i;
        var btn;
        var baseClassName = isFullScreenBtn ? 'cylindo-icon-fullscreen-' : 'cylindo-icon-zoom-';
        var on = 'on';
        var off = 'off';
        var totalElements = this.elementsGroup.length;
        if (totalElements > 0) {
            for (i = 0; i < totalElements; i++) {
                btn = isFullScreenBtn ?
                    this.elementsGroup[i].btnFullScreen :
                    this.elementsGroup[i].btnZoom;
                if (btn &&
                    btn.classList &&
                    btn.classList.contains(baseClassName + on)) {
                    btn.classList.remove(baseClassName + on);
                    btn.classList.add(baseClassName + off);
                }
            }
        }
    };
    StackedViewer.prototype.tryToRemoveElements = function (index) {
        var elementsExist;
        var elements;
        var indexInArray;
        if (index && this.elementsGroup.length) {
            elementsExist = this.elementsGroup.filter(function (elements) {
                return elements.index === index.toString();
            });
            if (elementsExist && elementsExist.length === 1) {
                elements = elementsExist[0];
                try {
                    elements.tooltip.remove();
                }
                catch (ex) {
                    this.logger.log('Tooltip to remove doesn\'t exist for this index.', index);
                }
                try {
                    this.trigger(this.events.FULLSCREEN_BTN_REMOVED, {
                        btnFullScreen: elements.btnFullScreen
                    });
                    this.dom.remove(elements.btnGroup);
                }
                catch (ex) {
                    this.logger.log('Buttons group to remove doesn\'t exist for this index.', index);
                }
                indexInArray = this.elementsGroup.indexOf(elements);
                this.elementsGroup.splice(indexInArray, 1);
            }
        }
    };

    StackedViewer.prototype.isFullScreen = function () {
        return this.fullScreenEnabled;
    };

    StackedViewer.prototype.enableFullScreen = function (fullScreenEnabled) {
        this.fullScreenEnabled = fullScreenEnabled;
        if (this.isFullScreen()) {
            this.wrapper.parentNode.classList.remove('is-stacked');
            this.wrapper.parentNode.classList.add('is-stacked-on-fullscreen');
            this.ul360.classList.add('is-carousel');

            this.removeMouseCallbacks();
            this.carouselViewer.addMouseCallbacks.call(this);
        }
        else {
            this.wrapper.parentNode.classList.add('is-stacked');
            this.wrapper.parentNode.classList.remove('is-stacked-on-fullscreen');
            this.ul360.classList.remove('is-carousel');

            this.carouselViewer.removeMouseCallbacks.call(this);
            this.addMouseCallbacks();
        }
        this.handleBtnClasses(true);
        if (this.customImageOn) {
            this.hideCarouselBtns();
        }
        else {
            this.showCarouselBtns();
        }
    };

    StackedViewer.prototype.handleActiveFrame = function (element, enable) {
        if (element && element.classList) {
            if (enable) {
                element.classList.add('active');
            }
            else {
                element.classList.remove('active');
            }
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new StackedViewer(opts);
        }
    };

    window.cylindo.addModule('stackedViewer', publicAPI);
}).call(this);
