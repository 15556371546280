(function () {
    'use strict';
    var LayerAbstract = cylindo.getModule('cylindo.classes.layer');
    function NoFeatureTooltip(opts) {
        if (opts && typeof opts === 'object') {
            opts.role = 'tooltip';
        }
        LayerAbstract.call(this, opts);
        this.wrapperClasses = ['cylindo-tooltip', 'cylindo-no-feature'];
        this.templatePattern = [
            '<span>!</span>',
            '<p><% text %></p>',
        ];
        this.setTemplate(this.templatePattern.join(''));
    }

    NoFeatureTooltip.prototype = Object.create(LayerAbstract.prototype);
    NoFeatureTooltip.prototype.constructor = NoFeatureTooltip;

    var publicAPI = {
        create: function (opts) {
            return new NoFeatureTooltip(opts);
        }
    };

    window.cylindo.addModule('tooltip.no-feaure', publicAPI);
}.call(this));
