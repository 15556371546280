(function () {
    'use strict';

    var GtmGA4 = function (opts) {
        var self = this;
        var dataLayer = opts.dataLayer;
        var events = opts.events;
        var googleMaps = cylindo.getModule('cylindo.core.google.maps');
        var util = cylindo.getModule('cylindo.core.util');
        var deviceCategories = googleMaps.getDeviceCategories();
        var deviceCategory = util.browser.isMobile() ? deviceCategories.MOBILE : deviceCategories.DESKTOP;
        var eventsData = googleMaps.getEventsData();

        self.initialized = false;
        self.init = init;
        self.saveEvent = saveEvent;
        init();
        function init() {
            var i = 0;
            self.instanceID = ++GtmGA4.counter;
            self.initialized = true;
        }

        function saveEvent(eventData, clientData) {
            var prop;
            var currentEvent;
            var toBeSent;
            var i = 0;
            var j = 0;
            var features = clientData.features instanceof Array ? clientData.features : [];
            var evendDataDetail = null;
            if (eventData && dataLayer) {
                try {
                    for (prop in events) {
                        if (events[prop] === eventData.e) {
                            currentEvent = eventsData[prop];
                        }
                    }
                    if (currentEvent && ((currentEvent.ONE_PER_PAGE && currentEvent.TRIGGERED) || !currentEvent.GA4) ){
                        return;
                    }
                    else {
                        toBeSent = {
                            'event': 'cylindoGA4Event',
                            'event_name': currentEvent.EVENT_NAME_GA4,
                            'model_code': decodeURIComponent(clientData.productCode),
                            'model_version': clientData.version,
                            'device': deviceCategory,
                        };
                        for (i = 0; i < features.length; i+=2) {
                            ++j;
                            toBeSent['feature_type_code_' + j] = decodeURIComponent(features[i]);
                            toBeSent['option_code_' + j] = decodeURIComponent(features[i + 1]);
                        }

                        evendDataDetail = eventData && eventData.d || {};

                        switch (eventData.e) {
                            case events.AR_LOADED:
                            case events.AR_SEEN:
                            case events.AR_LAUNCHED:
                            case events.AR_CLICKED:
                            case events.AR_BANNER_CLICKED:
                                toBeSent.ar_desktop_enabled = clientData.ARDesktop || false;
                                toBeSent.ar_banner_enabled = clientData.ARBanner || false;
                            break;
                            case events.FULLSCREEN:
                            case events.ZOOM:
                            case events.SPIN:
                            case events.FEATURE_CHANGED:
                            case events.THUMBNAIL_CLICK:
                                toBeSent.viewer_engagement_type = currentEvent.ACTION;
                            break;
                            case events.ALT_IMAGE_LOADED:
                            case events.ALT_IMAGE_VIEWED:
                                toBeSent.alt_model_code = decodeURIComponent(evendDataDetail.productCode) || '';
                                toBeSent.alt_model_version = evendDataDetail.version || '';
                                toBeSent.alt_frame_number = evendDataDetail.frameNumber || '';
                                toBeSent.alt_image_name = decodeURIComponent(evendDataDetail.imageName) || '';
                                toBeSent.alt_cylindo_content = evendDataDetail.isCylindoImage || false;
                                toBeSent.alt_image = evendDataDetail.image || '';
                                j = 0;
                                if (evendDataDetail.features) {
                                    for (i = 0; i < evendDataDetail.features.length; i+=2) {
                                        ++j;
                                        toBeSent['alt_feature_type_code_' + j] = decodeURIComponent(evendDataDetail.features[i]);
                                        toBeSent['alt_option_code_' + j] = decodeURIComponent(evendDataDetail.features[i + 1]);
                                    }    
                                }
                            break;
                            case events.ERROR_LOADING:
                                toBeSent.error_description = evendDataDetail.error_description || '';
                            break;
                        }
                        dataLayer.push(toBeSent);
                        currentEvent.TRIGGERED = true;
                    }
                }
                catch (ex) {
                    console.log('Something went wrong while trying to send the event using Google Tag Manager.', ex);
                }
            }
        }
    };

    GtmGA4.counter = 0;

    var publicAPI = {
        create: function (opts) {
            return new GtmGA4(opts);
        }
    };

    window.cylindo.addModule('cylindo.core.ga4.gtm', publicAPI);
}).call(this);

