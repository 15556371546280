
(function () {
    'use strict';


    var ImageServiceAPI = function () {
        var network = cylindo.getModule('cylindo.core.network');
        var config = cylindo.getModule('cylindo.core.config');
        var utils = cylindo.getModule('cylindo.core.util');
        var settings = {
            defaults: config.get('defaults'),
            urls: config.get('urls'),
            metadata: config.get('metadata')
        };

        this.getUrlV2 = function (accountID, productCode, frameNumber, size, features, removeEnvironmentShadow, proxy, ssl, version, ignoreCache) {
            var imageRequestData;
            var picturePaths;
            if (settings && utils) {
                imageRequestData = {
                    'accountID': accountID,
                    'productCode': productCode,
                    'frame': frameNumber,
                    'size': size,
                    'ftToBeShown': features,
                    'ssl': ssl || false,
                    'ignoreCache': ignoreCache,
                    'version': version,
                    'removeEnvironmentShadow': removeEnvironmentShadow,
                    'proxy': proxy
                };
                picturePaths = utils.picture.getPaths(settings, imageRequestData);
                if (picturePaths &&
                    typeof picturePaths.framePath === 'string' &&
                    picturePaths.framePath.length > 0) {
                    return picturePaths.framePath;
                }
                else {
                    console.log('Cylindo msg: CAPI module could not create the image.');
                    return null;
                }
            }
            else {
                console.log('Cylindo msg: no settings module.');
                return null;
            }
        };

        this.getUrl = function (callback, accountID, SKU, frameNumber, size, country, features, ssl, viewerType, skuVersion, ignoreCache) {

            try {
                console.warn('Cylindo: The getUrl method has been deprecated. Please use the Content API directly.');
            }
            catch (ex) {
                console.log('Cylindo: The getUrl method has been deprecated. Please use the Content API directly.');
            }

            ignoreCache = typeof ignoreCache === 'boolean' ? ignoreCache : settings.defaults.ignoreCache;

            if (settings) {
                var urls = settings.urls;
                var metaData = settings.metadata;
                var requestOpts = null;
                var imageRequestData = {
                    'accountid': accountID,
                    'sku': SKU,
                    'frame': frameNumber,
                    'size': size,
                    'country': country,
                    'features': features,
                    'ssl': (ssl ? 1 : 0),
                    'x-service-version': '3.1'
                };
                if (skuVersion) {
                    imageRequestData.skuVersion = skuVersion;
                }
                if (viewerType) {
                    imageRequestData.viewerType = viewerType;
                }
                if (ignoreCache) {
                    imageRequestData._ = Date.now();
                    imageRequestData.directives = 'IGNORE-CACHE';
                }

                requestOpts = {
                    type: "GET",
                    data: imageRequestData,
                };


                if (network) {
                    var url = urls.OLD_SERVICES_GET_IMAGE_URL;
                    url = network.resolveProtocol(url);
                    network.doRequest(url, onUrlSuccess, requestOpts, onUrlError);
                }
                else {
                    console.log("no network module");
                }
            }
            else {
                console.log("no settings module");
            }

            function onUrlSuccess(imgURL) {
                if (callback && typeof callback === "function") {
                    callback(imgURL, null);
                }
                else {
                    console.error("Callback sent to CAPI module is null or not a function.");
                }
            }

            function onUrlError(xhr) {
                console.log("could not get image from CAPI");
                if (callback && typeof callback === "function") {
                    callback(null, xhr);
                }
                else {
                    console.error("Callback sent to CAPI module is null or not a function.");
                }
            }
        };

        this.getImage = function (url, callback, authorizationKey, ignoreCache) {

            try {
                console.warn('Cylindo: The getImage method has been deprecated. Please use the Content API directly.');
            }
            catch (ex) {
                console.log('Cylindo: The getImage method has been deprecated. Please use the Content API directly.');
            }

            ignoreCache = typeof ignoreCache === 'boolean' ? ignoreCache : settings.defaults.ignoreCache;

            if (network) {
                network.requestImage(url, onImageSuccess, onImageError, ignoreCache, authorizationKey);
            }
            else {
                console.log('No network module found in CAPI module.');
            }

            function onImageSuccess(result) {
                if (callback) {
                    if (result !== null) {
                        var urlMaker = window.URL || window.webkitURL;
                        callback(urlMaker.createObjectURL(result, null));
                    }
                    else {
                        callback(null, 'Image data does not exist.');
                    }
                }
            }

            function onImageError(xhr) {
                if (callback) {
                    if (xhr.status == 429) {
                        callback(null, 'Request was throttled');
                    }
                    else if (xhr.status == 401) {
                        callback(null, 'Unauthrorized... Have you remembered the autorization header?');
                    }
                    else {
                        callback(null, 'xhr');
                    }
                }
            }
        };


    };
    var capi = new ImageServiceAPI();
    window.cylindo.addModule('cylindo.classes.capi', capi);
}.call(this));


