(function () {
    'use strict';
    var ViewAbstract = cylindo.getModule('cylindo.classes.abstract.view');
    var CarouselViewer = function (options) {
        ViewAbstract.call(this, options);

        var self = this;
        var util = cylindo.getModule('cylindo.core.util');
        var dom = options.dom;
        var scrollHelper = cylindo.getModule('cylindo.helpers.scroll');
        var easingHelper = cylindo.getModule('cylindo.helpers.easing');
        var tOut = null;
        var tOutValue = null;
        var isScrolling = false;
        var isTouchEvent = false;
        var carouselLeftBtn = document.createElement('div');
        var carouselRightBtn = document.createElement('div');
        var carouselLeftBtnIcon = document.createElement('a');
        var carouselRightBtnIcon = document.createElement('a');
        var animationItemsFinished = 0;
        var elemsToAnimate = null;

        var throttle = 10;

        this.util = util;
        this.dom = dom;
        this.wasDragging = false;
        this.isDragging = false;
        this.isAutoSliding = false;

        this.frameWidth = 512;
        this.mouseState = 0;
        this.prevIndex = null;
        this.deltaX = 0;
        this.ratio = null;
        this.resizeTimer = 0;
        this.pendingImages = [];
        this.carouselLeftBtnIcon = carouselLeftBtnIcon;
        this.carouselRightBtnIcon = carouselRightBtnIcon;
        this.carouselLeftBtn = carouselLeftBtn;
        this.carouselRightBtn = carouselRightBtn;
        this.autoSlideCarousel = autoSlideCarousel;
        this.refreshRequested = false;
        this.refRequestID = null;
        this.goToIndexRequested = false;
        this.indexRequested = null;
        this.showImageRequested = false;
        this.customImageUrl = null;
        this.customImageLarge = null;
        this.reloadAltContentRequested = false;

        this.isCustomViewer = false;
        this.defaultTooltip = 'tooltipCarouselText';
        this.pendingMovements = [];
        this.isRightDirection = null;
        this.errorAtPrepareToMoveCarousel = false;

        if (options.model.get('missingCombinationErrorText')) {
            this.noFeatureTooltip = cylindo.getModule('tooltip.no-feaure').create({
                parent: options.el,
                text: options.model.get('missingCombinationErrorText'),
                dom: dom
            });
        }

        this.model.on(this.model.events.FEATURES_CHANGED, this.tryToRefresh.bind(this));
        this.model.on(this.model.events.FEATURES_ERROR, onFeaturesError);
        this.model.on(this.model.events.FEATURES_NO_WARNING, this.onFeaturesUpdated.bind(this));
        this.model.on(this.model.events.CHANGED, this.updateView.bind(this));
        this.listClassName = 'cylindo-threesixty-list';
        this.viewerPresentation = 'carousel';

        this.enable = enable.bind(this);
        this.disable = disable.bind(this);
        this.addMouseCallbacks = addMouseCallbacks.bind(this);
        this.removeMouseCallbacks = removeMouseCallbacks.bind(this);

        tOutValue = this.model.get('mobileZoomDelay');

        if (this.noFeatureTooltip) {
            this.noFeatureTooltip.render();
            if (this.initializedWithError) {
                this.noFeatureTooltip.show();
            }
        }
        if (!options.preventRender) {
            this.render();
        }
        else {
            this.carouselViewer = util.object.extend({}, this);
        }

        function onFeaturesError() {
            if (self && self.noFeatureTooltip) {
                self.noFeatureTooltip.show();
            }
            self.trigger(self.events.FEATURES_ERROR, null);
        }
        function enable() {
            addMouseCallbacks.call(this);
            if (!this.customImageOn) {
                self.showCarouselBtns();
            }
            ViewAbstract.prototype.enable.call(this, true);
        }
        function disable() {
            removeMouseCallbacks.call(this);
            self.hideCarouselBtns();
            ViewAbstract.prototype.disable.call(this, true);
        }
        function addMouseCallbacks() {
            self.carouselLeftBtn.addEventListener('touchstart', tryToMoveCarousel, false);
            self.carouselRightBtn.addEventListener('touchstart', tryToMoveCarousel, false);
            self.wrapper.addEventListener('touchstart', touchStart, false);
            self.carouselLeftBtn.addEventListener('mouseup', tryToMoveCarousel);
            self.carouselRightBtn.addEventListener('mouseup', tryToMoveCarousel);
            self.wrapper.addEventListener('mousedown', mouseDown, false);
        }
        function removeMouseCallbacks() {
            self.carouselLeftBtn.removeEventListener('touchstart', tryToMoveCarousel, false);
            self.carouselRightBtn.removeEventListener('touchstart', tryToMoveCarousel, false);
            self.wrapper.removeEventListener('touchstart', touchStart, false);
            self.carouselLeftBtn.removeEventListener('mouseup', tryToMoveCarousel);
            self.carouselRightBtn.removeEventListener('mouseup', tryToMoveCarousel);
            self.wrapper.removeEventListener('mousedown', mouseDown, false);
            removeDocumentEvents();
        }
        function addDocumentEvents() {
            document.addEventListener('touchend', touchEnd, false);
            document.addEventListener('touchmove', touchMove, false);
            document.addEventListener('mouseup', mouseUp, false);
            document.addEventListener('mousemove', mouseMove, false);
        }
        function removeDocumentEvents() {
            document.removeEventListener('mouseup', mouseUp, false);
            document.removeEventListener('mousemove', mouseMove, false);
            document.removeEventListener('touchend', touchEnd, false);
            document.removeEventListener('touchmove', touchMove, false);
        }
        function touchStart(evt) {
            if (isEventOnVideo(evt)) {
                return;
            }
            isTouchEvent = true;
            scrollHelper.preventScrollX(self.wrapper.parentNode);
            self.setMobileCoords(evt);
            mouseDown(evt);
        }
        function touchEnd(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            if (tOut) {
                window.clearTimeout(tOut);
                tOut = null;
            }
            self.setMobileCoords(evt);
            mouseUp(evt);
            scrollHelper.allowScrollX(self.wrapper.parentNode);
            isScrolling = false;
            isTouchEvent = false;
        }
        function touchMove(evt) {
            var yDiff, xDiff;

            self.setMobileCoords(evt);

            yDiff = Math.abs(evt.clientY - self.mouseClickPos.startY);
            xDiff = Math.abs(evt.clientX - self.mouseClickPos.startX);

            if (!self.isSignificantMove(evt)) {
                return;
            }

            window.clearTimeout(tOut);
            tOut = null;

            if ((isScrolling || !self.wasDragging) && yDiff > xDiff) {
                isScrolling = true;
            }
            if (!isScrolling) {
                mouseMove(evt);
            }
        }
        function mouseDown(evt) {
            if (evt) {
                if (isEventOnVideo(evt) || isClickOnCarouselArrows(evt)) {
                    return;
                }
                if (!self.isRightClick(evt)) {
                    addDocumentEvents();
                    self.mouseState = 1;
                    self.mouseClickPos.startX = self.mouseClickPos.currentX = evt.clientX;
                    self.mouseClickPos.startY = self.mouseClickPos.currentY = evt.clientY;
                    self.mouseClickPos.startTime = evt.timeStamp;
                    self.currentEventTime = evt.timeStamp;
                }
                if (!evt.defaultPrevented && !isTouchEvent) {
                    evt.preventDefault();
                }
            }
        }
        function mouseMove(evt) {
            var deltaX = 0;
            var masterDeltaX = 0;
            var containerRect = self.ul360.getBoundingClientRect();
            var leftLimit = containerRect.left - containerRect.width / 4;
            var rightLimit = containerRect.right + containerRect.width / 4;
            var isRightDirection;
            if (self.mouseClickPos.startTime < Date.now() - throttle) {
                if (self.mouseState === 0) {
                    return;
                }
                if (evt && self.mouseState > 0) {
                    deltaX = evt.clientX - self.mouseClickPos.currentX;
                    masterDeltaX = evt.clientX - self.mouseClickPos.startX;
                    if (Math.abs(masterDeltaX) < 3 && !self.isDragging) {
                        return;
                    }
                    isRightDirection = masterDeltaX < 0 ? true : false;
                    self.wasDragging = true;
                    self.isDragging = true;
                    self.deltaX = deltaX;
                    self.mouseClickPos.currentX = evt.clientX;
                    self.mouseClickPos.currentY = evt.clientY;
                    self.currentEventTime = evt.timeStamp;
                    if (self.mouseClickPos.currentX >= leftLimit &&
                        self.mouseClickPos.currentX <= rightLimit) {
                        if (self.isAutoSliding) {
                            self.isRightDirection = isRightDirection;
                        }
                        else {
                            prepareToMoveCarousel(deltaX, isRightDirection);
                            self.ul360.classList.add('cylindo-dragging');
                        }
                    }
                }
                self.mouseClickPos.startTime = Date.now();
            }
        }
        function mouseUp(evt) {
            self.isDragging = false;
            if (self.mouseState == 1 && evt && !self.isRightClick(evt)) {
                self.mouseState = 0;
                self.deltaX = 0;
                setTimeout(function () {
                    removeDocumentEvents();
                }, 0);

                if (self.isAutoSliding) {
                    if (typeof self.isRightDirection === 'boolean') {
                        moveCarousel(self.isRightDirection);
                        self.isRightDirection = null;
                    }
                    return;
                }
                if (!self.wasDragging &&
                    !isScrolling &&
                    evt.type === 'mouseup' &&
                    !isTouchEvent) {
                    self.mouseClickPos.startX = self.mouseClickPos.currentX = evt.clientX;
                    self.mouseClickPos.startY = self.mouseClickPos.currentY = evt.clientY;
                    self.trigger(self.events.CLICKED, self.mouseClickPos);
                    return;
                }
                if (self.wasDragging) {
                    moveCarousel();
                }
                self.wasDragging = false;
                self.ul360.classList.remove('cylindo-dragging');
            }
        }
        function prepareToMoveCarousel(deltaX, isRightDirection, forced) {
            var currentActiveRect, currentActiveLeft, newActive, newActiveLeft, currentActivePos;
            var totalLiElements = self.ul360.querySelectorAll('li').length;
            var currentActive = self.ul360.querySelector('li.active:not(.is-rendering)');
            var nextSibling = getNextSibling(currentActive);
            var previousSibling = getPreviousSibling(currentActive);
            forced = forced || false;
            self.tryToRemoveCustomImage();
            deltaX = deltaX || 0;
            if (!forced && (self.isAutoSliding || totalLiElements === 1)) {
                return;
            }
            if (!currentActive ||
                (!isRightDirection && currentActive === previousSibling) ||
                (isRightDirection && currentActive === nextSibling)) {
                self.errorAtPrepareToMoveCarousel = true;
                return;
            }
            self.errorAtPrepareToMoveCarousel = false;
            currentActiveRect = currentActive.getBoundingClientRect();
            currentActivePos = self.dom.position(currentActive);
            if (isRightDirection) {
                cleanElement(previousSibling, nextSibling);
                newActive = nextSibling;
                newActive.classList.add('active-sibling');
                newActiveLeft = (currentActivePos.left + currentActiveRect.width) + deltaX;
            }
            else {
                cleanElement(nextSibling, previousSibling);
                newActive = previousSibling;
                newActive.classList.add('active-sibling');
                newActiveLeft = (currentActivePos.left - currentActiveRect.width) + deltaX;
            }
            currentActiveLeft = currentActivePos.left + deltaX;
            currentActive.style.left = currentActiveLeft + 'px';
            newActive.style.left = newActiveLeft + 'px';
            elemsToAnimate = {
                currentActive: { 'element': currentActive },
                newActive: { 'element': newActive }
            };
            function cleanElement(elToClean, elToCompare) {
                if (elToClean !== elToCompare) {
                    elToClean.style.left = '';
                    elToClean.classList.remove('active-sibling');
                }
            }
            function getNextSibling(el) {
                if (!el) {
                    return;
                }
                el = el.nextElementSibling || el.parentNode.firstElementChild;
                return el.classList.contains('is-rendering') ?
                    getNextSibling(el) : el;
            }
            function getPreviousSibling(el) {
                if (!el) {
                    return;
                }
                el = el.previousElementSibling || el.parentNode.lastElementChild;
                return el.classList.contains('is-rendering') ?
                    getPreviousSibling(el) : el;
            }
        }
        function moveCarousel(isRightDirection, forced) {
            var executeAnimation = false;
            var keepSameActive = false;
            var newActiveLeft = 0;
            var totalLiElements = self.ul360.querySelectorAll('li').length;
            var currentActive, currentActiveRect, currentActiveIndex, currentLeftPosition, currentActiveLeft, newIndex, newActive, i;
            forced = forced || false;
            if (self.errorAtPrepareToMoveCarousel) {
                return;
            }
            if (!forced && (self.isAutoSliding || totalLiElements === 1)) {
                self.pendingMovements.push(isRightDirection);
                return;
            }
            else if (self.pendingMovements.length === 0) {
                self.pendingMovements.push(isRightDirection);
            }
            self.isAutoSliding = true;
            currentActive = elemsToAnimate.currentActive.element;
            newActive = elemsToAnimate.newActive.element;
            currentActiveRect = currentActive.getBoundingClientRect();
            currentLeftPosition = self.dom.position(currentActive).left;
            if ((currentLeftPosition !== 0) ||
                typeof isRightDirection !== 'undefined') {
                executeAnimation = true;
            }
            if (currentLeftPosition <= 60 &&
                currentLeftPosition >= -60 &&
                typeof isRightDirection === 'undefined') {
                keepSameActive = true;
                isRightDirection = currentLeftPosition < 0 ? true : false;
                currentActiveLeft = 0;
                newActiveLeft = isRightDirection ? currentActiveRect.width : currentActiveRect.width * -1;
            }
            else if (currentLeftPosition < 0 || isRightDirection === true) {
                isRightDirection = true;
                currentActiveLeft = currentActiveRect.width * -1;
            }
            else if (currentLeftPosition > 0 || isRightDirection === false) {
                isRightDirection = false;
                currentActiveLeft = currentActiveRect.width;
            }
            elemsToAnimate.currentActive.desiredLeft = currentActiveLeft;
            elemsToAnimate.newActive.desiredLeft = newActiveLeft;
            if (keepSameActive) {
                prepareIfEntersFullscreen(newActive);
            }
            else {
                prepareIfEntersFullscreen(currentActive);
            }
            if (executeAnimation) {
                requestCarouselAnimation.call({
                    isRightDirection: isRightDirection,
                    elemsToAnimate: elemsToAnimate,
                    keepSameActive: keepSameActive
                });
            }
            else {
                self.isAutoSliding = false;
                if (elemsToAnimate && elemsToAnimate.newActive && elemsToAnimate.newActive) {
                    elemsToAnimate.newActive.element.classList.remove('active-sibling');
                    elemsToAnimate.newActive.element.style.left = '';
                }
                if (elemsToAnimate && elemsToAnimate.currentActive && elemsToAnimate.currentActive) {
                    elemsToAnimate.currentActive.element.style.left = '';
                }
            }
            function prepareIfEntersFullscreen(element) {
                var isFullScreen = self.wrapper.classList.contains('full-screen');
                if (!isFullScreen && element && element.classList) {
                    element.classList.add('cylindo-hide-on-fullscreen');
                }
            }

        }
        function requestCarouselAnimation() {
            var i;
            runAnimation.call(util.object.extend({}, {
                animateCurrent: true,
            }, this));

            runAnimation.call(util.object.extend({}, {
                animateCurrent: false,
            }, this));
        }
        function runAnimation() {
            var that = this;
            var delay = 16.7;
            var toAnimate = that.animateCurrent ? that.elemsToAnimate.currentActive : that.elemsToAnimate.newActive;
            var element, desiredLeft, currentLeft, left, steps, setIntervalId, carouselSlideSpeed;
            var currentStep = 0;
            var useEaseOutQuad = self.pendingMovements.length === 1 ? true : false;
            element = toAnimate.element;
            currentLeft = self.dom.position(element).left;
            desiredLeft = toAnimate.desiredLeft;
            carouselSlideSpeed = getSliderSpeed(Math.abs(desiredLeft - currentLeft) / 5);
            steps = Math.floor(Math.abs(desiredLeft - currentLeft) / carouselSlideSpeed);
            setIntervalId = setInterval(moveLeftMargin, delay);
            function moveLeftMargin() {
                try {
                    ++currentStep;
                    if (isNaN(steps) ||
                        currentStep >= steps ||
                        (typeof currentLeft === 'undefined' || typeof desiredLeft === 'undefined')) {
                        clearInterval(setIntervalId);
                        tryToFinishAnimation.call(that);
                    }
                    else {
                        if (useEaseOutQuad) {
                            left = easingHelper.easeOutQuad(currentStep, currentLeft, desiredLeft - currentLeft, steps);
                        }
                        else {
                            left = easingHelper.linearTween(currentStep, currentLeft, desiredLeft - currentLeft, steps);
                        }
                        left = Math.round(left * 100) / 100;
                        element.style.left = left + 'px';
                    }
                }
                catch (ex) {
                    self.logger.error('Cannot finish carousel animation', ex);
                    clearInterval(setIntervalId);
                    tryToFinishAnimation.call(that);
                }
            }

            function getSliderSpeed(maxSpeed) {
                var carouselSlideSpeed = self.model.get('carouselSlideSpeed') > 0 ?
                    self.model.get('carouselSlideSpeed') : 32;
                var proposedSliderSpeed = carouselSlideSpeed;
                var pendingMovementsLen = self.pendingMovements.length;

                if (self.pendingMovements.length > 1) {
                    proposedSliderSpeed = carouselSlideSpeed * pendingMovementsLen;
                    if (proposedSliderSpeed > maxSpeed) {
                        proposedSliderSpeed = maxSpeed;
                    }
                    carouselSlideSpeed = proposedSliderSpeed;
                }
                return carouselSlideSpeed;
            }
        }
        function tryToFinishAnimation() {
            ++animationItemsFinished;
            if (animationItemsFinished === 2) {
                animationItemsFinished = 0;
                finishAnimation.call(this);
            }
        }
        function finishAnimation() {
            var allFrames = self.framesHandler.getAllFramesToBeLoaded();
            var isCustomImage = false;
            var currentActive = this.elemsToAnimate && this.elemsToAnimate.currentActive ?
                this.elemsToAnimate.currentActive.element : null;
            var newActive = this.elemsToAnimate && this.elemsToAnimate.newActive ?
                this.elemsToAnimate.newActive.element : null;
            var altContent = allFrames.ALTERNATE.LEN;
            var totalFrames = allFrames.THREESIXTY.TO_BE_LOADED;
            var currentIndex, newIndex, indexInList, allElements;
            totalFrames = self.isCustomViewer ?
                altContent : totalFrames + altContent;
            if (currentActive && newActive) {
                allElements = dom.querySelectorAll(self.ul360, 'li');
                indexInList = allElements.indexOf(newActive) + 1;
                currentIndex = currentActive.dataset ? currentActive.dataset.index : currentActive.getAttribute('data-index');
                newIndex = newActive.dataset ? newActive.dataset.index : newActive.getAttribute('data-index');
                currentActive.style.left = '';
                currentActive.classList.remove('cylindo-hide-on-fullscreen');
                newActive.style.left = '';
                newActive.classList.remove('active-sibling');
                newActive.classList.remove('cylindo-hide-on-fullscreen');
                if (this.keepSameActive) {
                    removeAutoSlide();
                    return;
                }
                self.handleActiveFrame(currentActive, false);
                self.handleActiveFrame(newActive, true);
                if (self.framesHandler.is360Frame(newIndex)) {
                    self.prevIndex = newIndex;
                }
                else if (self.framesHandler.is360Frame(currentIndex)) {
                    self.prevIndex = currentIndex;
                }
                self.currentIndex = newIndex;
                if (this.isRightDirection) {
                    self.trigger(self.events.NEXT, {
                        index: newIndex,
                        frame: indexInList,
                        frames: totalFrames
                    });
                }
                else {
                    self.trigger(self.events.PREV, {
                        index: newIndex,
                        frame: indexInList,
                        frames: totalFrames
                    });
                }
                self.trigger(self.events.FRAME_ACTIVATED, { index: newIndex });
                self.trigger(self.events.CURRENT, { current: newIndex });
                self.handleTooltip(newActive, newIndex, currentIndex);
                self.handleZoomButton(newActive, newIndex, currentIndex);
                self.handleActionBtns(newActive, newIndex, isCustomImage);
            }
            removeAutoSlide();
            function removeAutoSlide() {
                var forceAutoSlide = true;
                if (self.pendingMovements.length > 0) {
                    self.pendingMovements.shift();
                    if (self.pendingMovements.length > 0) {
                        autoSlideCarousel(self.pendingMovements[0], forceAutoSlide);
                        return;
                    }
                }
                self.isAutoSliding = false;
                if (self.refreshRequested) {
                    self.refresh.call(self, null, { refRequestID: self.refRequestID });
                    self.refreshRequested = false;
                    self.refRequestID = null;
                }
                if (self.goToIndexRequested) {
                    self.goToIndex.call(self, self.indexRequested);
                }
                if (self.showImageRequested) {
                    self.showImage.call(self, self.customImageUrl, self.customImageLarge);
                }
                if (self.reloadAltContentRequested) {
                    self.reloadAltContent.call(self);
                }
            }
        }
        function tryToMoveCarousel(evt) {
            var deltaX = 0;
            var direction;
            var isRightDirection;
            if (self.isDragging) {
                return;
            }
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            if (evt && evt.target &&
                (evt.target.classList.contains('move-to-left') ||
                    evt.target.classList.contains('move-to-right'))) {
                direction = evt.target.dataset ? evt.target.dataset.direction : evt.target.getAttribute('data-direction');
                isRightDirection = direction === 'right' ? true : false;
                prepareToMoveCarousel(deltaX, isRightDirection);
                moveCarousel(isRightDirection);
            }
        }
        function autoSlideCarousel(isRightDirection, forced) {
            var deltaX = 0;
            prepareToMoveCarousel(deltaX, isRightDirection, forced);
            moveCarousel(isRightDirection, forced);
        }
        function isClickOnCarouselArrows(evt) {
            if (evt && evt.target &&
                evt.target.classList &&
                (evt.target.classList.contains('move-to-left') ||
                    evt.target.classList.contains('move-to-right'))) {
                return true;
            }
            return false;
        }
        function isEventOnVideo(evt) {
            var currentLi = self.ul360.querySelector('.active');
            if (currentLi && currentLi.classList.contains('cylindo-video')) {
                return true;
            }
            return false;
        }
    };
    CarouselViewer.prototype = Object.create(ViewAbstract.prototype);
    CarouselViewer.prototype.constructor = CarouselViewer;
    CarouselViewer.prototype.events = ViewAbstract.extendEvents({
        CLICKED: 'carousel:clicked',
        READY: 'carousel:ready',
        DOWNLOAD_COMPLETE: 'carousel:download:complete',
        CURRENT: 'carousel:current',
        IMG_LOADED: 'carousel:img:loaded',
        FEATURES_CHANGED: 'carousel:features:changed',
        FIRST_FRAME_COMPLETE: 'carousel:firstframe:complete',
        FRAME_ACTIVATED: 'carousel:frame:activated',
        ALT_CONTENT_REMOVED: 'carousel:alt:content:removed',
        ALT_CONTENT_LOADED: 'carousel:alt:content:loaded',
        CONTENT_TYPE_COMPLETE: 'carousel:content:type:complete',
        PREV: 'carousel:prev',
        NEXT: 'carousel:next',
        FEATURES_CANCELED: 'carousel:features:canceled',
        FEATURES_ERROR: 'carousel:features:error'
    });
    CarouselViewer.prototype.render = function () {
        ViewAbstract.prototype.tryToCreateTooltip.call(this);
        ViewAbstract.prototype.render.call(this);
    };
    CarouselViewer.prototype.goToIndex = function (index) {
        if (this.isAutoSliding) {
            this.goToIndexRequested = true;
            this.indexRequested = index;
            this.removeMovementsTail.call(this);
            return;
        }
        else {
            this.goToIndexRequested = false;
            this.indexRequested = null;
        }
        if (this.framesHandler.is360Frame(index)) {
            this.prevIndex = index;
        }
        else if (this.framesHandler.is360Frame(this.currentIndex)) {
            this.prevIndex = this.currentIndex;
        }
        this.tryToRemoveCustomImage();
        ViewAbstract.prototype.goToIndex.call(this, index);
        this.trigger(this.events.CURRENT, { current: index });
    };
    CarouselViewer.prototype.tryToRefresh = function (evt, data) {
        var refRequestID = data && data.refRequestID ? data.refRequestID : null;
        this.hideCarouselBtns();
        if (this.isAutoSliding) {
            this.refreshRequested = true;
            this.refRequestID = refRequestID;
            this.removeMovementsTail.call(this);
        }
        else {
            this.refreshRequested = false;
            this.refRequestID = null;
            this.refresh.call(this, null, { refRequestID: refRequestID });
        }
    };
    CarouselViewer.prototype.refresh = function (evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var allListElements;
        var currentElement = this.ul360 ? this.ul360.querySelector('.active') : null;
        var currentActiveIndex;
        var refRequestID;
        var penImagesLen = this.pendingImages.length;
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;

        this.viewerUpdatedStartTime = Date.now();
        refRequestID = data && data.refRequestID ?
            data.refRequestID : this.model.getRequestID();
        this.requestID = refRequestID;
        for (var i = 0; i < penImagesLen; i++) {
            if (refRequestID !== this.pendingImages[i].requestID &&
                this.currentIndex !== this.pendingImages[i].index) {
                this.pendingImages[i].prepareDefer.resolve();
                this.pendingImages[i].img.onload = null;
                this.pendingImages[i].img.onerror = null;
                this.pendingImages[i].img.src = '';
                this.pendingImages.splice(i, 1);
                i--;
                penImagesLen--;
            }
        }
        if (this.framesHandler.isAltId(this.currentIndex)) {
            this.goToIndex(this.prevIndex);
        }
        if (this.isComplete === false) {
            this.trigger(this.events.FEATURES_CANCELED, {
                features: this.model.getCanceledFeatures()
            });
        }
        this.firstFrameIsReady = false;
        this.isReady = false;
        this.isComplete = false;
        this.promises = [];
        this.frameCount = frameCount;
        this.currentFeatures = this.model.get('currentFeatures');
        this.url = this.currentFeatures.paths;
        allListElements = this.dom.querySelectorAll(this.ul360, 'li:not(.active):not([class*=alt]):not(.active-sibling)');
        allListElements.forEach(function (element) {
            element.classList.add('hidden');
            element.classList.add('is-rendering');
            element.innerHTML = '';
        });
        if (currentElement) {
            currentActiveIndex = this.framesHandler.isAltId(this.currentIndex) ?
                this.prevIndex : this.currentIndex;
            this.prepare(currentActiveIndex, refRequestID);
            this.promises = this.framesHandler.is360Frame(currentActiveIndex) ? this.promises : [];
        }

        Promise.all(this.promises).then(function () {
            this.refreshRest(refRequestID);
        }.bind(this));
    };
    CarouselViewer.prototype.refreshRest = function (requestID) {
        this.promises = [];
        this.downloadRest(requestID);
        Promise.all(this.promises).then(this.onFeaturesUpdated.bind(this));
    };
    CarouselViewer.prototype.updateView = function (evt, data) {
        var li, index;
        if (data && data.prop === this.defaultTooltip &&
            this.tooltip && this.tooltip.isVisible()) {
            li = this.ul360.querySelector('.active');
            index = li && li.dataset ? li.dataset.index : li.getAttribute('data-index');
            this.handleTooltip(li, index, index);
        }
    };
    CarouselViewer.prototype.onFeaturesUpdated = function () {
        if (!this.model.get('hasUnsupportedFeatures')) {
            if (this.noFeatureTooltip) {
                this.noFeatureTooltip.hide();
            }
        }
        this.trigger(this.events.FEATURES_CHANGED);
    };
    CarouselViewer.prototype.downloadRest = function (requestID) {
        var self = this;
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var oPreviousDeferred = null;
        var list = allFrames.VIEWER_ORDER.LIST;
        var shouldDownloadAll360Frames = this.framesHandler.shouldDownloadAll360Frames();
        var listLen = shouldDownloadAll360Frames ?
            allFrames.THREESIXTY.TO_BE_LOADED :
            allFrames.VIEWER_ORDER.LEN;
        var index;
        var i = 0;
        while (i < listLen && this.model.getRequestID() === requestID) {
            index = shouldDownloadAll360Frames ? i + 1 : list[i];
            if (index !== parseInt(this.currentIndex, 10) &&
                this.framesHandler.is360Frame(index)) {
                tryToPrepare(index, requestID);
            }
            i++;
        }
        function tryToPrepare(index, requestID) {
            if (oPreviousDeferred === null) {
                oPreviousDeferred = self.prepare(index, requestID);
            }
            else {
                oPreviousDeferred = oPreviousDeferred.then(function () {
                    return self.prepare(index, requestID);
                });
            }
        }
    };
    CarouselViewer.prototype.prepareLi = function (index) {
        var baseClassName = 'cylindo-viewer-frame-';
        var className = baseClassName + index;
        var li = document.createElement('li');
        var liSelection = this.dom.querySelectorAll(this.ul360, '.' + className);
        li.setAttribute('aria-hidden', true);
        if (liSelection && liSelection.length === 0) {
            li.classList.add(className);
            li.classList.add('hidden');
            li.classList.add('is-rendering');
            this.ul360.appendChild(li);
        }
        else {
            liSelection = liSelection[liSelection.length - 1];
            if (liSelection && !liSelection.classList.contains('is-rendering')) {
                liSelection.classList.add('is-rendering');
            }
        }
    };
    CarouselViewer.prototype.prepare = function (index, requestID) {
        var self = this;
        var frameDeferred = this.util.promise.create();
        var deferred = this.util.promise.create();
        var baseClassName, className, li, img, totalFrames, imgTimeout, liSelection, integerIndex;
        if (this.framesHandler.is360Frame(index)) {
            baseClassName = 'cylindo-viewer-frame-';
            className = baseClassName + index;
            li = document.createElement('li');
            li.setAttribute('aria-hidden', true);
            img = new Image();
            totalFrames = this.frameCount;
            imgTimeout = this.model.get('imageTimeout');
            this.prepareLi(index);
            liSelection = this.dom.querySelectorAll(this.ul360, '.' + className);
            if (liSelection && liSelection.length >= 1) {
                li = liSelection[liSelection.length - 1];
            }
            if (this.url) {
                var rtvOptions = {
                    requestID: requestID,
                    viewer: this,
                    urls: this.url,
                    baseClassName: baseClassName,
                    index: index,
                    li: li,
                    totalFrames: totalFrames,
                    contentKeys: this.contentKeys,
                    blendingModes: this.blendingModes,
                    imgTimeout: imgTimeout,
                    onImageLoad: this.onImageLoad,
                    onError: this.onError,
                    prepareDefer: deferred
                };

                frameDeferred = this.renderer.useStrategy(this.renderer.strategies.RTV_SINGLE_MERGED, rtvOptions);
            }
            else {
                img.src = '';
            }
            this.promises.push(deferred);
            if (index === this.currentIndex && !li.classList.contains('active')) {
                this.handleActiveFrame(li, true);
            }
        }
        else if (this.framesHandler.isAltId(index)) {
            integerIndex = parseInt(index.replace(this.framesHandler.prefixes.ALT, ''), 10);
            frameDeferred = this.prepareAltContent(integerIndex);
        }
        frameDeferred.then(function () {
            self.firstFrameEvt(index);
            deferred.resolve();
        });

        return deferred;
    };

    CarouselViewer.prototype.reloadAltContent = function (evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var altContentLen = allFrames.ALTERNATE.LEN;
        var i;
        var prevIndex = (this.framesHandler.is360Frame(this.currentIndex)) ?
            this.currentIndex :
            this.prevIndex ? this.prevIndex : 1;
        prevIndex = isNaN(prevIndex) ? prevIndex : parseInt(prevIndex, 10);
        if (this.isAutoSliding) {
            this.reloadAltContentRequested = true;
            this.removeMovementsTail.call(this);
        }
        else {
            this.reloadAltContentRequested = false;
            this.prevIndex = prevIndex;
            this.goToIndex(this.prevIndex);
            ViewAbstract.prototype.reloadAltContent.call(this, evt, data);
            for (i = 0; i < altContentLen; i++) {
                this.prepareAltContent(i, evt, data);
            }
        }
    };
    CarouselViewer.prototype.onReady = function () {
        ViewAbstract.prototype.onReady.call(this);
        ViewAbstract.prototype.sendFirstFrameCompleteEvt.call(this);
        this.tryToShowTooltip();
        this.showCarouselBtns();
    };
    CarouselViewer.prototype.createViewerList = function () {
        ViewAbstract.prototype.createViewerList.call(this);
        if (this.util.browser.isIE() || this.util.browser.isEdge()) {
            this.ul360.classList.add('is-ms');
        }
        this.ul360.classList.add('is-carousel');
        this.appendCarouselBtns();
    };
    CarouselViewer.prototype.appendCarouselBtns = function () {
        this.carouselLeftBtnIcon.classList.add('move-to-left', 'glyphicon', 'glyphicon-menu-left');
        this.carouselRightBtnIcon.classList.add('move-to-right', 'glyphicon', 'glyphicon-menu-right');
        this.carouselLeftBtnIcon.setAttribute('role', 'link');
        this.carouselLeftBtnIcon.setAttribute('aria-label', this.ariaLabel.texts.CAROUSEL_LEFT);
        this.carouselRightBtnIcon.setAttribute('role', 'link');
        this.carouselRightBtnIcon.setAttribute('aria-label', this.ariaLabel.texts.CAROUSEL_RIGHT);
        try {
            this.carouselLeftBtnIcon.dataset.direction = 'left';
            this.carouselRightBtnIcon.dataset.direction = 'right';
        }
        catch (ex) {
            this.carouselLeftBtnIcon.setAttribute('data-direction', 'left');
            this.carouselRightBtnIcon.setAttribute('data-direction', 'right');
        }
        if (this.util.browser.isMobile()) {
            this.carouselLeftBtn.classList.add('is-mobile');
            this.carouselRightBtn.classList.add('is-mobile');
        }
        this.carouselLeftBtn.classList.add('cylindo-carousel-btn', 'left-btn', 'temporarily-hidden');
        this.carouselRightBtn.classList.add('cylindo-carousel-btn', 'right-btn', 'temporarily-hidden');
        this.carouselLeftBtn.appendChild(this.carouselLeftBtnIcon);
        this.carouselRightBtn.appendChild(this.carouselRightBtnIcon);
        this.wrapper.appendChild(this.carouselLeftBtn);
        this.wrapper.appendChild(this.carouselRightBtn);
    };
    CarouselViewer.prototype.hideCarouselBtns = function () {
        this.carouselLeftBtn.classList.add('temporarily-hidden');
        this.carouselRightBtn.classList.add('temporarily-hidden');
    };
    CarouselViewer.prototype.showCarouselBtns = function () {
        this.carouselLeftBtn.classList.remove('temporarily-hidden');
        this.carouselRightBtn.classList.remove('temporarily-hidden');
    };
    CarouselViewer.prototype.onImageLoad = function () {
        var li = this.li;
        var newLiEl = li.cloneNode();
        var img = this.img;
        var layers = this.layers;
        var that = this.self;
        var deferred = this.deferred;
        var imagesArrLen = img.length;
        var altText = 'Frame ' + this.index + ' of "__productName__" ';
        var pendingImageIndex = -1;
        var i;

        for (i = 0; i < that.pendingImages.length; i++) {
            if (that.pendingImages[i].index === this.index) {
                pendingImageIndex = i;
            }
        }
        if (pendingImageIndex !== -1) {
            that.pendingImages.splice(pendingImageIndex, 1);
        }
        if (this.requestID !== that.model.getRequestID()) {
            return;
        }

        if (this.index === that.currentIndex ||
            (!that.framesHandler.is360Frame(this.currentIndex) && this.index === that.startFrame)) {
            that.firstImageLoadedTime = Date.now();
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        altText = altText.replace('__productName__', that.model.get('fileName'));
        if (img.length > 1) {
            for (i = 0; i < imagesArrLen; i++) {
                img[i].alt = altText + '(' + layers[i].type + ' layer - ' + layers[i].code + ')';
                newLiEl.appendChild(img[i]);
            }
        }
        else {
            if (img instanceof Image) {
                img.alt = altText;
                newLiEl.appendChild(img);
            } else {
                img[0].alt = altText;
                newLiEl.appendChild(img[0]);
            }
        }
        try {
            li.insertAdjacentElement('afterend', newLiEl);
        }
        catch (ex) {
            that.dom.insertAfter(newLiEl, li);
        }
        that.dom.remove(li);
        newLiEl.classList.remove('is-rendering');
        that.tryToEnableImageGroup();
        that.trigger(that.events.IMG_LOADED, { sequence: this.index, img: img });
        deferred.resolve();
    };
    CarouselViewer.prototype.onAltContentLoad = function () {
        if (this.viewer.isCustomViewer) {
            this.viewer.tryToEnableImageGroup();
            this.li.classList.remove('is-rendering');
            this.viewer.trigger(this.viewer.events.ALT_CONTENT_LOADED, { sequence: this.viewer.framesHandler.prefixes.ALT + this.index, viewerId: this.viewer.id });
        }
        else {
            ViewAbstract.prototype.onAltContentLoad.call(this);
        }
    };
    CarouselViewer.prototype.tryToEnableImageGroup = function () {
        var self = this;
        var addedFrames;
        var active;
        if (this.isCustomViewer) {
            addedFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-alt"]');
            active = this.ul360.querySelector('.active');
            if (!active) {
                this.firstImageLoadedTime = Date.now();
                this.handleActiveFrame(addedFrames[0], true);
            }
        }
        else {
            addedFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-"]:not([class*="-alt-"])');
            addedFrames = addedFrames.filter(function (element) {
                var className = element.className.match(/cylindo-viewer-frame-(\d+)/ig)[0];
                var occurrences = self.dom.querySelectorAll(self.ul360, '.' + className);
                return element.childNodes.length !== 0 &&
                    addedFrames.indexOf(element) === addedFrames.indexOf(occurrences[occurrences.length - 1]);
            });
        }
        addedFrames.forEach(function (element) {
            element.classList.remove('hidden');
        });
        if ((!this.isVisible && addedFrames.length > 0)) {
            this.displayViewer();
        }
        if (!this.isReady && addedFrames.length > 0) {
            this.onReady();
        }
        if (!this.isComplete && addedFrames.length > 0) {
            if (this.isCustomViewer) {
                this.onDownloadComplete();
                this.trigger(this.events.CONTENT_TYPE_COMPLETE, {
                    contentType: this.contentTypes.ALTERNATE
                });
            }
            else if (addedFrames.length >= this.frameCount) {
                this.onDownloadComplete();
            }
        }
    };
    CarouselViewer.prototype.tryToShowTooltip = function () {
        var currElement = this.ul360.querySelector('.active');
        var activeIndex = currElement.getAttribute('data-index');
        var is360Frame = this.framesHandler.is360Frame(activeIndex);
        var isAltContent = this.framesHandler.isAltId(activeIndex);
        var isVideo = currElement.classList.contains('cylindo-video');
        var isImgNotFound = currElement.classList.contains('cylindo-img-not-found');
        var carouselText = this.model.get('tooltipCarouselText');
        var altltImgText = this.model.get('tooltipAltImgText');
        var showTooltip = true;
        if (isImgNotFound || isVideo) {
            showTooltip = false;
        }
        if (this.tooltip && !this.tooltip.isVisible() && showTooltip) {
            if (is360Frame) {
                if (typeof carouselText === 'string' &&
                    carouselText.length > 0) {
                    this.showTooltip(carouselText);
                }
            }
            else if (isAltContent) {
                if (typeof altltImgText === 'string' &&
                    altltImgText.length > 0) {
                    this.showTooltip(altltImgText);
                }
            }
        }
    };
    CarouselViewer.prototype.tryToRemoveCustomImage = function () {
        var className = 'cylindo-custom-image';
        var customElement = this.ul360.querySelector('.' + className);
        try {
            this.dom.remove(customElement);
            this.customImageOn = false;
        }
        catch (ex) {
            logger.error('Cannot remove custome image', ex);
        }
        this.showCarouselBtns();
    };
    CarouselViewer.prototype.showImage = function (url, large) {
        if (this.isAutoSliding) {
            this.showImageRequested = true;
            this.customImageUrl = url;
            this.customImageLarge = large;
            this.removeMovementsTail.call(this);
        }
        else {
            this.showImageRequested = false;
            this.customImageUrl = null;
            this.customImageLarge = null;
            this.hideCarouselBtns();
            ViewAbstract.prototype.showImage.call(this, url, large);
        }
    };
    CarouselViewer.prototype.hideImage = function () {
        if (this.customImageOn) {
            this.showCarouselBtns();
            ViewAbstract.prototype.hideImage.call(this);
        }
    };
    CarouselViewer.prototype.isRightClick = function (evt) {
        return ViewAbstract.prototype.isRightClick.call(this, evt);
    };
    CarouselViewer.prototype.removeMovementsTail = function () {
        if (this.pendingMovements instanceof Array &&
            this.pendingMovements.length > 0) {
            this.pendingMovements = [];
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new CarouselViewer(opts);
        },
        getConstructor: function () {
            return CarouselViewer;
        }
    };

    window.cylindo.addModule('carouselViewer', publicAPI);
}).call(this);
