(function () {
    'use strict';

    var Vimeo = function (options) {
        var util = cylindo.getModule('cylindo.core.util');
        this.deferred = util.promise.create();
        this.apiProgress = Vimeo.apiProgress;
        this.id = options.id;
        this.videoId = options.videoId;
        this.APIUrl = 'https://player.vimeo.com/api/player.js';
        this.player = {};
        this.createVideo();
    };

    Vimeo.prototype.createVideo = function() {
        var self = this;
        var cb = function () {
            if (typeof window.Vimeo.Player === 'function') {
                self.player = new window.Vimeo.Player(self.id);
                self.deferred.resolve();
            }
        }; 
        this.checkAPIStatus(cb);
    };

    Vimeo.prototype.checkAPIStatus = function(cb) {
        if (!this.apiProgress.started && !this.apiProgress.finished && typeof window.Vimeo !== 'object') {
            this.embedAPIScript();
            this.apiProgress.fns.push(cb);
        } 
        else if (this.apiProgress.started && !this.apiProgress.finished && typeof window.Vimeo !== 'object') {
            this.apiProgress.fns.push(cb);
        }
        else if (typeof window.Vimeo === 'object') {
            cb.call();
        }
    };

    Vimeo.prototype.embedAPIScript = function () {
        var self = this;
        var tag = document.createElement('script');
        var firstScriptTag = document.getElementsByTagName('script')[0];
        tag.src = this.APIUrl;
        if (tag.readyState) {
            tag.onreadystatechange = function() {
              if ( tag.readyState === 'loaded' || tag.readyState === 'complete' ) {
                tag.onreadystatechange = null;
                self.APIListener();
              }
            };
        }
        else {
            tag.onload = function() {
              self.APIListener();
            };
        }
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        this.apiProgress.started = true;
    };

    Vimeo.prototype.APIListener = function() {
        this.apiProgress.finished = true;
        var len = this.apiProgress.fns.length;
        var i;
        for (i = 0; i < len; i++) {
            this.apiProgress.fns[i].call();
        }
        this.apiProgress.fns = [];
    };

    Vimeo.prototype.play = function () {
        this.player.play().then(function() {
        }).catch(function(error) { 
        });
    };

    Vimeo.prototype.pause = function () {
        this.player.pause().then(function() {
        }).catch(function(error) { 
        });
    };

    Vimeo.prototype.stop = function () {
    };

    Vimeo.prototype.destroy = function () {
    };

    var publicAPI = {
        create: function (opts) {
            return new Vimeo(opts);
        }
    };

    Vimeo.apiProgress = {
        'started' : false,
        'finished' : false,
        'fns' : []
    };

    window.cylindo.addModule('cylindo.video.vimeo', publicAPI);
}).call(this);