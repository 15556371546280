(function () {
    'use strict';

    var Gtag = function (opts) {
        var self = this;
        var dataLayer = opts.dataLayer;
        var events = opts.events;
        var gaKeys = opts.gaKeys;
        var prefixes = opts.prefixes;
        var googleMaps = cylindo.getModule('cylindo.core.google.maps');
        var eventsData = googleMaps.getEventsData();

        self.initialized = false;
        self.init = init;
        self.saveEvent = saveEvent;
        init();
        function init() {
            var i = 0;
            self.instanceID = ++Gtag.counter;
            self.initialized = true;
        }

        function saveEvent(eventData, clientData) {
            var gtagFn = 'gtag';
            var prop;
            var currentEvent;
            var isPageLoadEvent = false;
            var metricAdded = false;
            var withoutMetric = false;
            var dimensionsAdded = false;
            var toBeSent;
            var tmpToBeSent = {
                event: 'cylindoEvent',
                eventCategory: 'Cylindo',
                eventValue: undefined,
            };
            var pageLoadObj = {
                event: 'detail',
                ecommerce: {
                    detail: {
                        items: [{}]
                    }
                }
            };
            var key;
            tmpToBeSent[prefixes.DIMENSION + gaKeys.analytics360HitDimension] = undefined;
            tmpToBeSent[prefixes.DIMENSION + gaKeys.analytics360SessionDimension] = undefined;
            tmpToBeSent[prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = undefined;
            tmpToBeSent[prefixes.DIMENSION + gaKeys.analyticsARHitDimension] = undefined;
            tmpToBeSent[prefixes.DIMENSION + gaKeys.analyticsARSessionDimension] = undefined;
            tmpToBeSent[prefixes.METRIC + gaKeys.analytics360HitMetric] = undefined;
            tmpToBeSent[prefixes.METRIC + gaKeys.analyticsARHitMetric] = undefined;
            pageLoadObj.ecommerce.detail.items[0][prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = undefined;
            if (eventData && dataLayer) {
                try {
                    for (prop in events) {
                        if (events[prop] === eventData.e) {
                            currentEvent = eventsData[prop];
                        }
                    }
                    switch (eventData.e) {
                        case events.LOADED:
                            isPageLoadEvent = true;
                            withoutMetric = true;
                        break;
                        case events.SEEN:
                            withoutMetric = true;
                            break;
                        case events.FULLSCREEN:
                        case events.ZOOM:
                        case events.SPIN:
                        case events.FEATURE_CHANGED:
                        case events.THUMBNAIL_CLICK:
                            if (gaKeys.analytics360HitMetric) {
                                metricAdded = true;
                                tmpToBeSent[prefixes.METRIC + gaKeys.analytics360HitMetric] = 1;
                            }
                            break;
                        case events.AR_LOADED:
                        case events.AR_SEEN:
                            withoutMetric = true;
                            break;
                        case events.AR_CLICKED:
                        case events.AR_BANNER_CLICKED:
                        case events.AR_LAUNCHED:
                            if (gaKeys.analyticsARHitMetric) {
                                metricAdded = true;
                                tmpToBeSent[prefixes.METRIC + gaKeys.analyticsARHitMetric] = 1;
                            }
                            break;
                    }
                    if (currentEvent && ((currentEvent.ONE_PER_PAGE && currentEvent.TRIGGERED) || !currentEvent.UA) ){
                        return;
                    }
                    else {
                        tmpToBeSent.eventAction = currentEvent.ACTION;
                        tmpToBeSent.eventLabel = currentEvent.LABEL;
                        if (currentEvent.DIMENSION_VAL_360) {
                            if (gaKeys.analytics360HitDimension) {
                                tmpToBeSent[prefixes.DIMENSION + gaKeys.analytics360HitDimension] = currentEvent.DIMENSION_VAL_360;
                            }
                            if (gaKeys.analytics360SessionDimension) {
                                tmpToBeSent[prefixes.DIMENSION + gaKeys.analytics360SessionDimension] = currentEvent.DIMENSION_VAL_360;
                            }
                        }
                        if (currentEvent.DIMENSION_VAL_AR) {
                            if (gaKeys.analyticsARHitDimension) {
                                tmpToBeSent[prefixes.DIMENSION + gaKeys.analyticsARHitDimension] = currentEvent.DIMENSION_VAL_AR;
                            }
                            if (gaKeys.analyticsARSessionDimension) {
                                tmpToBeSent[prefixes.DIMENSION + gaKeys.analyticsARSessionDimension] = currentEvent.DIMENSION_VAL_AR;
                            }
                        }
                        if (isPageLoadEvent && eventData.d) {
                            if (gaKeys.analytics360ProductDimension && eventData.d.hasOwnProperty('t') && eventData.d.t > 0) {
                                dimensionsAdded = true;
                                pageLoadObj.ecommerce.detail.items[0][prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = 'Cylindo360';
                            }
                            if (dimensionsAdded) {
                                pageLoadObj.ecommerce.detail.items[0].id = clientData.productCode;
                                pageLoadObj.ecommerce.detail.items[0].name = clientData.productCode;
                                toBeSent = {
                                    'items': pageLoadObj.ecommerce.detail.items,
                                };
                                gtagaFn()('event', pageLoadObj.event, toBeSent);
                            }
                        }
                        if (metricAdded || withoutMetric) {
                            toBeSent = {
                                'event_action': tmpToBeSent.eventAction,
                                'event_category': tmpToBeSent.eventCategory,
                                'event_label': tmpToBeSent.eventLabel,
                                'value': tmpToBeSent.eventValue,
                            };
                            for (key in tmpToBeSent) {
                                if (key.indexOf(prefixes.DIMENSION) !== -1 || key.indexOf(prefixes.METRIC) !== -1) {
                                    toBeSent[key] = tmpToBeSent[key];
                                }
                            }
                            gtagaFn()('event', tmpToBeSent.eventAction, toBeSent);
                            currentEvent.TRIGGERED = true;
                        }
                    }
                }
                catch (ex) {
                    console.log('Something went wrong while trying to send the event using Global Site Tag.', ex);
                }
            }
            function gtagaFn() {
                return window[gtagFn] || window['_' + gtagFn];
            }

                    }
    };

    Gtag.counter = 0;

    var publicAPI = {
        create: function (opts) {
            return new Gtag(opts);
        }
    };

    window.cylindo.addModule('cylindo.core.ua.gtag', publicAPI);
}).call(this);

