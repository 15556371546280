(function () {
    'use strict';

    var publicServices = {
        preventScrollX: preventScrollX,
        allowScrollX: allowScrollX
    };
    var scrollPrevented = false;

    var tagsToDismiss = ['HTML', 'BODY'];

    var elementsWithScrollPrevented = [];
    function preventScrollX(element) {
        if (scrollPrevented) {
            return;
        }
        var scrollable = getScrollParent(element, false);
        var scrollableEl = scrollableIsDocument(scrollable) ? scrollable.body : scrollable;
        if (scrollableEl && scrollableEl.classList) {
            scrollableEl.classList.add('cylindo-prevent-scroll');

        }
        elementsWithScrollPrevented.push(scrollableEl);
        if (scrollable.parentNode && !scrollableIsDocument(scrollable)) {
            preventScrollX(scrollable);
        }
        else {
            scrollPrevented = true;
        }
    }
    function allowScrollX() {
        var i = 0;
        var element = null;
        for (i = 0; i < elementsWithScrollPrevented.length; i++) {
            element = elementsWithScrollPrevented[i];
            element.classList.remove('cylindo-prevent-scroll');

        }
        scrollPrevented = false;
    }
    function getScrollParent(element, includeHidden) {
        var elemStyle = getComputedStyle(element);
        var excludeStaticParent = elemStyle.position === 'absolute';
        var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
        var parent;
        if (elemStyle.position === 'fixed') {
            return element.ownerDocument || document;
        }
        for (parent = element; (parent = parent.parentElement);) {
            elemStyle = getComputedStyle(parent);
            if (excludeStaticParent && elemStyle.position === 'static') {
                continue;
            }
            if (parent !== document.documentElement &&
                tagsToDismiss.indexOf(parent.tagName) === -1 &&
                overflowRegex.test(elemStyle.overflowX) &&
                parent.scrollWidth > parent.clientWidth) {
                return parent;
            }
        }
        return element.ownerDocument || document;
    }
    function scrollableIsDocument(scrollable) {
        return scrollable instanceof HTMLDocument;
    }


    window.cylindo.addModule('cylindo.helpers.scroll', publicServices);
}).call(this);
