
(function () {
    'use strict';
    var DAO = function (opts) {
        var self = this;
        var config = cylindo.getModule('cylindo.core.config');
        var network = cylindo.getModule('cylindo.core.network');
        var utils = cylindo.getModule('cylindo.core.util');
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var database = cylindo.getModule('cylindo.classes.database').create({
            logger: opts.logger,
            viewerData: opts.viewerData
        });
        var logger = opts.logger;
        var currentDataStatus = opts.currentDataStatus;
        var settings = {
            defaults: config.get('defaults'),
            urls: config.get('urls'),
            metadata: config.get('metadata')
        };
        var requestsAborted = [];
        var previousRequestID = 0;
        var previousImage = null;
        var isFirstRun = true;
        var tags = opts.tags;
        var viewerDataRef = opts.viewerData;

        this.utils = utils;
        this.fetchFeatures = fetchFeatures;
        this.updatePaths = updatePaths;
        this.getResolvedFeatures = getResolvedFeatures;
        this.destroy = destroy;
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;

        function fetchFeatures(params) {
            var deferred = utils.promise.create();
            var startFrameAdress, additionalDataAddress;
            var ftToBeShown = params.features instanceof Array ? params.features.slice() : [];
            var paramsCopy = utils.object.extend({}, params, {
                ftToBeShown: ftToBeShown
            });
            var featuresKey = ftToBeShown.join('*');
            var picturePaths = utils.picture.getPaths(settings, paramsCopy);
            var accountID = paramsCopy.accountID;
            var productCode = paramsCopy.productCode;
            var ftKey = ftToBeShown.join('*');
            var variationCached = database && database.isInitialized() ?
                database.getData(productCode + '-' + ftKey) : null;
            var additionalDataCached = database && database.isInitialized() ?
                database.getData(productCode) : null;
            var refRequestID;
            var startFrame = paramsCopy.startFrame || 1;
            var firstFeaturesFailed = false;
            var firstSetOfFeatures = [];
            var requestID = null;
            var firstFrameImage = new Image();
            var errMsgs = {
                FIRST_RUN: 'First set of features failed while trying to get the images. Trying with the default set of features instead. Please check your product code and features to ensure this is a valid combination.',
                DEFAULT: 'Set of features failed while trying to get the images. Please check your product code and features to ensure this is a valid combination.',
                ABORTED: 'Set of features canceled'
            };
            startFrameAdress = picturePaths.framePath.replace('__FRAME_INDEX__', startFrame);
            additionalDataAddress = picturePaths.additionalDataUrl;
            if (ftToBeShown instanceof Array) {
                if (previousRequestID) {
                    logger.log('Previous request aborted.', previousRequestID);
                    requestsAborted.push(previousRequestID);
                    network.abort(previousRequestID);
                }
                if (previousImage) {
                    logger.log('Previous image request aborted.', previousImage);
                    previousImage.onload = null;
                    previousImage.onerror = null;
                    previousImage.src = '';
                }
                previousImage = firstFrameImage;
                if (variationCached) {
                    logger.log('Variation found in cache.', variationCached);
                    tryToResolve(variationCached);
                }
                else if (additionalDataCached) {
                    logger.log('Additional data found in cache.', additionalDataCached);
                    firstFrameImage.src = startFrameAdress;
                    firstFrameImage.onload = function () {
                        firstFrameImage.onload = null;
                        firstFrameImage.onerror = null;
                        additionalDataCached.firstFrameImage = firstFrameImage.cloneNode();
                        database.processData(productCode, featuresKey, additionalDataCached);
                        logger.log('Variation stored.', productCode, featuresKey, additionalDataCached);
                        tryToResolve(additionalDataCached);
                    };
                    firstFrameImage.onerror = function () {
                        firstFrameImage.onload = null;
                        firstFrameImage.onerror = null;
                        reject(paramsCopy);
                    };
                }
                else {
                    logger.log('Variation/AdditionalData not found in cache, trying to get it.', additionalDataAddress, firstFrameImage);
                    previousRequestID = requestID = network.doRequest(
                        additionalDataAddress,
                        function (response) {
                            var requestFromFirstRun = isFirstRun;
                            var additionalData = response ?
                                typeof response === 'object' ?
                                    response : JSON.parse(response) :
                                null;
                            isFirstRun = false;
                            if (!additionalData) {
                                reject(paramsCopy);
                                return;
                            }
                            if (!tags.isInitialized) {
                                tags.init(accountID, productCode, ftToBeShown, additionalData.viewerAnalyticsData);
                            }
                            if (startFrame < 1 || startFrame > additionalData.frameCount) {
                                logger.warning('The startFrame value provided is out of the limits. The startFrame property has been set to 1, the value '  + startFrame + ' has been dismissed.');
                                params.startFrame = startFrame = 1;
                                startFrameAdress = picturePaths.framePath.replace('__FRAME_INDEX__', 1);
                            }
                            additionalData.startFrame = startFrame;
                            previousRequestID = null;
                            if (database) {
                                if (!database.isInitialized()) {
                                    database.init();
                                }
                                database.setData(productCode, additionalData);
                                logger.log('Additional data stored.', productCode, additionalData);
                                firstFrameImage.src = startFrameAdress;
                                firstFrameImage.onload = function () {
                                    firstFrameImage.onload = null;
                                    firstFrameImage.onerror = null;
                                    additionalData.firstFrameImage = firstFrameImage.cloneNode();
                                    database.processData(productCode, featuresKey, additionalData);
                                    tags.updateProductVersion(viewerDataRef.version || additionalData.version);
                                    logger.log('Variation stored.', productCode, featuresKey, additionalData);
                                    tryToResolve(additionalData);
                                };
                                firstFrameImage.onerror = function () {
                                    firstFrameImage.onload = null;
                                    firstFrameImage.onerror = null;
                                    if (requestFromFirstRun) {
                                        tryWithDefault();
                                    }
                                    else {
                                        reject(paramsCopy);
                                    }
                                };
                            }
                            else {
                                logger.error('Database module not found.');
                                reject(paramsCopy);
                            }
                        },
                        {
                            method: 'OPTIONS'
                        },
                        function (errorResponse, xhr) {
                            var errorTxt = errorResponse && errorResponse.response || 'Unknown';
                            var abortedIndex = requestsAborted.indexOf(requestID);
                            if (abortedIndex !== -1) {
                                requestsAborted.splice(abortedIndex, 1);
                                logger.error(errMsgs.ABORTED, ftToBeShown);
                                return;
                            }
                            if (isFirstRun) {
                                isFirstRun = false;
                                tryWithDefault();
                            }
                            else {
                                reject(errorResponse);
                                logger.error(errMsgs.DEFAULT, errorTxt, ftToBeShown);

                            }
                        },
                        null
                    );
                }
            }
            else {
                reject(paramsCopy);
            }
            function reject(paramsRejected) {
                deferred.reject(paramsRejected);
            }
            function resolve(paramsResolved) {
                if (paramsResolved) {
                    paramsResolved.firstFeaturesFailed = firstFeaturesFailed;
                    paramsResolved.firstSetOfFeatures = firstSetOfFeatures;
                    deferred.resolve(paramsResolved);
                }
                else {
                    reject(paramsResolved);
                }
            }
            function tryToResolve(productData) {
                if (productData && productData.error) {
                    reject(productData);
                }
                else {
                    ++currentDataStatus.requestID;
                    refRequestID = currentDataStatus.requestID;
                    picturePaths = updatePaths(paramsCopy);
                    logger.log('Set of features resolved succesfully.', productData);
                    resolve({
                        paths: picturePaths,
                        data: productData,
                        refRequestID: refRequestID,
                        ftToBeShown: ftToBeShown,
                    });
                }
            }
            function tryWithDefault() {
                var retryDeferred;
                firstFeaturesFailed = true;
                firstSetOfFeatures = paramsCopy.features;
                logger.error(errMsgs.FIRST_RUN, paramsCopy.features);
                params.features = [];
                retryDeferred = fetchFeatures(params);
                retryDeferred.then(resolve, reject);
            }
            return deferred;
        }

        function updatePaths(params) {
            return utils.picture.getPaths(settings, params);
        }

        function getResolvedFeatures(params, cb) {
            var requestOpts = {
                type: 'GET'
            };
            var picturePaths = utils.picture.getPaths(settings, params);
            network.doRequest(
                picturePaths.resolvedFtPath,
                function (response, xhr) {
                    if (cb) {
                        cb(response);
                    }
                },
                requestOpts,
                function (response, xhr) {
                    if (cb) {
                        cb(response);
                    }
                },
                null
            );
        }

        function destroy() {
            if (previousRequestID) {
                network.abort(previousRequestID);
            }
            database = null;
            self.trigger(self.events.DESTROYED);
        }
    };

    DAO.prototype.events = {
        DESTROYED: 'dao:destroyed',
    };

    var publicAPI = {
        create: function (opts) {
            return new DAO(opts);
        }
    };

    window.cylindo.addModule('DAO', publicAPI);
}).call(this);