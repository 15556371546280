(function () {
    'use strict';

    var tags = cylindo.getModule('tags') || {};

    var TagsHandler = function (opts) {
        var self = this;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var util = cylindo.getModule('cylindo.core.util');
        var gaProperty = null;
        var gaProperties = {
            UA: 'Ua',
            GA4: 'Ga4'
        };
        var tagsHandler = null;
        var tagsHandlers = {
            UA: {
                GA: cylindo.getModule('cylindo.core.ua.ga'),
                GTAG: cylindo.getModule('cylindo.core.ua.gtag'),
                GTM: cylindo.getModule('cylindo.core.ua.gtm'),
            },
            GA4: {
                GTAG: cylindo.getModule('cylindo.core.ga4.gtag'),
                GTM: cylindo.getModule('cylindo.core.ga4.gtm'),
            }
        };
        var network = cylindo.getModule('cylindo.core.network');
        var clientData = {};
        var disableTags = false;
        opts = opts || {};
        this.on = pubsub.on;
        this.trigger = pubsub.trigger;
        this.off = pubsub.off;
        this.opts = opts;
        this.data = {
            instanceID: 0
        };
        this.destroy = destroy;
        this.send = send;
        this.updateProductCode = updateProductCode;
        this.updateFeatures = updateFeatures;
        this.updateProductVersion = updateProductVersion;
        this.updateARFlags = updateARFlags;
        this.disableTags = function (flag) {
            disableTags = flag;
        };

        this.isInitialized = false;
        this.init = init;

        function init(cid, productCode, features, _analyticsData) {
            var defaultDataLayerName = 'dataLayer';
            var gaName = null;
            var gaObj = 'GoogleAnalyticsObject';
            var gtagFnName = 'gtag';
            var errorMsg = "Cylindo Error: GTM/GTAG/GA core not found, please make sure you have one of them. For google analytics core make sure you've also created a tracker.";
            var propertyErrorMsg = "Cylindo Error: UA or GA4 property not defined";
            var modules = {
                GTM: 'GoogleTagManager',
                GTAG: 'GlobalSiteTag',
                GA: 'GoogleAnalytics',
            };
            self.isInitialized = true;
            if (self.data.instanceID) {
                self.destroy();
            }
            var analyticsData = _analyticsData && _analyticsData.hasOwnProperty('analyticsModule') ? _analyticsData : null;

            if (analyticsData && analyticsData.analyticsPropertyType) {
                gaProperty = (gaProperties.UA === analyticsData.analyticsPropertyType || gaProperties.GA4 === analyticsData.analyticsPropertyType) ?
                    analyticsData.analyticsPropertyType :
                    null;
                if (!gaProperty) {
                    if (console.error) {
                        console.error(propertyErrorMsg);
                    }
                    else {
                        console.log(propertyErrorMsg);
                    }
                    return;
                }
                opts.gaKeys = {
                    analytics360HitDimension: analyticsData.analytics360HitDimension,
                    analytics360SessionDimension: analyticsData.analytics360SessionDimension,
                    analytics360ProductDimension: analyticsData.analytics360ProductDimension,
                    analyticsARHitDimension: analyticsData.analyticsARHitDimension,
                    analyticsARSessionDimension: analyticsData.analyticsARSessionDimension,
                    analytics360HitMetric: analyticsData.analytics360HitMetric,
                    analyticsARHitMetric: analyticsData.analyticsARHitMetric,
                };
                opts.prefixes = {
                    DIMENSION: 'dimension',
                    METRIC: 'metric'
                };
                if (analyticsData.analyticsModule === modules.GA && gaFn()) {
                    gaFn()(function (_t) {
                        if (_t && typeof _t.get === 'function' && _t.get('name')) {
                            gaName = _t.get('name');
                        }
                        else if (gaFn() && typeof gaFn().getAll === 'function' && gaFn().getAll().length > 0) {
                            gaName = gaFn().getAll()[0].get('name');
                        }
                        if (tryToInitializeEventsHandler()) {
                            opts.gaName = gaName;
                            tagsHandler = tagsHandlers.UA.GA.create(util.object.extend(opts, self));
                        }
                    });
                }
                else if ((analyticsData.analyticsModule === modules.GTM || analyticsData.analyticsModule === modules.GTAG) && window[defaultDataLayerName]) {
                    gaName = defaultDataLayerName;
                    if (tryToInitializeEventsHandler()) {
                        opts.dataLayer = window[gaName];
                        if (gaProperty === gaProperties.GA4) {
                            if (analyticsData.analyticsModule === modules.GTAG && gtagFn()) {
                                tagsHandler = tagsHandlers.GA4.GTAG.create(util.object.extend(opts, self));
                            }
                            else if (analyticsData.analyticsModule === modules.GTM) {
                                tagsHandler = tagsHandlers.GA4.GTM.create(util.object.extend(opts, self));
                            }
                        }
                        else {
                            if (analyticsData.analyticsModule === modules.GTAG && gtagFn()) {
                                tagsHandler = tagsHandlers.UA.GTAG.create(util.object.extend(opts, self));
                            }
                            else if (analyticsData.analyticsModule === modules.GTM) {
                                tagsHandler = tagsHandlers.UA.GTM.create(util.object.extend(opts, self));
                            }
                        }
                    }
                }
            }
            function tryToInitializeEventsHandler() {
                if (!gaName) {
                    if (console.error) {
                        console.error(errorMsg);
                    }
                    else {
                        console.log(errorMsg);
                    }
                    return false;
                }
                else {
                    self.data.instanceID = ++TagsHandler.counter;
                    clientData.clientID = cid;
                    clientData.productCode = productCode;
                    clientData.features = features;
                    clientData.ARDesktop = false;
                    clientData.ARBanner = false;
                    return true;
                }
            }
            function gaFn() {
                return window[window[gaObj]];
            }
            function gtagFn() {
                return window[gtagFnName] || window['_' + gtagFnName];
            }
        }
        function destroy() {
            if (pubsub && typeof pubsub.destroy === 'function') {
                pubsub.destroy();
            }
        }
        function send(event, data, cb) {
            var dataToBeSend;
            if (disableTags) {
                return;
            }
            dataToBeSend = {
                t: Date.now(),
                e: event,
                d: data || {},
                c: cb || null
            };
            saveEvent(dataToBeSend);
        }
        function saveEvent(eventData) {
            if (tagsHandler && tagsHandler.initialized) {
                tagsHandler.saveEvent(eventData, clientData);
            }
        }
        function updateProductCode(productCode) {
            opts.productCode = productCode;
            clientData.productCode = productCode;
        }
        function updateFeatures(features) {
            opts.features = features;
            clientData.features = features;
        }
        function updateProductVersion(version) {
            opts.version = version;
            clientData.version = version;
        }
        function updateARFlags(ARDesktop, ARBanner) {
            opts.ARDesktop = ARDesktop;
            clientData.ARDesktop = ARDesktop;
            opts.ARBanner = ARBanner;
            clientData.ARBanner = ARBanner;
        }
    };
    TagsHandler.prototype.events = {
        LOADED: 1,
        SEEN: 2,
        FULLSCREEN: 3,
        ZOOM: 4,
        SPIN: 5,
        FEATURE_CHANGED: 6,
        THUMBNAIL_CLICK: 7,
        ROOM_SCENE_LOADED: 8,
        ROOM_SCENE_SEEN: 9,
        AR_LOADED: 10,
        AR_SEEN: 11,
        AR_LAUNCHED: 12,
        AR_CLICKED: 13,
        AR_BANNER_CLICKED: 14,
        FEATURE_ERROR: 15,
        ALT_IMAGE_LOADED: 16,
        ALT_IMAGE_VIEWED: 17,
        ERROR_LOADING: 18,
    };
    TagsHandler.counter = 0;
    tags.handler = {};
    tags.handler.create = function (opts) {
        return new TagsHandler(opts);
    };

    cylindo.addModule('tags', tags, true);
}).call(this);
