(function () {
    'use strict';
    var AbstractButton = cylindo.getModule('cylindo.classes.button');
    function UsdzBtn(opts) {
        opts.appendButton = false;
        AbstractButton.call(this, opts);
        var network = cylindo.getModule('cylindo.core.network');
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var paths = opts.settings.get('urls');
        var usdzEndPoint = paths.AR;
        var util = opts.util;

        this.util = util;
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;
        this.usdzSrc = null;
        this.network = network;
        this.settings = opts.settings;
        this.wrapperClasses = ['cylindo-button', 'cylindo-ar-button'];
        this.model = opts.model;
        this.logger = this.model.getLogger();
        this.currentFeatures = this.model.get('currentFeatures');
        this.urls = this.currentFeatures ? this.currentFeatures.paths : null;
        this.tags = opts.tags;
        this.preventEvents = opts.preventEvents;
        this.usdzEndPoint = usdzEndPoint;
        this.initialized = false;
        this.btnExistsPromise = this.util.promise.create();
        this.destroy = destroy;
        this.arOpen = false;
        function destroy() {
            pubsub.destroy();
            self.btn = null;
        }
    }
    UsdzBtn.prototype = Object.create(AbstractButton.prototype);
    UsdzBtn.prototype.constructor = UsdzBtn;
    UsdzBtn.prototype.render = function () {
        var self = this;
        var image = new Image();
        var arBtnContainer = document.createElement('div');
        var arBtnIcon = document.createElement('div');
        var arBtnText = document.createElement('div');
        arBtnText.innerHTML = self.model.get('ARBtnTxt');
        arBtnContainer.classList.add('ar-glyph-background');
        arBtnIcon.classList.add('ar-icon');
        arBtnText.classList.add('ar-text');
        arBtnContainer.appendChild(arBtnIcon);
        arBtnContainer.appendChild(arBtnText);
        AbstractButton.prototype.render.call(self);
        image.src = '';
        self.btn.appendChild(image);
        self.btn.appendChild(arBtnContainer);
        self.btn.setAttribute('rel', 'ar');
        self.btn.setAttribute('href', '#');
        self.btn.setAttribute('role', 'link');
        self.btn.setAttribute('aria-label', self.ariaLabel.texts.VIEW_IN_AR);
        self.btn.addEventListener('mousedown', preventViewerEvents, true);
        self.btn.addEventListener('mouseup', preventViewerEvents, true);
        self.btn.addEventListener('touchstart', preventViewerEvents, true);
        self.btn.addEventListener('touchend', openAR, true);
        self.btn.addEventListener("message", detectARQuickLookTap, false);
        self.tryToShow();
        function preventViewerEvents(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();
                self.preventEvents();
            }
        }
        function openAR(evt) {
            if (evt) {
                preventViewerEvents(evt);
            }
            self.trigger(self.events.AR_SHOW_MODAL, null);
            document.addEventListener('visibilitychange', function () {
                if (document.visibilityState === 'visible') {
                    self.trigger(self.events.AR_HIDE_MODAL, null);
                }
            });
            self.tags.send(self.tags.events.AR_LAUNCHED);
            self.tags.send(self.tags.events.AR_CLICKED);
            self.trigger(self.events.AR_LAUNCHED, null);
            setTimeout(function () {
                self.btn.click();
            }, 3000);
        }
        function detectARQuickLookTap(evt) {
            if (evt && evt.data === "_apple_ar_quicklook_button_tapped") {
                self.tags.send(self.tags.events.AR_BANNER_CLICKED);
                self.trigger(self.events.AR_ACTION_BTN_TAPPED, evt);
            }
        }
        self.initialized = true;
    };
    UsdzBtn.prototype.tryToShow = function () {
        var self = this;
        var usdzSrc;
        var isArEnabled;
        var arErrors = {
            AR_NOT_ENABLED: 'AR not enabled for this product or version.',
            WRONG_PATHS: 'AR paths not found.',
        };
        var error = '';
        self.currentFeatures = self.model.get('currentFeatures');
        isArEnabled = self.currentFeatures && self.currentFeatures.data ? self.currentFeatures.data.isArEnabled : false;
        self.urls = self.currentFeatures ? self.currentFeatures.paths : null;
        self.hide();
        if (self.model.isFirstSetOfFt() && self.model.didFirstFeaturesFailed()) {
            self.logger.error('First group of features failed, AR won\'t be displayed.');
            return;
        }
        if (!isArEnabled || !self.urls || !self.urls.arPath) {
            error = isArEnabled ? arErrors.WRONG_PATHS : arErrors.AR_NOT_ENABLED;
            self.trigger(self.events.AR_BUTTON_ERROR, {});
            self.logger.error('Cannot create USDZ file for this product.', error);
            return;
        }
        else {
            usdzSrc = self.urls.arPath.replace('__AR_FORMAT__', 'usdz');
        }
        usdzSrc = usdzSrc.replace('__AR_FILE__', 'AR');
        self.el.innerHTML = '';
        self.network.doRequest(usdzSrc, function (responsedata, xhr) {
            self.logger.log('Usdz file found for this combination. ', usdzSrc);
            arSuccess(usdzSrc);
        }, {
            type: 'HEAD',
        }, arFailed,
            null
        );
        function arSuccess(usdzSrc) {
            var property;
            var properties = [];
            var ARBannerConfig = self.model.get('ARBannerConfig');
            for (property in ARBannerConfig) {
                if (typeof ARBannerConfig[property] === 'string') {
                    properties.push(property + '=' + encodeURIComponent(ARBannerConfig[property]));
                }
            }
            if (properties.length > 0) {
                usdzSrc = usdzSrc + '#' + properties.join('&');
            }
            self.usdzSrc = usdzSrc;
            self.btn.setAttribute('href', self.usdzSrc);
            self.el.appendChild(self.btn);
            self.show();
            self.trigger(self.events.AR_BUTTON_READY, {
                url: self.usdzSrc
            });
            self.btnExistsPromise.resolve({
                usdz: self.usdzSrc,
            });
        }
        function arFailed() {
            var err = 'USDZ not found for the requested feature set.';
            self.usdzSrc = null;
            self.trigger(self.events.AR_BUTTON_ERROR, {});
            self.logger.error(err);
            self.btnExistsPromise.reject({
                err: err,
            });
        }
    };
    UsdzBtn.prototype.onFeaturesError = function () {
        var self = this;
        self.usdzSrc = null;
        self.hide();
        self.trigger(self.events.AR_BUTTON_ERROR, {});
    };

    UsdzBtn.prototype.getArQuickLookUrl = function () {
        return this.usdzSrc || null;
    };

    UsdzBtn.prototype.events = {
        AR_BUTTON_READY: 'ar:button:ready',
        AR_BUTTON_ERROR: 'ar:button:error',
        AR_LAUNCHED: 'ar:launched',
        AR_SHOW_MODAL: 'ar:show:modal',
        AR_HIDE_MODAL: 'ar:hide:modal',
        AR_ACTION_BTN_TAPPED: 'ar:action:btn:tapped',
        AR_SHOW_ERROR_MODAL: 'ar:show:error:modal',
    };
    var publicAPI = {
        create: function (opts) {
            return new UsdzBtn(opts);
        }
    };
    window.cylindo.addModule('cylindo.button.ar.usdz', publicAPI);
}.call(this));
