(function () {
    'use strict';

    function partial(method) {
        var storedArgs = Array.prototype.slice.call(arguments);
        storedArgs.shift();

        return function () {
            var methodArgs = Array.prototype.slice.call(arguments);
            var args = storedArgs.concat(methodArgs);

            return method.apply(null, args);
        };
    }
    function template(templateString) {
        var scapeElement = /<%([^%>]+)?%>/g;
        return function (data) {
            var matchData;
            var templateStringFixed = templateString;
            while((matchData = scapeElement.exec(templateStringFixed))) {
                templateStringFixed = templateStringFixed.replace(new RegExp(matchData[0], 'g'), data[matchData[1].trim()] || '');
            }
            return templateStringFixed;
        };
    }

    var publicServices = {
        partial: partial,
        template: template
    };

    window.cylindo.addModule('cylindo.helpers.function', publicServices);
}).call(this);
