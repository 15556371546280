(function () {
    'use strict';
    var DEFAULT = {};
    var ThumbScroll = function (options) {
        var that = this;
        var util = cylindo.getModule('cylindo.core.util');
        var dom = options.dom;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();

                options = options || {};

        this.pubsub = pubsub;
		this.on = pubsub.on;
		this.off = pubsub.off;
		this.trigger = pubsub.trigger;        
        this.util = util;
        this.dom = dom;
        this.el = options.el;
        this.thumbPageSize = options.thumbPageSize;
        this.min = 0;
        this.offset = options.offset || 0;
        this.offsetReference = 0;
        this.pressed = false;
        this.xform = 'transform';
        this.velocity = null;
        this.frame = null;
        this.timestamp = null;
        this.ticker = null;
        this.amplitude = null;
        this.target = null;
        this.timeConstant = options.timeConstant || 500;
        this.reference = null;
        this.parentEl = this.el.parentNode;
        this.wrapper = options.wrapper;
        this.isScrolling = false;
        this.isAnimating = false;
        this.closestSibling = null;
        this.model = options.model;
        this.leftBtn = this.wrapper.querySelector('.cylindo-thumbnail-slider.left');
        this.rightBtn = this.wrapper.querySelector('.cylindo-thumbnail-slider.right');


        this.thumbLocations = this.model.getThumbLocations();
        this.thumbLocation = this.model.get('thumbLocation');

        ['webkit', 'Moz', 'O', 'ms'].every(function (prefix) {
            var e = prefix + 'Transform';
            if (typeof this.el.style[e] !== 'undefined') {
                this.xform = e;
                return false;
            }
            return true;
        }.bind(this));

        this.endAnimation = this.endAnimation.bind(this);
        this.clearEndStep = this.clearEndStep.bind(this);
        this.tap = this.tap.bind(this);
        this.drag = this.drag.bind(this);
        this.release = this.release.bind(this);
        this.wheelScroll = this.wheelScroll.bind(this);
        this.startStep = this.startStep.bind(this);
        this.endStep = this.endStep.bind(this);

        this.wrapper.classList.add('has-scroll');
        this.wrapper.classList.add('scroll-min');

        this.leftBtn.setAttribute('aria-hidden', true);

        if (this.wrapper.classList.contains('scroll-max')) {
            this.wrapper.classList.remove('scroll-max');
            this.leftBtn.setAttribute('aria-hidden', false);
        }

        this.addEvents();

        if (this.offset !== 0) {
            setTimeout(function () {
                that.scroll(that.offset);
            }, 100);
        }
    };
    ThumbScroll.prototype.events = {
        THUMB_CLICKED: 'thumbscroll.clicked'
    };
    ThumbScroll.prototype.wheelScroll = function (e) {
        var delta = this.areVerticalThumbs() ? e.deltaY : e.deltaX;
        var currentPos;

                if (delta >= 2 || delta <= -2) {
            currentPos = this.currentPos(e);
            this.reference = currentPos;
            this.scroll(this.offset + delta);
            e.stopPropagation();
            return false;
        }    

    };
    ThumbScroll.prototype.currentPos = function (e) {
        if (e.targetTouches && e.targetTouches.length >= 1) {
            return this.areVerticalThumbs() ?
            e.targetTouches[0].clientY :
            e.targetTouches[0].clientX;
        }

        return this.areVerticalThumbs() ? e.clientY : e.clientX;
    };
    ThumbScroll.prototype.getElementWidth = function () {
        return this.areVerticalThumbs() ? 
        this.getMax() + this.parentEl.clientWidth :
        this.getMax() + this.parentEl.clientHeight;
    };
    ThumbScroll.prototype.getMax = function () {
        var max = this.areVerticalThumbs() ?
        this.el.childNodes[0].clientHeight * (this.el.childNodes.length - this.thumbPageSize) :
        this.el.childNodes[0].clientWidth * (this.el.childNodes.length - this.thumbPageSize);
        return max;
    };
    ThumbScroll.prototype.getStepSize = function () {
        return this.areVerticalThumbs() ?
        this.el.childNodes[0].clientHeight * this.thumbPageSize :
        this.el.childNodes[0].clientWidth * this.thumbPageSize;
    };
    ThumbScroll.prototype.getRelative = function () {
        var max = this.getMax();
        return this.areVerticalThumbs() ? 
        (this.parentEl.clientHeight - 20) / max:
        (this.parentEl.clientWidth - 20) / max;
    };
    ThumbScroll.prototype.scroll = function (position, smooth) {
        var translateProperty  = this.areVerticalThumbs() ?  'translateY' : 'translateX';
        this.max = this.getMax();
        this.offset = position > this.max ? this.max : position < this.min ? this.min : position;

        if (smooth) {
            this.el.style.transition = 'transform 200ms linear';
        }
        else {
            this.el.style.transition = 'none';
        }

        this.el.style[this.xform] = translateProperty + '(' + -this.offset + 'px)';

        if (this.offset === this.max) {
            this.leftBtn.setAttribute('aria-hidden', false);
            this.rightBtn.setAttribute('aria-hidden', true);
            this.wrapper.classList.remove('scroll-min');
            this.wrapper.classList.add('scroll-max');
        }
        else if (this.offset === this.min) {
            this.leftBtn.setAttribute('aria-hidden', true);
            this.rightBtn.setAttribute('aria-hidden', false);      
            this.wrapper.classList.add('scroll-min');
            this.wrapper.classList.remove('scroll-max');
        }
        else {
            this.leftBtn.setAttribute('aria-hidden', false);
            this.rightBtn.setAttribute('aria-hidden', false);      
            this.wrapper.classList.remove('scroll-min');
            this.wrapper.classList.remove('scroll-max');
        }
    };
    ThumbScroll.prototype.store = function () {
        var now, elapsed, delta, v;

        now = Date.now();
        elapsed = now - this.timestamp;
        this.timestamp = now;
        delta = this.offset - this.frame;
        this.frame = this.offset;

        v = 1000 * delta / (1 + elapsed);
        this.velocity = 0.8 * v + 0.2 * this.velocity;
    };
    ThumbScroll.prototype.autoScroll = function () {
        var elapsed, delta;
        if (this.amplitude) {
            elapsed = Date.now() - this.timestamp;
            delta = -this.amplitude * Math.exp(-elapsed / this.timeConstant);
            if (delta > 0.5 || delta < -0.5) {
                this.scroll(this.target + delta);
                requestAnimationFrame(this.autoScroll.bind(this));
            }
            else {
                this.scroll(this.target);
            }
        }
    };
    ThumbScroll.prototype.tap = function (e) {
        e.stopImmediatePropagation();
        this.addDocumentEvents();
        this.offsetReference = this.offset;
        if (this.getMax() <= 0) {
            return;
        }
        this.pressed = true;
        this.reference = this.currentPos(e);
        this.velocity = this.amplitude = 0;
        this.frame = this.offset;
        this.timestamp = Date.now();
        clearInterval(this.ticker);
        this.ticker = setInterval(this.store.bind(this), 100);

        this.closestSibling = setTimeout(function () {
            var li = e.target.tagName === 'IMG' ? e.target.parentElement : e.target.tagName === 'LI' ? e.target : null;
            var liRect = li.getBoundingClientRect();
            var thumbDiv = this.wrapper.querySelector('.cylindo-thumbnail-bar');
            var thumbDivRect = thumbDiv.getBoundingClientRect();
            var liMinOffset, liMaxOffset, ulMinOffset, ulMaxOffset;
            var childSize =  this.areVerticalThumbs() ? liRect.height : liRect.width;
            var smooth = true;
            var rightScroll;

            liMinOffset = this.areVerticalThumbs() ? liRect.top : liRect.left;
            liMaxOffset = this.areVerticalThumbs() ? liRect.top + liRect.height: liRect.left + liRect.width;
            ulMinOffset =  this.areVerticalThumbs() ? thumbDivRect.top : thumbDivRect.left;
            ulMaxOffset =  this.areVerticalThumbs() ? thumbDivRect.top + thumbDivRect.height : thumbDivRect.left + thumbDivRect.width;
            if (ulMinOffset + 2 > liMinOffset && ulMinOffset - 2 < liMinOffset) {
                this.scroll(this.offset + childSize * -1, true);
                rightScroll = false;
            }
            else if (liMinOffset < ulMinOffset + childSize && liMinOffset > ulMinOffset) {
                this.scroll(this.offset + (childSize - (liMinOffset - ulMinOffset)) * -1, smooth);
                rightScroll = false;
            }
            else if (liMaxOffset < ulMinOffset + childSize && liMaxOffset > ulMinOffset) {
                if (liMinOffset > ulMinOffset - 20) {
                    this.scroll(this.offset + (ulMinOffset - liMinOffset + childSize) * -1, smooth);
                }
                else {
                    this.scroll(this.offset + (ulMinOffset - liMinOffset) * -1, smooth);
                }
                rightScroll = false;
            }
            else if (ulMaxOffset + 2 > liMaxOffset && ulMaxOffset - 2 < liMaxOffset) {
                this.scroll(this.offset + childSize, true);
                rightScroll = true;
            }
            else if (liMaxOffset > ulMaxOffset - childSize && liMaxOffset < ulMaxOffset) {
                this.scroll(this.offset + liMaxOffset - (ulMaxOffset - childSize), smooth);
                rightScroll = true;
            }
            else if (liMinOffset > ulMaxOffset - childSize && liMinOffset < ulMaxOffset) {
                if (liMaxOffset > ulMaxOffset && (liMaxOffset - ulMaxOffset) < 20) {
                    this.scroll(this.offset + childSize + liMaxOffset - ulMaxOffset, smooth);
                } else {
                    this.scroll(this.offset + liMaxOffset - ulMaxOffset, smooth);
                }
                rightScroll = true;
            }
        }.bind(this), 300);

        e.stopPropagation();
        e.preventDefault();
        return false;
    };
    ThumbScroll.prototype.drag = function (e) {
        var position, delta;
        if (this.getMax() <= 0) {
            return;
        }
        if (this.pressed) {
            position = this.currentPos(e);
            delta = this.reference - position;
            if (delta > 3 || delta < -3) {
                clearTimeout(this.closestSibling);
                this.reference = position;
                this.scroll(this.offset + delta);
            }
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    };
    ThumbScroll.prototype.release = function (e) {
        var thumb;
        e.preventDefault();
        e.stopPropagation();
        this.removeDocumentEvents();
        if (this.offsetReference === this.offset) {
            thumb = e.target.tagName === 'IMG' ? e.target.parentElement : e.target.tagName === 'LI' ? e.target : null;
            if (thumb) {
                this.trigger(this.events.THUMB_CLICKED, {
                    thumb: thumb
                });
            }
        }
        if (this.getMax() <= 0) {
            return;
        }
        this.pressed = false;
        clearInterval(this.ticker);
        if (this.velocity > 10 || this.velocity < -10) {
            this.amplitude = 0.8 * this.velocity;
            this.target = Math.round(this.offset + this.amplitude);
            this.timestamp = Date.now();
            requestAnimationFrame(this.autoScroll.bind(this));
        }
        return false;
    };
    ThumbScroll.prototype.step = function (stepSize) {
        var delta = this.offset + stepSize;
        if (this.isScrolling) {
            requestAnimationFrame(function () {
                this.step(stepSize);
            }.bind(this));
            if (!this.timestamp || Date.now() - this.timestamp > 240) {
                this.timestamp = Date.now();
                this.isAnimating = true;
                this.scroll(delta);
            }
        }
    };
    ThumbScroll.prototype.stepBack = function () {
        var stepSize = this.getStepSize();
        stepSize *= -1;
        this.step(stepSize);
    };
    ThumbScroll.prototype.stepForward = function () {
        var stepSize = this.getStepSize();
        this.step(stepSize);
    };

    ThumbScroll.prototype.endAnimation = function () {
        this.isAnimating = false;
    };
    ThumbScroll.prototype.startStep = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.isScrolling = true;

        this.el.style.willChange = 'transform';
        this.el.style.transition = 'transform 230ms ease-in-out';

        this.el.addEventListener('transitionend', this.endAnimation);

        if (e.target.classList.contains('prev')) {
            this.stepBack();
        }
        else {
            this.stepForward();
        }
        return false;
    };
    ThumbScroll.prototype.endStep = function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.isAnimating) {
            this.clearTransitions();
        }
        else {
            this.el.addEventListener('transitionend', this.clearEndStep);
        }

        this.isScrolling = false;
        this.timestamp = null;

        return false;
    };
    ThumbScroll.prototype.clearEndStep = function () {
        this.clearTransitions();
        this.el.removeEventListener('transitionend', this.clearEndStep);
        this.el.removeEventListener('transitionend', this.endAnimation);
    };
    ThumbScroll.prototype.clearTransitions = function () {
        this.el.style.willChange = '';
        this.el.style.transition = '';
    };
    ThumbScroll.prototype.addEvents = function () {
        var self = this;
        var i;
        var sliderArrLen;

        if (this.wrapper) {
            var sliderArr = this.dom.querySelectorAll(this.wrapper, '.cylindo-thumbnail-slider');
            sliderArrLen = sliderArr.length;

            for (i = 0; i < sliderArrLen; i++) {
                var slider = sliderArr[i];
                slider.addEventListener('touchstart', self.startStep);
                slider.addEventListener('touchend', self.endStep);
                slider.addEventListener('mousedown', self.startStep, true);
                slider.addEventListener('mouseup', self.endStep, true);
            }
        }
        this.parentEl.addEventListener('touchstart', this.tap);
        this.parentEl.addEventListener('mousedown', this.tap, true);
        this.parentEl.addEventListener('mousewheel', this.wheelScroll, { passive: true });
    };
    ThumbScroll.prototype.addDocumentEvents = function () {
        document.addEventListener('touchmove', this.drag);
        document.addEventListener('touchend', this.release);
        document.addEventListener('mousemove', this.drag, true);
        document.addEventListener('mouseup', this.release, true);
    };
    ThumbScroll.prototype.removeDocumentEvents = function () {
        document.removeEventListener('touchmove', this.drag);
        document.removeEventListener('touchend', this.release);
        document.removeEventListener('mousemove', this.drag, true);
        document.removeEventListener('mouseup', this.release, true);
    };
    ThumbScroll.prototype.removeEvents = function () {
        var self = this;
        this.parentEl.removeEventListener('touchstart', this.tap);
        this.parentEl.removeEventListener('touchmove', this.drag);
        this.parentEl.removeEventListener('touchend', this.release);
        this.parentEl.removeEventListener('mousedown', this.tap, true);
        this.parentEl.removeEventListener('mousemove', this.drag, true);
        this.parentEl.removeEventListener('mouseup', this.release, true);
        this.parentEl.removeEventListener('mousewheel', this.wheelScroll);

        var i;
        var thumbSliders = this.dom.querySelectorAll(this.wrapper, '.cylindo-thumbnail-slider');
        var thumbSlidersLen = thumbSliders.length;

        if (this.wrapper) {
            for (i = 0; i < thumbSlidersLen; i++) {
                thumbSliders[i].removeEventListener('touchstart', self.startStep);
                thumbSliders[i].removeEventListener('touchend', self.endStep);
                thumbSliders[i].removeEventListener('mousedown', self.startStep, true);
                thumbSliders[i].removeEventListener('mouseup', self.endStep, true);
            }
        }
    };
    ThumbScroll.prototype.destroy = function () {
        this.removeEvents();
        this.wrapper.classList.remove('has-scroll');
        if (this.pubsub && typeof this.pubsub.destroy === 'function') {
            this.pubsub.destroy();
        }
    };

    ThumbScroll.prototype.getCurrentOffset = function () {
        return this.offset;
    };
    ThumbScroll.prototype.areVerticalThumbs = function() {
        return this.thumbLocation === this.thumbLocations.LEFT || this.thumbLocation === this.thumbLocations.RIGHT;
    };

    window.cylindo.addModule('cylindo.classes.thumbScroll', ThumbScroll);
}.call(this));
