
(function() {
  'use strict';

  var Configuration = function() {
    var environmentsSettings = cylindo.getModule('cylindo.settings.environments');
    var util = cylindo.getModule('cylindo.core.util');
    var defaultsValues = {
      defaults: {
        pictureDataPath: '..\\assets\\viewer\\',
        dataPathTemp: '..\\assets\\viewer\\'
      }
    };
    var settings = {};
    util.object.extend(true, settings, defaultsValues, environmentsSettings);
    this.get = function(name) {
      if (typeof name === 'string') {
        return settings[name];
      }
    };
    this.setDefault = function(name, value) {
      if (value) {
        return (settings.defaults[name] = value);
      }
    };

    this.getVersion = function() {
      return settings.metadata.VERSION;
    };
  };

  window.cylindo.addModule('cylindo.core.config', new Configuration());
}.call(this));
