
(function () {
    'use strict';

    var AriaLabel = function (options) {
        var model = options.model;
        this.texts = {
            TITLE: model.get('title'),
            PRESENTATION: ' viewer',  
            VIEW_IN_AR: model.get('ARBtnTxt'),
            ENTER_FULLSCREEN: 'Enter full screen mode',
            EXIT_FULLSCREEN: 'Exit full screen mode',
            ENTER_ZOOM: 'Enter zoom mode',
            EXIT_ZOOM: 'Exit zoom mode',
            THUMBNAILS_BAR: 'Thumbnails bar',
            THUMBNAIL_IMAGES: 'Thumbnail images',
            ACTION_BUTTONS_GROUP: 'Action buttons',
            THUMBS_LEFT: 'Move thumbs bar to the left',
            THUMBS_RIGHT: 'Move thumbs bar to the right',
            CAROUSEL_LEFT: 'Move carousel viewer to the left',
            CAROUSEL_RIGHT: 'Move carousel viewer to the right',
            ZOOM_CONTAINER: 'Zoom container',
            ZOOM_IMAGE: 'Zoomed image',
            AR_MODAL_WINDOW: 'AR modal window'
        };
    };
    var publicAPI = {
        create: function (opts) {
            return new AriaLabel(opts);
        }
    };
    window.cylindo.addModule('cylindo.aria.label', publicAPI);
}).call(this);
