(function () {
    'use strict';
    var LayerAbstract = cylindo.getModule('cylindo.classes.layer');
    var types = {
        AR: 'ar',
        AR_ERROR: 'ar-error',
        QR: 'qr',
    };
    function ArModal(opts) {
        var self = this;
        var functionHelper = cylindo.getModule('cylindo.helpers.function');
        var layerTemplate;
        var backToViewTemplate;
        var network = cylindo.getModule('cylindo.core.network');
        var type = opts.type;
        var errorText = opts.errorText;
        if (opts && typeof opts === 'object') {
            opts.role = 'dialog';
        }
        LayerAbstract.call(this, opts);
        self.network = network;
        self.preventEvents = opts.preventEvents;
        self.wrapperClasses = ['cylindo-ar-modal'];
        self.templatePattern = [
            '<p><% text %></p>',
        ];
        self.type = type;
        self.errorText = errorText;
        self.qrSrc = '';
        self.initialized = false;
        init();
        function init() {
            self.initialized = true;
            switch (type) {
                case types.QR:
                    layerTemplate = functionHelper.template('<h1><% text %></h1>');
                    self.templatePattern.unshift(layerTemplate({
                        text: opts.title
                    }));
                    backToViewTemplate = functionHelper.template('<a><div class="cylindo-back-icon"></div><% text %></a>');
                    self.templatePattern.unshift(backToViewTemplate({
                        text: opts.ARBackToViewTxt
                    }));
                    self.templatePattern.unshift();
                    self.templatePattern.push('<div class="cylindo-devices-img"></div>');
                    self.templatePattern.push('<div class="cylindo-qr-img-wrapper"><img class="cylindo-qr-img" /></div>');
                    self.wrapperClasses.push('is-qr-modal');
                    break;
                case types.AR:
                    self.templatePattern.unshift('<div class="cylindo-ar-loader"></div>');
                    self.templatePattern.push('<div class="cylindo-ar-white"></div>');
                    self.templatePattern.push('<a>Go to product page</a>');
                    self.wrapperClasses.push('is-ar-modal');
                    break;
            }
            self.setTemplate(self.templatePattern.join(''));
        }
    }

    ArModal.prototype = Object.create(LayerAbstract.prototype);
    ArModal.prototype.constructor = ArModal;
    ArModal.prototype.render = function () {
        var self = this;
        var button = null;
        LayerAbstract.prototype.render.call(self);
        if (self.type === types.QR) {
            self.updateCode();
            button = self.el.querySelector('a');
            if (button) {
                button.addEventListener('mousedown', preventEvents);
                button.addEventListener('mouseup', function (evt) {
                    preventEvents(evt);
                    self.hide(true);
                }, true);
                button.addEventListener('touchstart', preventEvents);
                button.addEventListener('touchend', function (evt) {
                    preventEvents(evt);     
                    self.hide(true);
                }, true);
            }
        }
        function preventEvents(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();
            }
            self.preventEvents();
        }
    };
    ArModal.prototype.updateCode = function (data) {
        var self = this;
        var img = self.el.querySelector('img.cylindo-qr-img');
        var src = data && data.qr || self.qrSrc;
        self.qrSrc = src;
        img.src = self.qrSrc;

    };
    ArModal.prototype.showError = function () {
        var self = this;
        var button = null;
        self.el.classList.remove('is-ar-modal');
        self.el.classList.add('is-ar-error-modal');
        self.show(self.errorText, true);
        button = self.el.querySelector('a');
        if (button) {
            button.addEventListener('touchend', function () {
                preventEvents();
                self.hide(true);
            });
        }
        function preventEvents(evt) {
            if (evt) {
                evt.stopPropagation();
                evt.stopImmediatePropagation();
            }
            self.preventEvents();
        }
    };
    ArModal.prototype.destroy = function() {
        var self = this;
        LayerAbstract.prototype.destroy.call(self);
    };

    var publicAPI = {
        create: function (opts) {
            return new ArModal(opts);
        },
        types: types,
    };

    window.cylindo.addModule('ar.modal', publicAPI);
}.call(this));
