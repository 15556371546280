
(function () {
    'use strict';
    var ViewerController = function (config) {
        var self = this;

        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var logger = null;
        var framesHandler = cylindo.getModule('cylindo.classes.frames.handler').create({});

        var tagsOpts = {
            cid: config.accountID,
            productCode: config.productCode,
            features: config.features,
        };
        var tags = cylindo.tags.handler.create(tagsOpts);
        var model = cylindo.getModule('model').create(config, tags, framesHandler);
        var view = cylindo.getModule('view').create(model, tags, framesHandler);

        var configuration = cylindo.getModule('cylindo.core.config');
        var capi = cylindo.getModule('cylindo.classes.capi');
        var loggerMap = {};

        loggerMap.LOG = 1;
        loggerMap.INFO = 1 << 1;
        loggerMap.WARNING = 1 << 2;
        loggerMap.ERROR = 1 << 3;
        loggerMap.EVENT = 1 << 4;

        var eventMap = {};
        eventMap[view.events.ZOOM] = self.events.ZOOM;
        eventMap[view.events.ZOOM_ENTER] = self.events.ZOOM_ENTER;
        eventMap[view.events.ZOOM_EXIT] = self.events.ZOOM_EXIT;
        eventMap[view.events.VIEWER_READY] = self.events.VIEWER_READY;
        eventMap[view.events.DOWNLOAD_COMPLETE] = self.events.VIEWER_COMPLETE;
        eventMap[view.events.THUMBS_READY] = self.events.THUMBS_READY;
        eventMap[view.events.THUMB_CLICKED] = self.events.THUMB_CLICKED;
        eventMap[view.events.ALT_THUMBS_CLICKED] = self.events.ALT_THUMBS_CLICKED;
        eventMap[view.events.FIRST_FRAME_COMPLETE] = self.events.FIRST_FRAME_READY;
        eventMap[view.events.ENTER_FULLSCREEN] = self.events.FULLSCREEN_ENTER;
        eventMap[view.events.EXIT_FULLSCREEN] = self.events.FULLSCREEN_EXIT;
        eventMap[view.events.START_SPIN] = self.events.SPIN_BEGIN;
        eventMap[view.events.END_SPIN] = self.events.SPIN_END;
        eventMap[view.events.PREV] = self.events.PREV;
        eventMap[view.events.NEXT] = self.events.NEXT;
        eventMap[view.events.FEATURES_CANCELED] = self.events.FEATURES_CANCELED;
        eventMap[view.events.FEATURES_ERROR] = self.events.FEATURES_ERROR;
        eventMap[view.events.AR_BUTTON_READY] = self.events.AR_BUTTON_READY;
        eventMap[view.events.AR_BUTTON_ERROR] = self.events.AR_BUTTON_ERROR;
        eventMap[view.events.AR_ACTION_BTN_TAPPED] = self.events.AR_ACTION_BTN_TAPPED;


        logger = model.getLogger();

        this.isFallbackController = false;

        this.on = pubsub.on;
        this.trigger = pubsub.trigger;
        this.off = pubsub.off;
        this.loggerMap = loggerMap;

        this.getControllerType = function () {
            return this.controllerType;
        };

        this.update = function (field, value) {
            self.setModelProperty(field, value);
        };

        this.updateAlternateContent = function (frames) {
            try {
                return model.updateAlternateContent(frames);
            }
            catch (ex) {
                self.logError('Could not set property ' + field + ' from model.', ex);
                return null;
            }
        };

        this.setProduct = function (productCode) {
            self.update('productCode', productCode);
        };

        this.setFeatures = function (featureArray) {
            self.update('features', featureArray);
        };

        this.destroy = function () {
            pubsub.destroy();
            tags.destroy();
            model.destroy();
            view.destroy();
            framesHandler.destroy();
            self.trigger(self.events.DESTROYED);
        };

        this.getProduct = function () {
            return self.getModelProperty('productCode');
        };

        this.getFeatures = function () {
            return self.getModelProperty('features');
        };

        this.getAccount = function () {
            return self.getModelProperty('accountID');
        };

        this.getVersion = function () {
            return configuration && self.isFunction(configuration.getVersion) ?
                configuration.getVersion() : null;
        };

        this.getRotationSpeed = function () {
            return self.getModelProperty('rotationSpeed');
        };

        this.getDebugInfo = function (search) {
            logger.getDebugInfo(search);
            return 'You can use bitwise operators to filter messages, e.g. instanceName.loggerMap.ERROR | instanceName.loggerMap.WARNING  ';
        };

        this.getUrlImage = function (frameNumber, size, ssl) {
            var threeSixtyMaxSize = view && self.isFunction(view.getThreesixtyMaxSize) ?
                view.getThreesixtyMaxSize() : 512;
            var accountID = self.getModelProperty('accountID');
            var productCode = self.getModelProperty('productCode');
            var features = self.getModelProperty('features');
            var version = self.getModelProperty('version');
            var ignoreCache = self.getModelProperty('ignoreCache');
            var removeEnvironmentShadow = self.getModelProperty('removeEnvironmentShadow');
            var proxy = self.getModelProperty('proxy');
            var maxSize = Math.min(size, threeSixtyMaxSize);
            try {
                return capi.getUrlV2(accountID, productCode, frameNumber, maxSize, features, removeEnvironmentShadow, proxy, ssl, version, ignoreCache);
            }
            catch (ex) {
                self.logError('Cannot get frame URL, CAPI module may not exist.', ex);
                return null;
            }
        };
        this.getCurrentFrameIndex = function () {
            return view &&
                self.isFunction(view.getCurrentThreesixtyIndex) ?
                view.getCurrentThreesixtyIndex() : null;
        };

        this.addToCart = function (price, currency, quantity, cb) {
            reportDeprecatedTracking();
        };

        this.printGrid = function () {
            if (view && self.isFunction(view.printGrid)) {
                view.printGrid();
            }
        };

        this.exitZoom = function () {
            if (view && self.isFunction(view.exitZoom)) {
                view.exitZoom();
            }
        };

        this.disableTags = function (flag) {
            if (tags && self.isFunction(tags.disableTags)) {
                tags.disableTags(flag);
            }
        };

         this.disableTracking = function (flag) {
            reportDeprecatedTracking();
        };

        this.rotate = function (degrees, milliseconds) {
            if (view && self.isFunction(view.rotate)) {
                view.rotate(degrees, milliseconds);
            }
        };

        this.goToAngle = function (angle) {
            if (view && self.isFunction(view.goToAngle)) {
                view.goToAngle(angle);
            }
        };

        this.previousSlide = function () {
            if (view && self.isFunction(view.previousSlide)) {
                view.previousSlide();
            }
        };

        this.nextSlide = function () {
            if (view && self.isFunction(view.nextSlide)) {
                view.nextSlide();
            }
        };

        this.zoom = function (x, y, index) {
            if (view && self.isFunction(view.zoom)) {
                view.zoom(x, y, index);
            }
        };

        this.getState = function () {
            if (view && self.isFunction(view.getState)) {
                return view.getState();
            }
            return null;
        };

        this.getArQuickLookUrl = function (callback) {
            if (view && self.isFunction(view.getArQuickLookUrl)) {
                view.getArQuickLookUrl(callback);
            }
        };

        this.showImage = function (url, large) {
            if (view && self.isFunction(view.showImage)) {
                view.showImage(url, large);
            }
        };

        this.hideImage = function () {
            if (view && self.isFunction(view.hideImage)) {
                view.hideImage();
            }
        };

        this.isRotating = function () {
            if (view && self.isFunction(view.isRotating)) {
                return view.isRotating();
            }
        };

        this.isPanning = function () {
            if (view && self.isFunction(view.isPanning)) {
                return view.isPanning();
            }
        };

        this.getResolvedFeatures = function (callback) {
            if (model) {
                model.getResolvedFeatures(callback);
            }
        };

        this.getModelProperty = function (field) {
            try {
                return model.get(field);
            }
            catch (ex) {
                self.logError('Could not get property ' + field + ' from model.', ex);
                return null;
            }
        };
        this.setModelProperty = function (field, value) {
            try {
                return model.set(field, value);
            }
            catch (ex) {
                self.logError('Could not set property ' + field + ' from model.', ex);
                return null;
            }
        };
        this.logError = function (msg, err) {
            try {
                logger.error(msg, err);
            }
            catch (ex) {
                if (console.error) {
                    console.error(msg, err);
                }
                else {
                    console.log('Error: ', msg, err);
                }
            }
        };
        this.isFunction = function (fn) {
            return typeof fn === 'function';
        };
        init();

        function init() {
            if (model.get('gaEvents') === false ||
                (model.get('overrideTracking') !== true && isCylindoOrLocalDomain())) {
                tags.disableTags(true);
            }

            if (model.get('overrideTracking') !== true && isCylindoOrLocalDomain()) {
                reportDeprecatedTracking();
            }

            view.on(view.events.ZOOM, forwardEvent);
            view.on(view.events.ZOOM_ENTER, forwardEvent);
            view.on(view.events.ZOOM_EXIT, forwardEvent);
            view.on(view.events.VIEWER_READY, forwardEvent);
            view.on(view.events.DOWNLOAD_COMPLETE, forwardEvent);
            view.on(view.events.THUMBS_READY, forwardEvent);
            view.on(view.events.THUMB_CLICKED, forwardEvent);
            view.on(view.events.ALT_THUMBS_CLICKED, forwardEvent);
            view.on(view.events.FIRST_FRAME_COMPLETE, forwardEvent);
            view.on(view.events.ENTER_FULLSCREEN, forwardEvent);
            view.on(view.events.EXIT_FULLSCREEN, forwardEvent);
            view.on(view.events.START_SPIN, forwardEvent);
            view.on(view.events.END_SPIN, forwardEvent);
            view.on(view.events.PREV, forwardEvent);
            view.on(view.events.NEXT, forwardEvent);
            view.on(view.events.FEATURES_CANCELED, forwardEvent);
            view.on(view.events.FEATURES_ERROR, forwardEvent);
            view.on(view.events.AR_BUTTON_READY, forwardEvent);
            view.on(view.events.AR_BUTTON_ERROR, forwardEvent);
            view.on(view.events.AR_ACTION_BTN_TAPPED, forwardEvent);

            self.initialized = true;

            function isCylindoOrLocalDomain() {
                var domains = /(cylindev.com|bugtest.it|cylindo.com|localhost|bs-local)/ig;
                return (domains.test(window.location.host) || window.location.protocol == 'file:');
            }
        }

        function forwardEvent(evt, data) {
            self.trigger(eventMap[evt], data);
        }

        function reportDeprecatedTracking() {
            try {
                console.warn('Cylindo\'s tracking functionality has been deprecated. Please contact support team for more information about it.');
            }
            catch (ex) {
                console.warn('Cylindo\'s tracking functionality has been deprecated. Please contact support team for more information about it.');
            }
        }
    };

    ViewerController.prototype.events = {
        DESTROYED: 'instance:destroyed',
        VIEWER_READY: 'instance:viewer:ready',
        VIEWER_COMPLETE: 'instance:viewer:complete',
        FIRST_FRAME_READY: 'instance:firstframe:ready',
        THUMBS_READY: 'instance:thumbs:ready',
        THUMB_CLICKED: 'instance:viewer:thumbs:clicked',
        ALT_THUMBS_CLICKED: 'instance:alt:thumbs:clicked',
        ZOOM: 'instance:zoom',
        ZOOM_ENTER: 'instance:zoom:enter',
        ZOOM_EXIT: 'instance:zoom:exit',
        FULLSCREEN_ENTER: 'instance:fullscreen:enter',
        FULLSCREEN_EXIT: 'instance:fullscreen:exit',
        SPIN_BEGIN: 'instance:threesixty:start',
        SPIN_END: 'instance:threesixty:end',
        PREV: 'instance:carousel:prev',
        NEXT: 'instance:carousel:next',
        ERROR: 'instance:error',
        FEATURES_CANCELED: 'instance:features:canceled',
        FEATURES_ERROR: 'instance:features:error',
        AR_BUTTON_READY: 'instance:ar:button:ready',
        AR_BUTTON_ERROR: 'instance:ar:button:error',
        AR_ACTION_BTN_TAPPED: 'instance:ar:action:btn:tapped'
    };

    var publicAPI = {
        create: function (config) {
            return new ViewerController(config);
        }
    };

    window.cylindo.addModule('cylindo.viewer.controller', publicAPI);
}).call(this);
