(function () {
    'use strict';
    var ViewAbstract = cylindo.getModule('cylindo.classes.abstract.view');
    var angles = cylindo.getModule('cylindo.util.angles');
    var ThreeSixty = function (options) {
        ViewAbstract.call(this, options);

        var self = this;
        var util = cylindo.getModule('cylindo.core.util');
        var dom = options.dom;
        var scrollHelper = cylindo.getModule('cylindo.helpers.scroll');
        var tOut = null;
        var tOutValue = null;
        var isScrolling = false;
        var isTouchEvent = false;

        var start;
        var end;

        this.util = util;
        this.dom = dom;
        this.wasRotating = false;
        this.rafIndex = null;
        this.isRotating = false;
        this.frameWidth = 512;
        this.mouseState = 0;
        this.rotateSpeed = 50;
        this.framesMoved = 0;
        this.totalFramesMoved = 0;
        this.deltaTime = 0;
        this.pixelsPerFrame = 0;
        this.initialFrame = null;
        this.endFrame = null;
        this.rotations = 0;
        this.updateTimeOut = 1000 / 60;
        this.isTailOfMovements = false;
        this.tailPromise = null;
        this.prevEndFrame = null;
        this.prevIndex = null;
        this.deltaX = 0;
        this.strength = 0;
        this.ease = 3;
        this.ratio = null;
        this.resizeTimer = 0;
        this.pendingImages = [];
        this.stopAutoRotation = false;
        this.isAutoRotating = false;
        this.defaultTooltip = 'tooltipDragText';
        this.documentEventsAdded = false;
        this.removeDocumentEventsForced = removeDocumentEventsForced;
        this.imagesRequested = 0;

        if (options.model.get('missingCombinationErrorText')) {
            this.noFeatureTooltip = cylindo.getModule('tooltip.no-feaure').create({
                parent: options.el,
                text: options.model.get('missingCombinationErrorText'),
                dom: dom
            });
        }

        this.model.on(this.model.events.FEATURES_CHANGED, this.refresh.bind(this));
        this.model.on(this.model.events.FEATURES_ERROR, onFeaturesError);
        this.model.on(this.model.events.FEATURES_NO_WARNING, this.onFeaturesUpdated.bind(this));
        this.model.on(this.model.events.CHANGED, this.updateView.bind(this));
        this.listClassName = 'cylindo-threesixty-list';
        this.viewerPresentation = '360viewer';

        this.enable = enable.bind(this);
        this.disable = disable.bind(this);
        this.addMouseCallbacks = addMouseCallbacks.bind(this);
        this.removeMouseCallbacks = removeMouseCallbacks.bind(this);

        this.targetLoadedPercentage = this.model.get('partialRotationStart'); 

        tOutValue = this.model.get('mobileZoomDelay');

        if (this.noFeatureTooltip) {
            this.noFeatureTooltip.render();
            if (this.initializedWithError) {
                this.noFeatureTooltip.show();
            }
        }
        this.render();
        function onFeaturesError() {
            if (self && self.noFeatureTooltip) {
                self.noFeatureTooltip.show();
            }
            self.trigger(self.events.FEATURES_ERROR, null);
        }
        function enable() {
            addMouseCallbacks.call(this);
            ViewAbstract.prototype.enable.call(this, true);
        }
        function disable() {
            removeMouseCallbacks.call(this);
            ViewAbstract.prototype.disable.call(this, true);
        }
        function addMouseCallbacks() {
            this.wrapper.addEventListener('touchstart', touchStart, false);
            this.wrapper.addEventListener('mousedown', mouseDown, false);

            document.addEventListener('keydown', tryToRotateViewerWithKeyboard);

        }
        function addDocumentEvents() {
            document.addEventListener('touchend', touchEnd, false);
            document.addEventListener('touchmove', touchMove, false);
            document.addEventListener('mouseup', mouseUp, false);
            document.addEventListener('mousemove', mouseMove, false);
            self.documentEventsAdded = true;
        }
        function removeDocumentEvents() {
            document.removeEventListener('mouseup', mouseUp, false);
            document.removeEventListener('mousemove', mouseMove, false);
            document.removeEventListener('touchend', touchEnd, false);
            document.removeEventListener('touchmove', touchMove, false);
            self.documentEventsAdded = false;
        }
        function removeDocumentEventsForced() {
            removeDocumentEvents();
            self.start = null;
            self.mouseState = 0;
            self.deltaX = 0;
            self.framesMoved = 0;
            self.wasRotating = false;
            self.isRotating = false;
            self.ul360.classList.remove('cylindo-rotating');
        }
        function removeMouseCallbacks() {
            this.wrapper.removeEventListener('mousedown', mouseDown, false);
            this.wrapper.removeEventListener('touchstart', touchStart, false);
            removeDocumentEvents();

            document.removeEventListener('keydown', tryToRotateViewerWithKeyboard);

        }
        function touchStart(evt) {
            if (isEventOnVideo(evt)) {
                return;
            }
            isTouchEvent = true;
            scrollHelper.preventScrollX(self.wrapper.parentNode);
            self.stopAutoRotation = true;
            self.setMobileCoords(evt);
            mouseDown(evt);
        }
        function touchEnd(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            if (tOut) {
                window.clearTimeout(tOut);
                tOut = null;
            }
            self.setMobileCoords(evt);
            mouseUp(evt);
            scrollHelper.allowScrollX(self.wrapper.parentNode);
            isScrolling = false;
            isTouchEvent = false;
        }
        function touchMove(evt) {
            var yDiff, xDiff;

            self.setMobileCoords(evt);

            yDiff = Math.abs(evt.clientY - self.mouseClickPos.startY);
            xDiff = Math.abs(evt.clientX - self.mouseClickPos.startX);

            if (!self.isSignificantMove(evt)) {
                return false;
            }

            window.clearTimeout(tOut);
            tOut = null;

            if ((isScrolling || !self.wasRotating) && yDiff > xDiff) {
                isScrolling = true;
            }

            if (!isScrolling) {
                mouseMove(evt);
            }
        }
        function mouseDown(evt) {
            if (self.wrapper) {
                self.wrapper.focus();
            }
            if (self.isTailOfMovements) {
                self.tailPromise.then(function () {
                    initMouseDown();
                });
                self.resetMovementsTail();
            }
            else {
                initMouseDown();
            }
            function initMouseDown() {
                if (isEventOnVideo(evt)) {
                    return;
                }
                self.stopAutoRotation = true;
                start = Date.now();
                if (evt) {
                    if (!self.isRightClick(evt)) {
                        addDocumentEvents();

                        self.initialFrame = self.currentIndex;
                        self.mouseState = 1;
                        self.prevEndFrame = self.endFrame || 1;
                        self.endFrame = self.currentIndex;
                        self.mouseClickPos.startX = self.mouseClickPos.currentX = evt.clientX;
                        self.mouseClickPos.startY = self.mouseClickPos.currentY = evt.clientY;
                        self.mouseClickPos.startTime = evt.timeStamp;

                        self.currentEventTime = evt.timeStamp;
                        self.totalFramesMoved = 0;
                        self.deltaTime = evt.timeStamp || new Date().getTime();
                    }

                    if (!evt.defaultPrevented && !isTouchEvent) {
                        evt.preventDefault();
                    }
                }
            }
        }
        function mouseMove(evt) {
            var maxAvailableIndex = self.getMaxAvalilableIndex();
            var framesToMove = 0;
            var previousActive = self.ul360.querySelector('.active');
            var previousIndex = previousActive ?
                previousActive.dataset ? previousActive.dataset.index : previousActive.getAttribute('data-index') :
                null;
            var newIndex;
            var nextActive = null;
            var deltaX = 0;
            previousIndex = parseInt(previousIndex, 10);

            self.pixelsPerFrame = self.calculatePixelsPerFrame();
            if (self.mouseState === 0 || (/alt\-\d/).test(self.currentIndex)) {
                return;
            }

            if (evt && self.mouseState > 0) {
                deltaX = evt.clientX - self.mouseClickPos.currentX;

                self.prevEndFrame = self.endFrame || 1;

                if (self.prevEndFrame !== self.endFrame && tOut) {
                    clearTimeout(tOut);
                    tOut = null;
                }

                if (Math.abs(deltaX) >= self.pixelsPerFrame) {
                    if (!self.isRotating) {
                        self.trigger(self.events.START, {
                            index: self.currentIndex
                        });
                    }
                    self.wasRotating = true;
                    self.isRotating = true;
                    if ((self.deltaX > 0 && deltaX < 0) || (self.deltaX < 0 && deltaX > 0)) {
                        self.tags.send(self.tags.events.SPIN);
                        self.initialFrame = self.currentIndex;
                        self.framesMoved = 0;

                        self.mouseClickPos.startX = self.mouseClickPos.currentX;
                        self.totalFramesMoved = 0;
                        self.deltaTime = evt.timeStamp || new Date().getTime();
                    }
                    self.deltaX = deltaX;
                    self.ul360.classList.add('cylindo-rotating');
                    framesToMove = self.getDistanceToFrames(deltaX);

                    if (deltaX > 0) {
                        ++self.framesMoved;
                        newIndex = framesToMove + self.currentIndex;
                        self.totalFramesMoved += framesToMove;
                    }
                    else {
                        --self.framesMoved;
                        newIndex = self.currentIndex - framesToMove;
                        self.totalFramesMoved -= framesToMove;
                    }
                    if (newIndex <= 0) {
                        newIndex = maxAvailableIndex + newIndex;
                    }
                    else if (newIndex > maxAvailableIndex) {
                        newIndex = newIndex - maxAvailableIndex;
                    }
                    if (newIndex !== previousIndex) {
                        self.currentIndex = self.isFrameAvailable(newIndex) ? newIndex : self.getNextAvailableFrame(previousIndex);
                        nextActive = self.ul360.querySelector('[data-index="' + self.currentIndex + '"]:not(.hidden)');
                        self.handleActiveFrame(nextActive, true);
                        self.handleActiveFrame(previousActive, false);
                    }

                    self.mouseClickPos.currentX = evt.clientX;
                    self.mouseClickPos.currentY = evt.clientY;
                    self.currentEventTime = evt.timeStamp;

                    self.trigger(self.events.CURRENT, { current: self.currentIndex });
                }
            }
            self.mouseClickPos.startTime = Date.now();

            function end() {
                previousActive.style.zIndex = '';
                previousActive.removeEventListener('transitionend', end);
            }
        }
        function mouseUp(evt) {
            var removeEvents = true;
            calculateMovementsTail(evt);
            if (self.isTailOfMovements) {
                self.rafIndex = setTimeout(self.update.bind(self), self.updateTimeOut);
                removeEvents = false;
                removeDocumentEvents();
                self.tailPromise.then(function () {
                    finishRotation(evt);
                });
            }
            else {
                finishRotation(evt);
            }
            function finishRotation(evt) {
                if (self.mouseState == 1 && evt && !self.isRightClick(evt)) {
                    self.start = null;
                    self.mouseState = 0;
                    self.deltaX = 0;
                    if (removeEvents) {
                        setTimeout(function () {
                            removeDocumentEvents();
                        }, 0);
                    }
                    if (!self.wasRotating &&
                        !isScrolling &&
                        evt.type === 'mouseup' &&
                        !isTouchEvent) {
                        self.mouseClickPos.startX = self.mouseClickPos.currentX = evt.clientX;
                        self.mouseClickPos.startY = self.mouseClickPos.currentY = evt.clientY;
                        self.trigger(self.events.CLICKED, self.mouseClickPos);
                        return;
                    }
                    else if ((/alt\-\d/).test(self.currentIndex)) {
                        return;
                    }

                    if (self.wasRotating) {
                        self.trigger(self.events.END, {
                            index: self.currentIndex
                        });
                        self.tags.send(self.tags.events.SPIN);
                    }

                    self.framesMoved = 0;
                    self.wasRotating = false;
                    self.isRotating = false;
                    self.ul360.classList.remove('cylindo-rotating');
                }
                end = Date.now();
            }
        }
        function calculateMovementsTail(evt) {
            var speedMultiplier = self.model.get('speedMultiplier');
            var maxAvailableIndex = self.getMaxAvalilableIndex();
            var distance, endFrame;
            var mouseUpTimeStamp = evt && evt.timeStamp ? evt.timeStamp : new Date().getTime();
            var listRect = self.ul360.getBoundingClientRect();
            self.updateTimeOut = (mouseUpTimeStamp - self.deltaTime) / Math.abs(self.framesMoved);
            self.tailPromise = self.util.promise.create();
            self.isTailOfMovements = false;
            self.rotations = 0;
            self.endFrame = self.currentIndex;
            distance = evt.clientX - self.mouseClickPos.startX;
            if (distance > 0) {
                endFrame = Math.ceil(self.frameCount * speedMultiplier * (distance / listRect.width));
                endFrame = endFrame - self.totalFramesMoved;
                if (endFrame <= 0) {
                    return;
                }
            }
            else {
                endFrame = Math.floor(self.frameCount * speedMultiplier * (distance / listRect.width));
                endFrame = endFrame - self.totalFramesMoved;
                if (endFrame >= 0) {
                    return;
                }
            }
            if (self.updateTimeOut >= 25) {
                return;
            }
            self.isTailOfMovements = true;
            if (Math.abs(endFrame) > self.frameCount) {
                self.rotations = Math.floor(Math.abs(endFrame) / maxAvailableIndex);
                endFrame = endFrame % self.frameCount;
            }
            self.endFrame = self.currentIndex + endFrame;
            if (self.endFrame <= 0) {
                self.endFrame = maxAvailableIndex + self.endFrame;
            }
            else if (self.endFrame > maxAvailableIndex) {
                self.endFrame = self.endFrame - maxAvailableIndex;
            }
        }
        function isEventOnVideo(evt) {
            var currentLi = self.ul360.querySelector('.active');
            if (currentLi && currentLi.classList.contains('cylindo-video')) {
                return true;
            }
            return false;
        }

        function tryToRotateViewerWithKeyboard(evt)  {
            if (self.wrapper === document.activeElement) {
                if(evt) {
                    if (evt.keyCode == '37') {
                        self.autoRotate(-10, 0);
                    }
                    else if (evt.keyCode == '39') {
                        self.autoRotate(10, 0);
                    }
                }
            }
        }

    };

    ThreeSixty.prototype = Object.create(ViewAbstract.prototype);
    ThreeSixty.prototype.constructor = ThreeSixty;

    ThreeSixty.prototype.events = ViewAbstract.extendEvents({
        CLICKED: 'threesixty:clicked',
        READY: 'threesixty:ready',
        DOWNLOAD_COMPLETE: 'threesixty:download:complete',
        CURRENT: 'threesixty:current',
        IMG_LOADED: 'threesixty:img:loaded',
        FEATURES_CHANGED: 'threesixty:features:changed',
        FIRST_FRAME_COMPLETE: 'threesixty:firstframe:complete',
        START: 'threesixty:start',
        END: 'threesixty:end',
        FRAME_ACTIVATED: 'threesixty:frame:activated',
        ALT_CONTENT_REMOVED: 'threesixty:alt:content:removed',
        ALT_CONTENT_LOADED: 'threesixty:alt:content:loaded',
        CONTENT_TYPE_COMPLETE: 'threesixty:content:type:complete',
        FEATURES_CANCELED: 'threesixty:features:canceled',
        FEATURES_ERROR: 'threesixty:features:error'
    });

    ThreeSixty.prototype.render = function () {
        ViewAbstract.prototype.tryToCreateTooltip.call(this);
        ViewAbstract.prototype.render.call(this);
    };
    ThreeSixty.prototype.downloadContent = function (requestID) {
        var self = this;
        var startFrame = self.model.get('startFrame');
        var allFrames = self.framesHandler.getAllFramesToBeLoaded();
        var viewerOrder = allFrames.VIEWER_ORDER.LIST;
        var viewerOrderLen = allFrames.VIEWER_ORDER.LEN;
        var altPromises = 0;
        var ssPromises = 0;
        var oPreviousDeferred = null;
        var i, index;
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;
        var isFirst360Frame = true;
        var startIndex = 1;
        var j = startIndex;
        var partialRotationPercentage = this.model.get('partialRotationStart');
        var halfOfImagesLenght = frameCount * (partialRotationPercentage / 100);
        var increment = frameCount / halfOfImagesLenght;
        var indexes = [];
        this.isCustomViewer = frameCount === 0;
        this.frameCount = frameCount;
        if (viewerOrderLen > 0) {
            this.currentIndex = viewerOrder[0];
            this.prevIndex = startFrame;
            this.startFrame = startFrame;
            for (i = 0; i < viewerOrderLen; i++) {
                index = viewerOrder[i];
                if (self.framesHandler.is360Frame(index)) {
                    if (isFirst360Frame) {
                        if (index !== startFrame) {
                            this.currentIndex = this.currentIndex === index ?
                                startFrame : this.currentIndex;
                            index = startFrame;
                        }
                        tryToPrepare(index, requestID, i);
                        ++self.imagesRequested;
                        isFirst360Frame = false;
                        while (j <= frameCount && this.model.getRequestID() === requestID) {
                            if (j !== index) {
                                ++self.imagesRequested;
                                indexes.push({
                                    index: j,
                                    requestID: requestID
                                });
                                if (self.imagesRequested === Math.floor(halfOfImagesLenght)) {
                                    halfOfImagesLenght = Math.round(halfOfImagesLenght);
                                    tryToPrepareGroupOfImages(indexes);
                                    indexes = [];
                                    self.imagesRequested = 0;
                                }
                            }
                            j += increment;
                            if (j > frameCount && startIndex < increment) {
                                j = (++startIndex);
                            }
                        }
                    }
                }
                else {
                    if (self.framesHandler.isAltId(index)) {
                        altPromises++;
                    }
                    tryToPrepare(index, requestID, i);
                }
            }
        }
        else {
        }
        function tryToPrepare(index, requestID, arrayPos) {
            if (oPreviousDeferred === null) {
                oPreviousDeferred = self.prepare(index, requestID).then(function (result) {
                    tryToExecuteFrameActions(index, arrayPos);
                });
            }
            else {
                oPreviousDeferred = oPreviousDeferred.then(function () {
                    var deferred = self.prepare(index, requestID);
                    deferred.then(function (result) {
                        tryToExecuteFrameActions(index, arrayPos);
                    });
                    return deferred;
                });
            }
        }
        function tryToPrepareGroupOfImages(indexes) {
            if (oPreviousDeferred === null) {
                oPreviousDeferred = getPromise(indexes);
            }
            else {
                oPreviousDeferred = oPreviousDeferred.then(function () {
                    return getPromise(indexes);
                });
            }
            function getPromise(indexes) {
                var masterDeferred = self.util.promise.create();
                var promises = [];
                var i;
                for (i = 0; i < indexes.length; i++) {
                    promises.push(self.prepare(indexes[i].index, indexes[i].requestID));
                }
                Promise.all(promises).then(function () {
                    masterDeferred.resolve();
                });
                return masterDeferred;
            }
        }
        function tryToExecuteFrameActions(index, arrayPos) {
            if (self.framesHandler.isAltId(index)) {
                altPromises--;
                if (altPromises === 0) {
                    self.trigger(self.events.CONTENT_TYPE_COMPLETE, {
                        contentType: self.contentTypes.ALTERNATE
                    });
                }
            }
        }
    };

    ThreeSixty.prototype.calculatePixelsPerFrame = function () {
        var listRect = this.ul360.getBoundingClientRect();
        var wrapperRect = this.wrapper.getBoundingClientRect();
        var width = listRect.width;
        var availableFrameCount = this.getAvailableFrames().length;
        var rotations = this.getRotationSpeed();
        var scale = this.isFullScreen() ? width / wrapperRect.width : 1;

        return Math.floor((width / 2) / (availableFrameCount * (rotations * scale))); 
    };
    ThreeSixty.prototype.isRightClick = function (evt) {
        return ViewAbstract.prototype.isRightClick.call(this, evt);
    };
    ThreeSixty.prototype.goToIndex = function (index) {
        var i = parseInt(index, 10);
        var current = isNaN(this.currentIndex) ? 1 : this.currentIndex;
        var halfMaxIndex = this.getMaxAvalilableIndex() / 2;
        var self = this;
        self.updateTimeOut = 1000 / 60;
        if (this.isTailOfMovements) {
            this.tailPromise.then(function () {
                initGoToIndex();
            });
            this.resetMovementsTail();
        }
        else {
            initGoToIndex();
        }
        function initGoToIndex() {
            self.stopAutoRotation = true;
            if (self.framesHandler.is360Frame(self.currentIndex)) {
                self.prevIndex = self.currentIndex;
            }
            if (isNaN(i) || isNaN(self.currentIndex)) {
                if (self.documentEventsAdded) {
                    self.removeDocumentEventsForced();
                }
                ViewAbstract.prototype.goToIndex.call(self, index);
                self.trigger(self.events.CURRENT, { current: index });
            }
            else {
                if ((i - current) > halfMaxIndex || (i - current) <= -halfMaxIndex) {
                    self.deltaX = (i - current) * -1;
                } else {
                    self.deltaX = i - current;
                }
                self.endFrame = i;
                if (!self.isFrameAvailable(self.endFrame)) {
                    self.endFrame = self.getNextAvailableFrame(self.endFrame);
                }
                if (self.rafIndex) {
                    clearTimeout(self.rafIndex);
                    self.rafIndex = null;
                }
                self.wasThumbClicked = true;
                self.rafIndex = setTimeout(self.update.bind(self), self.updateTimeOut);
            }
        }
    };
    ThreeSixty.prototype.showImage = function (url, large) {
        var self = this;
        if (this.isTailOfMovements) {
            this.tailPromise.then(function () {
                doShowImage(url, large);
            });
            this.resetMovementsTail();
        }
        else {
            doShowImage(url, large);
        }
        function doShowImage(url, large) {
            ViewAbstract.prototype.showImage.call(self, url, large);
        }
    };
    ThreeSixty.prototype.moveToNextFrame = function () {
        var maxIndex = this.getMaxAvalilableIndex();

        this.prevIndex = this.currentIndex || 1;
        if (this.deltaX > 0) {
            ++this.currentIndex;
            this.currentIndex = this.currentIndex > maxIndex ? 1 : this.currentIndex;
        }
        else if (this.deltaX < 0) {
            --this.currentIndex;
            this.currentIndex = this.currentIndex < 1 ? maxIndex : this.currentIndex;
        }
        ++this.framesMoved;
    };
    ThreeSixty.prototype.getMaxAvalilableIndex = function () {
        var availableFrames = this.getAvailableFrames();
        var maxIndex = -1;
        var len = availableFrames.length;
        var i;

        for (i = 0; i < len; i++) {
            if (availableFrames[i] > maxIndex) {
                maxIndex = availableFrames[i];
            }
        }

        return maxIndex;
    };
    ThreeSixty.prototype.update = function () {
        var self = this;
        var updateDelay;
        var childs = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-"]') || [];

        if ((this.mouseState === 0 || this.isTailOfMovements) && this.endFrame && this.endFrame === this.currentIndex) {
            if (this.rotations === 0) {
                if (this.rotation > 0) {
                    this.rotations--;
                }
                this.endFrame = null;
                childs.forEach(function (element) {
                    element.style.transition = '';
                });
                if (this.isRotating) {
                    this.ul360.classList.remove('cylindo-rotating');
                }
                if (this.isTailOfMovements) {
                    this.resetMovementsTail();
                    return;
                }
                this.isRotating = false;
                this.framesMoved = 0;
                this.wasRotating = false;
                return;
            }
        }

        if (this.isTailOfMovements) {
            updateDelay = 1 + 2 / 100 - this.model.get('rotationSpeed') / 1000;
            this.updateTimeOut = this.updateTimeOut * updateDelay;
        }

        childs.forEach(function (element) {
            element.style.transition = 'unset';
        });

        this.rafIndex = setTimeout(this.update.bind(this), this.updateTimeOut);
        if (!this.isFrameAvailable(this.endFrame)) {
            this.endFrame = this.getNextAvailableFrame(this.prevEndFrame);
        }

        this.prevIndex = this.currentIndex || 1;

        if (this.endFrame &&
            (rotationCompleted() || this.endFrame !== this.currentIndex)) {
            if (rotationCompleted()) {
                this.rotations--;
            }
            this.wasRotating = true;
            if (this.framesHandler.is360Frame(this.currentIndex)) {
                this.moveToNextFrame();
            }
            if (isNaN(this.currentIndex)) {
                this.resetMovementsTail();
                this.isRotating = false;
            }
            else {
                this.isRotating = true;
            }

            if (this.isFrameAvailable(this.currentIndex)) {
                this.handleActiveFrame(this.ul360.querySelector('.active'), false);
                this.handleActiveFrame(this.ul360.querySelector('[data-index="' + this.currentIndex + '"]'), true);
            }

            this.trigger(this.events.CURRENT, { current: this.currentIndex });
        }

        function rotationCompleted() {
            return self.rotations > 0 && self.endFrame === self.currentIndex;
        }

    };
    ThreeSixty.prototype.getDistanceToFrames = function (distance) {
        var framesToMove;
        distance = Math.abs(distance);
        framesToMove = Math.floor(distance / this.pixelsPerFrame);
        framesToMove = Math.min(2, framesToMove);
        return framesToMove;
    };
    ThreeSixty.prototype.refresh = function (evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var allListElements;
        var currentElement = this.ul360 ? this.ul360.querySelector('.active') : null;
        var currentActiveIndex;
        var refRequestID;
        var penImagesLen = this.pendingImages.length;
        var downloading = penImagesLen;
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;

        this.viewerUpdatedStartTime = Date.now();

        refRequestID = data && data.refRequestID ?
            data.refRequestID : this.model.getRequestID();
        this.requestID = refRequestID;

        for (var i = 0; i < penImagesLen; i++) {
            if (refRequestID !== this.pendingImages[i].requestID &&
                this.currentIndex !== this.pendingImages[i].index) {
                this.pendingImages[i].prepareDefer.resolve();
                this.pendingImages[i].img.onload = null;
                this.pendingImages[i].img.onerror = null;
                this.pendingImages[i].img.src = '';
                this.pendingImages.splice(i, 1);
                i--;
                penImagesLen--;
            }
        }

        if (this.framesHandler.isAltId(this.currentIndex)) {
            this.goToIndex(this.prevIndex);
        }
        if (this.isComplete === false) {
            this.trigger(this.events.FEATURES_CANCELED, {
                features: this.model.getCanceledFeatures()
            });
            downloading = downloading === 0 ? this.frameCount : downloading;
        }
        this.firstFrameIsReady = false;
        this.isReady = false;
        this.isComplete = false;
        this.promises = [];
        this.imagesRequested = 0;
        this.preventRotation();
        this.targetLoadedPercentage = this.model.get('partialRotationStart');
        this.frameCount = frameCount;
        this.currentFeatures = this.model.get('currentFeatures');
        this.url = this.currentFeatures.paths;
        allListElements = this.dom.querySelectorAll(this.ul360, 'li:not(.active):not([class*=alt])');
        allListElements.forEach(function (element) {
            element.classList.add('hidden');
            element.innerHTML = '';
        });

        if (currentElement) {
            currentActiveIndex = this.framesHandler.isAltId(this.currentIndex) ?
                this.prevIndex : this.currentIndex;
            this.prepare(currentActiveIndex, refRequestID);
            if (this.framesHandler.is360Frame(currentActiveIndex)) {
                ++this.imagesRequested;
            }
            this.promises = this.framesHandler.is360Frame(currentActiveIndex) ? this.promises : [];
        }

        Promise.all(this.promises).then(function () {
            this.refreshRest(refRequestID);
        }.bind(this));
    };
    ThreeSixty.prototype.refreshRest = function (requestID) {
        this.promises = [];
        this.downloadRest(requestID);
        Promise.all(this.promises).then(this.onFeaturesUpdated.bind(this));
    };
    ThreeSixty.prototype.updateView = function (evt, data) {
        var li, index;
        if (data && data.prop === this.defaultTooltip &&
            this.tooltip && this.tooltip.isVisible()) {
            li = this.ul360.querySelector('.active');
            index = li && li.dataset ? li.dataset.index : li.getAttribute('data-index');
            this.handleTooltip(li, index, index);
        }
    };
    ThreeSixty.prototype.onFeaturesUpdated = function () {
        if (!this.model.get('hasUnsupportedFeatures')) {
            if (this.noFeatureTooltip) {
                this.noFeatureTooltip.hide();
            }
        }
        this.trigger(this.events.FEATURES_CHANGED);
    };
    ThreeSixty.prototype.downloadRest = function (requestID) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var partialRotationPercentage = this.model.get('partialRotationStart');
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;
        var oPreviousDeferred = null;
        var self = this;
        var startIndex = 1;
        var increment = frameCount / (frameCount * (partialRotationPercentage / 100));
        var i = startIndex;
        var indexes = [];
        var halfOfImagesLenght = frameCount * (partialRotationPercentage / 100);

        while (i <= frameCount && this.model.getRequestID() === requestID) {
            if (i !== parseInt(this.currentIndex, 10)) {
                ++self.imagesRequested;
                indexes.push({
                    index: i,
                    requestID: requestID
                });
                if (self.imagesRequested === Math.floor(halfOfImagesLenght)) {
                    halfOfImagesLenght = Math.round(halfOfImagesLenght);
                    tryToPrepareGroupOfImages(indexes);
                    indexes = [];
                    self.imagesRequested = 0;
                }
            }
            i += increment;
            if (i > frameCount && startIndex < increment) {
                i = (++startIndex);
            }
        }
        function tryToPrepareGroupOfImages(indexes) {
            if (oPreviousDeferred === null) {
                oPreviousDeferred = getPromise(indexes);
            }
            else {
                oPreviousDeferred = oPreviousDeferred.then(function () {
                    return getPromise(indexes);
                });
            }
            function getPromise(indexes) {
                var masterDeferred = self.util.promise.create();
                var promises = [];
                var i;
                for (i = 0; i < indexes.length; i++) {
                    promises.push(self.prepare(indexes[i].index, indexes[i].requestID));
                }
                Promise.all(promises).then(function () {
                    masterDeferred.resolve();
                });
                return masterDeferred;
            }
        }
    };

    ThreeSixty.prototype.prepare = function (index, requestID) {
        var self = this;
        var frameDeferred = this.util.promise.create();
        var deferred = this.util.promise.create();
        var baseClassName, className, li, img, totalFrames, imgTimeout, liSelection, integerIndex;
        if (this.framesHandler.is360Frame(index)) {
            baseClassName = 'cylindo-viewer-frame-';
            className = baseClassName + index;
            li = document.createElement('li');
            li.setAttribute('aria-hidden', true);
            img = new Image();
            totalFrames = this.frameCount;
            imgTimeout = this.model.get('imageTimeout');
            liSelection = this.dom.querySelectorAll(this.ul360, '.' + className);
            if (liSelection && liSelection.length >= 1) {
                li = liSelection[liSelection.length - 1];
            }
            li.classList.add(baseClassName + index);
            if (!li.clientHeight) {
                li.classList.add('hidden');
            }
            if (this.url) {
                var rtvOptions = {
                    requestID: requestID,
                    viewer: this,
                    urls: this.url,
                    baseClassName: baseClassName,
                    index: index,
                    li: li,
                    totalFrames: totalFrames,
                    imgTimeout: imgTimeout,
                    onImageLoad: this.onImageLoad,
                    onError: this.onError,
                    prepareDefer: deferred
                };

                frameDeferred = this.renderer.useStrategy(this.renderer.strategies.RTV_SINGLE_MERGED, rtvOptions);
            }
            else {
                img.src = '';
            }
            this.promises.push(deferred);
            if (index === this.currentIndex && !li.classList.contains('active')) {
                this.handleActiveFrame(li, true);
            }
            if (this.ul360) {
                this.ul360.appendChild(li);
            }
        }
        else if (this.framesHandler.isAltId(index)) {
            integerIndex = parseInt(index.replace(this.framesHandler.prefixes.ALT, ''), 10);
            frameDeferred = this.prepareAltContent(integerIndex);
        }
        frameDeferred.then(function () {
            self.firstFrameEvt(index);
            deferred.resolve();
        });

        return deferred;
    };
    ThreeSixty.prototype.reloadAltContent = function (evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var altContentLen = allFrames.ALTERNATE.LEN;
        var i;
        var prevIndex = this.framesHandler.is360Frame(this.currentIndex) ?
            this.currentIndex :
            this.prevIndex ? this.prevIndex : 1;
        prevIndex = isNaN(prevIndex) ? prevIndex : parseInt(prevIndex, 10);
        this.prevIndex = prevIndex;
        this.goToIndex(this.prevIndex);
        ViewAbstract.prototype.reloadAltContent.call(this, evt, data);
        for (i = 0; i < altContentLen; i++) {
            this.prepareAltContent(i, evt, data);
        }
    };
    ThreeSixty.prototype.preventRotation = function () {
        this.removeMouseCallbacks();
        this.hideTooltip(true);
    };
    ThreeSixty.prototype.onReady = function () {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        ViewAbstract.prototype.onReady.call(this);
        this.sendFirstFrameCompleteEvt(this);
        this.tryToShowTooltip();
    };
    ThreeSixty.prototype.createViewerList = function () {
        ViewAbstract.prototype.createViewerList.call(this);
        if (this.util.browser.isIE() || this.util.browser.isEdge()) {
            this.ul360.classList.add('is-ms');
        }
    };
    ThreeSixty.prototype.onImageLoad = function () {
        var li = this.li;
        var clonedLi = li.cloneNode();
        var img = this.img;
        var layers = this.layers;
        var that = this.self;
        var deferred = this.deferred;
        var imagesArrLen = img.length;
        var altText = 'Frame ' + this.index + ' of "__productName__" ';
        var pendingImageIndex = -1;
        var i;
        var repeatedLiElements = [];
        var baseClassName = 'cylindo-viewer-frame-';

        for (i = 0; i < that.pendingImages.length; i++) {
            if (that.pendingImages[i].index === this.index) {
                pendingImageIndex = i;
            }
        }
        if (pendingImageIndex !== -1) {
            that.pendingImages.splice(pendingImageIndex, 1);
        }
        if (this.requestID !== that.model.getRequestID()) {
            return;
        }

        if (this.index === that.currentIndex ||
            (!that.framesHandler.is360Frame(this.currentIndex) && this.index === that.startFrame)) {
            that.firstImageLoadedTime = Date.now();
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        altText = altText.replace('__productName__', that.model.get('fileName'));

        if (img.length > 1) {
            for (i = 0; i < imagesArrLen; i++) {
                img[i].alt = altText + '(' + layers[i].type + ' layer - ' + layers[i].code + ')';
                clonedLi.appendChild(img[i]);
            }
        }
        else {
            if (img instanceof Image) {
                img.alt = altText;
                clonedLi.appendChild(img);
            } else {
                img[0].alt = altText;
                clonedLi.appendChild(img[0]);
            }
        }

        repeatedLiElements = that.dom.querySelectorAll(that.ul360, '.' + baseClassName + this.index);
        that.ul360.appendChild(clonedLi);

        that.dom.remove(li);

        that.tryToEnableImageGroup();
        that.trigger(that.events.IMG_LOADED, { sequence: this.index, img: img });
        deferred.resolve();
    };

    ThreeSixty.prototype.tryToEnableImageGroup = function () {
        var self = this;
        var allFrames = self.framesHandler.getAllFramesToBeLoaded();
        var addedFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-"]:not([class*="-alt-"])');
        addedFrames = addedFrames.filter(function (element) {
            var className = element.className.match(/cylindo-viewer-frame-(\d+)/ig)[0];
            var occurrences = self.dom.querySelectorAll(self.ul360, '.' + className);
            return element.childNodes.length !== 0 &&
                addedFrames.indexOf(element) === addedFrames.indexOf(occurrences[occurrences.length - 1]);
        });
        var alternateContent = allFrames.ALTERNATE.LIST;
        var loadedPercentage = addedFrames.length / this.frameCount * 100;
        var frameSelection = this.dom.querySelectorAll(this.ul360, '.cylindo-viewer-frame-' + this.currentIndex);
        var firstFrame = frameSelection.length === 1 ? frameSelection[0] : frameSelection[frameSelection.length - 1];
        var enable = false;
        var frameIndex = firstFrame ? firstFrame.dataset ? firstFrame.dataset.index : firstFrame.getAttribute('data-index') : null;
        var partialRotationPercentage = this.model.get('partialRotationStart');
        var currentPercentage = addedFrames.length * 100 / this.frameCount;

        alternateContent = alternateContent instanceof Array ? alternateContent : [];
        frameIndex = this.framesHandler.is360Frame(frameIndex) ? parseInt(frameIndex, 10) : frameIndex;

        if (frameIndex === this.currentIndex &&
            firstFrame && firstFrame.classList &&
            firstFrame.classList.contains('hidden')) {
            enable = true;
        }
        else if (loadedPercentage >= this.targetLoadedPercentage) {
            this.targetLoadedPercentage *= 2;
            enable = true;
        }

        if (enable) {
            addedFrames.forEach(function (element) {
                element.classList.remove('hidden');
            });
            if ((!this.isVisible && addedFrames.length >= 1)) {
                this.displayViewer();
            }
            if (!this.isReady && currentPercentage >= partialRotationPercentage) {
                this.onReady();
                this.trigger(this.events.CONTENT_TYPE_COMPLETE, {
                    contentType: this.contentTypes.READY
                });
            }
            if (!this.isComplete && currentPercentage === 100) {
                this.onDownloadComplete();
            }
        }
    };
    ThreeSixty.prototype.getNextAvailableFrame = function (current) {
        var availableFrames = this.getAvailableFrames();
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;
        var logger = this.model.getLogger();
        availableFrames = availableFrames.filter(function (index) {
            return !isNaN(index);
        });
        var newCurrent = availableFrames[0];
        var len = availableFrames.length;
        var i = availableFrames.indexOf(current);
        var _i;
        var _j;
        while (i === -1) {
            current = this.deltaX > 0 ?
                (_j = current - 1) < 1 ? frameCount : _j :
                (_j = current + 1) > frameCount ? 1 : _j;
            i = availableFrames.indexOf(current);
        }
        if (this.deltaX > 0) {
            i = ((_i = i + 1) === len ? 0 : _i);
        }
        else {
            i = ((_i = i - 1) < 0 ? (len - 1) : _i);
        }
        newCurrent = availableFrames[i];

        return newCurrent;
    };

    ThreeSixty.prototype.tryToShowTooltip = function () {
        var currElement = this.ul360.querySelector('.active');
        var activeIndex = currElement.getAttribute('data-index');
        var is360Frame = this.framesHandler.is360Frame(activeIndex);
        var isAltContent = this.framesHandler.isAltId(activeIndex);
        var isVideo = currElement.classList.contains('cylindo-video');
        var isImgNotFound = currElement.classList.contains('cylindo-img-not-found');
        var threeSixtyText = this.model.get('tooltipDragText');
        var altltImgText = this.model.get('tooltipAltImgText');
        var showTooltip = true;
        if (isImgNotFound || isVideo) {
            showTooltip = false;
        }
        if (this.tooltip && !this.tooltip.isVisible() && showTooltip) {
            if (is360Frame) {
                if (typeof threeSixtyText === 'string' &&
                    threeSixtyText.length > 0) {
                    this.showTooltip(threeSixtyText);
                }
            }
            else if (isAltContent) {
                if (typeof altltImgText === 'string' &&
                    altltImgText.length > 0) {
                    this.showTooltip(altltImgText);
                }
            }
        }
    };
    ThreeSixty.prototype.autoRotate = function (degrees, milliseconds) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var framesToShow = [];
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;
        var degreesBetweenFrames = 360 / frameCount;
        var index = this.currentIndex;
        var currentAngle;
        var delay = 0;
        var keepSearching = true;
        var self = this;
        var isRightRotation = true;
        var angleDifference;
        var nextAngleDifference;
        var angle;
        if (isNaN(index) || !self.isReady || self.isAutoRotating) {
            return;
        }
        if (degreesBetweenFrames / 2 > Math.abs(degrees)) {
            return;
        }

        this.stopAutoRotation = false;

        milliseconds = typeof milliseconds !== 'undefined' ? milliseconds : 0;
        currentAngle = (index - 1) * degreesBetweenFrames;

        if (degrees < 0) {
            isRightRotation = false;
        }
        angle = currentAngle + degrees;

        while (keepSearching) {
            if (isRightRotation) {
                currentAngle += degreesBetweenFrames;
                if (index === frameCount) {
                    index = 1;
                }
                else {
                    index++;
                }
            }
            else {
                currentAngle -= degreesBetweenFrames;
                if (index === 1) {
                    index = frameCount;
                }
                else {
                    index--;
                }
            }
            framesToShow.push({
                angle: currentAngle,
                index: index
            });
            angleDifference = Math.abs(currentAngle - angle);
            nextAngleDifference = isRightRotation ?
                Math.abs(currentAngle + degreesBetweenFrames - angle) :
                Math.abs(currentAngle - degreesBetweenFrames - angle);
            if (angleDifference < degreesBetweenFrames &&
                angleDifference < nextAngleDifference) {
                keepSearching = false;
            }
        }
        delay = framesToShow.length > 0 ? milliseconds / framesToShow.length : 0;
        self.executeAutoRotation(framesToShow, delay, isRightRotation, frameCount);
    };
    ThreeSixty.prototype.goToAngle = function (angle) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        if (angle < 0 || angle > 360) {
            this.model.getLogger().warning('Angle should be a value between 0 and 360');
            return;
        }
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;
        var index = this.currentIndex;
        var self = this;
        if (isNaN(index) || !self.isReady || self.isAutoRotating) {
            return;
        }
        this.stopAutoRotation = false;
        var indexes = angles.degreesToIndexes([angle], frameCount);
        if (indexes.length) {
            this.goToIndex(indexes[0]);
        }
    };

    ThreeSixty.prototype.executeAutoRotation = function (framesToShow, delay, isRightRotation, frameCount) {
        var indexToDisplay = 0;
        var rotationFn;
        var self = this;
        if (framesToShow.length) {
            if (delay === 0) {
                framesToShow = [framesToShow[framesToShow.length - 1]];
            }
            rotationFn = setInterval(function () {
                var frame;
                var previousActive;
                var nextFrame;
                var currentIndex;
                var nextIndex;
                if (indexToDisplay === 0 && delay > 0) {
                    self.isAutoRotating = true;
                    self.trigger(self.events.START, {
                        index: self.currentIndex
                    });
                }
                if (indexToDisplay < framesToShow.length) {
                    currentIndex = framesToShow[indexToDisplay].index;
                    frame = self.ul360.querySelector('[data-index="' + currentIndex + '"]');
                    previousActive = self.ul360.querySelector('.active');
                    if (isRightRotation) {
                        nextIndex = framesToShow[indexToDisplay].index === frameCount ? 1 :
                            framesToShow[indexToDisplay].index + 1;
                    }
                    else {
                        nextIndex = framesToShow[indexToDisplay].index === 1 ? frameCount :
                            framesToShow[indexToDisplay].index - 1;
                    }
                    nextFrame = self.ul360.querySelector('[data-index="' + nextIndex + '"]');
                    if (frame && frame.classList && frame.classList.contains('cylindo-img-not-found')) {
                        if (indexToDisplay === framesToShow.length - 1) {
                            tryToStopRotation();
                        }
                    }
                    else if (frame &&
                        !frame.classList.contains('hidden') &&
                        !frame.classList.contains('active') &&
                        !self.stopAutoRotation) {
                        self.handleActiveFrame(previousActive, false);
                        self.currentIndex = currentIndex;
                        self.handleActiveFrame(self.ul360.querySelector('[data-index="' + self.currentIndex + '"]'), true);
                        self.trigger(self.events.CURRENT, { current: self.currentIndex });
                    }
                    else if (frame &&
                        !frame.classList.contains('hidden') &&
                        frame.classList.contains('active') && delay === 0 &&
                        !self.stopAutoRotation) {
                        self.trigger(self.events.CURRENT, { current: self.currentIndex });
                    }
                    else if (nextFrame &&
                        !nextFrame.classList.contains('hidden') &&
                        !nextFrame.classList.contains('active') &&
                        indexToDisplay === framesToShow.length - 1 &&
                        !self.stopAutoRotation) {
                        self.currentIndex = nextIndex;
                        self.handleActiveFrame(previousActive, false);
                        self.handleActiveFrame(self.ul360.querySelector('[data-index="' + self.currentIndex + '"]'), true);
                        self.trigger(self.events.CURRENT, { current: self.currentIndex });
                    }
                    else {
                        tryToStopRotation();
                    }
                    if (indexToDisplay === framesToShow.length - 1 && delay > 0) {
                        self.isAutoRotating = false;
                        self.trigger(self.events.END, {
                            index: isNaN(self.currentIndex) ? self.prevIndex : self.currentIndex
                        });
                    }
                    indexToDisplay++;
                }
                else {
                    clearInterval(rotationFn);
                }
                function tryToStopRotation() {
                    if (self.stopAutoRotation) {
                        self.isAutoRotating = false;
                        self.trigger(self.events.END, {
                            index: isNaN(self.currentIndex) ? self.prevIndex : self.currentIndex
                        });
                        clearInterval(rotationFn);
                        return;
                    }
                }
            }, delay);
        }
    };
    ThreeSixty.prototype.resetMovementsTail = function () {
        clearTimeout(this.rafIndex);
        this.rafIndex = null;
        try {
            this.tailPromise.resolve();
        }
        catch (ex) {
            this.model.getLogger().log('Tail of movements did not exist');
        }
        this.tailPromise = this.util.promise.create();
        this.isTailOfMovements = false;
        this.rotations = 0;
        this.endFrame = this.currentIndex;
        this.updateTimeOut = 1000 / 60;
    };
    ThreeSixty.prototype.getRotationSpeed = function () {
        var rotationSpeed = this.model.get('rotationSpeed');
        var isIE = this.util.browser.isEdge() || this.util.browser.isIE();
        if (isIE && this.isFullScreen()) {
            rotationSpeed = rotationSpeed / 2;
        }
        return rotationSpeed;
    };
    ThreeSixty.prototype.isFullScreen = function () {
        var viewerContainer = this.ul360.parentNode;
        return viewerContainer && viewerContainer.classList.contains('full-screen');
    };

    var publicAPI = {
        create: function (opts) {
            return new ThreeSixty(opts);
        }
    };

    window.cylindo.addModule('threesixty', publicAPI);
}).call(this);