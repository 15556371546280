//CylindoViewer Module
/*
    This File will define the main Cylindo Viewer class, and will add to the global scope the Viewer Module
    which will expose a "create" method that should be used inside the third party pages to generate new viewer 
    instances which will be returned by the method.

    i.e.
    
    var sofaViewer = Cylindo.Viewer.create({...});

    The instance returned will expose a high level interface as public methods, hiding the true complexity of the 
    operations the viewer execute behind scenes. Some of the methods and properties available in the viewer instance will be:

    //get, update, reset, on, destroy. maybe a few more will be added down the road

    The user will be able to subscribe to some custom events via 'on' method like - 'ready'
*/
(function () {
    'use strict';
    /**
     * The cylindo viewer constructor
     *     
     * @constructor
     */
    var Viewer = function () {
        /**
         * For the viewer class we will develop a standard MVC architecture to delegate to the right module the tasks that should 
         * be managed on it's side.
         */
        var instances = {};
        /**
         * Array of valid property names.
         */
        /**
         * Obtain the viewer services module to provide the functionality as
         * public API.
         */
        var viewerServices = cylindo.getModule('cylindo.viewer.services');
        var viewerProperties = cylindo.getModule('cylindo.viewer.properties');

        /**
         * Expose the service functions through the viewer object.
         */
        this.services = {
            skuExists: viewerServices.skuExists // More service functions can be added after this line.
        };
        /**
         * Create new instance of CylindoViewer using a configuration object.
         * This configuration object will contain all the customizable elements
         * that describe the viewer.
         *
         * .i.e 
         * {
         *    'accountID': 'Tylersofa',
         *    'productCode': 4500,
         *    'features': ["1", "WHiTE", "2", "Espresso"],
         *    'containerID': 'tylersofa-viewer'
         * }
         * @param {object} opts - object with properties
         */
        this.create = function (opts) {
            var util = cylindo.getModule('cylindo.core.util');
            var instance = null;
            // Show default fallback image if no features are defined
            if (opts && viewerProperties.areRequiredPropertiesPresent(opts)) {
                // report any unknown properties (case mismatch or spelling errors)
                viewerProperties.reportUnknownProperties(opts);

                if (opts.containerID) {
                    // Remove the # from the container ID in case it has been passed in.
                    opts.containerID = opts.containerID.replace(/^#/, '');
                }

                //Backwards compatibility
                if (opts.hasOwnProperty('SKU')) {
                    opts.productCode = opts.SKU;
                    delete opts.SKU;
                }
                if (opts.hasOwnProperty('skuVersion')) {
                    opts.version = opts.skuVersion;
                    delete opts.skuVersion;
                }
                if (opts.hasOwnProperty('imageResolution')) {
                    opts.size = opts.imageResolution;
                    delete opts.imageResolution;
                }
    
                /*
                    Controller will act as a facilitator between the main viewer instance and the model and view, and also
                    when it makes sense; It will also deal with other modules and notify the View
                    and Model when changes caused by external modules be required.
                */
                try {
                    instance = cylindo.getModule('cylindo.controller').create(opts);
                    // storage reference to instance created
                    instances[opts.productCode + '_' + opts.containerID] = instance;
                    instances[opts.productCode + '_' + opts.containerID].on(instance.events.DESTROYED, function () {
                        delete instances[opts.productCode + '_' + opts.containerID];
                    });
                }
                catch (e) {
                    console.error(e);
                }
                return instance;
            }
            else {
                throw 'Cylindo 360 HD Viewer require a valid options object to instantiate. Please refer to the integration guide';
            }
        };

        /**
         * get all viewer instances in an array
         */
        this.getInstances = function () {
            return instances;
        };

        /**
         * return stored viewers by productCode + containerID
         */
        this.getViewer = function (id) {
            return instances[id] || null;
        };

        // delete all viewer instances created
        this.destroyAll = function () {
            for (var index in instances) {
                if (instances.hasOwnProperty(index)) {
                    instances[index].destroy();
                }
            }
            instances = {};
        };

        /*
         * Global setter
         * Update will replace all the setters available to provide a single simplified way to make changes
         * after the instance has been created, will received an object with the new values to apply
         */
        this.update = function (instanceID, field, params) {
            var instance = instances[instanceID];
            if (!instance) {
                throw Error('Instance doesn\'t exist');
            }
            instance.hideImage();
            instance.update(field, params);
        };

        /**
         * Remove instance created and all dependencies
         * @param {number} instanceID 
         */
        this.destroy = function (instanceID) {
            var instance = instance[instanceID];
            if (!instance) {
                throw Error('Instance doesn\'t exist');
            }
            instance.destroy();
            instance = null;
            delete instances[instanceID];
        };
    };

    // Add the module as a public member to the global cylindo object.
    window.cylindo.addModule('viewer', new Viewer(), true);
}).call(this);
