(function () {
    'use strict';

    var Youtube = function (options) {
        var util = cylindo.getModule('cylindo.core.util');
        this.deferred = util.promise.create();
        this.apiProgress = Youtube.apiProgress;
        this.id = options.id;
        this.videoId = options.videoId;
        this.APIUrl = 'https://www.youtube.com/iframe_api';
        this.player = {};
        this.createPlayer();
    };

    Youtube.prototype.createPlayer = function() {
        var self = this;
        var cb = function () {
            if (typeof window.YT.Player === 'function') {
                self.player = new window.YT.Player(self.id, {
                    events: {
                        onError: function() {
                            self.deferred.reject();
                        },
                        onReady: function() {
                            self.deferred.resolve();
                        }
                    }
                });
            }
        }; 
        this.checkAPIStatus(cb);
    };

    Youtube.prototype.checkAPIStatus = function(cb) {
        if (!this.apiProgress.started && !this.apiProgress.finished && typeof window.YT !== 'object') {
            this.embedAPIScript();
            this.apiProgress.fns.push(cb);
        } 
        else if ((this.apiProgress.started && !this.apiProgress.finished) || (typeof window.YT === 'object' && window.YT.loaded === 0)) {
            this.apiProgress.fns.push(cb);
        }
        else if (typeof window.YT === 'object') {
            cb.call();
        }
    };

    Youtube.prototype.embedAPIScript = function () {
        var tag = document.createElement('script');
        var firstScriptTag = document.getElementsByTagName('script')[0];
        tag.src = this.APIUrl;
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        this.apiProgress.started = true;
        this.APIListener();
    };

    Youtube.prototype.APIListener = function() {
        var self = this;
        window.onYouTubeIframeAPIReady = function () {
            self.apiProgress.finished = true;
            var len = self.apiProgress.fns.length;
            var i;
            for (i = 0; i < len; i++) {
                self.apiProgress.fns[i].call();
            }
            self.apiProgress.fns = [];
        };        
    };

    Youtube.prototype.play = function () {
        this.player.playVideo();
    };

    Youtube.prototype.pause = function () {
        this.player.pauseVideo();
    };

    Youtube.prototype.stop = function () {
        this.player.stopVideo();
    };

    Youtube.prototype.destroy = function () {
        this.player.destroy();
    };

    var publicAPI = {
        create: function (opts) {
            return new Youtube(opts);
        }
    };

    Youtube.apiProgress = {
        'started' : false,
        'finished' : false,
        'fns' : []
    };

    window.cylindo.addModule('cylindo.video.youtube', publicAPI);
}).call(this);