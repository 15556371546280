
(function () {
	'use strict';

	var ThumbScroll = cylindo.getModule('cylindo.classes.thumbScroll');
	var network = cylindo.getModule('cylindo.core.network');
	var angles = cylindo.getModule('cylindo.util.angles');
	var Thumbnails = function (configuration) {
		var self = this;

		var model = configuration.model;
		var logger = model.getLogger();
		var tags = configuration.tags;
		var el = configuration.el;
		var url = configuration.url;
		var wasThumbClicked = false;

		var thumbScroll = null;
		var backgroundColorDefined = false;

		var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
		var util = cylindo.getModule('cylindo.core.util');
		var dom = configuration.dom;
		var viewerPresentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
		var contentHelper = cylindo.getModule('cylindo.helpers.content.to.load');
		var currentFeatures = null;
		var contentTypes = contentHelper.getContentTypes();
		var thumbLocation = model.get('thumbLocation');
		var thumbLocations = model.getThumbLocations();
		var thumbSizePercentage = (100 / model.get('thumbPageSize')) + '%';
		var framesHandler = configuration.framesHandler;
		var allFrames;

		var isFirstRun = true;

		var promises = [];

		this.id = null;
		this.initialized = false;
		this.ready = false;
		this.isHidden = true;
		this.hiding = false;
		this.delayedRender = false;
		this.featuresChanging = false;

		this.show = show;
		this.hide = hide;
		this.on = pubsub.on;
		this.off = pubsub.off;
		this.trigger = pubsub.trigger;
		this.destroy = destroy;
		this.setActive = setActive;
		this.render = render;
		this.thumbsHeight = thumbsHeight;
		this.framesHandler = framesHandler;
		this.ariaLabel = configuration.ariaLabel;

		this.el = el;

		this.requestID = 0;

		init();


		function init() {
			var thumbLocationBaseClass = 'thumb-location-';
			if (model.get('presentation') === viewerPresentations.STACKED.NAME) {
				return;
			}
			if (model.get('thumbs') && el && el.parentNode) {
				el.parentNode.classList.add(thumbLocationBaseClass + thumbLocation);
			}
			self.id = ++Thumbnails.counter;
			self.initialized = true;
			model.on(model.events.FEATURES_CHANGED, hide);
			model.on(model.events.CHANGED, render);
			framesHandler.on(framesHandler.events.RELOAD_THUMBNAILS, render);

			self.trigger(self.events.CREATED);
		}
		function render(evt, data) {
			var ul = document.createElement('ul');
			var ssPromises = [];
			var altPromises = [];
			var refRequestID;
			var i;
			var index;
			var active = el.querySelector('.active');
			var ssBaseZeroIndex;
			var altBaseZeroIndex;
			var thumbCount = model.get('thumbCount');
			var alternateContent, thumbnailsList, thumbnailsLen;
			var wrapper = el ? el.parentNode : null;

			currentFeatures = model.get('currentFeatures');
			allFrames = framesHandler.getAllFramesToBeLoaded();
			alternateContent = allFrames.ALTERNATE.LIST;
			thumbnailsList = allFrames.THUMB.LIST;
			thumbnailsLen = allFrames.THUMB.LEN;
			if (self.hiding) {
				self.delayedRender = true;
				return;
			}
			if (evt && data && evt === model.events.CHANGED &&
				framesHandler && framesHandler.initialized &&
				framesHandler.propsToValidateOrder.indexOf(data.prop) !== -1) {
				return;
			}
			if (!currentFeatures && (!alternateContent || !alternateContent.length)) {
				return;
			}

			if (currentFeatures && currentFeatures.paths) {
				ul.classList.add('cylindo-rtv');
			}
			if (wrapper) {
				wrapper.setAttribute('aria-label', self.ariaLabel.texts.THUMBNAILS_BAR);
			}
			ul.setAttribute('role', 'list');
			ul.setAttribute('aria-label', self.ariaLabel.texts.THUMBNAIL_IMAGES);
			ul.classList.add('cylindo-thumbnail-list');
			self.requestID = model.getRequestID();
			refRequestID = self.requestID;

			for (i = 0; i < thumbnailsLen; i++) {
				index = thumbnailsList[i];
				if (framesHandler.isAltId(index)) {
					altBaseZeroIndex = parseInt(index.replace(/alt-/g, ''), 10);
					altPromises.push(prepareAlternateThumbs(altBaseZeroIndex, alternateContent[altBaseZeroIndex], ul, thumbCount));
				}
				else if (framesHandler.is360Frame(index)) {
					prepareThumbs(index, ul, active);
				}
			}

			Promise.all(altPromises).then(function () {
				this.trigger(this.events.CONTENT_TYPE_COMPLETE, {
					contentType: contentTypes.ALTERNATE_THUMB
				});
			}.bind(self));
			Promise.all(promises).then(onThumbsReady);

			function onThumbsReady() {
				var thumbnailsLen = allFrames.THUMB.LEN;
				var wrapper = el ? el.parentNode : null;
				var opts = {
					el: ul,
					wrapper: wrapper,
					thumbPageSize: model.get('thumbPageSize'),
					dom: dom,
					model: model,
				};
				var ts = allFrames.THUMB.THREESIXTY_THUMB_LEN;
				var ac = allFrames.ALTERNATE.LEN;
				var firstThumb = allFrames.THUMB.LEN ? allFrames.THUMB.LIST[0] : null;
				var frameToActivate = framesHandler.is360Frame(firstThumb) ? model.get('startFrame') : firstThumb;

				promises = [];

				if (refRequestID !== model.getRequestID() || !el) {
					return;
				}

				removeEvents();

				el.innerHTML = '';
				el.appendChild(ul);
				el.parentNode.classList.add('has-thumbs');

				if (thumbScroll) {
					if (self.featuresChanging) {
						opts.offset = thumbScroll.getCurrentOffset();
					}
					thumbScroll.off(thumbScroll.events.THUMB_CLICKED, thumbClicked);
					thumbScroll.destroy();
				}
				if (thumbnailsLen > opts.thumbPageSize) {
					thumbScroll = new ThumbScroll(opts);
					thumbScroll.on(thumbScroll.events.THUMB_CLICKED, thumbClicked);
				}

				initEvents();

				if (isFirstRun) {
					isFirstRun = false;
					if (!active) {
						setActive(frameToActivate);
					}
				}

				self.ready = true;
				self.trigger(self.events.READY);
				self.trigger(self.events.CONTENT_TYPE_COMPLETE, {
					contentType: contentTypes.FRAME_THUMB
				});
				self.featuresChanging = false;
			}
		}

		function prepareAlternateThumbs(nImageIndex, altContent, elUl, thumbCount) {
			var currentActive = el.querySelector('.active');
			var deferred = util.promise.create();
			var img = new Image();
			var li = document.createElement('li');
			var vendorThumbSource = '';
			var src = '';
			var url = '';
			var vimeoData = {
				dataType: 'jsonp',
				jsonp: 'callback',
				timeout: 10000
			};
			var vimeoOembed = ('https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/');
			if (util.browser.isSafari() && parseFloat(util.browser.browser.version, 10) < 7) {
				vimeoOembed = network.resolveProtocol(vimeoOembed);
			}
			changeThumbSize(li);
			if (thumbCount === 0 &&
				nImageIndex === 0 &&
				!currentActive) {
				li.classList.add('active');
			}

			li.classList.add('environment-thumb');

			if (typeof altContent.provider === 'undefined') {
				url = altContent.image;
			}
			else if (altContent.provider === 'direct') {
				url = altContent.sources[0].src;
			}
			else if (altContent.provider === 'vimeo' || altContent.provider === 'youtube') {
				url = altContent.url;
			}

			if (altContent.provider) {
				li.classList.add('cylindo-video-thumb');
				img.classList.add('is-video-thumb');
			}
			else {
				li.classList.add('loading');
			}

			try {
				li.dataset.index = framesHandler.prefixes.ALT + nImageIndex;
				li.dataset.alternateIndex = nImageIndex;
				li.dataset.url = url;
			}
			catch (ex) {
				li.setAttribute('data-index', framesHandler.prefixes.ALT + nImageIndex);
				li.setAttribute('data-alternate-index', nImageIndex);
				li.setAttribute('data-url', url);
			}
			if (img.addEventListener) {
				img.addEventListener('load', onLoad);
			}
			else if (img.attachEvent) {
				img.attachEvent('onload', onLoad);
			}

			img.onerror = onAltError;
			if (typeof altContent.provider === 'undefined' || altContent.thumb) {
				src = network.resolveProtocol(altContent.thumb || altContent.image);
				img.src = src;
				li.setAttribute('data-alt-image-url', altContent.image);
			}
			else {
				if (altContent.provider === 'youtube' && altContent.videoId !== null) {
					vendorThumbSource = '//img.youtube.com/vi/' + altContent.videoId + '/default.jpg';
					img.src = network.resolveProtocol(vendorThumbSource);
				}
				else if (altContent.provider === 'vimeo' && altContent.videoId !== null) {
					vendorThumbSource = vimeoOembed + altContent.videoId;
					network.doRequest(vendorThumbSource, onVimeoInfoReady.bind({ img: img }), vimeoData, onVimeoInfoFail.bind({ img: img, model: model }), null);
				}
				else if (altContent.provider === 'not-supported' || altContent.provider === 'direct') {
					img.onerror = null;
					img.src = model.get('fallbackVideoImage');
					img.alt = 'Fallback video image';
				}
			}
			if (model.get('buildNumber')) {
				img.src += '&bn=' + model.get('buildNumber');
			}
			removeDraggableBehavior(img);
			img.setAttribute('role', 'link');
			img.alt = altContent.description ? (altContent.description + ' - thumb') : 'Alternative thumb: ' + (+ nImageIndex + 1);

			elUl.appendChild(li);

			function onLoad() {
				li.classList.remove('loading');
				li.appendChild(img);
				deferred.resolve();
				self.trigger(self.events.IMG_LOADED);
			}

			function onAltError() {
				src = model.get('fallbackImage');
				img.onerror = onEnvFallbackError;
				img.src = src;
			}
			function onEnvFallbackError() {
				src = model.get('defaultFallbackImage');
				logger.error('Fallback image not found ' + src);
				img.onerror = null;
				img.src = src;
			}

			return deferred;

		}

		function onVimeoInfoReady(responsedata, xhr) {
			this.img.src = responsedata.thumbnail_url;
		}

		function onVimeoInfoFail(responsedata, xhr) {
			this.img.src = this.model.get('fallbackVideoImage');
			this.img.alt = 'Fallback video image';
		}

		function prepareThumbs(index, ul, active) {
			var deferred = util.promise.create();
			var allFrames = framesHandler.getAllFramesToBeLoaded();
			var li = document.createElement('li');
			var imgTimeout = model.get('imageTimeout');
			var timeout;
			var dataIndex = null;
			var imageCount = allFrames.THREESIXTY.LEN;
			var constant = 360 / imageCount;
			var thumbsDegree = Math.round((index - 1) * constant);
			var rotatedDegreesText = 'rotated ' + thumbsDegree + ' degrees';
			var img = new Image();
			var src = currentFeatures && currentFeatures.paths ?
				network.resolveProtocol(currentFeatures.paths.thumbPath) : '';
			var data = {
				deferred: deferred,
				img: img,
			};

			src = src.replace('__FRAME_INDEX__', index);

			promises.push(deferred);

			if (active) {
				dataIndex = active.dataset ? active.dataset.index : active.getAttribute('data-index');
				if (dataIndex === '' + index) {
					li.classList.add('active');
				}
			}

			try {
				li.dataset.index = index;
			}
			catch (ex) {
				li.setAttribute('data-index', index);
			}
			changeThumbSize(li);

			if (imgTimeout > 0) {
				timeout = setTimeout(onError.bind(data), imgTimeout);
			}

			if (img.addEventListener) {
				img.addEventListener('load', onLoad.bind(data));
			}
			else if (img.attachEvent) {
				img.attachEvent('onload', onLoad.bind(data));
			}

			img.onerror = onError.bind(data);
			img.alt = model.get('fileName') + ', ' + ' ' + rotatedDegreesText;
			img.setAttribute('role', 'link');
			img.src = src;
			removeDraggableBehavior(img);
			li.appendChild(img);
			ul.appendChild(li);
			function onError() {
				var fallbackImg = model.get('fallbackImage');
				var img = new Image();
				this.img.onerror = onFallbackFail.bind(this);

				if (img.addEventListener) {
					img.addEventListener('load', onLoad.bind(this));
				}
				else if (img.attachEvent) {
					img.attachEvent('onload', onLoad.bind(this));
				}

				this.img.alt = 'Fallback image';
				this.img.src = fallbackImg;
				listenOnError(this.img);

				clearTimeout();
				if (fallbackImg) {
					img.src = fallbackImg;
				}
			}
			function onLoad() {
				clearTimeout();
				this.deferred.resolve();
				self.trigger(self.events.IMG_LOADED);
			}

			function clearTimeout() {
				if (timeout) {
					window.clearTimeout(timeout);
					timeout = null;
				}
			}
			function listenOnError(img) {
				img.onerror = onFallbackFail.bind({
					img: img
				});
			}
			function onFallbackFail() {
				var defaultFallbackImage = model.get('defaultFallbackImage');
				this.img.src = defaultFallbackImage;
				this.img.onerror = null;
			}
		}

		function initEvents() {
			var thumbs = dom.querySelectorAll(el, 'li');
			if (thumbs && thumbs.length > 0) {
				thumbs.forEach(function (thumb) {
					thumb.addEventListener('mousedown', onThumbClick, true);
				});
			}
		}
		function removeEvents() {
			var thumbs = dom.querySelectorAll(el, 'li');
			if (thumbs && thumbs.length > 0) {
				thumbs.forEach(function (thumb) {
					thumb.removeEventListener('mousedown', onThumbClick, true);
				});
			}
		}
		function onThumbClick(evt) {
			var altImgUrl = this.dataset.altImageUrl || '';
			var index = this.dataset ? this.dataset.index : this.getAttribute('data-index');
			var alternateIndex = this.dataset ? this.dataset.alternateIndex : this.getAttribute('data-alternate-index');
			var alternateUrl = this.dataset ? this.dataset.url : this.getAttribute('data-url');
			var thumbs = dom.querySelectorAll(el, 'li');
			var position = thumbs.indexOf(this) + 1;
			var contentType = null;
			var currentActive = el.querySelector('.active');
			var currentIndex = currentActive ?
				currentActive.dataset ? currentActive.dataset.index : currentActive.getAttribute('data-index') :
				null;

			if (alternateIndex) {
				if (!this.classList.contains('is-video-thumb')) {
					contentType = 1;
				}
				else {
					contentType = 2;
				}
			}
			if (!isNaN(index) && !isNaN(currentIndex)) {
				wasThumbClicked = true;
			}

			if (currentActive) {
				currentIndex = currentActive.dataset ? currentActive.dataset.index : currentActive.getAttribute('data-index');
				currentActive.classList.remove('active');
			}

			this.classList.add('active');

			self.trigger(self.events.CLICK, {
				index: index,
				alternateIndex: alternateIndex,
				contentType: contentType,
				image: altImgUrl,
			});
			tags.send(tags.events.THUMBNAIL_CLICK);
		}

		function thumbClicked(evt, data) {
			onThumbClick.call(data.thumb);
		}

		function destroy() {
			var thumbs = dom.querySelectorAll(el, 'li');
			if (thumbScroll) {
				thumbScroll.destroy();
			}
			thumbs.forEach(function (thumb) {
				thumb.removeEventListener('mousedown', onThumbClick);
			});
			el.parentElement.classList.remove('has-thumbs');
			dom.remove(el);
			el = null;
			pubsub.destroy();
		}

		function show(animate) {
			var parent = el.parentNode;
			if (animate) {
				dom.fadeIn(parent, 800);
			}
			else {
				dom.fadeIn(parent, 0);
			}
			self.isHidden = false;
		}

		function hide(animate) {
			var parent = el.parentNode;
			self.hiding = true;
			if (animate) {
				if (model.events.FEATURES_CHANGED === animate) {
					self.featuresChanging = true;
				}
				dom.fadeOut(parent, 600, function () {
					self.hiding = false;
					if (self.delayedRender) {
						self.delayedRender = false;
						self.render();
					}
				});
			}
			else {
				dom.fadeOut(parent, 0, function () {
					self.hiding = false;
				});
			}
			self.isHidden = true;
		}
		function getClosestThumb(index) {
			var allFrames = framesHandler.getAllFramesToBeLoaded();
			var dataIndexList = dom.querySelectorAll(el, 'li[data-index]:not([data-index*="alt-"])');
			var indexesList = [];
			var closestThumb = 0;
			var totalFrames = allFrames.THREESIXTY.LEN;
			var distance = 0;
			var i = 0;

			for (i = 0; i < dataIndexList.length; i++) {
				try {
					indexesList.push(Number(dataIndexList[i].dataset.index));
				}
				catch (ex) {
					indexesList.push(Number(dataIndexList[i].getAttribute('data-index')));
				}
			}
			try {
				indexesList = indexesList.sort(function (a, b) { return a - b; });
				closestThumb = indexesList.reduce(function (previous, current) {
					if ((index > indexesList[indexesList.length - 1])) {
						distance = (totalFrames - indexesList[indexesList.length - 1]) / 2;
						return (index > (indexesList[indexesList.length - 1]) + distance) ? indexesList[0] : indexesList[indexesList.length - 1];
					}
					else {
						return (Math.abs(current - index) < Math.abs(previous - index) ? current : previous);
					}
				});
				return closestThumb;
			}
			catch (ex) {
				logger.warning('360 thumbnails are not ready or unavailable to activate the closest angle to the frame displayed.');
			}
		}

		function setActive(index) {
			var li = el.querySelector('.active');
			var currentActive = li;
			var listIndex;
			if (wasThumbClicked) {
				try {
					listIndex = li.dataset.index;
				}
				catch (ex) {
					listIndex = li.getAttribute('data-index');
				}
				if (parseInt(index, 10) === parseInt(listIndex, 10)) {
					wasThumbClicked = false;
				}
				return;
			}
			if (isNaN(index)) {
				li = el.querySelector('li[data-index="' + index + '"]');
			}
			else {
				li = el.querySelector('li[data-index="' + getClosestThumb(index) + '"]');
			}
			if (li) {
				if (currentActive && currentActive.classList) {
					currentActive.classList.remove('active');
				}
				li.classList.add('active');
			}
		}
		function thumbsHeight() {
			var thumbsRect = self.el ? self.el.getBoundingClientRect() : { height: 0 };
			return thumbsRect.height || 128;
		}

		function changeThumbSize(li) {
			if (thumbLocation === thumbLocations.BOTTOM ||
				thumbLocation === thumbLocations.TOP) {
				li.style.width = thumbSizePercentage;
			}
			else if (thumbLocation === thumbLocations.LEFT ||
				thumbLocation === thumbLocations.RIGHT) {
				li.style.height = thumbSizePercentage;
			}
		}

		function removeDraggableBehavior(img) {
			try {
				img.draggable = false;
				img.ondragstart = function () {
					return false;
				};
			}
			catch (ex) {
			}
		}

	};

	Thumbnails.prototype.events = {
		CREATED: 'ui:thumb:created',
		DATAREADY: 'ui:thumb:dataready',
		READY: 'ui:thumb:ready',
		SCROLL: 'ui:thumb:scroll',
		SCROLL_UP: 'ui:thumb:scroll:up', 
		SCROLL_DOWN: 'ui:thumb:scroll:down', 
		CLICK: 'ui:thumb:click',
		IMG_LOADED: 'ui:thumb:loaded',
		CONTENT_TYPE_COMPLETE: 'ui:thumb:content:type:complete'
	};

	Thumbnails.counter = 0;

	var publicAPI = {
		create: function (config) {
			return new Thumbnails(config);
		}
	};

	window.cylindo.addModule('thumbnails', publicAPI);
}).call(this);
