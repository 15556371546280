(function () {
    'use strict';
    var LayerAbstract = cylindo.getModule('cylindo.classes.layer');
    function ZoomTooltip(opts) {
        if (opts && typeof opts === 'object') {
            opts.role = 'tooltip';
        }
        LayerAbstract.call(this, opts);
        this.wrapperClasses = ['cylindo-tooltip', 'cylindo-drag-tooltip'];
        this.templatePattern = [
            '<span class="left" aria-hidden="true"></span><% text %><span class="right" aria-hidden="true"></span>'
        ];
        this.setTemplate(this.templatePattern.join(''));
    }

    ZoomTooltip.prototype = Object.create(LayerAbstract.prototype);
    ZoomTooltip.prototype.constructor = ZoomTooltip;

    var publicAPI = {
        create: function (opts) {
            return new ZoomTooltip(opts);
        }
    };

    window.cylindo.addModule('tooltip.zoom', publicAPI);
}.call(this));
