(function () {
  'use strict';
  var LayerAbstract = cylindo.getModule('cylindo.classes.layer');
  function ViewerTooltip(opts) {
    if (opts && typeof opts === 'object') {
      opts.role = 'tooltip';
    }
    LayerAbstract.call(this, opts);
    this.wrapperClasses = ['cylindo-tooltip', 'cylindo-drag-tooltip', 'cylindo-drag-to-rotate-tooltip'];
    this.templatePattern = [
      '<span class="left" aria-hidden="true"></span><% text %><span class="right" aria-hidden="true"></span>'
    ];
    this.setTemplate(this.templatePattern.join(''));
  }

  ViewerTooltip.prototype = Object.create(LayerAbstract.prototype);
  ViewerTooltip.prototype.constructor = ViewerTooltip;

  var publicAPI = {
    create: function (opts) {
      return new ViewerTooltip(opts);
    }
  };

  window.cylindo.addModule('tooltip.viewer', publicAPI);
}.call(this));
