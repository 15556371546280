(function () {
    'use strict';

    var tracking = cylindo.getModule('tracking') || {};
    var CheckoutTracking = function (clientID) {
    };

    CheckoutTracking.prototype.checkout = function (items, totalPrice, currency, cb) {
        var toBeDeprecated = 'Cylindo: checkout method has been deprecated, please consult our documentation or contact the Support team to look for alternatives.';
        if (console.warn) {
            console.warn(toBeDeprecated);
        }
        else {
            console.log(toBeDeprecated);
        }    
        cb(false);
    };

    CheckoutTracking.prototype.getItem = function (sku, quantity, price, org_price, features) {
        var toBeDeprecated = 'Cylindo: getItem method has been deprecated, please consult our documentation or contact the Support team to look for alternatives.';
        if (console.warn) {
            console.warn(toBeDeprecated);
        }
        else {
            console.log(toBeDeprecated);
        }    
        return {
            sku: sku,
            quantity: quantity,
            price: price,
            org_price: org_price,
            features: features.join('*')
        };
    };

    tracking.checkout = {};
    tracking.checkout.create = function (clientID) {
        var toBeDeprecated = 'Cylindo: \'checkout\' module has been deprecated, please consult our documentation or contact the Support team to look for alternatives.';
        if (console.warn) {
            console.warn(toBeDeprecated);
        }
        else {
            console.log(toBeDeprecated);
        }    
        return new CheckoutTracking(clientID);
    };

    cylindo.addModule('tracking', tracking, true);
}).call(this);