(function () {
	'use strict';

	var FramesHandler = function () {
		var self = this;
		var model = null;
		var logger = null;
		var util = cylindo.getModule('cylindo.core.util');
		var viewerPresentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
		var videoHelper = cylindo.getModule('cylindo.helpers.video');
		var angles = cylindo.getModule('cylindo.util.angles');
		var modelValues = cylindo.getModule('cylindo.model.values');
		var modelDefaults = modelValues ? modelValues.getDefaults() : null;
		var prefixes = {
			PREALT: 'pre-alt-',
			ALT: 'alt-',
			DEG: 'deg',
		};
		var framesDefinedByUser;
		var presentation = getPresentation();
		var first360 = null;
		var last360 = null;
		var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
		var cache = null;
		self.on = pubsub.on;
		self.trigger = pubsub.trigger;
		self.off = pubsub.off;
		self.initialize = initialize;
		self.prepareToUpdateAltContent = prepareToUpdateAltContent;
		self.isValidAltObject = isValidAltObject;
		self.isAltId = isAltId;
		self.isDegreeIndex = isDegreeIndex;
		self.is360Frame = is360Frame;
		self.prefixes = prefixes;
		self.angles = angles;
		self.initialized = false;
		self.destroy = destroy;
		function initialize(_model) {
			model = _model;
			logger = model.getLogger();
			self.reordered = false;
			self.getAllFramesToBeLoaded = getAllFramesToBeLoaded;
			self.shouldDownloadAll360Frames = shouldDownloadAll360Frames;
			self.validateOrder = validateOrder;
			self.getStartFrame = getStartFrame;
			self.prepareToReorder = prepareToReorder;
			self.cleanCache = cleanCache;
			self.propsToValidateOrder = ['alternateContent', 'thumbCount', 'thumbAngles'];
			framesDefinedByUser = model.get('framesSetOnInit');
			self.initialized = true;
		}
		function validateOrder(evt, data) {
			var alternateContent = model.get('alternateContent') instanceof Array ?
				model.get('alternateContent') : [];
			var altContentLen = alternateContent.length;
			var total360Frames = model.get('currentFeatures') ? model.get('frameCount') : 0;
			var realThumbCount = 0;
			var requestedThumbs = model.get('thumbCount');
			var frames = model.get('frames');
			var index = null;
			var thumbAngles = model.get('thumbAngles');
			var i = 0;
			var frameStep;
			var startFrame = model.get('startFrame');
			var joinWithAltContent = false;
			var altContentObjWithinFrames = false;
			var altContentWithIndexes = false;
			var ssWithinFrames = false;
			var degree;
			var currentAltContentIndex;
			var currentAltContentObj;
			var altContentFixed = [];
			var loggerMsgs = {
				OBJ_WITHIN_FRAMES: 'Alternate content objects order defined within frames array.',
				ALT_CONTENT_IGNORED: 'When the alternate images/videos are declared within frames array, the alternateContent value will be ignored.',
				ALT_CONTENT_REPLACED: 'alternateContent value will be modified because the frames order does not correspond with the alternateContent array provided.',
				ALT_ID_WITHIN_FRAMES: 'Alternate content indexes order defined within frames array.',
				WRONG_ALT_INDEX: 'Some alternate indexes don\'t exist within alternateContent array. Please check that alternateContent is an array and verify if the index inside the alternateContent array is a valid object.',
				WRONG_OBJECT: 'Object removed from frames array because is not a valid Alternate Content object.',
				MIXED_CONTENT: 'Alternate content indexes defined inside frames array will be ignored when frames array also contains alternate content objects.',
				FRAMES_PRIORITY: 'When frames property is set in the configuration object it has priority over thumbCount and thumbAngles properties.',
				ALT_UNSORTED: 'Some alternate indexes where not sorted inside the frames array. These alternate images will not be displayed.',
				INVALID_ANGLE: 'The angle you provided within frames array is invalid, so that frame will not be displayed.',
				REMOVING_WRONG_INDEX: 'Wrong index removed from the frames array.',
				ALT_NEW_ORDER_IGNORED: 'The desired order for alternate content  will be ignored because wrong indexes were provided in the configuration. In case alternate content exists will be displayed at the end of the thumbnails bar',
				CANNOT_ADD_FRAME: 'This frame could not be added. If this is a single viewer and you already defined a 360 frame to be displayed all the other 360 frames will be ignored. Otherwise if this is a custom viewer all the 360 frames will be ignored.',
				FORCE_360_FRAME: 'Frames array should contain at least one 360 frame. The first frame of the requested product will be included into the frames array.',
			};
			var expectedAltFrames;
			var expectedAltIndex = 0;
			var altContentShouldBeFixed = false;
			var preventChangeEvent = true;
			var doSplice = false;
			var updateAltContent = false;
			var j;
			getPresentation();
			expectedAltFrames = getExpectedFrames(altContentLen);

			if (frames instanceof Array && frames.length > 0 && framesDefinedByUser) {
				if ((parseInt(requestedThumbs, 10) !== frames.length) ||
					(thumbAngles && thumbAngles.length)) {
					logger.log(loggerMsgs.FRAMES_PRIORITY);
				}
				for (i = 0; i < frames.length; i++) {
					index = frames[i];
					if (util.object.isPlainObject(index)) {
						if (isValidAltObject(index)) {
							if (!altContentObjWithinFrames) {
								if (altContentWithIndexes) {
									for (j = 0; j < i; j++) {
										if (isAltId(frames[j])) {
											logger.error(loggerMsgs.MIXED_CONTENT);
											logger.error(loggerMsgs.REMOVING_WRONG_INDEX, frames[j]);
											frames.splice(j, 1);
											i--;
											j--;
										}
									}
								}
								altContentFixed = [];
								logger.log(loggerMsgs.OBJ_WITHIN_FRAMES, frames);
								altContentObjWithinFrames = true;
								if (altContentLen) {
									logger.warning(loggerMsgs.ALT_CONTENT_IGNORED, alternateContent);
								}
							}
							altContentFixed.push(index);
							frames[i] = prefixes.ALT + (altContentFixed.length - 1);
							continue;
						}
						else {
							logger.error(loggerMsgs.WRONG_OBJECT, index);
							doSplice = true;
						}
					}
					else if (isDegreeIndex(index)) {
						if (!singleFrameAlreadyDefined() && !isCustomViewer()) {
							degree = angles.degreesToIndexes([index.replace(/deg/g, '')], total360Frames);
							if (degree.length === 1) {
								frames[i] = index = degree[0];
								if (first360 === null) {
									first360 = index;
								}
								last360 = index;
								realThumbCount++;
							}
							else {
								logger.log(loggerMsgs.INVALID_ANGLE, index);
								doSplice = true;
							}
						}
						else {
							logger.error(loggerMsgs.CANNOT_ADD_FRAME, index);
							doSplice = true;
						}
					}
					else if (isAltId(index)) {
						if (!altContentWithIndexes) {
							logger.log(loggerMsgs.ALT_ID_WITHIN_FRAMES, frames);
							altContentWithIndexes = true;
						}
						if (!altContentObjWithinFrames) {
							currentAltContentIndex = parseInt(index.replace(/alt-/g, ''), 10);
							currentAltContentObj = alternateContent[currentAltContentIndex];
							if (util.object.isPlainObject(currentAltContentObj) &&
								isValidAltObject(currentAltContentObj)) {
								altContentFixed.push(currentAltContentObj);
								frames[i] = index = prefixes.ALT + (altContentFixed.length - 1);
								expectedAltFrames.splice(currentAltContentIndex, 1);
							}
							else {
								doSplice = true;
								logger.error(loggerMsgs.WRONG_ALT_INDEX, index);
							}
							if (expectedAltIndex !== currentAltContentIndex) {
								altContentShouldBeFixed = true;
							}
							expectedAltIndex++;
						}
						else {
							doSplice = true;
							logger.error(loggerMsgs.MIXED_CONTENT);
						}
					}
					else if (is360Frame(index)) {
						if (!singleFrameAlreadyDefined() && !isCustomViewer()) {
							if (isString(index)) {
								frames[i] = index = parseInt(index, 10);
							}
							if (first360 === null) {
								first360 = index;
							}
							last360 = index;
							realThumbCount++;
						}
						else {
							logger.error(loggerMsgs.CANNOT_ADD_FRAME, index);
							doSplice = true;
						}
					}
					else {
						doSplice = true;
					}
					if (doSplice) {
						logger.error(loggerMsgs.REMOVING_WRONG_INDEX, index);
						frames.splice(i, 1);
						i--;
						doSplice = false;
					}
				}

				if (altContentObjWithinFrames) {
					updateAltContent = true;
				}
				else if (altContentWithIndexes) {
					if (altContentFixed.length) {
						if (expectedAltFrames.length > 0) {
							for (i = 0; i < expectedAltFrames.length; i++) {
								logger.error(loggerMsgs.ALT_UNSORTED, prefixes.ALT + expectedAltFrames[i]);
							}
							altContentShouldBeFixed = true;
						}
						if (altContentShouldBeFixed) {
							logger.warning(loggerMsgs.ALT_CONTENT_REPLACED, altContentFixed);
							updateAltContent = true;
						}
					}
					else {
						logger.error(loggerMsgs.ALT_NEW_ORDER_IGNORED, frames);
						joinWithAltContent = true;
					}
				}
				else if (altContentLen) {
					joinWithAltContent = true;
				}
			}
			else {
				frames = [];
				joinWithAltContent = true;
				if (model.get('cylindoContent') === true) {
					if (thumbAngles && thumbAngles.length) {
						frames = angles.degreesToIndexes(thumbAngles, model.get('frameCount'));
						realThumbCount = frames.length;
					}
					else if (presentation === viewerPresentations.THREESIXTY.NAME ||
						presentation === viewerPresentations.CAROUSEL.NAME) {
						realThumbCount = requestedThumbs > total360Frames ? 5 : requestedThumbs;
						frameStep = Math.floor(total360Frames / realThumbCount);
						for (i = 0; i < realThumbCount; i++) {
							frames[i] = (i * frameStep + 1);
						}
					}
					else if (presentation === viewerPresentations.SINGLE.NAME) {
						realThumbCount = 1;
						frames = [startFrame];
					}
					if (presentation === viewerPresentations.CAROUSEL.NAME ||
						presentation === viewerPresentations.STACKED.NAME) {
						first360 = 1;
						last360 = model.get('frameCount');
						frames = !frames || frames.length === 0 ? [first360] : frames;
						realThumbCount = frames.length;
					}
					else {
						first360 = startFrame;
					}
				}
			}
			if (joinWithAltContent && altContentLen) {
				for (i = 0; i < altContentLen; i++) {
					frames.push(prefixes.ALT + i);
				}
			}
			if (shouldForce360Frame()) {
				frames.unshift(1);
				first360 = 1;
				last360 = 1;
				realThumbCount = 1;
				logger.error(loggerMsgs.FORCE_360_FRAME, frames);
			}
			if (updateAltContent) {
				model.set('alternateContent', altContentFixed, preventChangeEvent);
			}
			model.set('frames', frames, preventChangeEvent);
			model.set('thumbCount', realThumbCount, preventChangeEvent);
			model.set('thumbAngles', null, preventChangeEvent);
			if (is360Frame(first360)) {
				model.set('startFrame', first360, preventChangeEvent);
			}
			if (evt && data && evt === model.events.CHANGED) {
				if (data.prop === 'alternateContent') {
					self.trigger(self.events.RELOAD_ALT_CONTENT, data);
				}
				else if (data.prop === 'thumbCount' || data.prop === 'thumbAngles') {
					self.trigger(self.events.RELOAD_THUMBNAILS, data);
				}
			}
			self.reordered = true;
			cleanCache();
			function singleFrameAlreadyDefined() {
				getPresentation();
				return presentation === viewerPresentations.SINGLE.NAME && realThumbCount > 0;
			}
			function shouldForce360Frame() {
				var result = false;
				var i;
				if (realThumbCount === 0) {
					if (presentation === viewerPresentations.THREESIXTY.NAME ||
						presentation === viewerPresentations.SINGLE.NAME) {
						result = true;
					}
					else if (presentation === viewerPresentations.STACKED.NAME ||
						presentation === viewerPresentations.CAROUSEL.NAME) {
						if (frames && frames.length > 0) {
							for (i = 0; i < frames.length; i++) {
								if (!isAltId(frames[i])) {
									result = true;
									break;
								}
							}
						}
						else {
							result = true;
						}
					}
				}
				return result;
			}
		}
		function isValidAltObject(obj) {
			var acceptedProviders = ['direct', 'youtube', 'vimeo'];
			return obj && (obj.image || (obj.provider && acceptedProviders.indexOf(obj.provider) !== -1));
		}
		function isCustomViewer() {
			getPresentation();
			return presentation === viewerPresentations.CUSTOM.NAME;
		}
		function getExpectedFrames(len) {
			var expectedFrames = [];
			var i;
			for (i = 0; i < len; i++) {
				expectedFrames.push(i);
			}
			return expectedFrames;
		}
		function getStartFrame() {
			startFrame = model && model.get('startFrame') ? model.get('startFrame') : 1;
		}

		function isAltId(index) {
			return isString(index) && index.indexOf(prefixes.ALT) !== -1;
		}

		function isDegreeIndex(index) {
			return isString(index) && index.indexOf(prefixes.DEG) !== -1;
		}

		function isString(index) {
			return typeof index === 'string';
		}

		function isNumber(index) {
			return typeof index === 'number' && !isNaN(index);
		}

		function is360Frame(index) {
			var defaultFrameCount = modelDefaults ? modelDefaults.frameCount : 0;
			var total360Frames = model ?
				model.get('currentFeatures') ? model.get('frameCount') : 0 :
				defaultFrameCount;
			if (isString(index)) {
				index = parseInt(index, 10);
			}
			return isNumber(index) && index > 0 && index <= total360Frames;
		}
		function getAllFramesToBeLoaded() {
			var thumbs, cylindoContent, altContent, altContentLen, total360Frames, frames, framesLen, toBeLoadedLen, thumbsList, thumbsLen, frames360, thumbs360Len, i;
			if (!cache) {
				thumbs = model.get('thumbs') === true;
				cylindoContent = model.get('cylindoContent');
				altContent = model.get('alternateContent') instanceof Array ?
					model.get('alternateContent') : [];
				altContentLen = altContent.length;
				total360Frames = cylindoContent && model.get('currentFeatures') ? model.get('frameCount') : 0;
				frames = model.get('frames') instanceof Array ? model.get('frames') : [];
				framesLen = frames.length;
				toBeLoadedLen = cylindoContent ? total360Frames : 0;
				thumbsList = thumbs ? frames : [];
				thumbsLen = thumbsList.length;
				frames360 = model.get('thumbCount');
				thumbs360Len = thumbs ? frames360 : 0;
				altContent = videoHelper.getVideoId(altContent);

				if (cylindoContent && !shouldDownloadAll360Frames()) {
					toBeLoadedLen = frames360;
				}
				cache = {
					ALTERNATE: {
						LIST: altContent,
						LEN: altContentLen,
					},
					THREESIXTY: {
						TO_BE_LOADED: toBeLoadedLen,
						LEN: total360Frames,
						FRAMES: frames,
						FIRST: first360,
						LAST: last360,
					},
					THUMB: {
						ENABLED: thumbs,
						LIST: thumbsList,
						LEN: thumbsLen,
						THREESIXTY_THUMB_LEN: thumbs360Len,
					},
					VIEWER_ORDER: {
						LIST: frames,
						LEN: framesLen,
					},
					ALL: {
						LEN: toBeLoadedLen + altContentLen + thumbsLen,
					}
				};
			}
			return cache;
		}
		function shouldDownloadAll360Frames() {
			var framesSetOnInit = model.get('framesSetOnInit');
			getPresentation();
			return (presentation === viewerPresentations.THREESIXTY.NAME) ||
				((presentation === viewerPresentations.CAROUSEL.NAME ||
					presentation === viewerPresentations.STACKED.NAME) && !framesSetOnInit);
		}
		function getPresentation() {
			presentation = model ? model.get('presentation') : null;
		}
		function prepareToReorder() {
			self.reordered = false;
		}
		function prepareToUpdateAltContent(altOrFramesArr) {
			var allFrames = getAllFramesToBeLoaded();
			var frameCount = model.get('frameCount');
			var altMixed = false;
			var currentFrames = allFrames.VIEWER_ORDER.LIST;
			var currentFramesLen = allFrames.VIEWER_ORDER.LEN;
			var currentAltContent = allFrames.ALTERNATE.LIST;
			var currentAltContentLen = allFrames.ALTERNATE.LEN;
			var i, j, index, degree;
			var newAltContLen = 0;
			var useObjects = false;
			var useIndexes = false;
			var hasCylindoContent = false;
			var canUpdate = false;
			var frames = [];
			var alternateContent = [];
			var expectedCylindoContent = [];
			var useFramesToReorder = false;
			var cylindoContentLen;
			if ((altOrFramesArr instanceof Array && altOrFramesArr.length === 0) ||
				altOrFramesArr === null) {
				removeAltContent();
			}
			else if (altOrFramesArr instanceof Array && altOrFramesArr.length > 0) {
				for (i = 0; i < currentFramesLen; i++) {
					if (is360Frame(currentFrames[i])) {
						expectedCylindoContent.push(currentFrames[i]);
					}
				}
				for (i = 0; i < altOrFramesArr.length; i++) {
					index = altOrFramesArr[i];
					if (isDegreeIndex(index)) {
						degree = angles.degreesToIndexes([index.replace(/deg/g, '')], frameCount);
						index = degree.length === 1 ? degree[0] : null;
					}
					if (is360Frame(index)) {
						if (expectedCylindoContent[0] !== index) {
							logger.error('The supplied frames array should have the same 360 frames that were declared on the initialization, only alt content order or elements can be modified with this function.', index, currentFrames);
							return false;
						}
						expectedCylindoContent.splice(expectedCylindoContent.indexOf(index), 1);
						hasCylindoContent = true;
						cylindoContentLen++;
					}
					else if (isAltId(index)) {
						if (currentFrames.indexOf(index) === -1) {
							logger.error('The requested index to be reordered does not exist in the current alternate content array.', index);
							return false;
						}
						useIndexes = true;
						newAltContLen++;
					}
					else if (isValidAltObject(index)) {
						useObjects = true;
						newAltContLen++;
					}
					else {
						logger.error('Wrong value provided into the alt content arr please provide correct values to update the alternate content.', index);
						return false;
					}
				}
				if (useIndexes && useObjects) {
					logger.error('Alternate objects are used to replace the current content while indexes are used to reorder the content, so they should not be mixed.');
				}
				else if (hasCylindoContent) {
					if (expectedCylindoContent.length > 0) {
						logger.error('The supplied frames array should have the same 360 frames that were declared on the initialization, only alt content order or elements can be modified with this function.');
						return false;
					}
					else {
						if (newAltContLen === 0) {
							removeAltContent();
						}
						else {
							if (useObjects) {
								framesDefinedByUser = true;
								canUpdate = true;
								frames = altOrFramesArr;
								alternateContent = [];
							}
							else if (useIndexes) {
								framesDefinedByUser = true;
								canUpdate = true;
								frames = altOrFramesArr;
								alternateContent = currentAltContent;
							}
						}
					}
				}
				else if (useObjects || useIndexes) {
					if (useObjects && altOrFramesArr.length === currentAltContentLen) {
						canUpdate = true;
						frames = currentFrames;
						alternateContent = altOrFramesArr;
					}
					else if (useIndexes && altOrFramesArr.length === currentAltContentLen) {
						j = 0;
						for (i = 0; i < currentFramesLen; i++) {
							if (isAltId(currentFrames[i])) {
								frames[i] = altOrFramesArr[j];
								j++;
							}
							else {
								frames[i] = currentFrames[i];
							}
						}
						canUpdate = true;
						alternateContent = currentAltContent;
					}
					else {
						for (i = 0; i < currentFramesLen - 1; i++) {
							if (isAltId(currentFrames[i]) && !isAltId(currentFrames[i + 1])) {
								logger.warning('Current alternative content was reordered when viewer was initialized. Alternative content will be placed at the end of the viewer because new content length is different than the earlier.');	
								break;
							}
						}
						removeAltContent();
						canUpdate = true;
						frames = frames.concat(altOrFramesArr);
						alternateContent = useObjects ? altOrFramesArr : currentAltContent;
					}
				}
				else {
					logger.error('Something went wrong while trying to update the alt content.');
					return false;
				}
			}
			else {
				logger.error('Cannot update the alternate content with the parameter sent.');
				return false;
			}

			for (i = 0; i < frames.length - 1; i++) {
				if ((isAltId(frames[i]) || isValidAltObject(frames[i])) && 
					(!isAltId(frames[i + 1]) && !isValidAltObject(frames[i + 1]))) {
					useFramesToReorder = true;
				}
			}

			return {
				canUpdate: canUpdate,
				frames: frames,
				alternateContent: alternateContent,
				useFramesToReorder: useFramesToReorder,
			};

			function removeAltContent() {
				canUpdate = true;
				alternateContent = null;
				for (i = 0; i < currentFramesLen; i++) {
					if (!isAltId(currentFrames[i])) {
						frames.push(currentFrames[i]);
					}
				}
			}
		}
		function cleanCache() {
			cache = null;
		}
		function destroy() {
			pubsub.destroy();
		}
	};

	FramesHandler.prototype.events = {
		RELOAD_ALT_CONTENT: 'frames:handler:reload:altcontent',
	};

	var publicAPI = {
		create: function () {
			return new FramesHandler();
		}
	};

	window.cylindo.addModule('cylindo.classes.frames.handler', publicAPI);
}.call(this));
