(function () {
    'use strict';
    var ViewAbstract = cylindo.getModule('cylindo.classes.abstract.view');
    var CustomViewer = function (options) {
        var self = this;
        var scrollHelper = cylindo.getModule('cylindo.helpers.scroll');
        var util = cylindo.getModule('cylindo.core.util');
        var dom = options.dom;
        var isTouchEvent = false;

        ViewAbstract.call(this, options);

        this.dom = dom;
        this.alternateContent = options.alternateContent;
        this.listClassName = 'cylindo-custom-list';
        this.viewerPresentation = 'custom';
        this.firstFrameComplete = false;
        this.defaultTooltip = 'tooltipAltImgText';

        this.render();

        this.addMouseCallbacks = function () {
            this.wrapper.addEventListener('mouseup', triggerClick);
            this.wrapper.addEventListener('touchstart', touchStart, false);
            this.wrapper.addEventListener('touchmove', touchMove, false);
            this.wrapper.addEventListener('touchend', touchEnd, false);
        };

        this.removeMouseCallbacks = function () {
            this.wrapper.removeEventListener('mouseup', triggerClick);
            this.wrapper.removeEventListener('touchstart', touchStart, false);
            this.wrapper.removeEventListener('touchmove', touchMove, false);
            this.wrapper.removeEventListener('touchend', touchEnd, false);
        };

        function touchStart(evt) {
            isTouchEvent = true;
            scrollHelper.preventScrollX(self.wrapper.parentNode);
            self.setMobileCoords(evt);
        }

        function touchEnd(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            self.setMobileCoords(evt);
            scrollHelper.allowScrollX(self.wrapper.parentNode);
            isTouchEvent = false;

                    }

        function touchMove(evt) {
            self.setMobileCoords(evt);
        }

        function triggerClick(evt) {
            var pos = {};
            pos.currentX = pos.startX = evt.clientX || evt.changedTouches[0].clientX;
            pos.currentY = pos.startY = evt.clientY || evt.changedTouches[0].clientY;
            pos.startTime = evt.timeStamp;

            self.trigger(self.events.CLICKED, pos);
        }


    };

    CustomViewer.prototype = Object.create(ViewAbstract.prototype);
    CustomViewer.prototype.constructor = CustomViewer;

    CustomViewer.prototype.events = ViewAbstract.extendEvents({
        READY: 'custom:ready',
        DOWNLOAD_COMPLETE: 'custom:download:complete',
        CURRENT: 'custom:current',
        CLICKED: 'custom:clicked',
        IMG_LOADED: 'custom:img:loaded',
        FIRST_FRAME_COMPLETE: 'custom:firstframe:complete',
        FRAME_ACTIVATED: 'custom:frame:activated',
        ALT_CONTENT_REMOVED: 'custom:alt:content:removed',
        ALT_CONTENT_LOADED: 'custom:alt:content:loaded',
        CONTENT_TYPE_COMPLETE: 'custom:content:type:complete'
    });

    CustomViewer.prototype.render = function () {
        ViewAbstract.prototype.tryToCreateTooltip.call(this);
        ViewAbstract.prototype.render.call(this);
    };


    CustomViewer.prototype.prepare = function (index) {
        var deferred = this.util.promise.create();
        var integerIndex;

        if (this.framesHandler.isAltId(index)) {
            integerIndex = parseInt(index.replace(this.framesHandler.prefixes.ALT, ''), 10);
            deferred = this.prepareAltContent(integerIndex);
            if (parseInt(integerIndex, 10) === 0) {
                deferred.then(function() {
                    this.trigger(this.events.FIRST_FRAME_COMPLETE, { index: 1, frameCount: 0 });
                }.bind(this));
            }
        }

        return deferred;
    };
    CustomViewer.prototype.reloadAltContent = function (evt, data) {
        ViewAbstract.prototype.reloadAltContent.call(this, evt, data);
        this.firstFrameComplete = false;
        this.isComplete = false;
        this.downloadContent();
    };
    CustomViewer.prototype.onImageLoad = function () {
        throw new Error('Method Prepare must be implemented.');
    };

    CustomViewer.prototype.onAltContentLoad = function () {
        var self = this.viewer;
        var width = self.naturalWidth;
        var hMargin = (self.wrapper.clientWidth - width) / 2;
        var visibleElements;
        var visibleElementsLen;

        if (self.ul360 && !self.ul360.style.width) {
            self.ul360.style.width = width + 'px';
            self.ul360.style.marginLeft = hMargin + 'px';
            self.ul360.style.marginRight = hMargin + 'px';
        }

        this.li.classList.remove('hidden');
        this.li.classList.remove('is-rendering');
        self.trigger(self.events.ALT_CONTENT_LOADED, { sequence: self.framesHandler.prefixes.ALT + this.index, viewerId: self.id });
        if (!self.firstFrameComplete) {
            self.firstImageLoadedTime = Date.now();
            self.handleActiveFrame(this.li, true);

            if (self.tooltip && !this.li.classList.contains('cylindo-video') && !this.li.classList.contains('cylindo-img-not-found')) {
                self.showTooltip(self.model.get('tooltipAltImgText'));
            }
            self.firstFrameComplete = true;
            self.sendFirstFrameCompleteEvt();
        }
        if (!self.isReady) {
            self.onReady();
        }
        if (!self.isVisible) {
            self.displayViewer();
        }
        visibleElements = self.dom.querySelectorAll(self.ul360, 'li:not(.hidden)');
        visibleElementsLen = visibleElements ? visibleElements.length : 0;
        if (!self.isComplete && visibleElementsLen === self.alternateContent.length) {
            self.onDownloadComplete();
            self.trigger(self.events.CONTENT_TYPE_COMPLETE, {
                contentType: self.contentTypes.ALTERNATE
            });
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new CustomViewer(opts);
        }
    };

    window.cylindo.addModule('customViewer', publicAPI);
}).call(this);
