(function () {
    'use strict';

    var FullScreenMap = function () {
        var self = this;
        var fullscreenMaps = {
            request: [
                'requestFullscreen',
                'webkitRequestFullscreen',
                'mozRequestFullScreen',
                'msRequestFullscreen'
            ],
            exit: [
                'exitFullscreen',
                'webkitExitFullscreen',
                'mozCancelFullScreen',
                'msExitFullscreen'
            ],
            change: [
                'onfullscreenchange',
                'onwebkitfullscreenchange',
                'onmozfullscreenchange',
                'onmsfullscreenchange',
                'MSFullscreenChange'
            ],
            element: [
                'fullscreenElement',
                'webkitFullscreenElement',
                'mozFullScreenElement',
                'msFullscreenElement'
            ]
        };
        function getFullScreenFn(names) {
            var fn = null;
            names.every(function (f) {
                if (typeof document[f] !== 'undefined' || typeof document.body[f] !== 'undefined') {
                    if (f === 'onmsfullscreenchange' &&
                        util.browser.browser.name === 'Internet Explorer' &&
                        util.browser.browser.version === '11.0') {
                        f = 'MSFullscreenChange';
                    }
                    fn = f;
                    return false;
                }
                return true;
            });
            return fn;
        }
        function init() {
            self.request = getFullScreenFn(fullscreenMaps.request);
            self.exit = getFullScreenFn(fullscreenMaps.exit);
            self.change = getFullScreenFn(fullscreenMaps.change);
            self.element = getFullScreenFn(fullscreenMaps.element);
        }

        init();

    };
    var publicAPI = {
        create: function () {
            return new FullScreenMap();
        }
    };
    window.cylindo.addModule('cylindo.helpers.fullscreen.map', publicAPI);
}).call(this);
