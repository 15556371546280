(function () {
    'use strict';

    var MessageQueue = function (options) {
        this.id = ++MessageQueue.counter;
        this.loggerLimit = options.loggerLimit;
        this.messages = [];
    };

    MessageQueue.prototype.add = function(message) {
        this.checkLoggerLimit();
        this.messages.push(message);
    };

    MessageQueue.prototype.checkLoggerLimit = function () {
        if (this.messages.length === this.loggerLimit) {
            this.messages.shift();
        }
    };

    MessageQueue.prototype.getMessagesByImportance = function (search) {
        return this.messages.filter(function(message, i) { 
            return message.bin & search;
        });
    };

    MessageQueue.prototype.getAllMessages = function () {
        return this.messages;
    };

    MessageQueue.counter = 0;

    var publicAPI = {
        create: function (opts) {
            return new MessageQueue(opts);
        }
    };

    window.cylindo.addModule('cylindo.message.queue', publicAPI);
}).call(this);