
(function () {
    'use strict';
    var Bar = function (opts) {
        var self = this;
        var dom = opts.dom;

        var container = opts.container;
        var bar = document.createElement('div');
        var el = container.querySelector('.cylindo-loading-bar');
        var model = opts.model;
        var totalImages = 0;
        var loadedImages = 0;
        var thumbLocations = model ? model.getThumbLocations() : {};
        var shouldResetPosition = false;
        var framesHandler = opts.framesHandler;

        this.show = show;
        this.hide = hide;
        this.updateBar = updateBar;
        this.startLoading = startLoading;

        framesHandler.on(framesHandler.events.RELOAD_ALT_CONTENT, startLoading);
        model.on(model.events.FEATURES_CHANGED, startLoading);
        model.on(model.events.AR_CONFIG_CHANGED, startLoading);
        model.on(model.events.FEATURES_ERROR, resetLoading);

        bar.style.height = '100%';
        bar.style.width = '0%';

        while (el.hasChildNodes()) {
            el.removeChild(el.firstChild);
        }

        el.appendChild(bar);

        function show(shouldShowAnimation) {
            shouldShowAnimation = typeof shouldShowAnimation === 'boolean' ? shouldShowAnimation : true;
            if (model.get('thumbLocation') === thumbLocations.TOP &&
                el &&
                el.parentElement &&
                el.parentElement.classList &&
                !el.parentElement.classList.contains('has-height')) {
                el.style.position = 'relative';
                el.style.top = '-5px';
                shouldResetPosition = true;
            }
            if (shouldShowAnimation) {
                dom.fadeIn(el);
            }
            else {
                dom.fadeIn(el, 0);
            }
        }
        function hide(shouldShowAnimation) {
            shouldShowAnimation = typeof shouldShowAnimation === 'boolean' ? shouldShowAnimation : true;
            if (shouldShowAnimation) {
                dom.fadeOut(el, 600, resetPosition);
            }
            else {
                dom.fadeOut(el, 0, resetPosition);
            }
            function resetPosition() {
                if (shouldResetPosition) {
                    shouldResetPosition = false;
                    el.style.position = '';
                    el.style.top = '';
                }
            }
        }
        function destroy() {
            dom.remove(bar);
        }


        function startLoading(evt, data) {
            var allFrames = framesHandler.getAllFramesToBeLoaded();
            var showAnimation = false;

            totalImages = allFrames.ALL.LEN;
            if (evt === framesHandler.events.RELOAD_ALT_CONTENT) {
                totalImages = allFrames.ALTERNATE.LEN + allFrames.THUMB.LEN;
            }
            else if (evt === model.events.FEATURES_CHANGED) {
                totalImages = allFrames.THREESIXTY.TO_BE_LOADED + allFrames.THUMB.LEN;
            }
            if (isNaN(totalImages)) {
                return false;
            }
            loadedImages = 0;
            bar.classList.add('no-animation');
            bar.style.width = '0';
            show(showAnimation);
        }
        function resetLoading() {
            var showAnimation = true;
            if (loadedImages < totalImages) {
                hide(showAnimation);
            }
        }
        function updateBar() {
            var percent = 0;
            ++loadedImages;
            if (totalImages === 0) {
                return false;
            }

            percent = Math.min(((loadedImages / totalImages) * 100), 100);

            bar.style.width = percent + '%';

            if (loadedImages === 1) {
                bar.classList.remove('no-animation');
            }

            if (percent === 100) {
                hide();
            }
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new Bar(opts);
        }
    };

    window.cylindo.addModule('loadingBar', publicAPI);
}).call(this);
