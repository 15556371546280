
if (typeof window.cylindo !== 'undefined') {
    try {
        if (console.warn) {
            console.warn('Cylindo.Logger/warning : The variable \'window.cylindo\' is already defined in this scope, please do not use that variable name.');
        }
        else {
            console.log('Cylindo.Logger/log: The variable \'window.cylindo\' is already defined in this scope, please do not use that variable name.');
        }
    } catch (ex) {
    }
}

window.cylindo = (function () {
    var that = this;
    var modules = {};
    var readyCallbacks = [];
    var isReady = false;

    var Cylindo = function () { };
    Cylindo.prototype.addModule = function (name, module, isPublic) {
        modules[name] = module;
        if (isPublic) {
            Cylindo.prototype[name] = module;
        }
    };
    Cylindo.prototype.getModule = function (name) {
        return modules[name] || null;
    };
    Cylindo.prototype.updateModule = function (name, module, isPublic) {
        this.addModule.apply(this, arguments);
    };
    Cylindo.prototype.on = function (eventName, callback) {
        if (isReady) {
            callback.apply();
            return;
        }
        if (eventName === 'ready') {
            readyCallbacks.push(callback);
        }
    };
    Cylindo.prototype.trigger = function (eventName) {
        if (eventName === 'ready') {
            for (var i = 0; i < readyCallbacks.length; i += 1) {
                if (readyCallbacks[i]) {
                    readyCallbacks[i].apply();
                    readyCallbacks[i] = null;
                }
            }
            isReady = true;
        }
    };

    return new Cylindo();
}).call(this);

initAll();
cylindo.trigger('ready');
