(function () {
    'use strict';

    var Video = function (options) {

        var self = this;
        var util = cylindo.getModule('cylindo.core.util');

        this.deferred = options.deferred;
        this.id = options.id;
        this.videoId = options.videoId ? options.videoId : null;
        this.provider = options.provider;
        this.li = options.li;
        this.player = {};
        this.autoplay = options.autoplay ? options.autoplay : false;
        this.viewer = options.viewer;
        this.logger = this.viewer && this.viewer.model ?
            this.viewer.model.getLogger() : null;
        this.viewerId = options.viewer.id;
        this.video = options.video;
        this.youtube = cylindo.getModule('cylindo.video.youtube');
        this.vimeo = cylindo.getModule('cylindo.video.vimeo');
        this.direct = cylindo.getModule('cylindo.video.direct');

        bindEvents();
        function bindEvents() {
            var opts = {};
            if (self.provider === 'direct') {
                opts = {
                    id: self.id,
                    video: self.video
                };
                self.player = self.direct.create(opts);
            }
            else if (self.provider === 'youtube') {
                opts = {
                    id: self.id,
                    videoId: self.videoId
                };
                self.player = self.youtube.create(opts);
            }
            else if (self.provider === 'vimeo') {
                opts = {
                    id: self.id,
                    videoId: self.videoId
                };
                self.player = self.vimeo.create(opts);
            }
            Promise.all([self.player.deferred]).then(function () {
                self.viewer.on(self.viewer.events.ALT_CONTENT_LOADED, changeVideoStatus);
                self.viewer.on(self.viewer.events.FRAME_ACTIVATED, changeVideoStatus);
                self.viewer.on(self.viewer.events.ALT_CONTENT_REMOVED, destroy);
                self.deferred.resolve();
                if (self.li.classList.contains('active') && self.autoplay && !util.browser.isMobile()) {
                    play();
                }
            });

        }
        function changeVideoStatus() {
            if (self.li !== null) {
                if (self.li.classList.contains('active') &&
                    self.autoplay &&
                    !util.browser.isMobile()) {
                    play();
                }
                else {
                    pause();
                }
            }
        }
        function destroy() {
            self.viewer.off(self.viewer.events.FRAME_ACTIVATED, changeVideoStatus);
            self.viewer.off(self.viewer.events.ALT_CONTENT_REMOVED, destroy);
            self.player.destroy();
            self.provider = null;
            self.id = null;
            self.videoId = null;
            self.provider = null;
            self.li = null;
        }
        function play() {
            try {
                self.player.play();
            }
            catch (ex) {
                if (self.logger) {
                    self.logger.error('Cannot not play the video', ex);
                }
            }
        }
        function pause() {
            try {
                self.player.pause();
            }
            catch (ex) {
                if (self.logger) {
                    self.logger.error('Cannot not pause the video', ex);
                }
            }

        }
        function stop() {
            try {
                self.player.stop();
            }
            catch (ex) {
                if (self.logger) {
                    self.logger.error('Cannot not stop the video', ex);
                }
            }
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new Video(opts);
        }
    };

    window.cylindo.addModule('cylindo.video', publicAPI);
}).call(this);
