(function () {
    'use strict';
    var validProperties = [
        'accountID',
        'alternateContent',
        'alternateContentZoom',
        'ARDesktop',
        'ARBanner',
        'ARBannerConfig',
        '_ARBannerConfig',
        'ARQuickLook',
        'backgroundColor',
        'buildNumber',
        'carouselSlideSpeed',
        'containerID',
        'country',
        'customZoomContainer',
        'cylindoContent',
        'debug',
        'environmentZoom',
        'fallbackImage',
        'fallbackVideoImage',
        'features',
        'fileName',
        'focusOnFeatureChange',
        'format',
        'fullscreen',
        'frames',
        'gaEvents',
        'height',
        'imageResolution',
        'imageTimeout',
        'loggerLimit',
        'maxZoom',
        'missingCombinationErrorText',
        'mobileZoomDelay',
        'outlineOnFocus',
        'overrideTracking',
        'presentation',
        'productCode',
        'progressBar',
        'proxy',
        'rotationSpeed',
        'removeEnvironmentShadow',
        'speedMultiplier',
        'size',
        'SKU',
        'skuVersion',
        'startFrame',
        'thumbAngles',
        'thumbCount',
        'thumbLocation',
        'thumbPageSize',
        'thumbs',
        'title',
        'tooltipAltImgText',
        'tooltipCarouselText',
        'tooltipDragText',
        'tooltipStackedPosition',
        'tooltipStackedText',
        'tooltipZoomText',
        'version',
        'viewerType',
        'width',
        'zoom',
        'zoomBackgroundColor',
        'zoomButton',
        'zoomMode',
        'zoomOverlapsThumbs'
    ];
    var getUnknownProperties = function (properties) {
        var unknownProperties = [];
        var removedPropsFound = [];
        var removedProps = [
            'threeSixty',
            'alternateImages',
        ];
        var toBeDeprecatedPropsFound = [];
        var toBeDeprecatedProps = [
            'country',
            'SKU',
            'skuVersion',
            'buildNumber',
            'environmentZoom',
            'viewerType',
            'imageResolution',
        ];
        if (Object && (typeof Object.keys === 'function')) {
            var keys = Object.keys(properties);
            var i;
            if (keys && keys.length) {
                for (i = 0; i < keys.length; i++) {
                    if (toBeDeprecatedProps.includes(keys[i])) {
                        toBeDeprecatedPropsFound.push(keys[i]);
                    }
                    if (removedProps.includes(keys[i])) {
                        removedPropsFound.push(keys[i]);
                    }
                    else if (!validProperties.includes(keys[i])) {
                        unknownProperties.push(keys[i]);
                    }
                }
            }
        }
        return {
            unknownProperties: unknownProperties,
            removedProps: removedPropsFound,
            toBeDeprecatedProperties: toBeDeprecatedPropsFound
        };
    };
    var reportUnknownProperties = function (properties) {
        if (properties && properties.debug) {
            var wrongProperties = getUnknownProperties(properties);
            if (wrongProperties.unknownProperties.length > 0) {
                console.warn('WARNING: Unknown properties supplied to the 360 HD Viewer:',
                    wrongProperties.unknownProperties,
                    '\nPlease check spelling and camel case as properties are case sensitive');
            }
            if (wrongProperties.removedProps.length > 0) {
                console.warn('WARNING: Obsolete properties supplied to the 360 HD Viewer:',
                    wrongProperties.removedProps,
                    '\nPlease check our documentation for this viewer version.');
            }
            if (wrongProperties.toBeDeprecatedProperties.length > 0) {
                console.warn('WARNING: Some of the properties supplied to the 360 HD Viewer has been deprecated:',
                    wrongProperties.toBeDeprecatedProperties,
                    '\nPlease check our documentation for this viewer version to update the configuration object.');
            }
        }
    };
    var areRequiredPropertiesPresent = function (properties) {
        if (properties) {
            return (typeof properties.accountID !== 'undefined' && (properties.productCode || properties.SKU) && properties.features !== 'undefined' && properties.containerID);
        }
        return false;
    };
    var publicServices = {
        getUnknownProperties: getUnknownProperties,
        reportUnknownProperties: reportUnknownProperties,
        areRequiredPropertiesPresent: areRequiredPropertiesPresent,
    };
    window.cylindo.addModule('cylindo.viewer.properties', publicServices);
}).call(this);