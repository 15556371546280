(function () {
    'use strict';

    var ViewAbstract = function (options) {
        var self = this;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();

        this.id = ++ViewAbstract.counter;
        this.enabled = true;
        this.promises = [];
        this.promisesCompleted = 0;
        this.ul360 = null;
        this.currentIndex = 1;
        this.initialized = true;
        this.wrapper = options.el;
        this.model = options.model;
        this.logger = options.logger;
        this.listClassName = '';
        this.currentFeatures = this.model.get('currentFeatures');
        this.contentKeys = null;
        this.url = this.currentFeatures ? this.currentFeatures.paths : null;
        this.firstImageLoadedTime = 0;
        this.viewerUpdatedStartTime = Date.now();
        this.customImageOn = false;
        this.isReady = false;
        this.firstFrameIsReady = false;
        this.isVisible = false;
        this.isComplete = false;
        this.util = cylindo.getModule('cylindo.core.util');
        this.dom = options.dom;
        this.dpiDensity = this.util.browser.getDPI();
        this.mouseClickPos = {
            startX: 0,
            startY: 0,
            currentX: 0,
            currentY: 0
        };
        this.startFrame = 1;

        this.requestID = 0;
        this.altRequestID = 0;
        this.renderer = cylindo.getModule('cylindo.render.strategy');
        this.network = cylindo.getModule('cylindo.core.network');
        this.contentHelper = cylindo.getModule('cylindo.helpers.content.to.load');
        this.contentTypes = this.contentHelper.getContentTypes();
        this.framesHandler = options.framesHandler;
        this.tags = options.tags;
        this.presentation = options.presentation;
        this.alternateContentPromises = [];
        this.ariaLabel = options.ariaLabel;
        this.initializedWithError = options.initializedWithError || false;

        this.framesHandler.on(this.framesHandler.events.RELOAD_ALT_CONTENT, this.processChange.bind(this));
        this.model.on(this.model.events.CHANGED, this.processChange.bind(this));
        this.pubsub = pubsub;
        this.on = pubsub.on;
        this.once = pubsub.once;
        this.trigger = pubsub.trigger;
        this.off = pubsub.off;
    };

    ViewAbstract.extendEvents = function (events) {
        var util = cylindo.getModule('cylindo.core.util');
        if (!events || !util.object.isPlainObject(events)) {
            events = {};
        }
        return util.object.extend({}, ViewAbstract.prototype.events, events);
    };

    ViewAbstract.prototype.events = {
        CUSTOM_IMG_CLICKED: 'view:custom:img:clicked',
        READY_TO_BE_SHOWN: 'viewer:ready:to:be:shown',
    };

    ViewAbstract.prototype.setMobileCoords = function (evt) {
        evt.clientX = evt.touches.length ? evt.touches[0].clientX : evt.changedTouches[0].clientX;
        evt.clientY = evt.touches.length ? evt.touches[0].clientY : evt.changedTouches[0].clientY;
    };
    ViewAbstract.prototype.isSignificantMove = function (evt) {
        var moved = true;
        var threshold = 2 / this.dpiDensity;
        var diffX = Math.abs(evt.clientX - this.mouseClickPos.startX);
        var diffY = Math.abs(evt.clientY - this.mouseClickPos.startY);

        if (diffX <= threshold && diffY <= threshold) {
            moved = false;
        }
        return moved;
    };

    ViewAbstract.prototype.getCurrentThreesixtyIndex = function () {
        return this.currentIndex;
    };

    ViewAbstract.prototype.processChange = function (evt, data) {
        if (evt === this.framesHandler.events.RELOAD_ALT_CONTENT) {
            this.reloadAltContent(evt, data);
        }
        else if (data) {
            if(data.prop === 'backgroundColor' ||
                data.prop === 'removeEnvironmentShadow' ||
                data.prop === 'buildNumber') {
                try {
                    this.refresh.call(this);
                }
                catch (ex) {
                }
            } else if (data.prop === 'ARBannerConfig') {
                try {
                    this.model.updateRequestID();
                    this.refresh.call(this, 'ar:banner:config:changed', {
                        refRequestID: this.model.getRequestID(),
                    });
                }
                catch(ex) {
                }
            }
        }
    };

    ViewAbstract.prototype.onReady = function () {
        this.isReady = true;
        this.addMouseCallbacks();
        this.trigger(this.events.READY);
    };

    ViewAbstract.prototype.displayViewer = function () {
        this.isVisible = true;
        this.ul360.style.transition = 'opacity 0.2s linear';
        this.trigger(this.events.READY_TO_BE_SHOWN, null);
    };

    ViewAbstract.prototype.onDownloadComplete = function () {
        this.isComplete = true;
        this.promises = [];
        this.promisesCompleted = 0;
        this.trigger(this.events.DOWNLOAD_COMPLETE);
        this.trigger(this.events.CONTENT_TYPE_COMPLETE, {
            contentType: this.contentTypes.FRAME
        });
    };

    ViewAbstract.prototype.render = function () {
        var refRequestID;
        this.viewerUpdatedStartTime = Date.now();

        this.requestID = this.model.getRequestID();
        refRequestID = this.requestID;

        if (this.ul360) {
            this.removeMouseCallbacks();
            this.dom.remove(this.ul360);
        }
        this.createViewerList();
        this.downloadContent(refRequestID);
    };

    ViewAbstract.prototype.downloadContent = function (requestID) {
        var self = this;
        var startFrame = self.model.get('startFrame');
        var allFrames = self.framesHandler.getAllFramesToBeLoaded();
        var viewerOrder = allFrames.VIEWER_ORDER.LIST;
        var viewerOrderLen = allFrames.VIEWER_ORDER.LEN;
        var altPromises = 0;
        var ssPromises = 0;
        var oPreviousDeferred = null;
        var i, j, index;
        var frameCount = allFrames.THREESIXTY.TO_BE_LOADED;
        var downloadAll360Frames = self.framesHandler.shouldDownloadAll360Frames();
        var isFirst360Frame = true;
        this.isCustomViewer = frameCount === 0;
        this.frameCount = frameCount;
        if (viewerOrderLen > 0) {
            this.currentIndex = viewerOrder[0];
            this.prevIndex = startFrame;
            this.startFrame = startFrame;
            for (i = 0; i < viewerOrderLen; i++) {
                index = viewerOrder[i];
                if (self.framesHandler.is360Frame(index)) {
                    if (downloadAll360Frames) {
                        if (isFirst360Frame) {
                            if (index !== startFrame) {
                                this.currentIndex = this.currentIndex === index ?
                                    startFrame : this.currentIndex;
                                index = startFrame;
                            }
                            tryToPrepare(index, requestID, i);
                            isFirst360Frame = false;
                            for (j = 1; j <= frameCount; j++) {
                                if (j !== index) {
                                    tryToPrepare(j, requestID, i);
                                }
                            }
                        }
                    }
                    else {
                        tryToPrepare(index, requestID, i);
                    }
                }
                else {
                    if (self.framesHandler.isAltId(index)) {
                        altPromises++;
                    }
                    tryToPrepare(index, requestID, i);
                }
            }
        }
        else {
        }
        function tryToPrepare(index, requestID, arrayPos) {
            if (oPreviousDeferred === null) {
                oPreviousDeferred = self.prepare(index, requestID).then(function (result) {
                    tryToExecuteFrameActions(index, arrayPos);
                });
            }
            else {
                oPreviousDeferred = oPreviousDeferred.then(function () {
                    var deferred = self.prepare(index, requestID);
                    deferred.then(function (result) {
                        tryToExecuteFrameActions(index, arrayPos);
                    });
                    return deferred;
                });
            }
        }
        function tryToExecuteFrameActions(index, arrayPos) {
            if (self.framesHandler.isAltId(index)) {
                altPromises--;
                if (altPromises === 0) {
                    self.trigger(self.events.CONTENT_TYPE_COMPLETE, {
                        contentType: self.contentTypes.ALTERNATE
                    });
                }
            }
        }
    };

    ViewAbstract.prototype.downloadRest = function () {
        throw new Error('Method MUST be implemented.');
    };


    ViewAbstract.prototype.addMouseCallbacks = function () {
        throw new Error('Method MUST be implemented.');
    };

    ViewAbstract.prototype.removeMouseCallbacks = function () {
        throw new Error('Method MUST be implemented.');
    };

    ViewAbstract.prototype.createViewerList = function () {
        this.ul360 = this.wrapper.getElementsByTagName('ul')[0];
        this.ul360.style.display = '';
        this.ul360.setAttribute('role', 'list');
        this.ul360.setAttribute('aria-label', this.model.get('presentation') + this.ariaLabel.texts.PRESENTATION);
        this.ul360.classList.add(this.listClassName);
    };

    ViewAbstract.prototype.goToIndex = function (index) {
        var li = this.ul360.querySelector('.cylindo-viewer-frame-' + index);
        var prevLi = this.ul360.querySelector('.active');
        var prevIndex = prevLi ?
            prevLi.dataset ? prevLi.dataset.index : prevLi.getAttribute('data-index') :
            null;
        var isCustomImage = false;

        if (li) {
            if (prevLi) {
                this.handleActiveFrame(prevLi, false);
            }
            this.handleActiveFrame(li, true);
            this.currentIndex = isNaN(index) ? index : parseInt(index, 10);
            this.trigger(this.events.FRAME_ACTIVATED, { index: index });
            this.handleTooltip(li, index, prevIndex);
            this.handleZoomButton(li, index, prevIndex);
            this.handleActionBtns(li, index, isCustomImage);
        }

        this.ul360.classList.toggle('cylindo-display-alt-image', isNaN(index));
    };
    ViewAbstract.prototype.prepareAltContent = function (index, evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var deferred = this.util.promise.create();
        var baseClassName = 'cylindo-viewer-frame-';
        var className = baseClassName + 'alt-';
        var frames = allFrames.VIEWER_ORDER.LIST;
        var framesLen = allFrames.VIEWER_ORDER.LEN;
        var contentToPreload = allFrames.ALTERNATE.LIST;
        var baseZeroAltIndex = frames.indexOf(this.framesHandler.prefixes.ALT + index);
        var baseOneAltIndex = baseZeroAltIndex + 1;
        var li, options, refAltRequestID;
        var useFramesToReorder = data && data.useFramesToReorder || false;
        var prevFrameIndex, prevFrame, nextFrameIndex, nextFrame;
        var i;
        if (useFramesToReorder) {
            prevFrameIndex = baseZeroAltIndex > 0 ? frames[baseZeroAltIndex - 1] : null;
            for (i = baseZeroAltIndex + 1; i < framesLen; i++) {
                if (!this.framesHandler.isAltId(frames[i])) {
                    nextFrameIndex = frames[i];
                    break;
                }
            }
            prevFrame = this.ul360.querySelector('.' + baseClassName + prevFrameIndex);
            nextFrame = this.ul360.querySelector('.' + baseClassName + nextFrameIndex);
        }

        this.altRequestID = this.model.getAltRequestID();
        refAltRequestID = this.altRequestID;

        li = document.createElement('li');
        li.setAttribute('aria-hidden', true);
        li.classList.add(className + index);
        li.classList.add('is-alt-content');
        li.classList.add('hidden');
        li.classList.add('is-rendering');
        if (prevFrame) {
            this.dom.insertAfter(li, prevFrame);
        }
        else if (nextFrame) {
            this.dom.insertBefore(li, nextFrame);
        }
        else {
            this.ul360.appendChild(li);
        }

        if (typeof contentToPreload[index].provider === 'undefined') {
            options = {
                index: index,
                viewer: this,
                li: li,
                imageToPreload: contentToPreload[index],
                refAltRequestID: refAltRequestID,
                baseOneAltIndex: baseOneAltIndex,
                prefix: this.framesHandler.prefixes.ALT,
            };
            deferred = this.renderer.useStrategy(this.renderer.strategies.ALT_IMAGE, options);
        }
        else {
            if (contentToPreload[index].provider === 'direct') {
                options = {
                    index: index,
                    viewer: this,
                    li: li,
                    videoToPreload: contentToPreload[index],
                    refAltRequestID: refAltRequestID,
                    prefix: this.framesHandler.prefixes.ALT,
                };
                deferred = this.renderer.useStrategy(this.renderer.strategies.ALT_VIDEO, options);
            }
            else if (contentToPreload[index].provider === 'youtube' ||
                contentToPreload[index].provider === 'vimeo' ||
                contentToPreload[index].provider === 'not-supported') {
                options = {
                    index: index,
                    viewer: this,
                    li: li,
                    videoToPreload: contentToPreload[index],
                    refAltRequestID: refAltRequestID,
                    prefix: this.framesHandler.prefixes.ALT,
                };
                deferred = this.renderer.useStrategy(this.renderer.strategies.ALT_VENDOR, options);
            }
        }

        deferred.then(onAltContentLoad.bind(options));
        this.alternateContentPromises.push(deferred);

        return deferred;

        function onAltContentLoad() {
            this.viewer.onAltContentLoad.call(this);
        }
    };
    ViewAbstract.prototype.reloadAltContent = function (evt, data) {
        var altContent = this.dom.querySelectorAll(this.ul360, '[class*="-alt-"]');
        if (altContent && altContent.length > 0) {
            this.dom.remove(altContent);
        }
        this.trigger(this.events.ALT_CONTENT_REMOVED);
    };

    ViewAbstract.prototype.prepare = function () {
        throw new Error('Method Prepare must be implemented.');
    };

    ViewAbstract.prototype.onError = function () {
        var that = this;
        this.img.onerror = this.self.util.fallback.loadFallbackImage.bind({
            img: this.img,
            li: this.li,
            model: this.self.model,
            errorText: 'Fallback image not found: '
        });
        this.img.src = '';
        this.self.hideTooltip(true);
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    };

    ViewAbstract.prototype.onImageLoad = function () {
        throw new Error('Method Prepare must be implemented.');
    };
    ViewAbstract.prototype.enable = function (isThreeSixty) {
        if (isThreeSixty === true) {
            this.ul360.style.visibility = 'visible';
        } else {
            this.ul360.classList.add('opacity-one');
        }

        this.enabled = true;
    };
    ViewAbstract.prototype.disable = function (isThreeSixty) {
        if (isThreeSixty === true) {
            this.ul360.style.visibility = 'hidden';
        } else {
            this.ul360.classList.remove('opacity-one');
        }

        this.enabled = false;
    };
    ViewAbstract.prototype.destroy = function () {
        var penImagesLen = this.pendingImages ? this.pendingImages.length : 0;
        var i;
        if (this.rafIndex) {
            this.logger.log('Current rotation stopped for 360 Viewer.');
            clearTimeout(this.rafIndex);
            this.rafIndex = null;
        }
        this.logger.log('360 Viewer destroyed.');
        this.removeMouseCallbacks();
        this.wrapper.innerHTML = '';
        this.promises = [];
        this.promisesCompleted = 0;
        this.ul360 = null;
        this.currentIndex = 1;
        this.initialized = false;
        this.wrapper = null;
        this.wrapper = null;
        this.listClassName = '';
        this.pubsub.destroy();
        for (i = 0; i < penImagesLen; i++) {
            this.pendingImages[i].img.onload = null;
            this.pendingImages[i].img.onerror = null;
            this.pendingImages[i].img.src = '';
            this.pendingImages.splice(i, 1);
            i--;
            penImagesLen--;
        }
    };
    ViewAbstract.prototype.getAvailableFrames = function () {
        var activeFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-"]:not(.hidden):not(.cylindo-img-not-found)');
        if (typeof activeFrames === 'undefined') {
            return [];
        }
        activeFrames = activeFrames
            .map(function (frame) {
                var index = frame.dataset ? frame.dataset.index : frame.getAttribute('data-index');
                return isNaN(index) ? index : parseInt(index, 10);
            });
        activeFrames.sort(function(a, b) {
            return a - b;
        });
        return activeFrames;
    };
    ViewAbstract.prototype.isFrameAvailable = function (index) {
        var loadedFrames = this.getAvailableFrames();
        index = isNaN(index) ? index : ~~index;
        return (loadedFrames.indexOf(index) !== -1);
    };
    ViewAbstract.prototype.showImage = function (url, large) {
        var loaderEl = this.wrapper.querySelector('.cylindo-loader');
        var className = 'cylindo-custom-image';
        var li = document.createElement('li');
        var img = new Image();
        var bindOpts = {
            loaderEl: loaderEl,
            ul360: this.ul360,
            img: img,
            li: li,
            instance: this
        };
        var src;
        var isCustomImage = true;
        var customElements = [];
        li.setAttribute('aria-hidden', true);
        this.ul360.style.display = 'none';
        li.classList.add(className);
        li.appendChild(img);
        try {
            li.dataset.zoomImage = large || url;
            li.dataset.url = url;
        }
        catch (ex) {
            li.setAttribute('data-zoom-image', (large || url));
            li.setAttribute('data-url', url);
        }

        try {
            customElements = this.dom.querySelectorAll(this.ul360, '.' + className);
            this.dom.remove(customElements);
        }
        catch (ex) { }
        img.onload = onCustomImageLoaded.bind(bindOpts);
        img.onerror = onError.bind(bindOpts);
        src = url;
        img.src = src;
        this.customImageOn = true;

        function onCustomImageLoaded() {
            var prevLi = this.ul360.querySelector('.active');
            this.img.onload = null;
            this.instance.dom.fadeOut(this.loaderEl);
            this.ul360.appendChild(this.li);
            if (prevLi) {
                this.instance.handleActiveFrame(prevLi, false);
            }
            this.instance.handleActiveFrame(this.li, true);
            this.ul360.style.display = 'block';
            this.instance.handleZoomButton(this.li, '', '');
            this.instance.handleActionBtns(this.li, '', isCustomImage);
            this.instance.trigger(this.instance.events.FRAME_ACTIVATED, { index: null });
        }

        function onError() {
            var fallbackImg = this.instance.model.get('fallbackImage');
            this.img.onerror = onFallbackImageFail.bind(this);
            this.img.src = fallbackImg;
        }

        function onFallbackImageFail() {
            var fallbackImg = this.instance.model.get('defaultFallbackImage');
            this.img.onerror = null;
            this.img.src = fallbackImg;
        }
        return null;
    };
    ViewAbstract.prototype.hideImage = function () {
        var i;
        var childrenArrLen = this.ul360.childNodes.length;
        var li;
        var url;
        var index;
        var prevIndex;
        var isCustomImage = false;
        for (i = 0; i < childrenArrLen; i++) {
            li = this.ul360.childNodes[i];
            if (li.classList.contains('cylindo-custom-image')) {
                url = li.dataset ? li.dataset.url : li.getAttribute('data-url');
            }
            index = li.dataset ? li.dataset.index : li.getAttribute('data-index');
            if (li.classList.contains('active')) {
                this.handleActiveFrame(li, false);
                if (typeof index !== 'undefined') {
                    prevIndex = index;
                }
            }
            if (index == this.currentIndex) {
                this.handleActiveFrame(li, true);
                this.handleTooltip(li, index, prevIndex);
                this.handleZoomButton(li, index, prevIndex);
                this.handleActionBtns(li, index, isCustomImage);
                this.trigger(this.events.FRAME_ACTIVATED, { index: index });
            }
        }
        this.customImageOn = false;
    };

    ViewAbstract.prototype.handleTooltip = function (li, index, prevIndex) {
        var self = this;
        var isFallbackImage = false;
        var tooltipText = this.getTooltipText();
        var tooltipAltImgText = this.model.get('tooltipAltImgText');
        var currentLi = self.ul360.querySelector('.active');
        var showImmediate;
        index = '' + index;
        prevIndex = '' + prevIndex;
        isFallbackImage = currentLi ? currentLi.classList.contains('cylindo-img-not-found') : false;
        if (typeof self.tooltip !== 'undefined' && !isFallbackImage) {
            self.tooltip.el.classList.remove('cylindo-tooltip-empty-text');
            if (this.framesHandler.is360Frame(index)) {
                if (typeof tooltipText === 'string' && tooltipText.length > 0) {
                    showImmediate = hideTooltipIfNeeded();
                    self.showTooltip(tooltipText, !showImmediate);
                }
                else {
                    self.tooltip.el.classList.add('cylindo-tooltip-empty-text');
                    self.hideTooltip(true);
                }
            }
            else if (self.framesHandler.isAltId(index)) {
                if (li.classList.contains('cylindo-video')) {
                    self.tooltip.el.classList.add('cylindo-tooltip-empty-text');
                    self.hideTooltip(true);
                }
                else {
                    if (typeof tooltipAltImgText === 'string' && tooltipAltImgText.length > 0) {
                        showImmediate = hideTooltipIfNeeded();
                        self.showTooltip(tooltipAltImgText, !showImmediate);
                    }
                    else {
                        self.tooltip.el.classList.add('cylindo-tooltip-empty-text');
                        self.hideTooltip(true);
                    }
                }
                self.tooltip.changeText(self.model.get('tooltipAltImgText'));
            }
        }
        else {
            if (isFallbackImage) {
                self.hideTooltip();
            }
        }
        function hideTooltipIfNeeded() {
            if ((self.framesHandler.is360Frame(index) && self.framesHandler.is360Frame(prevIndex)) ||
                (self.framesHandler.isAltId(index) && self.framesHandler.isAltId(prevIndex))) {
                return false;
            }
            else {
                self.hideTooltip(true);
                return true;
            }
        }
    };

    ViewAbstract.prototype.handleZoomButton = function (li, index, prevIndex) {
        var self = this;
        var actionButtons = self.dom.siblings(self.ul360, 'cylindo-action-button-group');
        var btnThreeSixty = actionButtons && actionButtons.length === 1 ?
            actionButtons[0].querySelector('.threesixty-button') : null;
        var isMobile = this.util.browser.isMobile();
        var ul360Rect = self.ul360.getBoundingClientRect();
        var imgURL = null;
        var zoomURL = null;
        var activeImg;
        index = '' + index;
        prevIndex = '' + prevIndex;
        if (isMobile && li &&
            self.model.get('zoomButton') &&
            (btnThreeSixty && btnThreeSixty.classList)) {
            if (!li.classList.contains('cylindo-video') &&
                !li.classList.contains('cylindo-img-not-found')) {
                activeImg = li.childNodes[0];
                if (activeImg) {
                    imgURL = activeImg.src || (activeImg.dataset ? activeImg.dataset.src : activeImg.getAttribute('data-src'));
                }
                zoomURL = li.dataset ? li.dataset.zoomImage : li.getAttribute('data-zoom-image');
                if (zoomURL && zoomURL.indexOf('data:image') === -1) {
                    zoomURL = '//' + (zoomURL.split('//')[1]);
                }
                if (zoomURL && imgURL && zoomURL === imgURL.replace(/^http(s)*:/i, '')) {
                    if (activeImg &&
                        (activeImg.naturalHeight <= ul360Rect.height ||
                            activeImg.naturalWidth <= ul360Rect.width)) {
                        if (!btnThreeSixty.classList.contains('temporarily-hidden')) {
                            btnThreeSixty.classList.add('temporarily-hidden');
                        }
                        return;
                    }
                }
                if (self.framesHandler.is360Frame(index)) {
                    if (self.model.get('zoom')) {
                        if (btnThreeSixty.classList.contains('temporarily-hidden')) {
                            btnThreeSixty.classList.remove('temporarily-hidden');
                        }
                    }
                }
                else if (self.framesHandler.isAltId(index)) {
                    if (self.model.get('alternateContentZoom')) {
                        if (btnThreeSixty.classList.contains('temporarily-hidden')) {
                            btnThreeSixty.classList.remove('temporarily-hidden');
                        }
                    }
                    else {
                        if (!btnThreeSixty.classList.contains('temporarily-hidden')) {
                            btnThreeSixty.classList.add('temporarily-hidden');
                        }
                    }
                }
                else if (li.classList.contains('cylindo-custom-image')) {
                    if (self.model.get('alternateContentZoom')) {
                        if (btnThreeSixty.classList.contains('temporarily-hidden')) {
                            btnThreeSixty.classList.remove('temporarily-hidden');
                        }
                    }
                    else {
                        if (!btnThreeSixty.classList.contains('temporarily-hidden')) {
                            btnThreeSixty.classList.add('temporarily-hidden');
                        }
                    }
                }
            }
            else {
                if (!btnThreeSixty.classList.contains('temporarily-hidden')) {
                    btnThreeSixty.classList.add('temporarily-hidden');
                }
            }
        }
    };
    ViewAbstract.prototype.handleActionBtns = function (li, index, isCustomImage) {
        var isFullScreen = this.wrapper.classList.contains('full-screen');
        var actionButtons = this.dom.siblings(this.ul360, 'cylindo-action-button-group');
        if (actionButtons && actionButtons.length) {
            if (this.framesHandler.is360Frame(index) || isCustomImage) {
                actionButtons[0].classList.remove('temporarily-hidden');
            }
            else {
                if (li.classList.contains('cylindo-video')) {
                    if (isFullScreen) {
                        if (!actionButtons[0].classList.contains('video-fullscreen-allowed')) {
                            actionButtons[0].classList.add('video-fullscreen-allowed');
                        }
                    }
                    if (!actionButtons[0].classList.contains('permanently-hidden')) {
                        actionButtons[0].classList.add('temporarily-hidden');
                    }
                }
                else if (!actionButtons[0].classList.contains('permanently-hidden')) {
                    actionButtons[0].classList.remove('temporarily-hidden');
                }
            }
        }
    };

    ViewAbstract.prototype.isRightClick = function (evt) {
        if ('which' in evt) {
            return (evt.which === 3);
        }
        else if ('button' in evt) {
            return (evt.button === 2);
        }
        return false;
    };
    ViewAbstract.prototype.tryToCreateTooltip = function () {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var displayTooltipOnInit = this.getTooltipText() ? true : false;
        var hasEnvContent = allFrames.ALTERNATE.LEN > 0 ?
            true : false;
        var hasEnvTooltip = (typeof this.model.get('tooltipAltImgText') === 'string' &&
            this.model.get('tooltipAltImgText').length > 0) ?
            true : false;
        if (this.getTooltipText() ||
            (hasEnvContent && hasEnvTooltip)) {
            this.tooltip = cylindo.getModule('tooltip.viewer').create({
                parent: this.wrapper,
                text: this.getTooltipText(),
                dom: this.dom
            });
        }
        if (this.tooltip) {
            this.tooltip.render();
            if (!displayTooltipOnInit) {
                this.tooltip.el.classList.add('cylindo-tooltip-empty-text');
                this.hideTooltip(true);
            }
        }
    };
    ViewAbstract.prototype.getTooltipText = function () {
        return this.model.get(this.defaultTooltip);
    };
    ViewAbstract.prototype.showTooltip = function (text, immediate) {
        if (this.tooltip) {
            if (this.enabled === false) {
                this.tooltip.changeText(text);
            }
            else {
                this.tooltip.show(text, immediate);
            }
        }
    };
    ViewAbstract.prototype.hideTooltip = function (immediate) {
        if (this.tooltip) {
            this.tooltip.hide(immediate);
        }
    };

    ViewAbstract.prototype.sendFirstFrameCompleteEvt = function (immediate) {
        this.trigger(this.events.CONTENT_TYPE_COMPLETE, {
            contentType: this.contentTypes.FIRST_FRAME,
            elapsed: this.firstImageLoadedTime - this.viewerUpdatedStartTime
        });
    };

    ViewAbstract.prototype.getThumbsDetails = function () {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var thumbsDetails = {
            'ts': -1,
            'ac': -1
        };
        if (allFrames.THUMB.ENABLED) {
            thumbsDetails.ts = allFrames.THUMB.THREESIXTY_THUMB_LEN;
            thumbsDetails.ac = allFrames.ALTERNATE.LEN;
        }
        return thumbsDetails;
    };
    ViewAbstract.prototype.onAltContentLoad = function () {
        var self = this;
        var index = self.viewer.framesHandler.prefixes.ALT + self.index;
        self.li.classList.remove('hidden');
        self.li.classList.remove('is-rendering');
        self.viewer.trigger(self.viewer.events.ALT_CONTENT_LOADED, { 
            sequence: self.viewer.framesHandler.prefixes.ALT + self.index,
            viewerId: self.viewer.id,
            image: self.imageToPreload && self.imageToPreload.image ? self.imageToPreload.image : null,
         });
        if (index === self.viewer.currentIndex) {
            self.viewer.handleActiveFrame(self.li, true);
            if (!self.viewer.isVisible) {
                self.viewer.displayViewer();
            }
        }
    };

    ViewAbstract.prototype.handleActiveFrame = function (element, enable) {
        if (element && element.classList) {
            if (enable) {
                element.setAttribute('aria-hidden', 'false');
                element.classList.add('active');
            }
            else {
                element.setAttribute('aria-hidden', 'true');
                element.classList.remove('active');
            }
        }
    };

    ViewAbstract.prototype.firstFrameEvt = function (index) {
        if (!this.firstFrameIsReady) {
            this.firstFrameIsReady = true;
            this.trigger(this.events.FIRST_FRAME_COMPLETE, { index: index, frameCount: this.frameCount });
        }
    };

    ViewAbstract.counter = 0;

    window.cylindo.addModule('cylindo.classes.abstract.view', ViewAbstract);
}).call(this);
