(function () {
    'use strict';
    function ARQuickLookBtn(opts) {
        var self = this;
        var arModes = cylindo.getModule('cylindo.helpers.ar.modes');
        var usdz = cylindo.getModule('cylindo.button.ar.usdz');
        var glb = cylindo.getModule('cylindo.button.ar.glb');
        var qr = cylindo.getModule('cylindo.button.ar.qr');

        var util = opts.util;
        var arInstance = null;
        var lastTryToTriggerSeenCall = 0;
        var model = opts.model;
        this.model = model;
        this.arSeen = false;
        this.seenEventsAttached = true;
        this.ariaLabel = opts.ariaLabel;
        if (model.get('arMode') === arModes.ARQUICKLOOK) {
            arInstance = usdz.create(opts);
        }
        else if (model.get('arMode') === arModes.SCENE_VIEWER) {
            arInstance = glb.create(opts);
        }
        else if (model.get('arMode') === arModes.QR) {
            arInstance = qr.create(opts);
        }
        if (arInstance) {
            arInstance.handleARButtonReady = handleARButtonReady;
            return util.object.extend(this, arInstance);
        }

        function handleARButtonReady() {
            if (!tryToTriggerARSeenEvent()) {
                if (self.seenEventsAttached) {
                    removeSeenEvents();
                }
                if (window.addEventListener) {
                    window.addEventListener('scroll', tryToTriggerARSeenEvent, false);
                    window.addEventListener('resize', tryToTriggerARSeenEvent, false);
                    self.seenEventsAttached = true;
                }
                else if (window.attachEvent) {
                    window.attachEvent('onscroll', tryToTriggerARSeenEvent);
                    window.attachEvent('onresize', tryToTriggerARSeenEvent);
                    self.seenEventsAttached = true;
                }
            }
        }
        function tryToTriggerARSeenEvent() {
            if (lastTryToTriggerSeenCall && (Date.now() - lastTryToTriggerSeenCall) < 250) {
                return;
            }
            lastTryToTriggerSeenCall = Date.now();
            if (!self.arSeen && isContainerInViewport()) {
                self.arSeen = true;
                self.tags.send(self.tags.events.AR_SEEN);
                if (self.seenEventsAttached) {
                    removeSeenEvents();
                }
                return true;
            }
            return false;
        }
        function removeSeenEvents() {
            if (window.removeEventListener) {
                window.removeEventListener('scroll', tryToTriggerARSeenEvent, false);
                window.removeEventListener('resize', tryToTriggerARSeenEvent, false);
                self.seenEventsAttached = false;
            }
            else if (window.attachEvent) {
                window.detachEvent('onscroll', tryToTriggerARSeenEvent);
                window.detachEvent('onresize', tryToTriggerARSeenEvent);
                self.seenEventsAttached = false;
            }
        }
        function isContainerInViewport() {
            var windowHeight, windowWidth, vertInView, horInView;
            var rect = self.el && self.el.getBoundingClientRect();
            if (!rect) {
                return false;
            }
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);
            windowWidth = (window.innerWidth || document.documentElement.clientWidth);
            vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
            horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
            return (vertInView && horInView);
        }
    }
    ARQuickLookBtn.prototype.render = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.tryToShow = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.onFeaturesError = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.previousErrorFound = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.getArQuickLookUrl = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.on = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.off = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.destroy = function () {
        throw new Error('Method MUST be implemented.');
    };
    ARQuickLookBtn.prototype.sendInitialTags = function () {
        var self = this;
        var ARBannerSupportedKeys = ['ios', 'android', 'ios-android'];
        var arDesktopEnabled = self.model.get('ARDesktop') === true ? true : false;
        var arBannerEnabled = ARBannerSupportedKeys.indexOf(self.model.get('ARBanner')) !== -1 ? true : false;
        if (self && self.tags) {
            self.tags.updateARFlags(arDesktopEnabled, arBannerEnabled);
            self.tags.send(self.tags.events.AR_LOADED);
            if (!self.arSeen) {
                self.handleARButtonReady();
            }
        }
    };
    ARQuickLookBtn.prototype.events = {};
    var publicAPI = {
        create: function (opts) {
            return new ARQuickLookBtn(opts);
        }
    };
    window.cylindo.addModule('cylindo.button.ar', publicAPI);
}.call(this));
