(function () {
    'use strict';
    var moduleKey = 'cylindo.core.util';
    var util = window.cylindo.getModule(moduleKey) || {};
    util.fallback = {
        loadFallbackImage: loadFallbackImage,
        fallbackFail: fallbackFail
    };

    window.cylindo.addModule(moduleKey, util);
    function loadFallbackImage() {
        var fallbackOptions = parseFallbackProperties(this.model, this.config);
        var img = this.img;
        var li = this.li;
        var imageStatus = this.imageStatus;
        var logger = fallbackOptions.logger;
        var fallbackImage = fallbackOptions.fallbackImage;
        var defaultFallbackImage = fallbackOptions.defaultFallbackImage;
        var BUSAPIImage = fallbackOptions.BUSAPIImage;
        var useBUSAPI = BUSAPIImage && BUSAPIImage.length;
        var srcToBeUsed = '';
        if (useBUSAPI && this.useBUSAPI === false) {
            useBUSAPI = this.useBUSAPI;
        }
        srcToBeUsed = fallbackImage !== defaultFallbackImage ? fallbackImage : BUSAPIImage && useBUSAPI ? BUSAPIImage : defaultFallbackImage;
        if (!srcToBeUsed) {
            logger.error('Valid image must be provided as fallback image.', srcToBeUsed);
            return;
        }
        img.src = srcToBeUsed;
        img.onerror = fallbackFail.bind({
            fallbackImage: fallbackImage,
            defaultFallbackImage: defaultFallbackImage,
            BUSAPIImage: BUSAPIImage,
            logger: logger,
            img: img,
            useBUSAPI: this.useBUSAPI
        });
        if (li && li.classList) {
            li.classList.add('cylindo-img-not-found');
        }
        if (imageStatus && typeof imageStatus === 'object') {
            imageStatus.notFound = true;
        }
    }
    function fallbackFail() {
        var defaultFallbackImage = this.defaultFallbackImage;
        var fallbackImage = decodeURIComponent(this.fallbackImage);
        var BUSAPIImage = decodeURIComponent(this.BUSAPIImage);
        var useBUSAPI = BUSAPIImage && BUSAPIImage.length;
        var logger = this.logger;
        var errorText = this.errorText || 'Fallback image not found: ';
        var img = this.img;
        var currentSrc = decodeURIComponent(img.src);
        if (useBUSAPI && this.useBUSAPI === false) {
            useBUSAPI = this.useBUSAPI;
        }
        try {
            logger.error(errorText + currentSrc);
        }
        catch (ex) {
            console.log(errorText + currentSrc);
        }
        if (currentSrc === fallbackImage &&
            fallbackImage !== defaultFallbackImage) {
            if (useBUSAPI) {
                img.src = BUSAPIImage;
            }
            else {
                useDefaultFallback(img);
            }
        }
        else if (currentSrc === BUSAPIImage) {
            useDefaultFallback(img);
        }
        else if (currentSrc === defaultFallbackImage) {
            img.onerror = null;
        }
        function useDefaultFallback(img) {
            img.src = defaultFallbackImage;
            img.onerror = null;
        }
    }
    function parseFallbackProperties(model, config) {
        var network = cylindo.getModule('cylindo.core.network');
        var fallbackProperties = {
            fallbackImage: null,
            defaultFallbackImage: null,
            BUSAPIImage: null,
            logger: null
        };
        if (model) {
            fallbackProperties.fallbackImage = model.get('fallbackImage');
            fallbackProperties.defaultFallbackImage = model.get('defaultFallbackImage');
            fallbackProperties.BUSAPIImage = model.get('BUSAPIImage');
            fallbackProperties.logger = model.getLogger();
        }
        else if (config) {
            fallbackProperties.fallbackImage = network.resolveProtocol(config.fallbackImage);
            fallbackProperties.defaultFallbackImage = network.resolveProtocol(config.defaultFallbackImage);
            fallbackProperties.BUSAPIImage = network.resolveProtocol(config.BUSAPIImage);
            fallbackProperties.logger = null;
        }
        return fallbackProperties;
    }
}).call(this);