(function () {
    'use strict';

    var Ga = function (opts) {
        var self = this;
        var gaInstanceName = opts.gaName;
        var events = opts.events;
        var gaKeys = opts.gaKeys;
        var prefixes = opts.prefixes;
        var googleMaps = cylindo.getModule('cylindo.core.google.maps');
        var eventsData = googleMaps.getEventsData();

                self.initialized = false;
        self.init = init;
        self.saveEvent = saveEvent;
        init();
        function init() {
            self.instanceID = ++Ga.counter;
            self.initialized = true;
        }

        function saveEvent(eventData, clientData) {
            var gao = 'GoogleAnalyticsObject';
            var prop;
            var currentEvent;
            var isPageLoadEvent = false;
            var toBeSent = {
                hitType: 'event',
                event: 'cylindoEvent',
                eventCategory: 'Cylindo',
                eventValue: undefined,
            };
            var pageLoadObj = {
                id: clientData.productCode,
                name: clientData.productCode,
            };
            var metricAdded = false;
            var withoutMetric = false;
            var dimensionsAdded = false;
            if (eventData && gaFn()) {
                try {
                    for (prop in events) {
                        if (events[prop] === eventData.e) {
                            currentEvent = eventsData[prop];
                        }
                    }
                    switch (eventData.e) {
                        case events.LOADED:
                            isPageLoadEvent = true;
                            withoutMetric = true;
                            break;
                        case events.SEEN:
                        case events.FULLSCREEN:
                        case events.ZOOM:
                        case events.SPIN:
                        case events.FEATURE_CHANGED:
                        case events.FEATURE_ERROR:
                        case events.THUMBNAIL_CLICK:
                            if (gaKeys.analytics360HitMetric) {
                                metricAdded = true;
                                toBeSent[prefixes.METRIC + gaKeys.analytics360HitMetric] = 1;
                            }
                            break;
                        case events.AR_LOADED:
                        case events.AR_SEEN:
                            withoutMetric = true;
                            break;
                            case events.AR_CLICKED:
                            case events.AR_BANNER_CLICKED:
                            case events.AR_LAUNCHED:
                            if (gaKeys.analyticsARHitMetric) {
                                metricAdded = true;
                                toBeSent[prefixes.METRIC + gaKeys.analyticsARHitMetric] = 1;
                            }
                            break;
                    }
                    if (currentEvent && ((currentEvent.ONE_PER_PAGE && currentEvent.TRIGGERED) || !currentEvent.UA) ){
                        return;
                    }
                    else {
                        toBeSent.eventAction = currentEvent.ACTION;
                        toBeSent.eventLabel = currentEvent.LABEL;
                        if (currentEvent.DIMENSION_VAL_360) {
                            if (gaKeys.analytics360HitDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analytics360HitDimension] = currentEvent.DIMENSION_VAL_360;
                            }
                            if (gaKeys.analytics360SessionDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analytics360SessionDimension] = currentEvent.DIMENSION_VAL_360;
                            }
                        }
                        if (currentEvent.DIMENSION_VAL_AR) {
                            if (gaKeys.analyticsARHitDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analyticsARHitDimension] = currentEvent.DIMENSION_VAL_AR;
                            }
                            if (gaKeys.analyticsARSessionDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analyticsARSessionDimension] = currentEvent.DIMENSION_VAL_AR;
                            }
                        }
                        if (isPageLoadEvent && eventData.d) {
                            if (gaKeys.analytics360ProductDimension && eventData.d.hasOwnProperty('t') && eventData.d.t > 0) {
                                dimensionsAdded = true;
                                pageLoadObj[prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = 'Cylindo360';
                            }
                            if (dimensionsAdded) {
                                gaFn()('require', 'ec');
                                gaFn()(gaInstanceName + '.ec:addProduct', pageLoadObj);
                                gaFn()(gaInstanceName + '.ec:setAction', 'detail');
                                gaFn()(gaInstanceName + '.send', 'event', 'Ecommerce', 'Product Detail View', undefined, undefined);
                            }
                        }
                        if (metricAdded || withoutMetric) {
                            gaFn()(gaInstanceName + '.send', 'event', toBeSent);
                            currentEvent.TRIGGERED = true;
                        }
                    }
                }
                catch (ex) {
                    console.log('Something went wrong while trying to send the event using Google Analytics.', ex);
                }
            }
            function gaFn() {
                return window[window[gao]];
            }
        }
    };

    Ga.counter = 0;

    var publicAPI = {
        create: function (opts) {
            return new Ga(opts);
        }
    };

    window.cylindo.addModule('cylindo.core.ua.ga', publicAPI);
}).call(this);

