(function () {
    'use strict';

    var publicServices = {
        getContent: getContent,
        getContentTypes: getContentTypes
    };
    var contentTypes = {
        FIRST_FRAME: 'f',
        READY: 'r',
        FRAME: 't',
        ALTERNATE: 'ac',
        FRAME_THUMB: 'tt',
        ALTERNATE_THUMB: 'act'
    };

    function getContentTypes() {
        return contentTypes;
    }

    function getContent(framesHandler, viewerDetails) {
        var allFrames = framesHandler.getAllFramesToBeLoaded();
        var presentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
        var totalFrames = allFrames.THREESIXTY.TO_BE_LOADED;
        var thumbs = allFrames.THUMB.ENABLED;
        var altContent = allFrames.ALTERNATE.LIST;
        var contentToLoad = [];
        var thumbsToLoad = [];
        var thumbsToUpdate = [];
        var contentToUpdate = [];
        var i;
        if (viewerDetails.presentation === presentations.CUSTOM.NAME) {
            if (altContent.length) {
                contentToLoad.push({ type: contentTypes.FIRST_FRAME, downloaded: false, elapsed: 0 });
                contentToUpdate.push({ type: contentTypes.FIRST_FRAME, downloaded: false, elapsed: 0 });
                contentToLoad.push({ type: contentTypes.ALTERNATE, downloaded: false, elapsed: 0 });
                contentToUpdate.push({ type: contentTypes.ALTERNATE, downloaded: false, elapsed: 0 });
            }
        }
        else {
            if (viewerDetails.presentation === presentations.THREESIXTY.NAME) {
                contentToLoad.push({ type: contentTypes.READY, downloaded: false, elapsed: 0 });
                contentToUpdate.push({ type: contentTypes.READY, downloaded: false, elapsed: 0 });
            }
            contentToLoad.push({ type: contentTypes.FIRST_FRAME, downloaded: false, elapsed: 0 });
            contentToUpdate.push({ type: contentTypes.FIRST_FRAME, downloaded: false, elapsed: 0 });
            contentToLoad.push({ type: contentTypes.FRAME, downloaded: false, elapsed: 0 });
            contentToUpdate.push({ type: contentTypes.FRAME, downloaded: false, elapsed: 0 });
            if (altContent.length) {
                contentToLoad.push({ type: contentTypes.ALTERNATE, downloaded: false, elapsed: 0 });
            }
        }
        if (thumbs &&
            viewerDetails.presentation !== presentations.STACKED.NAME) {
            for (i = 0; i < contentToLoad.length; i++) {
                if (contentToLoad[i].type !== contentTypes.READY &&
                    contentToLoad[i].type !== contentTypes.FIRST_FRAME) {
                    thumbsToLoad.push({ type: contentToLoad[i].type + 't', downloaded: false, elapsed: 0 });
                }
            }
            for (i = 0; i < contentToUpdate.length; i++) {
                if (contentToLoad[i].type !== contentTypes.READY &&
                    contentToLoad[i].type !== contentTypes.FIRST_FRAME) {
                    thumbsToUpdate.push({ type: contentToUpdate[i].type + 't', downloaded: false, elapsed: 0 });
                }
            }
        }
        contentToLoad = contentToLoad.concat(thumbsToLoad);
        contentToUpdate = contentToUpdate.concat(thumbsToUpdate);
        return {
            contentToLoad: contentToLoad,
            contentToUpdate: contentToUpdate,
            downloaded: false, elapsed: 0,
            totalFrames: totalFrames
        };
    }

    window.cylindo.addModule('cylindo.helpers.content.to.load', publicServices);
}).call(this);
