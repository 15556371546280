(function () {
    'use strict';
    var LayerAbstract = cylindo.getModule('cylindo.classes.layer');
    function TooltipNotAvailable(opts) {
        LayerAbstract.call(this, opts);
        this.wrapperClasses = ['cylindo-tooltip', 'cylindo-tooltip-not-available'];
        this.templatePattern = [
            '<span>!</span>',
            '<p><% text %></p>',
        ];
        this.setTemplate(this.templatePattern.join(''));
    }

    TooltipNotAvailable.prototype = Object.create(LayerAbstract.prototype);
    TooltipNotAvailable.prototype.constructor = TooltipNotAvailable;

    var publicAPI = {
        create: function (opts) {
            return new TooltipNotAvailable(opts);
        }
    };

    window.cylindo.addModule('tooltip.not.available', publicAPI);
}.call(this));
