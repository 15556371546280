(function () {
	'use strict';

	var moduleKey = 'cylindo.core.util';
    var util = window.cylindo.getModule(moduleKey) || {};

	util.debounce_throttle = {
		debounce: debounce,
		throttle: throttle
	};

	window.cylindo.addModule(moduleKey, util);

	function debounce(func, wait, options) {
		var lastArgs,
			lastThis,
			maxWait,
			result,
			timerId,
			lastCallTime,
			lastInvokeTime = 0,
			leading = false,
			maxing = false,
			trailing = true;

		if (isObject(options)) {
			leading = !!options.leading;
			maxing = 'maxWait' in options;
			maxWait = maxing ? Math.max(options.maxWait || 0, wait) : maxWait;
			trailing = 'trailing' in options ? !!options.trailing : trailing;
		}
		function invokeFunc(time) {
			var args = lastArgs,
				thisArg = lastThis;

			lastArgs = lastThis = undefined;
			lastInvokeTime = time;
			result = func.apply(thisArg, args);
			return result;
		}
		function leadingEdge(time) {
			lastInvokeTime = time;
			timerId = setTimeout(timerExpired, wait);
			return leading ? invokeFunc(time) : result;
		}
		function remainingWait(time) {
			var timeSinceLastCall = time - lastCallTime,
				timeSinceLastInvoke = time - lastInvokeTime,
				result = wait - timeSinceLastCall;

			return maxing ? Math.min(result, maxWait - timeSinceLastInvoke) : result;
		}
		function shouldInvoke(time) {
			var timeSinceLastCall = time - lastCallTime,
				timeSinceLastInvoke = time - lastInvokeTime;

			return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
				(timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
		}
		function timerExpired() {
			var time = Date.now();
			if (shouldInvoke(time)) {
				return trailingEdge(time);
			}
			timerId = setTimeout(timerExpired, remainingWait(time));
		}
		function trailingEdge(time) {
			timerId = undefined;

			if (trailing && lastArgs) {
				return invokeFunc(time);
			}
			lastArgs = lastThis = undefined;
			return result;
		}
		function cancel() {
			if (timerId !== undefined) {
				clearTimeout(timerId);
			}
			lastInvokeTime = 0;
			lastArgs = lastCallTime = lastThis = timerId = undefined;
		}
		function flush() {
			return timerId === undefined ? result : trailingEdge(Date.now());
		}
		function debounced() {
			var time = Date.now(),
				isInvoking = shouldInvoke(time);

			lastArgs = arguments;

			lastThis = this;

			lastCallTime = time;

			if (isInvoking) {
				if (timerId === undefined) {
					return leadingEdge(lastCallTime);
				}
				if (maxing) {
					timerId = setTimeout(timerExpired, wait);
					return invokeFunc(lastCallTime);
				}
			}
			if (timerId === undefined) {
				timerId = setTimeout(timerExpired, wait);
			}
			return result;
		}
		debounced.cancel = cancel;
		debounced.flush = flush;
		return debounced;
	}

	function isObject(value) {
		var type = typeof value;
		return value !== null && (type == 'object' || type == 'function');
	}

	function throttle(func, wait, options) {
		var leading = true,
			trailing = true;

		if (isObject(options)) {
			leading = 'leading' in options ? !!options.leading : leading;
			trailing = 'trailing' in options ? !!options.trailing : trailing;
		}
		return debounce(func, wait, {
			'leading': leading,
			'maxWait': wait,
			'trailing': trailing
		});
	}

}).call(this);






