(function () {
    var VIEWER_VERSION = '4.6.8';
    var IS_STABLE_VERSION = false;
    function getEnvironmentDomain() {
        return 'cylindo.com';
    }
    var SERVICES_BASE = '//content.cylindo.com';
    var viewerVersion = 'v' + VIEWER_VERSION;
    var environment = {
        urls: {
            SERVICES_BASE: SERVICES_BASE,
            CYLINDO_CLIENTS: '//viewer.' + getEnvironmentDomain() + '/' + viewerVersion + '/',
            TRACKING_PAGE: '//track.' + getEnvironmentDomain() + '/pages/t.aspx',
            TRACKING_SERVICES_BASE: '//track.' + getEnvironmentDomain() + '/tracking/',
            GETTRACKINGID_SERVICE: '/standard/viewer/gettrackingid_v2_1?callback=?&customerid={0}',
            DEFAULT_FALLBACK_IMAGE: '//viewer.' + getEnvironmentDomain() + '/' + viewerVersion + '/assets/images/no-image-found.gif',
            DEFAULT_FALLBACK_VIDEO_IMAGE: '//viewer.' + getEnvironmentDomain() + '/' + viewerVersion + '/assets/images/fallback_video_image.png',
            SERVICES_GET_IMAGE_URL: SERVICES_BASE + '/api/v2/standard/viewer/getimageurl',
            PRODUCT_EXISTS: SERVICES_BASE + '/api/v2/__accid__/products/__productCode__/configuration',
            BUSAPI: '//busapicdnendpoint.azureedge.net/getimage/',
            AR: '//s3.amazonaws.com/test.cylindev.com/AR/',
            OLD_SERVICES_GET_IMAGE_URL: '//viewer-services.cylindo.com/standard/viewer/getimageurl',
            ARV_GOOGLE: '//arvr.google.com/scene-viewer/1.0'
        },
        defaults: {
            ignoreCache: false,
            loggerLimit: 100
        },
        metadata: {
            VERSION: VIEWER_VERSION,
            TRACKING_API: '1.8',
            TRACKING_MSG_VERSION: 3
        }
    };
    window.cylindo.addModule('cylindo.settings.environments', environment);
}.call(this));