(function () {
    'use strict';
    var self = this;
    var util = cylindo.getModule('cylindo.core.util');
    var config = cylindo.getModule('cylindo.core.config');
    var network = window.cylindo.getModule('cylindo.core.network');

    var publicServices = {
        skuExists: skuExists
    };
    function skuExists(accountID, productCode, cb) {
        var deferred = util.promise.create();
        var exists = false;
        var address;
        var requestOpts = {
            type: 'HEAD'
        };
        var toBeDeprecated = 'Cylindo: skuExists method has been deprecated, please consult our documentation or contact the Support team to look for alternatives.';
        if (console.warn) {
            console.warn(toBeDeprecated);
        }
        else {
            console.log(toBeDeprecated);
        }    

        address = network.resolveProtocol(config.get('urls').PRODUCT_EXISTS);
        address = address.replace('__accid__', accountID).replace('__productCode__', productCode);
        network.doRequest(
            address,
            function (response, xhr) {
                exists = true;
                deferred.resolve(exists);
                if (cb) {
                    cb(exists);
                }
            },
            requestOpts,
            function (response, xhr) {
                exists = false;
                deferred.resolve(exists);
                if (cb) {
                    cb(exists);
                }
            },
            null
        );
        return deferred;
    }

    window.cylindo.addModule('cylindo.viewer.services', publicServices);
}).call(this);
