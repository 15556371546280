(function () {
    'use strict';

    var Gtm = function (opts) {
        var self = this;
        var dataLayer = opts.dataLayer;
        var events = opts.events;
        var gaKeys = opts.gaKeys;
        var prefixes = opts.prefixes;
        var googleMaps = cylindo.getModule('cylindo.core.google.maps');
        var eventsData = googleMaps.getEventsData();

        self.initialized = false;
        self.init = init;
        self.saveEvent = saveEvent;
        init();
        function init() {
            var i = 0;
            self.instanceID = ++Gtm.counter;
            self.initialized = true;
        }

        function saveEvent(eventData, clientData) {
            var prop;
            var currentEvent;
            var isPageLoadEvent = false;
            var metricAdded = false;
            var withoutMetric = false;
            var dimensionsAdded = false;

            var toBeSent = {
                event: 'cylindoEvent',
                eventCategory: 'Cylindo',
                eventValue: undefined,
            };
            var pageLoadObj = {
                event: 'eec.detail',
                ecommerce: {
                    detail: {
                        products: [{}]
                    }
                }
            };
            toBeSent[prefixes.DIMENSION + gaKeys.analytics360HitDimension] = undefined;
            toBeSent[prefixes.DIMENSION + gaKeys.analytics360SessionDimension] = undefined;
            toBeSent[prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = undefined;
            toBeSent[prefixes.DIMENSION + gaKeys.analyticsARHitDimension] = undefined;
            toBeSent[prefixes.DIMENSION + gaKeys.analyticsARSessionDimension] = undefined;
            toBeSent[prefixes.METRIC + gaKeys.analytics360HitMetric] = undefined;
            toBeSent[prefixes.METRIC + gaKeys.analyticsARHitMetric] = undefined;
            pageLoadObj.ecommerce.detail.products[0][prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = undefined;
            if (eventData && dataLayer) {
                try {
                    for (prop in events) {
                        if (events[prop] === eventData.e) {
                            currentEvent = eventsData[prop];
                        }
                    }
                    switch (eventData.e) {
                        case events.LOADED:
                            isPageLoadEvent = true;
                            withoutMetric = true;
                        break;
                        case events.SEEN:
                            withoutMetric = true;
                            break;
                        case events.FULLSCREEN:
                        case events.ZOOM:
                        case events.SPIN:
                        case events.FEATURE_CHANGED:
                        case events.FEATURE_ERROR:
                        case events.THUMBNAIL_CLICK:
                            if (gaKeys.analytics360HitMetric) {
                                metricAdded = true;
                                toBeSent[prefixes.METRIC + gaKeys.analytics360HitMetric] = 1;
                            }
                            break;
                        case events.AR_LOADED:
                        case events.AR_SEEN:
                            withoutMetric = true;
                            break;
                        case events.AR_CLICKED:
                        case events.AR_BANNER_CLICKED:
                        case events.AR_LAUNCHED:
                            if (gaKeys.analyticsARHitMetric) {
                                metricAdded = true;
                                toBeSent[prefixes.METRIC + gaKeys.analyticsARHitMetric] = 1;
                            }
                            break;
                    }
                    if (currentEvent && ((currentEvent.ONE_PER_PAGE && currentEvent.TRIGGERED) || !currentEvent.UA) ){
                        return;
                    }
                    else {
                        toBeSent.eventAction = currentEvent.ACTION;
                        toBeSent.eventLabel = currentEvent.LABEL;
                        if (currentEvent.DIMENSION_VAL_360) {
                            if (gaKeys.analytics360HitDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analytics360HitDimension] = currentEvent.DIMENSION_VAL_360;
                            }
                            if (gaKeys.analytics360SessionDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analytics360SessionDimension] = currentEvent.DIMENSION_VAL_360;
                            }
                        }
                        if (currentEvent.DIMENSION_VAL_AR) {
                            if (gaKeys.analyticsARHitDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analyticsARHitDimension] = currentEvent.DIMENSION_VAL_AR;
                            }
                            if (gaKeys.analyticsARSessionDimension) {
                                toBeSent[prefixes.DIMENSION + gaKeys.analyticsARSessionDimension] = currentEvent.DIMENSION_VAL_AR;
                            }
                        }
                        if (isPageLoadEvent && eventData.d) {
                            if (gaKeys.analytics360ProductDimension && eventData.d.hasOwnProperty('t') && eventData.d.t > 0) {
                                dimensionsAdded = true;
                                pageLoadObj.ecommerce.detail.products[0][prefixes.DIMENSION + gaKeys.analytics360ProductDimension] = 'Cylindo360';
                            }
                            if (dimensionsAdded) {
                                pageLoadObj.ecommerce.detail.products[0].id = clientData.productCode;
                                pageLoadObj.ecommerce.detail.products[0].name = clientData.productCode;
                                dataLayer.push(pageLoadObj);
                            }
                        }
                        if (metricAdded || withoutMetric) {
                            dataLayer.push(toBeSent);
                            currentEvent.TRIGGERED = true;
                        }
                    }
                }
                catch (ex) {
                    console.log('Something went wrong while trying to send the event using Google Tag Manager.', ex);
                }
            }
        }
    };

    Gtm.counter = 0;

    var publicAPI = {
        create: function (opts) {
            return new Gtm(opts);
        }
    };

    window.cylindo.addModule('cylindo.core.ua.gtm', publicAPI);
}).call(this);

