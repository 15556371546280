(function () {
    'use strict';
    function linearTween(t, b, c, d) {
        return c * t / d + b;
    }
    function easeInQuad(t, b, c, d) {
        t /= d;
        return c * t * t + b;
    }
    function easeOutQuad(t, b, c, d) {
        t /= d;
        return -c * t * (t - 2) + b;
    }
    function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) {
            return c / 2 * t * t + b;
        }
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }

    var publicServices = {
        linearTween: linearTween,
        easeInQuad: easeInQuad,
        easeOutQuad: easeOutQuad,
        easeInOutQuad: easeInOutQuad,
    };

    window.cylindo.addModule('cylindo.helpers.easing', publicServices);
}).call(this);