(function () {
    'use strict';
    function FullScreenBtn(opts) {
        var self = this;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var fullscreenMap = cylindo.getModule('cylindo.helpers.fullscreen.map').create();
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;
        this.destroy = destroy;
        this.model = opts.model || null;
        this.btn = opts.btn || null;
        this.viewerContainer = opts.viewerContainer || null;
        this.enabled = false;
        this.available = true;
        this.requestFullscreen = fullscreenMap.request;
        this.exitFullscreen = fullscreenMap.exit;
        this.fullscreenChangeEvent = (fullscreenMap.change || '').replace(/^on/, '');
        this.fullscreenElement = fullscreenMap.element;
        this.subscribeToFullScreenChangeEvt = subscribeToFullScreenChangeEvt;
        this.cancelSubscriptionToFullScreenChangeEvt = cancelSubscriptionToFullScreenChangeEvt;
        this.ariaLabel = opts.ariaLabel;
        function subscribeToFullScreenChangeEvt() {
            document.addEventListener(self.fullscreenChangeEvent, forwardChangeEvt, false);
        }
        function cancelSubscriptionToFullScreenChangeEvt() {
            document.removeEventListener(self.fullscreenChangeEvent, forwardChangeEvt, false);
        }
        function forwardChangeEvt(evt) {
            self.trigger(self.events.FULLSCREEN_CHANGED, evt);
        }
        function destroy() {
            pubsub.destroy();
            self.btn = null;
            self.trigger(self.events.DESTROYED);
        }
        self.shouldHideButton();
        self.initialized = true;
    }
    FullScreenBtn.prototype.constructor = FullScreenBtn;
    FullScreenBtn.prototype.isAvailable = function () {
        return this.available;
    };
    FullScreenBtn.prototype.isEnabled = function () {
        return this.enabled;
    };
    FullScreenBtn.prototype.setStatus = function (status) {
        this.enabled = status;
    };
    FullScreenBtn.prototype.setBtn = function (btn) {
        this.btn = btn;
    };
    FullScreenBtn.prototype.shouldHideButton = function () {
        if (this.model &&
            (this.model.get('fullscreen') !== true ||
                !(this.requestFullscreen && this.exitFullscreen))) {
            this.available = false;
            this.btn.classList.add('permanently-hidden');
        }
    };
    FullScreenBtn.prototype.toggleBtnIcon = function (isEntering) {
        if (this.btn) {
            if (isEntering) {
                this.btn.setAttribute('aria-label', this.ariaLabel.texts.EXIT_FULLSCREEN);
                this.btn.classList.remove('cylindo-icon-fullscreen-off');
                this.btn.classList.add('cylindo-icon-fullscreen-on');
            }
            else {
                this.btn.setAttribute('aria-label', this.ariaLabel.texts.ENTER_FULLSCREEN);
                this.btn.classList.remove('cylindo-icon-fullscreen-on');
                this.btn.classList.add('cylindo-icon-fullscreen-off');
            }
        }
    };
    FullScreenBtn.prototype.close = function () {
        document[this.exitFullscreen]();
    };
    FullScreenBtn.prototype.processFullScreenEvent = function (isEntering) {
        var isEnteringFullscreen = typeof isEntering === 'boolean' ?
            isEntering : document[this.fullscreenElement];
        if (this.viewerContainer &&
            this.model &&
            this.model.isCustomBackgroundColor() &&
            this.model.get('backgroundColor')) {
            this.viewerContainer.style.backgroundColor = isEnteringFullscreen ?
                '#' + this.model.get('backgroundColor') : '';
        }
        if (isEnteringFullscreen) {
            this.addFullScreenClass();
        }
        else {
            this.removeFullScreenClass();
        }
        this.toggleBtnIcon(isEnteringFullscreen);
    };
    FullScreenBtn.prototype.addFullScreenClass = function () {
        this.viewerContainer.classList.add('full-screen');
    };
    FullScreenBtn.prototype.removeFullScreenClass = function () {
        this.viewerContainer.classList.remove('full-screen');
    };
    FullScreenBtn.prototype.show = function () {
        this.btn.style.display = 'block';
    };
    FullScreenBtn.prototype.toggleFullScreen = function () {
        if (this.isEnabled()) {
            this.processFullScreenEvent(false);
            this.close();
        }
        else {
            this.subscribeToFullScreenChangeEvt();
            this.viewerContainer[this.requestFullscreen]();
        }
    };
    FullScreenBtn.prototype.events = {
        CLICK: 'fullscreen:click',
        TOUCHSTART: 'fullscreen:touchstart',
        TOUCHEND: 'fullscreen:touchend',
        FULLSCREEN_CHANGED: 'fullscreen:changed',
        DESTROYED: 'fullscreen:destroyed',
    };
    var publicAPI = {
        create: function (opts) {
            return new FullScreenBtn(opts);
        }
    };
    window.cylindo.addModule('cylindo.fullscreen.btn', publicAPI);
}.call(this));
