(function () {
    'use strict';
    var Rtv = function (options) {
        var self = this;

        var cache = cylindo.getModule('cylindo.core.cache').create();

        this.setData = setData;
        this.getData = getData;
        this.processData = processData;
        function getData(key) {
            return cache.get(key);
        }

        function setData(key, value) {
            cache.set(key, value);
        }

        function processData(productCode, featuresKey, productData) {
            try {
                setData(productCode + '-' + featuresKey, productData);
            }
            catch (ex) {
                self.logger.error('Cannot store the features.', ex);
            }
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new Rtv(opts);
        }
    };

    window.cylindo.addModule('cylindo.classes.database.rtv', publicAPI);
}).call(this);
