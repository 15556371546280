(function () {
    'use strict';
    var functionHelper = cylindo.getModule('cylindo.helpers.function');
    var AbstractLayer = function (opts) {
        opts = opts || {};
        var layerTemplate = functionHelper.template('<div><% text %></div>');
        var util = cylindo.getModule('cylindo.core.util');
        var dom = opts.dom;
        this.util = util;
        this.dom = dom;
        this.text = opts.text || '';
        this.el = null;
        this.parentEl = opts.parent || document.getElementsByTagName('body')[0];
        this.role = opts.role || '';
        this.setTemplate = function (newTemplate) {
            layerTemplate = functionHelper.template(newTemplate);
        };
        this.getTemplate = function (data) {
            data = data || {};
            return layerTemplate(data);
        };
    };

    AbstractLayer.prototype.render = function (data) {
        var self = this;
        var i, template;
        data = data || {};
        data = this.util.object.extend({}, data, {
            text: this.text
        });
        template = this.getTemplate(data);
        this.el = document.createElement('div');
        if (this.wrapperClasses && this.wrapperClasses instanceof Array) {
            this.wrapperClasses.forEach(function (wrapperClass) {
                self.el.classList.add(wrapperClass);
            });
        }
        this.el.innerHTML = template;
        this.el.setAttribute('role', self.role);
        this.el.setAttribute('aria-label', self.text);
        this.parentEl.appendChild(this.el);
    };
    AbstractLayer.prototype.remove = function () {
        this.dom.remove(this.el);
    };
    AbstractLayer.prototype.isInDOM = function () {
        return this.parentEl && this.el && this.parentEl.contains(this.el);
    };
    AbstractLayer.prototype.show = function (text, immediate) {
        if (immediate) {
            this.dom.fadeIn(this.el, 0);
        }
        else {
            this.dom.fadeIn(this.el);
        }
        this.changeText(text);
    };
    AbstractLayer.prototype.changeText = function (text) {
        var template = this.getTemplate({ text: text });
        if (text) {
            this.el.setAttribute('aria-label', this.text);
            this.text = text;
            this.el.innerHTML = template;
        }
    };
    AbstractLayer.prototype.hide = function (immediate) {
        if (immediate) {
            this.dom.fadeOut(this.el, 0);
        }
        else {
            this.dom.fadeOut(this.el);
        }
    };
    AbstractLayer.prototype.getText = function () {
        return this.text;
    };
    AbstractLayer.prototype.setText = function (text) {
        this.text = text || '';
    };

    AbstractLayer.prototype.setParent = function (element) {
        this.parentEl = element;
    };

     AbstractLayer.prototype.isVisible = function () {
        return this.el.style.display === 'block';
    };

     AbstractLayer.prototype.destroy = function () {
        var self = this;
        if (self.isInDOM() && self.parentEl && self.el) {
            self.remove();    
        }
        self.layerTemplate = null;
        self.text = null;
        self.el = null;
        self.parentEl = null;
    };

    window.cylindo.addModule('cylindo.classes.layer', AbstractLayer);
}.call(this));
