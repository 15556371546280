
(function () {
    'use strict';

    var publicServices = {
        getDefaults: getDefaults,
        getBooleans: getBooleans,
        getArrays: getArrays,
        getNotCfgAtRuntime: getNotCfgAtRuntime,
        getAllowedPresentations: getAllowedPresentations,
        getThumbLocations: getThumbLocations,
    };

    function getDefaults() {
        var network = cylindo.getModule('cylindo.core.network');
        var settings = cylindo.getModule('cylindo.core.config');
        var util = cylindo.getModule('cylindo.core.util');
        var thumbLocations = getThumbLocations();
        var screenWidth = screen && screen.width ? screen.width : null;

        return {
            accountID: null,
            alternateContent: null,
            alternateContentZoom: true,
            ARBtnTxt: 'View in your space',
            ARLoadingTxt: 'Loading',
            arMode: '',
            ARNotAvailableTxt: 'Unfortunately, your mobile<br /> device does not support<br /> this feature.',
            ARDesktop: false,
            ARQuickLook: true,
            ARBanner: 'none',
            ARBannerConfig: null,
            ARBackToViewTxt: 'Back to 360 view',
            backgroundColor: 'FFFFFF',
            buildNumber: null,
            BUSAPIImage: '',
            BUSAPIFrame: '01',
            BUSAPIExtension: 'jpg',
            containerID: null,
            country: 'us', 
            carouselSlideSpeed: 32, 
            customZoomContainer: null,
            cylindoContent: true,
            debug: false,
            drawImageBackground: true,
            defaultFallbackImage: network.resolveProtocol(settings.get('urls').DEFAULT_FALLBACK_IMAGE),
            environmentZoom: false,
            fallbackImage: network.resolveProtocol(settings.get('urls').DEFAULT_FALLBACK_IMAGE),
            fallbackVideoImage: network.resolveProtocol(settings.get('urls').DEFAULT_FALLBACK_VIDEO_IMAGE),
            features: null,
            fileName: null,
            focusOnFeatureChange: false,
            format: 'JPG',
            frameCount: 32,
            frames: null,
            framesSetOnInit: false,
            fullRTVData: null,
            fullscreen: true,
            gaEvents: true,
            getArRedirectUrl: null,
            gridSize: 1024, 
            hasUnsupportedFeatures: false,
            height: 512,
            ignoreCache: settings.get('defaults').ignoreCache,
            ignoreRTVCache: 0,
            imageResolution: null,
            imageTimeout: 0,
            keepAliveInterval: 60000,
            loggerLimit: settings.get('defaults').loggerLimit,
            maxZoom: (util.browser.isMobile() && screenWidth && screenWidth < 768) ? '2k' : '4k',
            missingCombinationErrorText: 'Sorry, we do not have images for the requested feature set.',
            mobileZoomDelay: 250,
            outlineOnFocus: false,
            overrideTracking: false, 
            partialRotationStart: 50,
            presentation: 'threesixty',
            productCode: null,
            progressBar: true,
            proxy: '',
            QRCodeTitleTxt: 'View this product<br /> in your space',
            QRCodeTxt: 'Point your smartphone or tablet\'s<br /> camera at the QR code below.',
            removeEnvironmentShadow: false,
            rotationSpeed: 1, 
            size: null,
            SKU: null,
            showGrid: false, 
            showGridCoordinates: false, 
            speedMultiplier: 1.2,
            startFrame: 1,
            thumbAngles: null,
            thumbCount: 5,
            thumbLocation: thumbLocations.BOTTOM,
            thumbPageSize: 5,
            thumbs: true,
            title: '360 HD Product Viewer',
            tooltipAltImgText: util.browser.isMobile() ? 'Use Button to Zoom' : 'Click to Zoom', 
            tooltipCarouselText: util.browser.isMobile() ?
                'Use Button to Zoom' : 'Click to Zoom', 
            tooltipDragText: util.browser.isMobile() ?
                'Drag to Rotate. Use Button to Zoom' : 'Drag to Rotate. Click to Zoom', 
            tooltipDragTextFixed: false,
            tooltipNoZoomText: 'Could not zoom in on the image.',
            tooltipStackedPosition: 1, 
            tooltipStackedText: util.browser.isMobile() ? 'Use Button to Zoom' : 'Click to Zoom',
            tooltipZoomText: util.browser.isMobile() ? 'Drag to Pan' : 'Move the Mouse to Pan', 
            tooltipZoomTextFixed: false,
            version: null, 
            viewerType: 2, 
            width: 512,
            zoom: true,
            zoomBackgroundColor: 'FFFFFF',
            zoomButton: true,
            zoomMargin: 256,
            zoomMode: 'mouseMove', 
            zoomOverlapsThumbs: true,
        };
    }

   function getBooleans() {
        return [
            'ARQuickLookBtn',
            'cylindoContent',
            'debug',
            'drawImageBackground',
            'environmentZoom',
            'focusOnFeatureChange',
            'fullscreen',
            'gaEvents',
            'progressBar',
            'outlineOnFocus',
            'overrideTracking',
            'removeEnvironmentShadow',
            'showGrid',
            'showGridCoordinates',
            'thumbs',
            'tooltipDragTextFixed',
            'zoom',
            'zoomButton',
            'zoomOverlapsThumbs',
        ];
    }
   function getArrays() {
        return [
            'alternateContent',
            'features',
            'frames',
            'thumbAngles',
        ];
    }
    function getNotCfgAtRuntime() {
        return [
            'accountID',
            'environmentZoom',
            'alternateContentZoom',
            'format',
            'ARDesktop',
            'ARQuickLook',
            'zoomOverlapsThumbs',
            'thumbs',
            'thumbLocation',
            'imageResolution',
            'size',
            'proxy',
        ];
    }

   function getAllowedPresentations() {
        return [
            'threesixty',
            'single',
            'carousel',
            'stacked',
        ];
    }

    function getThumbLocations() {
        return {
            TOP: 'top',
            BOTTOM: 'bottom',
            LEFT: 'left',
            RIGHT: 'right',
        };
    }

    window.cylindo.addModule('cylindo.model.values', publicServices);
}).call(this);
