(function () {
    'use strict';

    var ViewerFactory = function (opts) {
        var self = this;
        var instance = null;
        var presentation = null;
        var list_class = null;
        var util = cylindo.getModule('cylindo.core.util');
        var viewerPresentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
        var threesixty = cylindo.getModule('threesixty');
        var carouselViewer = cylindo.getModule('carouselViewer');
        var singleViewer = cylindo.getModule('singleViewer');
        var customViewer = cylindo.getModule('customViewer');
        var stackedViewer = cylindo.getModule('stackedViewer');
        var instanceCreator = null;
        var framesHandler = opts.framesHandler;
        var allFrames = framesHandler.getAllFramesToBeLoaded();
        var model = opts.model;
        var logger = opts.logger;
        var options = opts;
        var totalOfFrames = allFrames.THREESIXTY.TO_BE_LOADED;
        var totalAltContent = allFrames.ALTERNATE.LEN;
        this.createViewerInstance = createViewerInstance;
        this.getInstanceDetails = getInstanceDetails;
        this.prepareViewerCreator =prepareViewerCreator;
        this.initialized = true;
        function createViewerInstance() {
            try {
                if (instanceCreator) {
                    instance = instanceCreator.create(options);
                }                
            }
            catch (ex) {
                logger.error('An error happened while trying to create the viewer instance.', ex);
            }
            finally {
                return getInstanceDetails.call();
            }
        }
        function prepareViewerCreator() {
            var preventChangeEvent = true;
            presentation = model.get('presentation');
            if (presentation === viewerPresentations.THREESIXTY.NAME && totalOfFrames > 1) {
                list_class = viewerPresentations.THREESIXTY.LIST_CLASS;
                model.set('presentation', 'threesixty', preventChangeEvent);
                options.presentation = presentation;
                instanceCreator = threesixty;
                logger.log('Threesixty viewer instance prepared to be created.', options);
            }
            else if (totalOfFrames > 0 &&
                (presentation === viewerPresentations.SINGLE.NAME ||
                (presentation === viewerPresentations.THREESIXTY.NAME && totalOfFrames === 1))) {
                list_class = viewerPresentations.SINGLE.LIST_CLASS;
                model.set('presentation', 'single', preventChangeEvent);
                tryToModifyDefault360Tooltip();
                presentation = viewerPresentations.SINGLE.NAME;
                options.presentation = presentation;
                instanceCreator = singleViewer;
                logger.log('Single viewer instance prepared to be created.', options);
            }
            else if (presentation === viewerPresentations.CAROUSEL.NAME &&
                (totalOfFrames + totalAltContent) > 0) {
                list_class = viewerPresentations.CAROUSEL.LIST_CLASS;
                options.presentation = presentation;
                instanceCreator = carouselViewer;
                logger.log('Carousel viewer instance prepared to be created.', options);
            }
            else if (presentation === viewerPresentations.STACKED.NAME &&
                (totalOfFrames + totalAltContent) > 0) {
                list_class = viewerPresentations.STACKED.LIST_CLASS;
                options.presentation = presentation;
                instanceCreator = stackedViewer;
                logger.log('Stacked viewer instance prepared to be created.', options);
            }
            else if (totalAltContent) {
                list_class = viewerPresentations.CUSTOM.LIST_CLASS;
                model.set('presentation', 'custom', preventChangeEvent);
                presentation = viewerPresentations.CUSTOM.NAME;
                options.presentation = presentation;
                instanceCreator = customViewer;
                logger.log('Custom viewer instance prepared to be created.', options);
            }
            else {
                logger.error('Cannot create instance with the provided parameters', options);
            }
        }

        function getInstanceDetails() {
            return {
                instance: instance,
                presentation: presentation,
                list_class: list_class
            };
        }

        function tryToModifyDefault360Tooltip() {
            var tooltipDragTextFixed = model.get('tooltipDragTextFixed');
            var preventChangeEvent = true;
            if (tooltipDragTextFixed !== true) {
                if (util.browser.isMobile()) {
                    model.set('tooltipDragText', 'Use Button to Zoom', preventChangeEvent);
                }
                else {
                    model.set('tooltipDragText', 'Click to Zoom', preventChangeEvent);
                }
            }
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new ViewerFactory(opts);
        }
    };

    window.cylindo.addModule('cylindo.classes.viewer.factory', publicAPI);
}.call(this));
