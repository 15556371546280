(function () {
   'use strict';
   function degreesToIndexes(degrees, frameCount) {
      var constant = 360 / frameCount;
      var indexes = [];
      var len = degrees.length;
      var i;

      for (i = 0; i < len; ++i) {
         if (typeof degrees[i] === 'string') {
            try {
               degrees[i] = parseInt(degrees[i], 10);
            }
            catch (e) {
               continue;
            }
         }
         if (degrees[i] !== null) {
            degrees[i] = degrees[i] % 360;
            if (degrees[i] < 0) {
               degrees[i] = 360 + degrees[i];
            }
            indexes.push(((Math.round(degrees[i] / constant)) % frameCount) + 1);
         }
      }

      return indexes;
   }
   var publicAPI = {
      degreesToIndexes: degreesToIndexes
   };
   window.cylindo.addModule('cylindo.util.angles', publicAPI);
}).call(this);