(function () {
    'use strict';
    var AbstractButton = cylindo.getModule('cylindo.classes.button');
    function QrBtn(opts) {
        opts.appendButton = false;
        AbstractButton.call(this, opts);
        var network = cylindo.getModule('cylindo.core.network');
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var paths = opts.settings.get('urls');
        var endPoint = paths.AR;
        var util = opts.util;

        this.util = util;
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;
        this.src = null;
        this.qrSrc = null;
        this.network = network;
        this.settings = opts.settings;
        this.wrapperClasses = ['cylindo-button', 'cylindo-ar-button'];
        this.model = opts.model;
        this.logger = this.model.getLogger();
        this.currentFeatures = this.model.get('currentFeatures');
        this.urls = this.currentFeatures ? this.currentFeatures.paths : null;
        this.tags = opts.tags;
        this.preventEvents = opts.preventEvents;
        this.endPoint = endPoint;
        this.initialized = false;
        this.btnExistsPromise = this.util.promise.create();
        this.destroy = destroy;
        this.btnImg = null;
        this.btnIcon = null;
        this.btnTxt = null;
        this.btnContainer = null;
        function destroy() {
            pubsub.destroy();
            self.btn = null;
        }
    }
    QrBtn.prototype = Object.create(AbstractButton.prototype);
    QrBtn.prototype.constructor = QrBtn;
    QrBtn.prototype.render = function () {
        var self = this;
        var image = new Image();
        var arBtnContainer = document.createElement('div');
        var arBtnIcon = document.createElement('div');
        var arBtnText = document.createElement('div');
        arBtnText.innerHTML = self.model.get('ARBtnTxt');
        arBtnContainer.classList.add('ar-glyph-background');
        arBtnIcon.classList.add('ar-icon');
        arBtnText.classList.add('ar-text');
        arBtnContainer.appendChild(arBtnIcon);
        arBtnContainer.appendChild(arBtnText);
        AbstractButton.prototype.render.call(self);
        image.src = '';
        self.btnImg = image;
        self.btnIcon = arBtnIcon;
        self.btnTxt = arBtnText;
        self.btnContainer = arBtnContainer;
        self.btn.appendChild(image);
        self.btn.appendChild(arBtnContainer);
        self.btn.setAttribute('rel', 'ar');
        self.btn.setAttribute('href', '#');
        self.btn.setAttribute('role', 'link');
        self.btn.setAttribute('aria-label', self.ariaLabel.texts.VIEW_IN_AR);
        self.btn.addEventListener('click', preventViewerEvents, true);
        self.btn.addEventListener('mousedown', preventViewerEvents, true);
        self.btn.addEventListener('mouseup', openARModal, true);
        self.btn.addEventListener('touchstart', preventViewerEvents, true);
        self.btn.addEventListener('touchend', openARModal, true);
        self.tryToShow();
        function preventViewerEvents(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();
                self.preventEvents();
            }
        }
        function openARModal(evt) {
            preventViewerEvents(evt);
            self.trigger(self.events.AR_SHOW_MODAL, null);
            self.tags.send(self.tags.events.AR_CLICKED);
            self.trigger(self.events.AR_LAUNCHED, null);
        }
        self.initialized = true;
    };
    QrBtn.prototype.tryToShow = function () {
        var self = this;
        var qrSrc;
        var usdzSrc;
        var glbSrc;
        var isArEnabled;
        var arErrors = {
            AR_NOT_ENABLED: 'AR not enabled for this product or version.',
            WRONG_PATHS: 'AR paths not found.',
        };
        var error = '';
        self.currentFeatures = self.model.get('currentFeatures');
        isArEnabled = self.currentFeatures && self.currentFeatures.data ? self.currentFeatures.data.isArEnabled : false;
        self.urls = self.currentFeatures ? self.currentFeatures.paths : null;
        self.hide();
        if (self.model.isFirstSetOfFt() && self.model.didFirstFeaturesFailed()) {
            self.logger.error('First group of features failed, AR won\'t be displayed.');
            return;
        }
        if (!isArEnabled || !self.urls || !self.urls.arPath) {
            error = isArEnabled ? arErrors.WRONG_PATHS : arErrors.AR_NOT_ENABLED;
            self.trigger(self.events.AR_BUTTON_ERROR, {});
            self.logger.error('Cannot create 3d file for this product.', error);
            return;
        }
        usdzSrc = self.urls.arPath.replace('__AR_FORMAT__', 'usdz');
        glbSrc = self.urls.arPath.replace('__AR_FORMAT__', 'glb');
        usdzSrc =  usdzSrc.replace('__AR_FILE__', 'AR');
        glbSrc =  glbSrc.replace('__AR_FILE__', 'AR');
        qrSrc = self.urls.qrPath.replace('__AR_FILE__', self.model.get('fileName') || self.model.get('productCode'));

        self.el.innerHTML = '';
        self.network.doRequest(usdzSrc, function (responsedata, xhr) {
            self.logger.log('USDZ file found for this combination. ', usdzSrc);
            arSuccess(usdzSrc);
        }, {
            type: 'HEAD',
        }, function (response, xhr) {
            self.network.doRequest(glbSrc, function (response, xhr) {
                self.logger.log('GLB file found for this combination. ', glbSrc);
                arSuccess(glbSrc);
            }, { type: 'HEAD' }, arFailed, null);
        },
        null
        );
        function arSuccess(src) {
            var _ARBannerConfig = self.model.get('_ARBannerConfig') || {};
            var ARBannerSupportedKeys = {
                IOS: 'ios',
                ANDROID: 'android',
                BOTH: 'ios-android'
            };
            var ARBannerSupportedValues = [
                ARBannerSupportedKeys.IOS,
                ARBannerSupportedKeys.ANDROID,
                ARBannerSupportedKeys.BOTH
            ];    
            var arBanner = ARBannerSupportedValues.indexOf(self.model.get('ARBanner')) !== -1 ? self.model.get('ARBanner') : null;
            var property;
            var properties = [];
            var props  = {
                ACTION: 'action',
                QR_ACTION: 'qrAction',
                BANNER: 'banner'
            };
            _ARBannerConfig[props.ACTION] = _ARBannerConfig[props.QR_ACTION];
            _ARBannerConfig[props.BANNER] = arBanner;
            for (property in _ARBannerConfig) {
                if (typeof _ARBannerConfig[property] === 'string') {
                    properties.push(property + '=' + encodeURIComponent(_ARBannerConfig[property]));
                }
            }
            if (properties.length > 0) {
                qrSrc = qrSrc + (qrSrc.indexOf('?') !== -1 ? '&' : '?') + properties.join('&');
            }
            self.src = src;
            self.qrSrc = qrSrc;
            self.btn.setAttribute('href', self.src);
            if (self.util.browser.isIE()) {
                self.btn.innerHTML = '';
                self.btnTxt.innerHTML = self.model.get('ARBtnTxt');
                self.btn.appendChild(self.btnImg);
                self.btnContainer.appendChild(self.btnIcon);
                self.btnContainer.appendChild(self.btnTxt);
                self.btn.appendChild(self.btnContainer);
            }
            self.el.appendChild(self.btn);
            self.show();
            self.trigger(self.events.AR_BUTTON_READY, {
                url: self.src,
                qr: qrSrc,
            });
            self.btnExistsPromise.resolve({
                ar: self.src,
                qr: qrSrc,
            });
        }
        function arFailed() {
            var err = 'USDZ not found for the requested feature set.';
            self.src = null;
            self.qrSrc = null;
            self.trigger(self.events.AR_BUTTON_ERROR, {});
            self.logger.error(err);
            self.btnExistsPromise.reject({
                err: err,
            });
        }
    };
    QrBtn.prototype.onFeaturesError = function () {
        var self = this;
        self.src = null;
        self.qrSrc = null;
        self.hide();
        self.trigger(self.events.AR_BUTTON_ERROR, {});
    };

    QrBtn.prototype.getArQuickLookUrl = function () {
        return this.src || null;
    };

    QrBtn.prototype.events = {
        AR_BUTTON_READY: 'ar:button:ready',
        AR_BUTTON_ERROR: 'ar:button:error',
        AR_LAUNCHED: 'ar:launched',
        AR_SHOW_MODAL: 'ar:show:modal',
        AR_HIDE_MODAL: 'ar:hide:modal',
        AR_SHOW_ERROR_MODAL: 'ar:show:error:modal',
    };
    var publicAPI = {
        create: function (opts) {
            return new QrBtn(opts);
        }
    };
    window.cylindo.addModule('cylindo.button.ar.qr', publicAPI);
}.call(this));
