
(function () {
    'use strict';

    var PubSub = function () {
        var self = this;
        var listeners = {};

        this.trigger = function (evtName, data) {
            var i, len;
            if (!listeners[evtName]) {
                return false;
            }
            len = listeners[evtName].length;
            for (i = 0; i < len; i++) {
                defer(listeners[evtName][i], evtName, data);
            }

            function defer(fn, evtName, data) {
                setTimeout(function () { 
                    fn(evtName, data);
                }, 0);
            }
        };

        this.on = function (evtName, callback) {
            if (!listeners[evtName]) {
                listeners[evtName] = [];
            }
            listeners[evtName].push(callback);
            return this;
        };

        this.once = function (evtName, callback) {
            var cbWrapper = generateWrapper(evtName, callback);
            this.on(evtName, cbWrapper);
            return this;
        };

        this.off = function (evtName, callback) {
            var cbWrapper = generateWrapper(evtName, callback);
            var listenersArrLen = listeners[evtName] ? listeners[evtName].length : 0;
            var i;

             for (i = 0; i < listenersArrLen; i++) {
                if (listeners[evtName][i] === callback || listeners[evtName][i].toString() === cbWrapper.toString()) {
                    listeners[evtName].splice(i, 1);
                    listenersArrLen--;
                    i--;
                }
            }

            return this;
        };

        this.destroy = function () {
            listeners = {};
        };

        function generateWrapper(evtName, callback) {
            function cbWrapper(_evt, _data) {
                callback(_evt, _data); 
                var listenersArrLen = listeners &&
                listeners[evtName] &&
                listeners[evtName].length > 0 ?
                listeners[evtName].length : 0; 
                var i;

                for (i = 0; i < listenersArrLen; i++) {
                    if (listeners[evtName][i] === cbWrapper) {
                        listeners[evtName].splice(i, 1);
                        listenersArrLen--;
                        i--;    
                    }
                }
            }
            return cbWrapper;
        }
    };

    var publicAPI = {
        create: function () {
            return new PubSub();
        }
    };

    window.cylindo.addModule('cylindo.util.pubsub', publicAPI);
}).call(this);
