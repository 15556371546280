(function () {
    'use strict';

    var Dom = function () {
        var self = this;
        var util = cylindo.getModule('cylindo.core.util');
        this.getRect = getRect;
        this.position = position;
        this.offset = offset;
        this.siblings = siblings;
        this.remove = remove;
        this.insertAfter = insertAfter;
        this.insertBefore = insertBefore;
        this.querySelectorAll = querySelectorAll;
        this.getSupportedPropertyName = getSupportedPropertyName;
        this.fadeIn = fadeIn;
        this.fadeOut = fadeOut;
        this.scrollTo = scrollTo;
        this.currentAnimations = [];
        this.animations = {
            FADEIN: 'fadeIn',
            FADEOUT: 'fadeOut'
        };
        this.animationId = 0;
        this.height = height;
        function animate(elem, style, unit, from, to, time, prop) {
            var start = new Date().getTime();
            var ticker;
            if (!elem) {
                return;
            }
            ticker = setInterval(function () {
                var step = Math.min(1, (new Date().getTime() - start) / time);
                if (prop) {
                    elem[style] = (from + step * (to - from)) + unit;
                }
                else {
                    elem.style[style] = (from + step * (to - from)) + unit;
                }
                if (step >= 1) {
                    clearInterval(ticker);
                }
            }, 17);
            if (prop) {
                elem[style] = from + unit;
            } else {
                elem.style[style] = from + unit;
            }
        }
        function scrollTo(target) {
            var to = offset(target).top;
            var from = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            animate(document.documentElement || document.scrollingElement, 'scrollTop', '', from, to, 1000, true);
        }
        function getSupportedPropertyName(properties) {
            var i = 0;
            var propsLen;
            if (properties instanceof Array) {
                propsLen = properties.length;
                for (i; i < propsLen; i++) {
                    if (typeof document.body.style[properties[i]] != 'undefined') {
                        return properties[i];
                    }
                }
            }
            return null;
        }
        function isAnimated(animation) {
            var i;
            for (i = 0; i < self.currentAnimations.length; i++) {
                if (self.currentAnimations[i].element === animation.element &&
                    self.currentAnimations[i].id !== animation.id) {
                    return self.currentAnimations[i];
                }
            }
            return false;
        }
        function stopAnimation(animation) {
            clearInterval(animation.ticker);
            if (animation.callbacks && animation.callbacks instanceof Array) {
                animation.callbacks.forEach(function (callback) {
                    if (util.object.isFunction(callback)) {
                        callback();
                    }
                });
            }
            removeFromArray(animation);
        }
        function removeFromArray(animation) {
            if (self.currentAnimations.indexOf(animation) !== -1) {
                self.currentAnimations.splice(self.currentAnimations.indexOf(animation), 1);
            }
        }
        function prepareToAnimate(earlierAnimation, toAvoid, toStop, milliseconds, callback) {
            if ((earlierAnimation && earlierAnimation.type === toStop) || milliseconds === 0) {
                if (earlierAnimation) {
                    stopAnimation(earlierAnimation);
                }
                return true;
            }
            else if (earlierAnimation && earlierAnimation.type === toAvoid) {
                if (callback) {
                    earlierAnimation.callbacks.push(callback);
                }
                return false;

            }
        }
        function fadeIn(element, milliseconds, callback) {
            var ticker, earlierAnimation;
            var opacity = 0.1;  
            var delay = milliseconds && milliseconds > 0 ? milliseconds / 100 : 10;
            var callbacks = callback &&
                typeof callback === 'function' ?
                [callback] : [];
            var shouldContinue;
            var id = self.animationId++;
            var currentAnimation = {
                id: id,
                element: element,
                type: self.animations.FADEIN,
                callbacks: callbacks
            };
            if (!element) {
                return;
            }
            self.currentAnimations.push(currentAnimation);
            earlierAnimation = isAnimated(currentAnimation);
            if (earlierAnimation || milliseconds === 0) {
                shouldContinue = prepareToAnimate(earlierAnimation, self.animations.FADEIN, self.animations.FADEOUT, milliseconds, callback);
                if (!shouldContinue) {
                    removeFromArray(currentAnimation);
                    return;
                }
                else if (milliseconds === 0) {
                    removeFromArray(currentAnimation);
                    element.style.opacity = 1;
                    element.style.display = 'block';
                    element.style.filter = 'alpha(opacity=100)';
                    if (callback && util.object.isFunction()) {
                        callback();
                    }
                    return;
                }
            }
            element.style.opacity = 0;
            element.style.filter = 'alpha(opacity=0)';
            element.style.display = 'block';
            ticker = setInterval(function () {
                if (opacity >= 1 || element.style.display === 'none') {
                    element.style.opacity = '';
                    element.style.filter = '';
                    stopAnimation(currentAnimation);
                }
                element.style.opacity = opacity;
                element.style.filter = 'alpha(opacity=' + opacity * 100 + ')';
                opacity += opacity * 0.1;
            }, delay);
            currentAnimation.ticker = ticker;
        }
        function fadeOut(element, milliseconds, callback) {
            var ticker, earlierAnimation;
            var opacity = 1;  
            var delay = milliseconds && milliseconds > 0 ? milliseconds / 100 : 8;
            var callbacks = callback &&
                typeof callback === 'function' ?
                [callback] : [];
            var shouldContinue;
            var id = self.animationId++;
            var currentAnimation = {
                id: id,
                element: element,
                type: self.animations.FADEOUT,
                callbacks: callbacks
            };
            if (!element) {
                return;
            }
            self.currentAnimations.push(currentAnimation);
            earlierAnimation = isAnimated(currentAnimation);
            if (earlierAnimation || milliseconds === 0) {
                shouldContinue = prepareToAnimate(earlierAnimation, self.animations.FADEOUT, self.animations.FADEIN, milliseconds, callback);
                if (!shouldContinue) {
                    removeFromArray(currentAnimation);
                    return;
                }
                else if (milliseconds === 0) {
                    removeFromArray(currentAnimation);
                    element.style.opacity = 0;
                    element.style.display = 'none';
                    element.style.filter = 'alpha(opacity=0)';
                    if (callback && util.object.isFunction(callback)) {
                        callback();
                    }
                    return;
                }
            }
            ticker = setInterval(function () {
                if (opacity <= 0.1) {
                    element.style.display = 'none';
                    stopAnimation(currentAnimation);
                }
                element.style.opacity = opacity;
                element.style.filter = 'alpha(opacity=' + opacity * 100 + ')';
                opacity -= opacity * 0.1;
            }, delay);
            currentAnimation.ticker = ticker;
        }
        function insertAfter(newNode, referenceNode) {
            if (referenceNode.nextSibling) {
                referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
            }
            else {
                referenceNode.parentNode.appendChild(newNode);
            }
        }
        function insertBefore(newNode, referenceNode) {
            referenceNode.parentNode.insertBefore(newNode, referenceNode);
        }
        function siblings(element, selector) {
            var siblings = [];
            var sibling = element.parentNode.firstChild;
            if (element) {
                for (; sibling; sibling = sibling.nextSibling) {
                    if (sibling.nodeType !== 1 || sibling === element) {
                        continue;
                    }
                    siblings.push(sibling);
                }
                if (typeof selector === 'string' && selector.length) {
                    return siblings.filter(function (element) {
                        return element.id === selector || element.classList.contains(selector);
                    });
                }
                return siblings;
            }
        }
        function remove(elements) {
            if (elements) {
                if (elements instanceof Array) {
                    elements.forEach(function (el) {
                        removeElement(el);
                    });
                }
                else {
                    removeElement(elements);
                }
            }
            function removeElement(el) {
                if (el.parentNode !== null) {
                    el.parentNode.removeChild(el);
                }
            }
        }
        function querySelectorAll(element, query) {
            if (element) {
                return Array.prototype.slice.call(element.querySelectorAll(query));
            }
        }
        function getRect(element) {
            return element.getBoundingClientRect();
        }
        function offset(element) {
            var rect = element.getBoundingClientRect();
            var win = element.ownerDocument.defaultView;
            return {
                top: rect.top + win.pageYOffset,
                left: rect.left + win.pageXOffset
            };
        }
        function position(element) {
            var offsetParent, offsetEl, doc;
            var parentOffset = { top: 0, left: 0 };
            var elem;
            if (!element) {
                return;
            }
            if (element.style.position === 'fixed') {
                offsetEl = element.getBoundingClientRect();
            }
            else {
                offsetEl = offset(element);
                doc = element.ownerDocument;
                offsetParent = element.offsetParent || doc.documentElement;
                while (offsetParent &&
                    (offsetParent === doc.body || offsetParent === doc.documentElement) &&
                    offsetParent.style.position === 'static') {
                    offsetParent = offsetParent.parentNode;
                }
                if (offsetParent && offsetParent !== element && offsetParent.nodeType === 1) {
                    parentOffset = offset(offsetParent);
                    parentOffset.top += offsetParent.style.borderTopWidth;
                    parentOffset.left += offsetParent.style.borderLeftWidth;
                }
            }
            return {
                top: offsetEl.top - parentOffset.top - element.style.marginTop,
                left: offsetEl.left - parentOffset.left - element.style.marginLeft
            };
        }
        function height(el) {
            var elStyle, currInVal;
            var heightProp = 'height';
            var elClientRectsLen = el && el.getClientRects ?
                el.getClientRects().length : null;
            var elRect = el ? el.getBoundingClientRect() : null;
            var height = elRect ? elRect.height : null;
            var tmpInVal = {
                position:'absolute',
                visibility: 'hidden',
                display: 'block'
            };
            var swap = false;
            if (height === 0) {
                if (!elRect.width || 
                    (elClientRectsLen !== null && !elClientRectsLen)) {
                    swap = true;
                    currInVal = {
                        position: el.style.position,
                        visibility: el.style.visibility,
                        display: el.style.display,
                    };
                    el.style.position = tmpInVal.position;
                    el.style.visibility = tmpInVal.visibility;
                    el.style.display = tmpInVal.display;
                }
                elStyle = el && window && window.getComputedStyle ? window.getComputedStyle(el) : null;
                height = elStyle && elStyle[heightProp] ? parseFloat(elStyle[heightProp]) : 0;
                height = isNaN(height) ? 0 : height;
                if (swap) {
                    el.style.position = currInVal.position;
                    el.style.visibility = currInVal.visibility;
                    el.style.display = currInVal.display;
                }
            }
            return height;
        }
    };
    var publicAPI = {
        create: function () {
            return new Dom();
        }
    };
    window.cylindo.addModule('cylindo.dom', publicAPI);

}).call(this);