(function () {
    'use strict';

    var moduleKey = 'cylindo.core.util';
    var util = window.cylindo.getModule(moduleKey) || {};
    var network = window.cylindo.getModule('cylindo.core.network');

    util.picture = {
        getPaths: getPaths,
    };

    window.cylindo.addModule(moduleKey, util);

    function getPaths(settings, params) {
        var ignoreCache = typeof params.ignoreCache === 'boolean' ? params.ignoreCache : settings.defaults.ignoreCache;
        var timestamp = Date.now();
        var resolveUrlParams = {};
        var urlParams = {};
        var arParams = {};
        var qrParams = {
            campaign: 'desktop',
            redirect_url: typeof params.getArRedirectUrl === 'function' ? encodeURIComponent(decodeURIComponent(params.getArRedirectUrl())) : encodeURIComponent(window.location.href),
        };
        var paths = {
            framePath: '',
            thumbPath: '',
            zoomPath: '',
            arPath: '',
        };
        var resolvedFtUrl = '';
        var additionalDataUrl = '';
        var mainUrl = '';
        var mainArUrl = '';
        var mainQrArUrl = '';
        var content_api_v2 = params && params.proxy && params.proxy.length > 0 ?
            params.proxy :
            settings && settings && settings.urls.SERVICES_BASE ?
                settings.urls.SERVICES_BASE : '';
        var fileName = params.fileName || params.productCode;
        var format = params.format || 'JPG';
        var features = params.ftToBeShown instanceof Array ? params.ftToBeShown : [];
        var featuresLen = features.length;
        var featuresToBeShown = [];
        var i;
        var contetAPIUrl;
        var protocols = {
            HTTPS: 'https:',
            HTTP: 'http:',
        };
        var productCodeEncoded = encodeURIComponent(params.productCode);
        if (features instanceof Array && content_api_v2 && content_api_v2.length > 0) {
            content_api_v2 = network.resolveProtocol(content_api_v2) + '/api/v2';
            for (i = 0; i < featuresLen; i += 2) {
                featuresToBeShown.push(encodeURIComponent(String(features[i])) + ':' + encodeURIComponent(String(features[i + 1])));
            }
            contetAPIUrl = params.hasOwnProperty('ssl') ?
                params.ssl ? protocols.HTTPS + content_api_v2.replace(/^(https?:|)/, '') : protocols.HTTP + content_api_v2.replace(/^(https?:|)/, '') :
                content_api_v2;
            mainUrl = contetAPIUrl + '/' +
                params.accountID + '/products/' +
                productCodeEncoded + '/frames/__FRAME_INDEX__/' +
                fileName + '.' + format;
            additionalDataUrl = contetAPIUrl + '/' +
                params.accountID + '/products/' +
                productCodeEncoded + '/additionaldata';

            resolvedFtUrl = contetAPIUrl + '/' +
                params.accountID + '/products/' +
                productCodeEncoded + '/resolvefeatures';

            mainArUrl = contetAPIUrl + '/' +
                params.accountID + '/products/' +
                productCodeEncoded + '/AR/' +
                '__AR_FILE__' + '.__AR_FORMAT__';
            mainQrArUrl = contetAPIUrl + '/' +
                params.accountID + '/products/' +
                productCodeEncoded + '/ARQR/' +
                '__AR_FILE__';
            if (ignoreCache) {
                urlParams.directives = 'ignore-cache';
                arParams.directives = 'ignore-cache';
                qrParams.directives = 'ignore-cache';
                urlParams._ = timestamp;
                arParams._ = timestamp;
                qrParams._ = timestamp;
            }
            if (params.version) {
                urlParams.version = params.version;
                arParams.version = params.version;
                qrParams.version = params.version;
            }
            if (params.backgroundColor) {
                urlParams.background = String(params.backgroundColor).toUpperCase();
            }
            if (params.buildNumber) {
                urlParams.bn = params.buildNumber;
                arParams.bn = params.buildNumber;
                qrParams.bn = params.buildNumber;
            }
            if (featuresToBeShown) {
                urlParams.feature = featuresToBeShown;
                arParams.feature = featuresToBeShown;
                qrParams.feature = featuresToBeShown;
                resolveUrlParams.feature = featuresToBeShown;
            }
            if (params.size) {
                urlParams.size = params.size;
            }
            if (params.frame) {
                mainUrl = mainUrl.replace('__FRAME_INDEX__', params.frame);
            }
            if (params.removeEnvironmentShadow) {
                urlParams.removeEnvironmentShadow = true;
                arParams.removeEnvironmentShadow = true;
                qrParams.removeEnvironmentShadow = true;
            }

            paths.framePath = network.addQueryString(mainUrl, urlParams);
            paths.additionalDataUrl = additionalDataUrl;



            urlParams.size = 128;

            paths.thumbPath = network.addQueryString(mainUrl, urlParams);

            delete urlParams.background;

            delete urlParams.size;

            urlParams.size = 128;


            delete urlParams.size;

            urlParams.crop = '(__COORDS__)';


            paths.zoomPath = network.addQueryString(mainUrl, urlParams);

            paths.arPath = network.addQueryString(mainArUrl, arParams);

            paths.qrPath = network.addQueryString(mainQrArUrl, qrParams);

            paths.resolvedFtPath = network.addQueryString(resolvedFtUrl, resolveUrlParams);
        }
        return paths;
    }

}).call(this);
