(function () {
    'use strict';
    var AbstractButton = cylindo.getModule('cylindo.classes.button');
    function GlbBtn(opts) {
        opts.appendButton = false;
        AbstractButton.call(this, opts);
        var self = this;
        var network = cylindo.getModule('cylindo.core.network');
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var paths = opts.settings.get('urls');
        var glbEndPoint = paths.AR;
        var util = opts.util;
        var viewerContainer = opts.parent;
        var dom = opts.dom;
        this.util = util;
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;
        this.glbSrc = null;
        this.network = network;
        this.settings = opts.settings;
        this.wrapperClasses = ['cylindo-button', 'cylindo-ar-button'];
        this.model = opts.model;
        this.logger = this.model.getLogger();
        this.currentFeatures = this.model.get('currentFeatures');
        this.urls = this.currentFeatures ? this.currentFeatures.paths : null;
        this.tags = opts.tags;
        this.preventEvents = opts.preventEvents;
        this.glbEndPoint = glbEndPoint;
        this.initialized = false;
        this.btnExistsPromise = this.util.promise.create();
        this.fallbackInvoked = false;
        this.viewerContainer = viewerContainer;
        this.tryToShow = tryToShow;
        this.onFeaturesError = onFeaturesError;
        this.getArQuickLookUrl = getArQuickLookUrl;
        this.dom = dom;
        this.destroy = destroy;
        this.arOpen = false;

        function tryToShow() {
            var glbSrc;
            var isArEnabled;
            var arErrors = {
                AR_NOT_ENABLED: 'AR not enabled for this product or version.',
                WRONG_PATHS: 'AR paths not found.',
            };
            var error = '';
            self.currentFeatures = self.model.get('currentFeatures');
            isArEnabled = self.currentFeatures && self.currentFeatures.data ? self.currentFeatures.data.isArEnabled : false;
            self.urls = self.currentFeatures ? self.currentFeatures.paths : null;
            self.hide();
            if (self.model.isFirstSetOfFt() && self.model.didFirstFeaturesFailed()) {
                self.logger.error('First group of features failed, AR won\'t be displayed.');
                return;
            }
            if (!isArEnabled || !self.urls || !self.urls.arPath) {
                error = isArEnabled ? arErrors.WRONG_PATHS : arErrors.AR_NOT_ENABLED;
                self.trigger(self.events.AR_BUTTON_ERROR, {});
                self.logger.error('Cannot create GLB file for this product. ', error);
                return;
            }
            else {
                glbSrc = self.urls.arPath.replace('__AR_FORMAT__', 'glb');
            }
            glbSrc = glbSrc.replace('__AR_FILE__', 'AR');
            self.el.innerHTML = '';
            self.network.doRequest(glbSrc, function (response, xhr) {
                self.logger.log('Glb file found for this combination. ', glbSrc);
                arSuccess(glbSrc);
            }, {
                type: 'HEAD',
            }, arFailed, null);
            function arSuccess(glbSrc) {
                self.glbSrc = glbSrc;
                self.el.appendChild(self.btn);
                self.btn.removeEventListener('touchend', onARBtnClickHandler, true);
                self.btn.addEventListener('touchend', onARBtnClickHandler, true);
                self.show();
                self.trigger(self.events.AR_BUTTON_READY, {
                    url: self.glbSrc
                });
                self.btnExistsPromise.resolve({
                    glb: self.glbSrc,
                });
            }
            function arFailed() {
                var err = 'GLB not found for the requested feature set.';
                self.glbSrc = null;
                self.btn.removeEventListener('touchend', onARBtnClickHandler, true);
                self.trigger(self.events.AR_BUTTON_ERROR, {});
                self.logger.error(err);
                self.btnExistsPromise.reject({
                    err: err,
                });
            }
        }
        function onARBtnClickHandler(evt) {
            var anchor = document.createElement('a');
            var glbSrc = encodeURIComponent(self.glbSrc);
            var noArViewerSigil = '#model-viewer-no-ar-fallback';
            var location = window.location.toString();
            var locationUrl = new URL(location);
            var modelUrl = new URL(self.network.resolveProtocol(paths.ARV_GOOGLE));
            var ARBannerConfig = self.model.get('ARBannerConfig');
            var title = ARBannerConfig && ARBannerConfig.title ? ARBannerConfig.title : '';
            var link = title ?
                typeof self.model.get('getArRedirectUrl') === 'function' ?
                    encodeURIComponent(decodeURIComponent(self.model.get('getArRedirectUrl')())) :
                    encodeURIComponent(location) : '';
            var scheme = modelUrl.protocol.replace(':', '');
            var intent, undoHashChange, op;
            if (evt) {
                evt.stopPropagation();
                self.preventEvents();
            }
            if (self.fallbackInvoked) {
                return;
            }
            self.trigger(self.events.AR_SHOW_MODAL, null);
            document.addEventListener('visibilitychange', function () {
                if (document.visibilityState === 'visible') {
                    self.trigger(self.events.AR_HIDE_MODAL, null);
                }
            });
            self.tags.send(self.tags.events.AR_LAUNCHED);
            self.tags.send(self.tags.events.AR_CLICKED);
            self.trigger(self.events.AR_LAUNCHED, null);
            locationUrl.hash = noArViewerSigil;
            title = encodeURIComponent(title);
            modelUrl.protocol = 'intent://';
            intent = modelUrl.toString();
            op = intent.indexOf('?') !== -1 ? '&' : '?';
            intent += op + 'file=' + glbSrc + '&mode=ar_only&resizable=true&link=' + link + '&title=' + title + '#Intent;scheme=' + scheme + ';package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=' + encodeURIComponent(locationUrl.toString()) + ';end;';
            undoHashChange = function () {
                if (window.location.hash === noArViewerSigil && !self.fallbackInvoked) {
                    self.fallbackInvoked = true;
                    window.history.back();
                    self.hide();
                    self.trigger(self.events.AR_BUTTON_ERROR, {});
                    self.trigger(self.events.AR_SHOW_ERROR_MODAL, {});
                }
            };
            window.addEventListener('hashchange', undoHashChange, { once: true });
            anchor.setAttribute('href', intent);
            setTimeout(function () {
                anchor.click();
            }, 3000);
        }

        function onFeaturesError() {
            self.glbSrc = null;
            self.hide();
            self.trigger(self.events.AR_BUTTON_ERROR, {});
        }
        function getArQuickLookUrl() {
            var mode = 'ar_only';
            var resizable = true;
            var glbSrc = encodeURIComponent(self.glbSrc);
            var noArViewerSigil = '#model-viewer-no-ar-fallback';
            var location = window.location.toString();
            var locationUrl = new URL(location);
            var modelUrl = new URL(self.network.resolveProtocol(paths.ARV_GOOGLE));
            var ARBannerConfig = self.model.get('ARBannerConfig');
            var title = ARBannerConfig && ARBannerConfig.title ? ARBannerConfig.title : '';
            var link = title ?
                typeof self.model.get('getArRedirectUrl') === 'function' ?
                    encodeURIComponent(decodeURIComponent(self.model.get('getArRedirectUrl')())) :
                    encodeURIComponent(location) : '';
            var scheme = modelUrl ? modelUrl.protocol.replace(':', '') : null;
            var intent, op;
            if (!glbSrc) {
                return null;
            }
            locationUrl.hash = noArViewerSigil;
            title = encodeURIComponent(title);
            modelUrl.protocol = 'intent://';
            intent = modelUrl.toString();
            op = intent.indexOf('?') !== -1 ? '&' : '?';
            intent += op + 'file=' + glbSrc + '&link=' + link + '&title=' + title + '&mode=' + mode + '&resizable=' + resizable + '#Intent;scheme=' + scheme + ';package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=' + encodeURIComponent(locationUrl.toString()) + ';end;';
            return intent;

        }
        function destroy() {
            pubsub.destroy();
            self.btn = null;
        }
    }
    GlbBtn.prototype = Object.create(AbstractButton.prototype);
    GlbBtn.prototype.constructor = GlbBtn;
    GlbBtn.prototype.render = function () {
        var self = this;
        var arBtnContainer = document.createElement('div');
        var arBtnIcon = document.createElement('div');
        var arBtnText = document.createElement('div');
        arBtnText.innerHTML = self.model.get('ARBtnTxt');
        arBtnContainer.classList.add('ar-glyph-background');
        arBtnIcon.classList.add('ar-icon');
        arBtnText.classList.add('ar-text');
        arBtnContainer.appendChild(arBtnIcon);
        arBtnContainer.appendChild(arBtnText);
        AbstractButton.prototype.render.call(self);
        self.btn.appendChild(arBtnContainer);
        self.btn.setAttribute('href', '#');
        self.btn.setAttribute('role', 'link');
        self.btn.setAttribute('aria-label', self.ariaLabel.texts.VIEW_IN_AR);
        self.btn.addEventListener('mousedown', preventViewerEvents, true);
        self.btn.addEventListener('mouseup', preventViewerEvents, true);
        self.btn.addEventListener('touchstart', preventViewerEvents, true);
        self.tryToShow();
        function preventViewerEvents(evt) {
            if (evt) {
                evt.stopPropagation();
                evt.stopImmediatePropagation();
            }
        }
        self.initialized = true;
    };

    GlbBtn.prototype.events = {
        AR_BUTTON_READY: 'ar:button:ready',
        AR_BUTTON_ERROR: 'ar:button:error',
        AR_LAUNCHED: 'ar:launched',
        AR_SHOW_MODAL: 'ar:show:modal',
        AR_HIDE_MODAL: 'ar:hide:modal',
        AR_ACTION_BTN_TAPPED: 'ar:action:btn:tapped', 
        AR_SHOW_ERROR_MODAL: 'ar:show:error:modal',
    };
    var publicAPI = {
        create: function (opts) {
            return new GlbBtn(opts);
        }
    };
    window.cylindo.addModule('cylindo.button.ar.glb', publicAPI);
}.call(this));
