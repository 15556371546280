(function () {
    'use strict';

    var Database = function (opts) {
        var self = this;
        var databaseObj = null;
        var logger = opts.logger;
        var viewerData = opts.viewerData;

        this.enabled = false;
        this.init = init;
        this.isInitialized = isInitialized;

        this.getData = getData;
        this.setData = setData;
        this.processData = processData;

        function getData(key) {
            return databaseObj.getData(key);
        }

        function setData(key, value) {
            databaseObj.setData(key, value);
        }


        function processData() {
            return databaseObj.processData.apply(null, arguments);
        }

        function isInitialized() {
            return self.initialized;
        }

        function init() {
            var opts = {
                viewerData: viewerData,
                logger: logger,
            };
            if (self.initialized) {
                return;
            }
            else {
                self.initialized = true;
            }
            databaseObj = cylindo.getModule('cylindo.classes.database.rtv').create(opts);
        }

    };

    var publicAPI = {
        create: function (opts) {
            return new Database(opts);
        }
    };

    window.cylindo.addModule('cylindo.classes.database', publicAPI);
}.call(this));