
(function () {
    'use strict';
    var Controller = function (options) {
        var self = this;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var instance = null;
        var eventMap = {};
        var deprecatedMsgs = {
            toBeDeprecated: 'Cylindo: Some of the requested methods has been deprecated, please consult our documentation or contact the Support team to look for alternatives.',
        };
        var deprecatedFns = {
            ADD_TO_CART: 'addToCart',
            GET_AR_QUICKLOOK_URL: 'getArQuickLookUrl',
            GET_CURRENT_FRAME_URL: 'getCurrentFrameUrl',
            GET_FRAME_URL: 'getFrameUrl',
            GET_SKU: 'getSKU',
            GET_STATE: 'getState',
            SHOW_IMAGE: 'showImage',
            HIDE_IMAGE: 'hideImage',
            DISABLE_TRACKING: 'disableTracking',
        };
        var opts = options;
        this.on = pubsub.on;
        this.trigger = pubsub.trigger;
        this.off = pubsub.off;

        tryToCreatecontroller();

        eventMap[self.events.ZOOM] = self.events.ZOOM;
        eventMap[self.events.ZOOM_ENTER] = self.events.ZOOM_ENTER;
        eventMap[self.events.ZOOM_EXIT] = self.events.ZOOM_EXIT;
        eventMap[self.events.VIEWER_READY] = self.events.VIEWER_READY;
        eventMap[self.events.VIEWER_COMPLETE] = self.events.VIEWER_COMPLETE;
        eventMap[self.events.THUMBS_READY] = self.events.THUMBS_READY;
        eventMap[self.events.THUMB_CLICKED] = self.events.THUMB_CLICKED;
        eventMap[self.events.ALT_THUMBS_CLICKED] = self.events.ALT_THUMBS_CLICKED;
        eventMap[self.events.FIRST_FRAME_READY] = self.events.FIRST_FRAME_READY;
        eventMap[self.events.FULLSCREEN_ENTER] = self.events.FULLSCREEN_ENTER;
        eventMap[self.events.FULLSCREEN_EXIT] = self.events.FULLSCREEN_EXIT;
        eventMap[self.events.SPIN_BEGIN] = self.events.SPIN_BEGIN;
        eventMap[self.events.SPIN_END] = self.events.SPIN_END;
        eventMap[self.events.PREV] = self.events.PREV;
        eventMap[self.events.NEXT] = self.events.NEXT;
        eventMap[self.events.DESTROYED] = self.events.DESTROYED;
        eventMap[self.events.ERROR] = self.events.ERROR;
        eventMap[self.events.FEATURES_CANCELED] = self.events.FEATURES_CANCELED;
        eventMap[self.events.FEATURES_ERROR] = self.events.FEATURES_ERROR;
        eventMap[self.events.AR_BUTTON_READY] = self.events.AR_BUTTON_READY;
        eventMap[self.events.AR_BUTTON_ERROR] = self.events.AR_BUTTON_ERROR;
        eventMap[self.events.AR_ACTION_BTN_TAPPED] = self.events.AR_ACTION_BTN_TAPPED;
        this.update = function (field, value) {
            if (instance.isFallbackController) {
                opts[field] = value;
                tryToCreatecontroller();
            }
            else {
                instance.update(field, value);
            }
        };

        this.updateAlternateContent = function (frames) {
            instance.updateAlternateContent(frames);
        };

        this.setProduct = function (productCode) {
            if (instance.isFallbackController) {
                opts.productCode = productCode;
                tryToCreatecontroller();
            }
            else {
                instance.setProduct(productCode);
            }
        };

        this.setFeatures = function (featureArray) {
            if (instance.isFallbackController) {
                opts.features = featureArray;
                tryToCreatecontroller();
            }
            else {
                instance.setFeatures(featureArray);
            }
        };

        this.destroy = function () {
            instance.destroy();
            pubsub.destroy();
        };


        this.getProduct = function () {
            return instance.getProduct();
        };

        this.getFeatures = function () {
            return instance.getFeatures();
        };

        this.getAccount = function () {
            return instance.getAccount();
        };

        this.getVersion = function () {
            return instance.getVersion();
        };

        this.getRotationSpeed = function () {
            return instance.getRotationSpeed();
        };

        this.getDebugInfo = function (search) {
            return instance.getDebugInfo(search);
        };

        this.getCurrentImageUrl = function (size, ssl) {
            return instance.getUrlImage(self.getCurrentFrameIndex(), size, ssl);
        };

        this.getCurrentFrameIndex = function () {
            return instance.getCurrentFrameIndex();
        };



         this.addToCart = function (price, currency, quantity, cb) {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.ADD_TO_CART);
            instance.addToCart(price, currency, quantity, cb);
        };
        this.getArQuickLookUrl = function (callback) {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.GET_AR_QUICKLOOK_URL);
            instance.getArQuickLookUrl(callback);
        };
        this.getCurrentFrameUrl = function (size, callback, ssl) {
            self.getFrameUrl(self.getCurrentFrameIndex(), size, callback, ssl);
        };
        this.getFrameUrl = function (frameNumber, size, callback, ssl) {
            var url = instance.getUrlImage(frameNumber, size, ssl);
            var error = 'An error ocurred while trying to get the current frame.';
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, [deprecatedFns.GET_FRAME_URL, deprecatedFns.GET_CURRENT_FRAME_URL]);
            try {
                if (url) {
                    callback(url);
                }
                else {
                    callback(url, error);
                }
            }
            catch (ex) {
                if (console.error) {
                    console.error(error, ex);
                }
                else {
                    console.log(error, ex);
                }
            }
        };
        this.getSKU = function () {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.GET_SKU);
            return instance.getProduct();
        };
        this.getState = function () {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.GET_STATE);
            return instance.getState();
        };
        this.showImage = function (url, large) {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.SHOW_IMAGE);
            instance.showImage(url, large);
        };
        this.hideImage = function () {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.HIDE_IMAGE);
            instance.hideImage();
        };
         this.disableTracking = function (flag) {
            triggerDeprecateMsg(deprecatedMsgs.toBeDeprecated, deprecatedFns.DISABLE_TRACKING);
            instance.disableTracking(flag);
        };
        this.getResolvedFeatures = function (cb) {
            if (instance && instance.getResolvedFeatures) {
                instance.getResolvedFeatures(cb);
            }
        };

        function tryToCreatecontroller() {
            try {
                instance = window.cylindo.getModule('cylindo.viewer.controller').create(opts);
            }
            catch (e) {
                console.error(e);
                if (instance === null) {
                    instance = cylindo.getModule('cylindo.core.fallback').create(opts);
                }
            }

            self.isRotating = instance.isRotating;
            self.isPanning = instance.isPanning;
            self.loggerMap = instance.loggerMap;

            self.printGrid = instance.printGrid;

            self.exitZoom = instance.exitZoom;

            self.disableTags = instance.disableTags;

            self.rotate = instance.rotate;

            self.goToAngle = instance.goToAngle;

            self.previousSlide = instance.previousSlide;

            self.nextSlide = instance.nextSlide;

            self.zoom = instance.zoom;

            instance.off(instance.events.ZOOM, forwardEvent);
            instance.off(instance.events.ZOOM_ENTER, forwardEvent);
            instance.off(instance.events.ZOOM_EXIT, forwardEvent);
            instance.off(instance.events.VIEWER_READY, forwardEvent);
            instance.off(instance.events.VIEWER_COMPLETE, forwardEvent);
            instance.off(instance.events.THUMBS_READY, forwardEvent);
            instance.off(instance.events.THUMB_CLICKED, forwardEvent);
            instance.off(instance.events.ALT_THUMBS_CLICKED, forwardEvent);
            instance.off(instance.events.FIRST_FRAME_READY, forwardEvent);
            instance.off(instance.events.FULLSCREEN_ENTER, forwardEvent);
            instance.off(instance.events.FULLSCREEN_EXIT, forwardEvent);
            instance.off(instance.events.SPIN_BEGIN, forwardEvent);
            instance.off(instance.events.SPIN_END, forwardEvent);
            instance.off(instance.events.PREV, forwardEvent);
            instance.off(instance.events.NEXT, forwardEvent);
            instance.off(instance.events.DESTROYED, forwardEvent);
            instance.off(instance.events.ERROR, forwardEvent);
            instance.off(instance.events.FEATURES_CANCELED, forwardEvent);
            instance.off(instance.events.FEATURES_ERROR, forwardEvent);
            instance.off(instance.events.AR_BUTTON_READY, forwardEvent);
            instance.off(instance.events.AR_BUTTON_ERROR, forwardEvent);
            instance.off(instance.events.AR_ACTION_BTN_TAPPED, forwardEvent);
            instance.on(instance.events.ZOOM, forwardEvent);
            instance.on(instance.events.ZOOM_ENTER, forwardEvent);
            instance.on(instance.events.ZOOM_EXIT, forwardEvent);
            instance.on(instance.events.VIEWER_READY, forwardEvent);
            instance.on(instance.events.VIEWER_COMPLETE, forwardEvent);
            instance.on(instance.events.THUMBS_READY, forwardEvent);
            instance.on(instance.events.THUMB_CLICKED, forwardEvent);
            instance.on(instance.events.ALT_THUMBS_CLICKED, forwardEvent);
            instance.on(instance.events.FIRST_FRAME_READY, forwardEvent);
            instance.on(instance.events.FULLSCREEN_ENTER, forwardEvent);
            instance.on(instance.events.FULLSCREEN_EXIT, forwardEvent);
            instance.on(instance.events.SPIN_BEGIN, forwardEvent);
            instance.on(instance.events.SPIN_END, forwardEvent);
            instance.on(instance.events.PREV, forwardEvent);
            instance.on(instance.events.NEXT, forwardEvent);
            instance.on(instance.events.DESTROYED, forwardEvent);
            instance.on(instance.events.ERROR, forwardEvent);
            instance.on(instance.events.FEATURES_CANCELED, forwardEvent);
            instance.on(instance.events.FEATURES_ERROR, forwardEvent);
            instance.on(instance.events.AR_BUTTON_READY, forwardEvent);
            instance.on(instance.events.AR_BUTTON_ERROR, forwardEvent);
            instance.on(instance.events.AR_ACTION_BTN_TAPPED, forwardEvent);

            self.initialized = true;

            if (instance.isFallbackController) {
                instance.show();
                setTimeout(function () {
                    self.trigger(self.events.ERROR, opts);
                }, 100);
            }
        }

        function forwardEvent(evt, data) {
            self.trigger(eventMap[evt], data);
        }

        function triggerDeprecateMsg(msg, fns) {
            if (console.warn) {
                console.warn(msg, fns);
            }
            else {
                console.log(msg, fns);
            }
        }
    };

    Controller.counter = 0;

    Controller.prototype.events = {
        DESTROYED: 'instance:destroyed',
        THREESIXTY_READY: 'instance:viewer:ready',
        THREESIXTY_COMPLETE: 'instance:viewer:complete',
        VIEWER_READY: 'instance:viewer:ready',
        VIEWER_COMPLETE: 'instance:viewer:complete',
        FIRST_FRAME_READY: 'instance:firstframe:ready',
        THUMBS_READY: 'instance:thumbs:ready',
        THREESIXTY_THUMB_CLICKED: 'instance:viewer:thumbs:clicked',
        THUMB_CLICKED: 'instance:viewer:thumbs:clicked',
        ALT_THUMBS_CLICKED: 'instance:alt:thumbs:clicked',
        ZOOM: 'instance:zoom',
        ZOOM_ENTER: 'instance:zoom:enter',
        ZOOM_EXIT: 'instance:zoom:exit',
        FULLSCREEN_ENTER: 'instance:fullscreen:enter',
        FULLSCREEN_EXIT: 'instance:fullscreen:exit',
        SPIN_BEGIN: 'instance:threesixty:start',
        SPIN_END: 'instance:threesixty:end',
        PREV: 'instance:carousel:prev',
        NEXT: 'instance:carousel:next',
        ERROR: 'instance:error',
        FEATURES_CANCELED: 'instance:features:canceled',
        FEATURES_ERROR: 'instance:features:error',
        AR_BUTTON_READY: 'instance:ar:button:ready',
        AR_BUTTON_ERROR: 'instance:ar:button:error',
        AR_ACTION_BTN_TAPPED: 'instance:ar:action:btn:tapped'
    };

    var publicAPI = {
        create: function (config) {
            return new Controller(config);
        }
    };

    window.cylindo.addModule('cylindo.controller', publicAPI);
}).call(this);
