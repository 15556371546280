
(function () {
    'use strict';
    var publicServices = {
        getLabels: getLabels,
        getActions: getActions,
        getEventsData: getEventsData,
        getDeviceCategories: getDeviceCategories,
    };
    var deviceCategories = {
        MOBILE: 'mobile',
        DESKTOP: 'desktop',
    };
    var labels = {
        CYLINDO_360: 'Cylindo360',
        CYLINDO_AR: 'CylindoAR',
        CYLINDO_VIEWER_GA4: 'Cylindo Viewer ',
        CYLINDO_AR_GA4: 'Cylindo AR ',
        CYLINDO_ALT_IMG_GA4: 'Cylindo Alt Image ',
    };
    var actions = {
        LOADED: 'Loaded',
        VIEWED: 'Viewed',
        SEEN: 'Seen',
        ENGAGED: 'Engaged',
        BUTTON_SEEN: 'Button seen',
        LAUNCHED: 'Lauched',
        CLICKED: 'Clicked',
        BANNER_CLICKED: 'Banner Clicked',
        ERROR_LOADING: 'Error Loading,'
    };
    function getDeviceCategories() {
        return deviceCategories;
    }
    function getLabels() {
        return labels;
    }
    function getActions() {
        return actions;
    }
    function getEventsData() {
        return {
            LOADED: {
                UA: true,
                GA4: true,
                ACTION: 'Loaded',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.LOADED + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.LOADED
            },
            SEEN: {
                UA: true,
                GA4: true,
                ACTION: 'Seen',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.SEEN + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.SEEN
            },
            FULLSCREEN: {
                UA: true,
                GA4: true,
                ACTION: 'Full screen',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.ENGAGED + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.ENGAGED
            },
            ZOOM: {
                UA: true,
                GA4: true,
                ACTION: 'Zoom',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.ENGAGED + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.ENGAGED
            },
            SPIN: {
                UA: true,
                GA4: true,
                ACTION: 'Spin',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.ENGAGED + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.ENGAGED
            },
            FEATURE_CHANGED: {
                UA: true,
                GA4: true,
                ACTION: 'Feature changed',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.ENGAGED + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.ENGAGED
            },
            FEATURE_ERROR: {
                UA: true,
                GA4: false,
                ACTION: 'Feature error',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.ENGAGED + ' ' +labels.CYLINDO_360
            },
            THUMBNAIL_CLICK: {
                UA: true,
                GA4: true,
                ACTION: 'Thumbnail click',
                LABEL: labels.CYLINDO_360,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                DIMENSION_VAL_360: actions.ENGAGED + ' ' + labels.CYLINDO_360,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.ENGAGED
            },
            AR_LOADED: {
                UA: true,
                GA4: true,
                ACTION: 'Loaded',
                LABEL: labels.CYLINDO_AR,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                EVENT_NAME_GA4: labels.CYLINDO_AR_GA4 + actions.LOADED
            },
            AR_SEEN: {
                UA: true,
                GA4: true,
                ACTION: 'Seen',
                LABEL: labels.CYLINDO_AR,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_AR: labels.CYLINDO_AR + ' ' + actions.BUTTON_SEEN,
                EVENT_NAME_GA4: labels.CYLINDO_AR_GA4 + actions.BUTTON_SEEN
            },
            AR_LAUNCHED: {
                UA: true,
                GA4: true,
                ACTION: 'AR Launched',
                LABEL: labels.CYLINDO_AR,
                ONE_PER_PAGE: true,
                TRIGGERED: false,
                DIMENSION_VAL_AR: labels.CYLINDO_AR + ' ' + actions.LAUNCHED,
                EVENT_NAME_GA4: labels.CYLINDO_AR_GA4 + actions.LAUNCHED
            },
            AR_CLICKED: {
                UA: true,
                GA4: true,
                ACTION: 'AR Clicked',
                LABEL: labels.CYLINDO_AR,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                DIMENSION_VAL_AR: labels.CYLINDO_AR + ' ' + actions.CLICKED,
                EVENT_NAME_GA4: labels.CYLINDO_AR_GA4 + actions.CLICKED
            },
            AR_BANNER_CLICKED: {
                UA: true,
                GA4: true,
                ACTION: 'AR Banner Clicked',
                LABEL: labels.CYLINDO_AR,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                DIMENSION_VAL_AR: labels.CYLINDO_AR + ' ' + actions.CLICKED,
                EVENT_NAME_GA4: labels.CYLINDO_AR_GA4 + actions.BANNER_CLICKED
            },
            ALT_IMAGE_LOADED: {
                UA: false,
                GA4: true,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                EVENT_NAME_GA4: labels.CYLINDO_ALT_IMG_GA4 + actions.LOADED
            },
            ALT_IMAGE_VIEWED: {
                UA: false,
                GA4: true,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                EVENT_NAME_GA4: labels.CYLINDO_ALT_IMG_GA4 + actions.VIEWED
            },
            ERROR_LOADING: {
                UA: false,
                GA4: true,
                ONE_PER_PAGE: false,
                TRIGGERED: false,
                EVENT_NAME_GA4: labels.CYLINDO_VIEWER_GA4 + actions.ERROR_LOADING
            },
        };
    }

    window.cylindo.addModule('cylindo.core.google.maps', publicServices);

}).call(this);
