(function () {
    'use strict';
    var moduleKey = 'cylindo.core.util';
    var util = window.cylindo.getModule(moduleKey) || {};
    util.object = {
        extend: extend,
        isPlainObject: isPlainObject,
        isEmptyObject: isEmptyObject,
        isFunction: isFunction,
        getRectFixed: getRectFixed
    };
    window.cylindo.addModule(moduleKey, util);
    function extend() {
        var options, name, src, copy, copyIsArray, clone;
        var target = arguments[0] || {};
        var i = 1;
        var length = arguments.length;
        var deep = false;
        if (typeof target === 'boolean') {
            deep = target;
            target = arguments[i] || {};
            i++;
        }
        if (typeof target !== 'object' && !isFunction(target)) {
            target = {};
        }
        for (; i < length; i++) {
            options = arguments[i];
            if (typeof options !== 'undefined' && options !== null) {
                for (name in options) {
                    src = target[name];
                    copy = options[name];
                    if (target === copy) {
                        continue;
                    }
                    if (deep && copy && (isPlainObject(copy) ||
                        (copyIsArray = Array.isArray(copy)))) {
                        if (copyIsArray) {
                            copyIsArray = false;
                            clone = src && Array.isArray(src) ? src : [];
                        }
                        else {
                            clone = src && isPlainObject(src) ? src : {};
                        }
                        target[name] = extend(deep, clone, copy);
                    }
                    else if (typeof copy !== 'undefined') {
                        target[name] = copy;
                    }
                }
            }
        }
        return target;
    }
    function isPlainObject(obj) {
        var proto;
        var constructor;
        var classToType = {};
        var toString = classToType.toString;
        var hasOwnProp = classToType.hasOwnProperty;
        var fnToString = hasOwnProp.toString;
        if (!obj || toString.call(obj) !== '[object Object]') {
            return false;
        }
        proto = Object.getPrototypeOf(obj);
        if (!proto) {
            return true;
        }
        constructor = hasOwnProp.call(proto, 'constructor') && proto.constructor;
        return typeof constructor === 'function' &&
            fnToString.call(constructor) === fnToString.call(Object);
    }
    function isEmptyObject(obj) {
        var name;
        for (name in obj) {
            return false;
        }
        return true;
    }
    function isFunction(obj) {
        return typeof obj === 'function' && typeof obj.nodeType !== 'number';
    }
    function getRectFixed(el) {
        var rect = {};
        var key;
        if (el) {
            rect = extend(rect, el.getBoundingClientRect());
            for (key in rect) {
                if (typeof rect[key] === 'number')  {
                    rect[key] = Math.round(rect[key]);
                }
            }
        }
        return rect;
    }
}).call(this);