(function () {
    'use strict';

    var presentations = {
        CAROUSEL: {
            NAME: 'carousel',
            LIST_CLASS: 'cylindo-threesixty-list'
        },
        SINGLE: {
            NAME: 'single',
            LIST_CLASS: 'cylindo-threesixty-list'
        },
        STACKED: {
            NAME: 'stacked',
            LIST_CLASS: 'cylindo-threesixty-list'
        },
        CUSTOM: {
            NAME: 'custom',
            LIST_CLASS: 'cylindo-custom-list'
        },
        THREESIXTY: {
            NAME: 'threesixty',
            LIST_CLASS: 'cylindo-threesixty-list'
        }
    };

    window.cylindo.addModule('cylindo.helpers.viewer.presentations', presentations);
}).call(this);
