(function () {
    'use strict';

    var publicServices = {
        getVideoId: getVideoId
    };

    function getVideoId(alternateContent) {
        var supportedProviders = ['direct', 'youtube', 'vimeo'];
        var youtubeIdRx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/videoseries\/|embed\/videoseries|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        var vimeoIdRx = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
        var index, len, videoId;

        len = alternateContent.length;

        for (index = 0; index < len; index++) {
            if (typeof alternateContent[index].provider !== 'undefined') {
                if (supportedProviders.indexOf(alternateContent[index].provider) !== -1) {
                    if (alternateContent[index].provider === 'youtube') {
                        videoId = alternateContent[index].url.match(youtubeIdRx);
                        alternateContent[index].videoId = videoId !== null && videoId[1].length > 0 ? videoId[1] : null;
                    }
                    else if (alternateContent[index].provider === 'vimeo') {
                        videoId = alternateContent[index].url.match(vimeoIdRx);
                        alternateContent[index].videoId = videoId !== null && videoId[3].length > 0 ? videoId[3] : null;
                    }
                }
                else {
                    alternateContent[index].provider = 'not-supported';
                }
            }
        }

        return alternateContent;
    }

    window.cylindo.addModule('cylindo.helpers.video', publicServices);
}).call(this);
