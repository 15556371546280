(function () {
    'use strict';
    var publicServices = {
        getInitialStates: getInitialStates,
        getAllPossibleStates: getAllPossibleStates
    };

    var genericStates = {
        GENERIC: {
            UINITIALIZED: 'Unititialized',
            INITIALIZING: 'Initializing'
        },
        CUSTOM: {
            FRAME: 'ImageOverride',
            ZOOM: 'ImageOverrideZoom',
            FULLSCREEN: 'ImageOverrideFullScreen',
            ZOOM_FULLSCREEN: 'ImageOverrideZoomFullScreen'
        },
        FALLBACK: {
            FRAME: 'Fallback',
            FULLSCREEN: 'FallbackFullScreen'
        }
    };
    var altPrefix = 'AlternateContent';
    var prefixes = ['Threesixty', 'Single', 'Carousel', 'Stacked'];
    var frameStates = {
        FRAME: {
            FRAME: '',
            ZOOM: 'Zoom',
            FULLSCREEN: 'FullScreen',
            ZOOM_FULLSCREEN: 'ZoomFullScreen'
        }
    };
    var alternateStates = {
        ALT_CONT: {
            FRAME: 'AlternateContent',
            ZOOM: 'AlternateContentZoom',
            FULLSCREEN: 'AlternateContentFullScreen',
            ZOOM_FULLSCREEN: 'AlternateContentZoomFullScreen',
        }
    };

    function getInitialStates() {
        return genericStates;
    }

    function getAllPossibleStates(presentation) {
        var presentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
        var util = cylindo.getModule('cylindo.core.util');
        var prefix;
        var states = getInitialStates();
        if (typeof presentation === 'string' && presentation.length > 0) {
            if (presentation === presentations.CUSTOM.NAME) {
                return util.object.extend({}, states, {
                    FRAME: {
                        FRAME: altPrefix + frameStates.FRAME.FRAME,
                        ZOOM: altPrefix + frameStates.FRAME.ZOOM,
                        FULLSCREEN: altPrefix + frameStates.FRAME.FULLSCREEN,
                        ZOOM_FULLSCREEN: altPrefix + frameStates.FRAME.ZOOM_FULLSCREEN
                    },
                }, alternateStates);
            }
            else {
                prefix = presentation.charAt(0).toUpperCase() + presentation.substr(1);
                prefix = prefixes.indexOf(prefix) !== -1 ? prefix : '';
                return util.object.extend({}, states, {
                    FRAME: {
                        FRAME: prefix + frameStates.FRAME.FRAME,
                        ZOOM: prefix + frameStates.FRAME.ZOOM,
                        FULLSCREEN: prefix + frameStates.FRAME.FULLSCREEN,
                        ZOOM_FULLSCREEN: prefix + frameStates.FRAME.ZOOM_FULLSCREEN
                    },
                }, alternateStates);
            }
        }
        else {
            return states;
        }
    }


    window.cylindo.addModule('cylindo.helpers.viewer.states', publicServices);
}).call(this);
