(function () {
    'use strict';

    var renderStrategy = {};
    var videoPlayer = cylindo.getModule('cylindo.video');
    var util = cylindo.getModule('cylindo.core.util');

    renderStrategy.strategies = {
        ALT_IMAGE: altImageRender,
        ALT_VENDOR: altVendorRender,
        ALT_VIDEO: altVideoRender,
        RTV_SINGLE_MERGED: rtvSingleMergedRender,
    };

    renderStrategy.useStrategy = function (fn, options) {
        try {
            return fn.call(null, options);
        } catch (ex) {
            console.log('The strategy was not found');
            console.error(ex);
            return true;
        }
    };
    function rtvSingleMergedRender(options) {
        var masterDeferred = util.promise.create();
        var model = options.viewer.model;
        var index = options.index;
        var urls = options.urls;
        var deferreds = [];
        var imgArray = [];
        var imageStatus = {
            notFound: false
        };
        var imgSrc = urls.framePath.replace('__FRAME_INDEX__', options.index);
        var zoomImgSrc = urls.zoomPath.replace('__FRAME_INDEX__', options.index);
        var deferred = util.promise.create();
        var img = new Image();
        var currentFeatures = model.get('currentFeatures');
        var additionalData = currentFeatures && currentFeatures.data  ? currentFeatures.data : null;
        var cachedImage = null;
        if (additionalData && 
            additionalData.startFrame === index && 
            additionalData.image) {
            cachedImage = productData.image || null;
        }
        img = cachedImage || img;
        removeDraggableBehavior(img);

        img.onload = function () {
            if (imageStatus.notFound) {
                options.li.classList.add('cylindo-img-not-found');
            }
            else {
                options.li.classList.remove('cylindo-img-not-found');
            }
            deferred.resolve();
        };

        img.onerror = util.fallback.loadFallbackImage.bind({
            img: img,
            imageStatus: imageStatus,
            model: model,
            errorText: 'Fallback image not found: '
        });

        if (!cachedImage) {
            img.src = imgSrc;
        }

        options.viewer.pendingImages.push({
            index: index,
            img: img,
            requestID: options.requestID,
            prepareDefer: options.prepareDefer || util.promise.create()
        });

        imgArray.push(img);
        deferreds.push(deferred);

        try {
            options.li.dataset.zoomImage = zoomImgSrc;
        }
        catch (ex) {
            options.li.setAttribute('data-zoom-image', zoomImgSrc);
        }

        return Promise.all(deferreds).then(function () {
            executeCommon(index, imgArray, masterDeferred, options);
        });
    }
    function altImageRender(options) {
        var deferred = util.promise.create();
        var index = options.index;
        var img = new Image();
        var zoomUrl = '';
        var imgSrc = '';
        zoomUrl = options.imageToPreload.zoom || options.imageToPreload.image;
        imgSrc = options.imageToPreload.image;

        try {
            options.li.dataset.index = options.prefix + index;
            options.li.dataset.zoomImage = zoomUrl;
            options.li.dataset.altorder = options.index;
            options.li.dataset.id = 'cylindo-thumb' + options.index;
            options.li.dataset.isAlternateImage = 1;
            options.li.dataset.altRequestId = options.refAltRequestID;
            options.li.dataset.baseOneAltIndex = options.baseOneAltIndex;
        }
        catch (ex) {
            options.li.setAttribute('data-index', options.prefix + index);
            options.li.setAttribute('data-zoom-image', zoomUrl);
            options.li.setAttribute('data-altorder', index);
            options.li.setAttribute('data-id', 'cylindo-thumb' + index);
            options.li.setAttribute('data-is-alternate-image', 1);
            options.li.setAttribute('data-alt-request-id', options.refAltRequestID);
            options.li.setAttribute('data-base-one-alt-index', options.baseOneAltIndex);
        }
        img.setAttribute('role', 'img');
        img.alt = options.imageToPreload.description || 'Alternative: ' + (+ index + 1);
        img.src = options.viewer.network.resolveProtocol(imgSrc);
        removeDraggableBehavior(img);
        img.onload = loadElement.bind({
            deferred: deferred,
            el: img,
            li: options.li
        });
        img.onerror = util.fallback.loadFallbackImage.bind({
            img: img,
            li: options.li,
            model: options.viewer.model,
            errorText: 'Fallback image not found: ',
            useBUSAPI: false
        });
        return deferred;

    }
    function altVideoRender(options) {
        var deferred = util.promise.create();
        var index = options.index;
        var provider = options.videoToPreload.provider;
        var video = document.createElement('video');
        var img = new Image();
        var videoOptions = options.videoToPreload.options;
        var autoplay = 1;
        var currentTime = 0.1;
        var videoLoaded = false;
        var sources, source, sourcesLen, poster, sourceIndex, elementId, key;
        var logger = options.viewer.model.getLogger();
        options.li.classList.add('cylindo-video');

        try {
            options.li.dataset.index = options.prefix + index;
            options.li.dataset.altorder = index;
            options.li.dataset.id = 'cylindo-thumb' + index;
            options.li.dataset.isAlternateVideo = 1;
            options.li.dataset.altRequestId = options.refAltRequestID;
        }
        catch (ex) {
            options.li.setAttribute('data-index', options.prefix + index);
            options.li.setAttribute('data-altorder', index);
            options.li.setAttribute('data-id', 'cylindo-thumb' + index);
            options.li.setAttribute('data-is-alternate-video', 1);
            options.li.setAttribute('data-alt-request-id', options.refAltRequestID);
        }
        sources = options.videoToPreload.sources;
        sourcesLen = sources instanceof Array ? sources.length : 0;
        poster = options.videoToPreload.poster ? options.videoToPreload.poster : '';

        if (sourcesLen && sourcesLen > 0) {
            for (sourceIndex = 0; sourceIndex < sourcesLen; sourceIndex++) {
                source = document.createElement('source');
                source.src = options.viewer.network.resolveProtocol(sources[sourceIndex].src);
                source.type = sources[sourceIndex].type;
                if (sourceIndex === sourcesLen - 1) {
                    source.onerror = loadFallbackVideoImage.bind({
                        li: options.li,
                        element: source,
                        img: img,
                        imageSrc: options.viewer.model.get('fallbackVideoImage')
                    });
                }
                video.appendChild(source);
            }
        }
        else {
            video.src = '';
            video.onerror = loadFallbackVideoImage.bind({
                li: options.li,
                element: video,
                img: img,
                imageSrc: options.viewer.model.get('fallbackVideoImage')
            });
        }
        img.onload = loadElement.bind({
            deferred: deferred,
            el: img,
            li: options.li,
            elementToRemove: video
        });

        elementId = 'cylindo-video-' + options.viewer.id + '-' + index;
        video.id = elementId;
        video.controls = 'true';
        if (util.browser.isChrome()) {
            try {
                video.controlsList = 'nodownload';
            }
            catch (err) {
                logger.warning('Can not set controlList for this browser version', err);
            }
        }

        if (poster.length > 0) {
            video.poster = options.viewer.network.resolveProtocol(poster);
        }
        if (typeof videoOptions === 'object') {
            for (key in videoOptions) {
                if (key === 'autoplay') {
                    autoplay = videoOptions[key];
                }
                else if (key === 'currentTime') {
                    try {
                        video.currentTime = !isNaN(videoOptions[key]) && videoOptions[key] > 0 ?
                            videoOptions[key] : currentTime;
                    }
                    catch (ex) {
                    }
                }
            }
        }
        video.addEventListener('loadstart', loadVideo);
        video.load();
        function loadVideo(event) {
            if (videoLoaded) {
                return;
            }
            else {
                videoLoaded = true;
            }
            video.removeEventListener('loadstart', loadVideo);
            options.li.appendChild(video);
            if (elementId) {
                videoPlayer.create({
                    deferred: deferred,
                    li: options.li,
                    id: elementId,
                    provider: provider,
                    viewer: options.viewer,
                    autoplay: autoplay,
                    video: video,
                });
            }
        }
        return deferred;
    }
    function altVendorRender(options) {
        var deferred = util.promise.create();
        var optionsToAvoid = ['enablejsapi', 'rel'];
        var index = options.index;
        var provider = options.videoToPreload.provider;
        var videoId = options.videoToPreload.videoId;
        var iframe = document.createElement('iframe');
        var img = new Image();
        var urlParams = [];
        var vendorOptions = options.videoToPreload.options;
        var autoplay = 1;
        var relatedVideos = 0;
        var url, elementId, key;

        options.li.classList.add('cylindo-video');

        try {
            options.li.dataset.index = options.prefix + index;
            options.li.dataset.url = options.videoToPreload.url;
            options.li.dataset.altorder = index;
            options.li.dataset.id = 'cylindo-thumb' + index;
            options.li.dataset.isAlternateVideo = 1;
            options.li.dataset.altRequestId = options.refAltRequestID;
        }
        catch (ex) {
            options.li.setAttribute('data-index', options.prefix + index);
            options.li.setAttribute('data-url', options.videoToPreload.url);
            options.li.setAttribute('data-altorder', index);
            options.li.setAttribute('data-id', 'cylindo-thumb' + index);
            options.li.setAttribute('data-is-alternate-video', 1);
            options.li.setAttribute('data-alt-request-id', options.refAltRequestID);
        }
        elementId = 'cylindo-video-' + options.viewer.id + '-' + index;
        iframe.setAttribute('id', elementId);
        iframe.setAttribute('allowFullScreen', '');
        iframe.setAttribute('mozallowfullscreen', '');
        iframe.setAttribute('webkitallowfullscreen', '');
        iframe.setAttribute('allow', 'autoplay');
        iframe.setAttribute('autoplay', '');
        iframe.setAttribute('title', elementId);
        if (typeof vendorOptions === 'object') {
            for (key in vendorOptions) {
                if (key === 'autoplay') {
                    autoplay = vendorOptions[key];
                }
                else if (optionsToAvoid.indexOf(key) === -1) {
                    urlParams.push(key + '=' + vendorOptions[key]);
                }
            }
            urlParams = urlParams.join('&');
        }
        if (provider === 'youtube' && videoId !== null) {
            urlParams = urlParams.length > 0 ? urlParams + '&enablejsapi=1&rel=0' : 'enablejsapi=1&rel=0';
            iframe.src = options.viewer.network.resolveProtocol('//www.youtube.com/embed/' + videoId + '?' + urlParams);
            iframe.classList.add('cylindo-video-youtube');
        }
        else if (provider === 'vimeo' && videoId !== null) {
            iframe.src = options.viewer.network.resolveProtocol('//player.vimeo.com/video/' + videoId + '?' + urlParams);
            iframe.classList.add('cylindo-video-vimeo');
        }
        else {
            loadFallbackVideoImage.call({
                img: img,
                imageSrc: options.viewer.model.get('fallbackVideoImage')
            });
        }
        iframe.onload = loadIframe.bind({
            iframe: iframe,
            li: options.li,
            videoId: videoId,
            id: elementId,
            provider: provider,
            viewer: options.viewer,
            autoplay: autoplay,
            deferred: deferred
        });
        img.onload = onIframeVideoError.bind({
            li: options.li,
            iframe: iframe,
            img: img,
            deferred: deferred
        });
        loadElement.call({
            el: iframe,
            li: options.li
        });

        return deferred;
    }

    function loadIframe() {
        var options = {
            deferred: this.deferred,
            li: this.li,
            id: this.id,
            videoId: this.videoId,
            provider: this.provider,
            viewer: this.viewer,
            autoplay: this.autoplay,
        };

        if (this.videoId && this.id) {
            videoPlayer.create(options);
        }
    }

    function loadElement() {
        if (typeof this.elementToRemove !== 'undefined') {
            this.li.removeChild(this.elementToRemove);
        }

        this.li.appendChild(this.el);
        if (typeof this.deferred !== 'undefined') {
            this.deferred.resolve();
        }
    }

    function loadFallbackVideoImage() {
        if (typeof this.element !== 'undefined') {
            this.element.onerror = null;
        }
        if (this.li && this.li.classList) {
            this.li.classList.add('cylindo-video-not-found');
        }
        this.img.src = this.imageSrc;
        this.img.alt = "Fallback video image";
    }

    function onIframeVideoError() {
        this.li.removeChild(this.iframe);
        this.li.appendChild(this.img);
        this.deferred.resolve();
    }

    function executeCommon(index, img, deferred, options) {
        var className = options.baseClassName + index;
        var imgScope, timeout;

        options.li.classList.add(className);
        try {
            options.li.dataset.index = index;
        }
        catch (ex) {
            options.li.setAttribute('data-index', index);
        }

        if (options.imgTimeout > 0) {
            timeout = setTimeout(function () {
                options.onError.call(imgScope);
            }.bind(options.viewer), options.imgTimeout);
        }

        imgScope = {
            requestID: options.requestID,
            index: index,
            self: options.viewer,
            img: img,
            li: options.li,
            layers: options.layers,
            totalFrames: options.totalFrames,
            deferred: deferred,
            timeout: timeout
        };

        if (img instanceof Image) {
            if (img.addEventListener) {
                img.addEventListener('load', options.onImageLoad.bind(imgScope));
            }
            else if (img.attachEvent) {
                img.attachEvent('onload', options.onImageLoad.bind(imgScope));
            }
            img.onerror = options.onError.bind(imgScope);
        }
        else {
            options.onImageLoad.call(imgScope);
        }
    }


    function removeDraggableBehavior(img) {
        try {
            img.draggable = false;
            img.ondragstart = function () {
                return false;
            };
        }
        catch (ex) {
        }
    }

    window.cylindo.addModule('cylindo.render.strategy', renderStrategy);
}).call(this);
