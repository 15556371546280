(function () {
    'use strict';
    function ZoomBtn(opts) {
        var self = this;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        this.isMobile = opts.isMobile;
        this.model = opts.model;
        this.btn = opts.btn;
        this.viewerContainer = opts.viewerContainer;
        this.available = true;
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;
        this.addEventsToBtn = addEventsToBtn;
        this.removeEventsToBtn = removeEventsToBtn;
        this.ariaLabel = opts.ariaLabel;
        this.destroy = destroy;

        function addEventsToBtn(btn) {
            btn = btn || self.btn;
            if (btn) {
                removeEventsToBtn(btn);
                btn.addEventListener('mousedown', forwardClickEvt, true);
                btn.addEventListener('touchstart', forwardTouchStartEvt, true);
                btn.addEventListener('touchend', forwardTouchEndEvt, true);
            }
        }
        function removeEventsToBtn(btn) {
            btn = btn || self.btn;
            if (btn) {
                btn.removeEventListener('mousedown', forwardClickEvt, true);
                btn.removeEventListener('touchstart', forwardTouchStartEvt, true);
                btn.removeEventListener('touchend', forwardTouchEndEvt, true);
            }
        }
        function forwardClickEvt(evt) {
            prepareViewerEvents(evt);
            self.trigger(self.events.CLICK, evt);
        }
        function forwardTouchStartEvt(evt) {
            prepareViewerEvents(evt);
            self.trigger(self.events.TOUCHSTART, evt);
        }
        function forwardTouchEndEvt(evt) {
            prepareViewerEvents(evt);
            self.trigger(self.events.TOUCHEND, evt);
        }
        function prepareViewerEvents(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();
            }
        }
        function destroy() {
            pubsub.destroy();
            self.btn = null;
            self.trigger(self.events.DESTROYED);
        }
        self.shouldHideButton();
        self.initialized = true;
    }
    ZoomBtn.prototype.constructor = ZoomBtn;
    ZoomBtn.prototype.isAvailable = function () {
        return this.available;
    };
    ZoomBtn.prototype.shouldHideButton = function () {
        if ((this.model && this.model.get('zoomButton') !== true) ||
            !this.isMobile ||
            (!this.model.get('cylindoContent') && !this.model.get('alternateContentZoom'))) {
            this.available = false;
            this.btn.classList.add('permanently-hidden');
        }
    };
    ZoomBtn.prototype.setBtn = function (btn) {
        this.btn = btn;
    };
    ZoomBtn.prototype.addBlinkClass = function () {
        this.btn.classList.add('blink');
    };
    ZoomBtn.prototype.removeBlinkClass = function () {
        setTimeout(function () {
            if (this.btn &&
                this.btn.classList &&
                this.btn.classList.contains('blink'))
            {
                this.btn.classList.remove('blink');
            }
        }.bind(this), 3200);
    };
    ZoomBtn.prototype.setZoomInIcon = function () {
        this.btn.setAttribute('aria-label', this.ariaLabel.texts.ENTER_ZOOM);
        this.btn.classList.remove('cylindo-icon-zoom-on');
        this.btn.classList.remove('cylindo-icon-zoom-out');
        this.btn.classList.add('cylindo-icon-zoom-off');
    };
    ZoomBtn.prototype.setZoomOutIcon = function (addZoomOutClass) {
        this.btn.setAttribute('aria-label', this.ariaLabel.texts.EXIT_ZOOM);
        this.btn.classList.remove('cylindo-icon-zoom-off');
        this.btn.classList.add('cylindo-icon-zoom-on');
        if (addZoomOutClass) {
            this.btn.classList.add('cylindo-icon-zoom-out');
        }
    };
    ZoomBtn.prototype.show = function () {
        this.btn.style.display = 'block';
    };
    ZoomBtn.prototype.hide = function () {
        this.btn.style.display = 'none';
    };
    ZoomBtn.prototype.events = {
        CLICK: 'zoom:click',
        TOUCHSTART: 'zoom:touchstart',
        TOUCHEND: 'zoom:touchend',
        DESTROYED: 'zoom:destroyed',
    };
    var publicAPI = {
        create: function (opts) {
            return new ZoomBtn(opts);
        }
    };
    window.cylindo.addModule('cylindo.zoom.btn', publicAPI);
}.call(this));

