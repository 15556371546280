(function () {
    'use strict';
    var Model = function (configuration, _tags, _framesHandler) {
        var self = this;
        var altRequestID = 1;
        var dao = cylindo.getModule('DAO');
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var logger = window.cylindo.getModule('cylindo.util.logger');
        var util = cylindo.getModule('cylindo.core.util');
        var settings = cylindo.getModule('cylindo.core.config');
        var network = cylindo.getModule('cylindo.core.network');
        var modelValues = cylindo.getModule('cylindo.model.values');
        var viewerPresentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
        var tags = _tags;
        var framesHandler = _framesHandler;
        var currentDataStatus = {
            requestID: 0
        };
        var ARBannerSupportedKeys = {
            IOS: 'ios',
            ANDROID: 'android',
            BOTH: 'ios-android'
        };
        var ARBannerSupportedValues = [
            ARBannerSupportedKeys.IOS,
            ARBannerSupportedKeys.ANDROID,
            ARBannerSupportedKeys.BOTH
        ];

        var thumbLocations = modelValues.getThumbLocations();

        var defaults = modelValues.getDefaults();

        var booleans = modelValues.getBooleans();
        var arrays = modelValues.getArrays();

        var notCfgAtRuntime = modelValues.getNotCfgAtRuntime();

        var allowedPresentations = modelValues.getAllowedPresentations();

        var viewerdata = null;
        var previousFeatures= [];
        var canceledFeatures = [];
        var previousProductCode = null;
        var newFeatures = null;

        var customBackgroundColor = false;
        var i;
        var ARHttpsError = "AR button will not be displayed because HD 360 Viewer is not served over a https protocol.";
        var ARConfigError = "'ARBannerConfig' has not been configured properly";
        var a = null;
        var canSetFeatures = false;
        var productCodeAsFileName = false;
        var firstSetOfFt = true;
        var firstFeaturesFailed = false;
        var arModes = cylindo.getModule('cylindo.helpers.ar.modes');
        var screenWidth = screen && screen.width ? screen.width : null;

        for (i = 0; i < booleans.length; i++) {
            if (configuration.hasOwnProperty(booleans[i])) {
                configuration[booleans[i]] = parseBoolean(configuration[booleans[i]]);
            }
        }
        for (i = 0; i < arrays.length; i++) {
            if (configuration.hasOwnProperty(arrays[i]) &&
                configuration[arrays[i]] instanceof Array) {
                configuration[arrays[i]] = configuration[arrays[i]].slice();
            }
        }
        configuration.productCode = configuration.productCode ? configuration.productCode.toUpperCase() : null;
        configuration.productCode = configuration.productCode ? decodeURIComponent(configuration.productCode) : null;
        previousProductCode = configuration.productCode;
        if (configuration.hasOwnProperty('features')) {
            if (!configuration.features || !(configuration.features instanceof Array)) {
                configuration.features = [];
            }
            if (configuration.features && configuration.features.length) {
                for (i = 0; i < configuration.features.length; i++) {
                    configuration.features[i] = decodeURIComponent(String(configuration.features[i])).toUpperCase();
                }    
            }
            previousFeatures = configuration.features;
        }
        if (!configuration.fileName && configuration.productCode) {
            productCodeAsFileName = true;
            configuration.fileName = configuration.productCode;
        }
        if (typeof configuration.fallbackImage !== 'string' ||
            (typeof configuration.fallbackImage === 'string' && configuration.fallbackImage.length === 0)) {
            delete configuration.fallbackImage;
        }
        if (configuration.fallbackImage) {
            configuration.fallbackImage = network.resolveProtocol(configuration.fallbackImage);
        }
        if (typeof configuration.tooltipDragText !== 'undefined') {
            configuration.tooltipDragTextFixed = true;
        }
        if (typeof configuration.zoomBackgroundColor !== 'undefined') {
            configuration.zoomBackgroundColor = typeof configuration.zoomBackgroundColor === 'string' ? configuration.zoomBackgroundColor : '';
        }
        if (typeof configuration.backgroundColor !== 'undefined') {
            customBackgroundColor = true;
            configuration.backgroundColor = typeof configuration.backgroundColor === 'string' ? configuration.backgroundColor : '';
            if (typeof configuration.zoomBackgroundColor === 'undefined') {
                configuration.zoomBackgroundColor = configuration.backgroundColor;
            }
        }
        if (configuration.zoomBackgroundColor === '00000000') {
            configuration.zoomBackgroundColor = '';
        }
        if (typeof configuration.environmentZoom !== 'undefined') {
            if (typeof configuration.alternateContentZoom === 'undefined') {
                configuration.alternateContentZoom = configuration.environmentZoom;
            }
        }
        if (typeof configuration.startFrame !== 'undefined') {
            if ((typeof configuration.startFrame !== 'number' ||
                typeof configuration.startFrame < 1 ||
                isNaN(configuration.startFrame))) {
                configuration.startFrame = 1;
            }
            configuration.startFrame = parseInt(configuration.startFrame);
        }
        if (typeof configuration.presentation !== 'undefined' &&
            allowedPresentations.indexOf(configuration.presentation) === -1) {
            configuration.presentation = viewerPresentations.THREESIXTY.NAME;
        }
        if (typeof configuration.presentation === viewerPresentations.CUSTOM.NAME) {
            configuration.cylindoContent = false;
        }
        if (typeof configuration.proxy !== 'undefined' && typeof configuration.proxy !== 'string') {
                configuration.proxy = '';
        }
        if (typeof configuration.frames !== 'undefined') {
            if (configuration.frames instanceof Array &&
                configuration.frames.length > 0) {
                configuration.framesSetOnInit = true;
            }
            else {
                configuration.frames = null;
            }
        }
        if (typeof configuration.carouselSlideSpeed !== 'undefined') {
            configuration.carouselSlideSpeed = parseFloat(configuration.carouselSlideSpeed, 10);
            configuration.carouselSlideSpeed = !isNaN(configuration.carouselSlideSpeed) ? configuration.carouselSlideSpeed : 100;
        }
        if (configuration.presentation === 'stacked') {
            configuration.thumbs = false;
        }
        if (typeof configuration.tooltipStackedPosition !== 'undefined' &&
            typeof configuration.tooltipStackedPosition !== 'number') {
            configuration.tooltipStackedPosition = 1;
        }
        if (typeof configuration.format !== 'undefined') {
            if (configuration.format.toUpperCase() !== 'PNG' && configuration.format.toUpperCase() !== 'JPG') {
                configuration.format = 'JPG';
            }
            configuration.format = configuration.format.toUpperCase();
        }
        if (typeof configuration.speedMultiplier !== 'undefined' &&
            (isNaN(configuration.speedMultiplier) || configuration.speedMultiplier < 1)) {
            configuration.speedMultiplier = 1.2;

        }
        if (typeof configuration.tooltipZoomText !== 'undefined') {
            configuration.tooltipZoomTextFixed = true;
        }
        if (typeof configuration.zoomMode !== 'undefined') {
            if ((configuration.zoomMode !== 'mouseMove' && configuration.zoomMode !== 'mouseDrag')) {
                configuration.zoomMode = 'mouseMove';
            }
            if (configuration.zoomMode === 'mouseDrag' &&
                typeof configuration.tooltipZoomText === 'undefined') {
                configuration.tooltipZoomText = 'Drag to Pan';
            }
        }
        if (configuration.ARDesktop !== true) {
            configuration.ARDesktop = false;
        }
        if (configuration.ARQuickLook !== false) {
            if (util.browser.isSceneViewerCandidate()) {
                if (window.location.protocol !== 'https:') {
                    configuration.ARQuickLook = false;
                    throwModelErr(ARHttpsError);
                }
                configuration.arMode = arModes.SCENE_VIEWER;
            }
            else if (util.browser.isARQuickLookCandidate()) {
                configuration.arMode = arModes.ARQUICKLOOK;
            }
            else if (configuration.ARDesktop && !util.browser.isMobile()) {
                configuration.arMode = arModes.QR;
            }
            else {
                configuration.ARQuickLook = false;
                configuration.arMode = null;
            }
        }
        if (ARBannerSupportedValues.indexOf(configuration.ARBanner) === -1) {
            configuration.ARBanner = 'none';
            configuration.ARBannerConfig = null;
        }
        else {
            configuration.ARBannerConfig = configuration.ARBannerConfig || {};
        }
        if (typeof configuration.ARBanner === 'string' &&
            typeof configuration.ARBannerConfig !== 'undefined') {
            if (configuration.arMode === null ||
                typeof configuration.ARBannerConfig !== 'object' ||
                configuration.ARBannerConfig === null) {
                configuration.ARBannerConfig = null;
                throwModelErr(ARConfigError);
            }
            else {
                configuration.ARBannerConfig = {
                    qrAction: configuration.ARBannerConfig.qrAction || 'Visit',
                    action: configuration.ARBannerConfig.action || 'Visit',
                    androidTitle: configuration.ARBannerConfig.androidTitle || '',
                    title: configuration.ARBannerConfig.title ||  configuration.productCode,
                    subtitle: configuration.ARBannerConfig.subtitle || configuration.productCode,
                    price: configuration.ARBannerConfig.price ? configuration.ARBannerConfig.price.toString() : null,
                };
                configuration._ARBannerConfig = configuration.ARBannerConfig;
                if (configuration.arMode === arModes.ARQUICKLOOK && 
                    (configuration.ARBanner === ARBannerSupportedKeys.IOS  || configuration.ARBanner === ARBannerSupportedKeys.BOTH)) {
                    configuration.ARBannerConfig = {
                        qrCallToAction: configuration.ARBannerConfig.qrAction,
                        callToAction: configuration.ARBannerConfig.action,
                        checkoutTitle: configuration.ARBannerConfig.title,
                        checkoutSubtitle: configuration.ARBannerConfig.subtitle,
                        price: configuration.ARBannerConfig.price,
                        canonicalWebPageURL: configuration.ARBannerConfig.link,
                    };
                }
                else if (configuration.arMode === arModes.SCENE_VIEWER) {
                    if (configuration.ARBanner === ARBannerSupportedKeys.ANDROID  || configuration.ARBanner === ARBannerSupportedKeys.BOTH) {
                        configuration.ARBannerConfig = {
                            title: configuration.ARBannerConfig.androidTitle || configuration.ARBannerConfig.title,
                        };
                    }
                    else {
                        configuration.ARBannerConfig = {};
                    }
                }
            }
        }

        if (typeof configuration.zoomOverlapsThumbs !== 'undefined' &&
            typeof configuration.zoomOverlapsThumbs !== 'boolean') {
            configuration.zoomOverlapsThumbs = false;
        }
        if (typeof configuration.thumbLocation !== 'undefined' &&
            configuration.thumbLocation !== thumbLocations.BOTTOM &&
            configuration.thumbLocation !== thumbLocations.TOP &&
            configuration.thumbLocation !== thumbLocations.LEFT &&
            configuration.thumbLocation !== thumbLocations.RIGHT) {
            configuration.thumbLocation = thumbLocations.BOTTOM;
        }
        if (typeof configuration.maxZoom !== 'undefined') {
            configuration.maxZoom = String(configuration.maxZoom).toLocaleLowerCase();
            if (['mixed', '4k', '2k'].indexOf(configuration.maxZoom) === -1) {
                delete configuration.maxZoom;
            }
        }
        if (typeof configuration.getArRedirectUrl !== 'function') {
            configuration.getArRedirectUrl = null;
        }
        viewerdata = util.object.extend(true, {}, defaults, configuration);
        if (viewerdata.cylindoContent &&
            viewerdata.accountID &&
            viewerdata.productCode) {
            viewerdata.BUSAPIImage = network.resolveProtocol(settings.get('urls').BUSAPI);
            viewerdata.BUSAPIImage = viewerdata.BUSAPIImage + [viewerdata.accountID, viewerdata.productCode, viewerdata.productCode + viewerdata.BUSAPIFrame + '.' + viewerdata.BUSAPIExtension].join('/');
        }

        this.id = ++Model.counter;
        this.isValid = function () {
            return true;
        };
        this.getRequestID = function () {
            return currentDataStatus.requestID;
        };
         this.updateRequestID = function () {
            ++currentDataStatus.requestID;
        };
        this.getAltRequestID = function () {
            return altRequestID;
        };

        this.prepareToFetchFeatures = function () {
            var logger = this.getLogger();
            dao = dao.create({
                logger: logger,
                currentDataStatus: currentDataStatus,
                viewerData: viewerdata,
                framesHandler: framesHandler,
                tags: tags,
            });
            self.on(self.events.FEATURES_ERROR, enableSetFeatures);
            self.on(self.events.FEATURES_CHANGED, enableSetFeatures);
        };
        this.fetchFeatures = function () {
            if (!viewerdata || !viewerdata.cylindoContent) { 
                return;
            }
            if (viewerdata.size) {
                if (typeof viewerdata.size === 'number' && !isNaN(viewerdata.size)) {
                    if (Math.floor(viewerdata.size) !== viewerdata.size) {
                        logger.warning('The resolution value must be an integer. Any decimal points will be ignored.');
                        viewerdata.size = Math.floor(viewerdata.size);
                    }
                }
                else {
                    viewerdata.size = null;
                    logger.error('The image resolution should be a numeric value.');
                }
            }
            dao.fetchFeatures(viewerdata).then(onFeatureSuccess, onFeatureError);
        };

        this.get = function (field) {
            var loggerProps = ['debug', 'loggerLimit'];
            if (viewerdata.hasOwnProperty(field)) {
                return viewerdata[field];
            }
            else {
                if (loggerProps.indexOf(field) !== -1) {
                    try {
                        if (console.warn) {
                            console.warn("Unknown property: '" + field + "'");
                        }
                        else {
                            console.log("Unknown property: '" + field + "'");
                        }
                    } catch (ex) {
                    }
                }
                else {
                    logger.warning("Unknown property: '" + field + "'");
                }
                return null;
            }
        };

        this.set = function (field, value, preventChangeEvent, allowChangeFeatures) {
            var i;
            var tmpPreviousFeatures;
            value = value && value instanceof Array ? value.slice() : value;
            allowChangeFeatures = allowChangeFeatures ? true : false;
            preventChangeEvent = preventChangeEvent || false;
            logger.log("Trying to set: '" + field + "' to: ", value);
            if (booleans.includes(field)) {
                value = parseBoolean(value);
            }
            if (notCfgAtRuntime.includes(field)) {
                logger.log("The property '" + field + "' is not configurable at runtime.");
                return;
            }
            if (field === 'zoomBackgroundColor' && value === '00000000') {
                value = '';
            }
            if (viewerdata.hasOwnProperty(field)) {
                if (field === 'productCode') {
                    value = value ? value : null;
                    value = value ? decodeURIComponent(value) : null;
                    if (!value || value === previousProductCode) {
                        logger.error("Could not update the productCode with the provided value.", value);
                        return;
                    }
                    viewerdata[field] = value;
                    if (viewerdata && viewerdata.currentFeatures) {
                        viewerdata.currentFeatures.data = null;
                    }
                    if (productCodeAsFileName) {
                        viewerdata.fileName = value;
                    }
                    tmpPreviousFeatures = previousFeatures;
                    newFeatures = null;
                    tags.updateProductCode(value);
                    self.set('features', tmpPreviousFeatures, false, true);

                    previousProductCode = value;
                    return;
                }
                if (field === 'features') {
                    if (!value) {
                        value = [];
                    }
                    if (!(value instanceof Array)) {
                        logger.error("When viewer has been configured to use features an array or null values should be provided to configure the features.", value);
                        return;
                    }
                    for (i = 0; i < value.length; i++) {
                        value[i] = decodeURIComponent(String(value[i]).toUpperCase());
                    }    
                    if (canSetFeatures || allowChangeFeatures) {
                        if (areSameFeatures(field, value)) {
                            logger.warning("Features set ignored because is the same than the displayed.", value);
                            return;
                        }
                        if (allowChangeFeatures) {
                            canSetFeatures = true;
                        }
                        else {
                            canSetFeatures = false;
                        }
                    }
                    else {
                        logger.warning("Can not process set these features at the moment. This set of features: '" + value + "' will be processed until the earlier features has been resolved.");
                        newFeatures = value;
                        return;
                    }
                }
                if (field === 'alternateContent') {
                    ++altRequestID;
                    this.updateAlternateContent(value);
                    return;
                }
                if (field === 'ARBannerConfig') {
                    if (ARBannerSupportedValues.indexOf(viewerdata.ARBanner) === -1) {
                        viewerdata.ARBanner = 'none';
                        value = null;
                    }
                    else {
                        value = value || {};
                    }
                    if (typeof viewerdata.ARBanner === 'string' &&
                        typeof value === 'object') {
                        if (viewerdata.arMode === null ||
                            typeof value !== 'object' ||
                            value === null) {
                            value = null;
                            throwModelErr(ARConfigError);
                        }
                        else {
                            value = {
                                qrAction: value.qrAction || 'Visit',
                                action: value.action || 'Visit',
                                androidTitle: value.androidTitle || '',
                                title: value.title ||  viewerdata.productCode,
                                subtitle: value.subtitle || '',
                                price: value.price ? value.price.toString() : null,
                            };
                            viewerdata._ARBannerConfig = value;
                            if (viewerdata.arMode === arModes.ARQUICKLOOK && 
                                (viewerdata.ARBanner === ARBannerSupportedKeys.IOS  || viewerdata.ARBanner === ARBannerSupportedKeys.BOTH)) {
                                value = {
                                    qrCallToAction: value.qrAction,
                                    callToAction: value.action,
                                    checkoutTitle: value.title,
                                    checkoutSubtitle: value.subtitle,
                                    price: value.price,
                                    canonicalWebPageURL: value.link,
                                };
                            }
                            else if (viewerdata.arMode === arModes.SCENE_VIEWER) {
                                if (viewerdata.ARBanner === ARBannerSupportedKeys.ANDROID  || viewerdata.ARBanner === ARBannerSupportedKeys.BOTH) {
                                    value = {
                                        title: value.title,
                                    };
                                }
                                else {
                                    value = {};
                                }
                            }
                        }
                    }
                    else {
                        value = null;
                    }
                    this.trigger(this.events.AR_CONFIG_CHANGED, { prop: field, value: value });
                }
                viewerdata[field] = value;
                update(field);
                if (viewerdata &&
                    viewerdata.currentFeatures &&
                    field !== 'features' &&
                    !preventChangeEvent) {
                    viewerdata.currentFeatures.paths = dao.updatePaths(util.object.extend(true, {}, viewerdata));
                    viewerdata.currentFeatures.data.image = null;
                }
                if (framesHandler && framesHandler.initialized) {
                    framesHandler.cleanCache();
                }
                if (field !== 'features' && !preventChangeEvent) {
                    this.trigger(this.events.CHANGED, { prop: field, value: value });
                }
            }
        };

        this.updateAlternateContent = function (altOrFramesArr, preventChangeEvent) {
            var field = 'alternateContent';
            var result = {};
            if (framesHandler && framesHandler.initialized) {
                framesHandler.cleanCache();
            }
            if (framesHandler &&
                framesHandler.initialized &&
                framesHandler.reordered) {
                result = framesHandler.prepareToUpdateAltContent(altOrFramesArr);
                if (result && result.canUpdate) {
                    framesHandler.prepareToReorder();
                    logger.log('updateAlternateContent - Trying to set frames to', result.frames);
                    logger.log('updateAlternateContent - Trying to set alternateContent to', result.alternateContent);
                    viewerdata.alternateContent = result.alternateContent;
                    viewerdata.frames = result.frames;
                    if (!preventChangeEvent) {
                        this.trigger(this.events.CHANGED, { prop: field, value: result.alternateContent, useFramesToReorder: result.useFramesToReorder });
                    }
                }
                else {
                    logger.log('alternateContent could not be updated.');
                }
            }
            else {
                viewerdata.alternateContent = altOrFramesArr;
                logger.log('Content has not been reordered yet, alternate content will be updated but frames will not be updated.');
            }
        };

        this.on = pubsub.on;

        this.once = pubsub.once;

        this.off = pubsub.off;

        this.trigger = pubsub.trigger;

        this.destroy = function () {
            viewerdata = {};
            pubsub.destroy();
            if (dao && typeof dao.destroy === 'function') {
                dao.destroy();
            }
        };

        this.getLogger = function () {
            return logger;
        };

        this.getCanceledFeatures = function () {
            return canceledFeatures || [];
        };

        this.isCustomBackgroundColor = function () {
            return customBackgroundColor;
        };

        this.getThumbLocations = function () {
            return thumbLocations;
        };
        this.getResolvedFeatures = function (cb) {
            if (dao && typeof dao.getResolvedFeatures === 'function') {
                dao.getResolvedFeatures(viewerdata, cb);
            }
        };
        this.isFirstSetOfFt = function() {
            return firstSetOfFt;
        };
        this.didFirstFeaturesFailed = function() {
            return firstFeaturesFailed;
        };
        function areSameFeatures(field, value) {
            var i;
            if (previousFeatures.length !== value.length) {
                return false;
            }
            for (i = 0; i < value.length; i++) {
                if (previousFeatures[i] !== value[i]) {
                    return false;
                }
            }
            if (previousProductCode !== viewerdata.productCode) {
                return false;
            }
            return true;
        }

        function update(field) {
            var value;
            if (field === 'features') {
                value = viewerdata.features;
                logger.log('Features has been changed.', previousFeatures, value);
                canceledFeatures = previousFeatures;
                previousFeatures = viewerdata.features.slice(0);
                firstSetOfFt = false;
                self.fetchFeatures();
                tags.updateFeatures(value);
                tags.send(tags.events.FEATURE_CHANGED);
            }
        }

        function parseBoolean(value) {
            if (value === 'true' || value === true) {
                return true;
            }
            else {
                return false;
            }
        }
        function onFeatureSuccess(obj) {
            var data = obj.data;
            var paths = obj.paths;
            var ftToBeShown = obj.ftToBeShown;
            if (!viewerdata.currentFeatures) {
                viewerdata.currentFeatures = {
                    data: data,
                    paths: paths,
                };
            }
            else {
                viewerdata.currentFeatures.data = data;
                viewerdata.currentFeatures.paths = paths;
            } 

            viewerdata.frameCount = data.frameCount;
            viewerdata.hasUnsupportedFeatures = false;
            viewerdata.ftToBeShown = ftToBeShown;
            firstFeaturesFailed = (firstSetOfFt && obj && obj.firstFeaturesFailed)  || false;
            self.trigger(self.events.FEATURES_NO_WARNING, viewerdata);
            self.trigger(self.events.FEATURES_CHANGED, obj);
        }
        function onFeatureError(errorObj) {
            var features = self.get('features');
            if (errorObj && errorObj.error) {
                logger.error('Could not set features with the provided array.', features, JSON.stringify(errorObj));
            }
            viewerdata.hasUnsupportedFeatures = true;
            self.trigger(self.events.FEATURES_ERROR, errorObj);
            tags.send(tags.events.FEATURE_ERROR);
            tags.send(tags.events.ERROR_LOADING, {
                error_description: 'Set of features could not be resolved.', 
            });
        }
        function enableSetFeatures() {
            logger.log('Enabled to set features.');
            canSetFeatures = true;
            if (newFeatures instanceof Array || typeof newFeatures === 'string') {
                logger.log("Retrying to change feature set: " + newFeatures);
                self.set('features', newFeatures);
                newFeatures = null;
            }
        }
        function throwModelErr() {
            try {
                if (console.warn) {
                    console.warn(msg);
                }
                else {
                    console.log(msg);
                }
            } catch (ex) { }
        }
        logger = logger.create(this);
    };

    Model.counter = 0;

    Model.prototype.events = {
        FEATURES_CHANGED: 'features:changed',
        FEATURES_ERROR: 'features:error',
        FEATURES_NO_WARNING: 'features:nowarning',
        ALL_FEATURES_LOADED: 'features:all:loaded',
        CHANGED: 'model:changed',
        AR_CONFIG_CHANGED: 'ar:config:changed',
    };

    var publicAPI = {
        create: function (config, tags, framesHandler) {
            return new Model(config, tags, framesHandler);
        }
    };

    window.cylindo.addModule('model', publicAPI);
}).call(this);
