(function () {
    'use strict';
    var moduleKey = 'cylindo.core.util';
    var util = window.cylindo.getModule(moduleKey) || {};
    util.promise = {
        create: create
    };
    window.cylindo.addModule(moduleKey, util);
    function create() {
        var promise;
        var resolveFn;
        var rejectFn;
        promise = new Promise(function (resolve, reject) {
            resolveFn = resolve;
            rejectFn = reject;
        });
        promise.resolve = resolveFn;
        promise.reject = rejectFn;
        return promise;
    }
}).call(this);