(function () {
    'use strict';

    var Direct = function (options) {
        var util = cylindo.getModule('cylindo.core.util');
        this.deferred = util.promise.create();
        this.id = options.id;
        this.player = options.video;
        this.createPlayer();
    };

    Direct.prototype.createPlayer = function() {
        this.deferred.resolve();
    };

    Direct.prototype.play = function () {
        this.player.play();
    };

    Direct.prototype.stop = function () {
    };

    Direct.prototype.pause = function () {
        this.player.pause();
    };

    Direct.prototype.destroy = function () {
    };

    var publicAPI = {
        create: function (opts) {
            return new Direct(opts);
        }
    };

    window.cylindo.addModule('cylindo.video.direct', publicAPI);
}).call(this);