(function () {
    'use strict';
    var FallbackController = function (options) {
        var opts = options;
        var util = cylindo.getModule('cylindo.core.util');
        var network = cylindo.getModule('cylindo.core.network');
        var settings = cylindo.getModule('cylindo.core.config');
        var capi = cylindo.getModule('cylindo.classes.capi');
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var fallbackImage = opts.fallbackImage || settings.get('urls').DEFAULT_FALLBACK_IMAGE;
        var defaultFallbackImage = settings.get('urls').DEFAULT_FALLBACK_IMAGE;
        var BUSAPIImage = '';
        var BUSAPIFrame = '01';
        var BUSAPIExtension = 'jpg';
        var cylindoContent = opts.cylindoContent === false ? false : true;
        var accountID = opts.accountID;
        var productCode = opts.productCode;
        if (cylindoContent && accountID && productCode) {
            BUSAPIImage = network.resolveProtocol(settings.get('urls').BUSAPI);
            BUSAPIImage = BUSAPIImage + [accountID, productCode, productCode + BUSAPIFrame + '.' + BUSAPIExtension].join('/');
        }

                this.isFallbackController = true;

                this.on = pubsub.on;
        this.trigger = pubsub.trigger;
        this.off = pubsub.off;

        this.show = function () {
            var container = null;
            var containerRect = null;
            var width = null;
            var urls = null;
            var capiImgUrl = null;
            try {
                container = document.getElementById(opts.containerID);
                containerRect = container.getBoundingClientRect();
                urls = settings.get('urls');
                if (containerRect) {
                    width = Math.round(containerRect.width);
                    if (opts.fallbackImage) {
                        embedFallbackImage(opts.fallbackImage);
                    }
                    else {
                        if (capi && (opts.features)) {
                            if (opts.version) {
                                capiImgUrl = capi.getUrlV2(opts.accountID, opts.productCode, 1, width, opts.features, opts.removeEnvironmentShadow, opts.proxy, (window.location.protocol === 'https:'), opts.version);
                            }
                            else {
                                capiImgUrl = capi.getUrlV2(opts.accountID, opts.productCode, 1, width, opts.features, opts.removeEnvironmentShadow, opts.proxy, (window.location.protocol === 'https:'));
                            }
                            getUrlComplete(capiImgUrl);
                        }
                        else {
                            console.log('Failed to load custom fallback image or CAPI image, viewer is triying to use BUSAPI or default fallback image instead.');
                            embedDefaultFallbackImage();
                        }
                    }
                }
                else {
                    throw 'no container defined. Please provide a container ID to embed the 360 HD Viewer into';
                }
            }
            catch (ex) {
                console.log(ex);
                embedDefaultFallbackImage();
            }
            function getUrlComplete(url) {
                if (url) {
                    loadImage(url);
                }
                else {
                    console.log('Could not get fallback image from CAPI');
                    embedDefaultFallbackImage();
                }
            }
        };

        this.destroy = function () {
            pubsub.destroy();
            this.trigger(this.events.DESTROYED);
        };


        function loadImage(imageURL) {
            var requestOpts = {
                type: 'GET'
            };

            network.doRequest(imageURL, onSuccess, requestOpts, onError, null);
            function onSuccess(response, xhr) {
                if (imageURL !== null) {
                    embedFallbackImage(imageURL);
                }
            }
            function onError(response, xhr) {
                if (xhr.status == 429) {
                    console.log('request was throttled');
                }
                embedDefaultFallbackImage();
            }
        }
        function embedDefaultFallbackImage() {
            embedFallbackImage(BUSAPIImage || defaultFallbackImage);
        }
        function embedFallbackImage(url) {
            var mainEl;
            var childNodesArrLen;
            var fbImage = new Image();
            url = network.resolveProtocol(url);
            if (url) {
                mainEl = document.getElementById(opts.containerID);
                fbImage.id = 'image';
                if (url === BUSAPIImage || url === defaultFallbackImage) {
                    fbImage.onerror = util.fallback.fallbackFail.bind({
                        fallbackImage: network.resolveProtocol(fallbackImage),
                        defaultFallbackImage: network.resolveProtocol(defaultFallbackImage),
                        BUSAPIImage: network.resolveProtocol(BUSAPIImage),
                        img: fbImage
                    });
                }
                else {
                    fbImage.onerror = util.fallback.loadFallbackImage.bind({
                        img: fbImage,
                        config: {
                            fallbackImage: fallbackImage,
                            defaultFallbackImage: defaultFallbackImage,
                            BUSAPIImage: BUSAPIImage
                        },
                        errorText: 'Fallback image not found: '
                    });    
                }
                if (util.browser.isIE()) {
                    fbImage.onload = function() {
                        this.style.width = this.naturalWidth + 'px';
                        this.style.height = this.naturalHeight + 'px';
                    };    
                }
                fbImage.src = url;
                childNodesArrLen = mainEl.childNodes.length;
                if (childNodesArrLen > 0) {
                    mainEl.childNodes[0].parentNode.insertBefore(fbImage, mainEl.childNodes[0]);
                }
                else {
                    mainEl.appendChild(fbImage);
                }

            }
            else {
                console.log('Embedding a fallback image requires a valid url');
            }
        }
    };

    FallbackController.prototype.events = {
        DESTROYED: 'instance:destroyed',
        VIEWER_READY: 'instance:viewer:ready',
        VIEWER_COMPLETE: 'instance:viewer:complete',
        FIRST_FRAME_READY: 'instance:firstframe:ready',
        THUMBS_READY: 'instance:thumbs:ready',
        THUMB_CLICKED: 'instance:viewer:thumbs:clicked',
        ALT_THUMBS_CLICKED: 'instance:alt:thumbs:clicked',
        ZOOM: 'instance:zoom',
        ZOOM_ENTER: 'instance:zoom:enter',
        ZOOM_EXIT: 'instance:zoom:exit',
        FULLSCREEN_ENTER: 'instance:fullscreen:enter',
        FULLSCREEN_EXIT: 'instance:fullscreen:exit',
        SPIN_BEGIN: 'instance:threesixty:start',
        SPIN_END: 'instance:threesixty:end',
        PREV: 'instance:carousel:prev',
        NEXT: 'instance:carousel:next',
        ERROR: 'instance:error',
        AR_BUTTON_READY: 'instance:ar:button:ready',
        AR_BUTTON_ERROR: 'instance:ar:button:error',
        AR_ACTION_BTN_TAPPED: 'instance:ar:action:btn:tapped',
        FEATURES_CANCELED: 'instance:features:canceled',
        FEATURES_ERROR: 'instance:features:error',
    };


    var publicAPI = {
        create: function (options) {
            return new FallbackController(options);
        }
    };
    window.cylindo.addModule('cylindo.core.fallback', publicAPI);
}).call(this);
