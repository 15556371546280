
(function () {
    'use strict';

    var moduleKey = 'cylindo.core.util';
    var util = window.cylindo.getModule(moduleKey) || {};
    var Browser = function () {
        var self = this;
        var ua = navigator.userAgent,
            t = true,
            ie = /(msie|trident)/i.test(ua),
            edge = /(edge)\/((\d+)?[\w\.]+)/i.test(ua),
            chrome = /chrome|crios/i.test(ua),
            phantom = /phantom/i.test(ua),
            iphone = /iphone/i.test(ua),
            ipad = /ipad/i.test(ua),
            touchpad = /touchpad/i.test(ua),
            silk = /silk/i.test(ua),
            safari = /safari/i.test(ua) && !chrome && !phantom && !silk,
            android = /android/i.test(ua),
            opera = /opera/i.test(ua) || /opr/i.test(ua),
            firefox = /firefox/i.test(ua),
            gecko = /gecko\//i.test(ua),
            seamonkey = /seamonkey\//i.test(ua),
            webkitVersion = /version\/(\d+(\.\d+)?)/i,
            firefoxVersion = /firefox\/(\d+(\.\d+)?)/i,
            chromeVersion = /(?:chrome|crios)\/(\d+(\.\d+)?)/i,
            iosOSVersion = /OS (\d+)_(\d+)_?(\d+)?/i,
            oculus = /OculusBrowser/i.test(ua),
            oculusVersion = /OculusBrowser\/(\d+(\.\d+)?)/i,
            o;
        var dpi = null;

        this.isEdge = function () {
            if (this.browser.msedge) {
                return true;
            }
        };
        this.isIE = function () {
            if (this.browser.msie) {
                return true;
            }
        };
        this.equalOrLessThanIE10 = function () {
            return (this.browser.msie && this.browser.version <= 10);
        };
        this.equalOrLessThanSafari6 = function () {
            return (!this.isMobile() &&
            this.isSafari() &&
            parseFloat(this.browser.version, 10) <= 6);
        };
        this.ff_true = function () {
            if (this.browser.firefox || this.browser.gecko) {
                return true;
            }
        };
        this.mac_true = function () {
            var macOSX = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
            if (macOSX) {
                return true;
            }
        };
        this.isChrome = function () {
            if (this.browser.chrome) {
                return true;
            }
            else {
                return false;
            }
        };
        this.chromeVersion = function () {
            if (this.browser.chrome) {
                return this.browser.version;
            }
            else {
                return null;
            }
        };
        this.isSafari = function () {
            if (this.browser.safari) {
                return true;
            }
            else {
                return false;
            }
        };
        this.isIOS = function () {
            if (this.browser.ios) {
                return true;
            }
            else {
                return false;
            }
        };
        this.getIOSVersion = function () {
            if (typeof this.browser.iosVersion !== 'undefined') {
                return this.browser.iosVersion;
            }
        };
        this.isSafariOnIOS = function () {
            if (this.browser.ios &&
                this.browser.isSafariOnIOS) {
                return true;
            }
            else {
                return false;
            }
        };
        this.isAndroid = function () {
            var tmpUA = ua.toLowerCase();
            if (tmpUA.indexOf('android') > -1 &&  tmpUA.indexOf('windows phone') === -1 ) {
                return true;
            }
            return false;
        };
        this.isAndroidMobile = function () {
            var tmpUA = ua.toLowerCase();
            if (self.isAndroid() && tmpUA.indexOf('mobile') > -1 ) {
                return true;
            }
            return false;
        };
        this.getAndroidVersion = function () {
            var tmpUA = ua.toLowerCase();
            var result, version;
            if (self.isAndroid()) {
                result = tmpUA.match(/\bandroid ; release\/([\d\.]+)/) || tmpUA.match(/\bandroid\s+([\d\.]+)/);
                version  = result && result[1] ? result[1] : null;
            }
            return version;
        };
        this.getDPI = function () {
            if (dpi) {
                return dpi;
            }

            try {
                dpi = window.devicePixelRatio;
            } catch (ex) {
                dpi = 1;
            }

            return dpi;
        };
        this.isMobile = function () {
            var mobile = false;
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                mobile = true;
            }
            return mobile;
        };

        this.isGSA = function () {
            return ua.match(/GSA\/(\d+)/g) ? true : false;
        };
         this.oculus_true = function () {
            if (this.browser.oculus) {
                return true;
            }
        };        
        this.isARQuickLookCandidate = function () {
            var isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window).MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
            var isWKWebView = window.webkit && window.webkit.messageHandlers ? true : false;
            var tempAnchor;
            if(isIOS){
                if(!isWKWebView){            
                    tempAnchor = document.createElement('a');
                    return tempAnchor.relList && tempAnchor.relList.supports && tempAnchor.relList.supports('ar') || false;
                }
                else if (this.isChrome() && parseFloat(this.chromeVersion(), 10) >= 87) {
                    return true;
                }
                else {
                    return  (/EdgiOS\/|FxiOS\/|GSA\/|DuckDuckGo\//).test(navigator.userAgent) ? true : false;
                }
            } 
            return false;
        };
         this.isSceneViewerCandidate = function () {
            return this.isAndroid() && !firefox && !oculus;
        };
        this.browser = detectBrowserVersion();
        function detectBrowserVersion() {

            if (ie) return {
                name: 'Internet Explorer',
                msie: t,
                version: ua.match(/(msie |rv:)(\d+(\.\d+)?)/i)[2]
            };

            if (edge) return {
                name: 'Microsoft Edge',
                msedge: t,
                version: ua.match(/(edge)\/((\d+)?[\w\.]+)/i)[2]
            };

            if (opera) return {
                name: 'Opera',
                opera: t,
                version: (ua.match(webkitVersion) || ua.match(webkitVersion) || ua.match(chromeVersion) || ua.match(/opr\/(\d+(\.\d+)?)/i))[1]
            };

            if (chrome) {
                o = {
                    name: 'Chrome',
                    webkit: t,
                    chrome: t,
                    version: ua.match(chromeVersion)[1],
                };

                o = addIOSDetails(o);
                return o;
            }

            if (phantom) return {
                name: 'PhantomJS',
                webkit: t,
                phantom: t,
                version: ua.match(/phantomjs\/(\d+(\.\d+)+)/i)[1]
            };

            if (touchpad) return {
                name: 'TouchPad',
                webkit: t,
                touchpad: t,
                version: ua.match(/touchpad\/(\d+(\.\d+)?)/i)[1]
            };

            if (silk) return {
                name: 'Amazon Silk',
                webkit: t,
                android: t,
                mobile: t,
                version: ua.match(/silk\/(\d+(\.\d+)?)/i)[1]
            };

            if (iphone || ipad) {
                o = {
                    name: iphone ? 'iPhone' : 'iPad',
                    webkit: t,
                    mobile: t,
                    ios: t,
                    iphone: iphone,
                    ipad: ipad
                };
                if (webkitVersion.test(ua)) {
                    o.version = ua.match(webkitVersion)[1];
                    o.iosVersion = ua.match(webkitVersion)[1];
                }
                else if (iosOSVersion.test(ua)) {
                    o.version = ua.match(iosOSVersion)[1];
                    o.iosVersion = ua.match(iosOSVersion)[1];
                }
                o.isSafariOnIOS = /safari/i.test(ua) ? true : false;

                return o;
            }

            if (android) return {
                name: 'Android',
                webkit: t,
                android: t,
                mobile: t,
                version: (ua.match(webkitVersion) || ua.match(firefoxVersion) || ua.match(chromeVersion))[1]
            };

            if (safari) return {
                name: 'Safari',
                webkit: t,
                safari: t,
                version: ua.match(webkitVersion)[1]
            };

            if (gecko) {
                o = {
                    name: 'Gecko',
                    gecko: t,
                    mozilla: t,
                    version: ua.match(firefoxVersion)[1]
                };

                if (firefox) {
                    o.name = 'Firefox';
                    o.firefox = t;
                }

                return o;
            }

            if (oculus) {
                return {
                    name: 'OculusBrowser',
                    oculus: t,
                    version: ua.match(oculusVersion)[1],
                };
            }

            if (seamonkey) {
                return {
                    name: 'SeaMonkey',
                    seamonkey: t,
                    version: ua.match(/seamonkey\/(\d+(\.\d+)?)/i)[1]
                };
            }
            function addIOSDetails(o) {
                if (iphone || ipad) {
                    o.isSafariOnIOS = false;
                    o.ios = true;
                    if (iosOSVersion.test(ua)) {
                        o.iosVersion = ua.match(iosOSVersion)[1];
                    }
                }
                return o;
            }

            return {};
        }
    };

    util.browser = new Browser();

    window.cylindo.addModule(moduleKey, util);
}).call(this);
